import {
  Box,
  Button,
  InlineGrid,
  InlineStack,
  Select,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { TFunction } from "i18next";
import { operationOptions, propertyOptions } from "Src/Constant/SelectOptions";
import { RuleRowsI } from "Src/Interface/@Profile";
import { useProfileContext } from "../../ProfileProvider";
import { disabledRuleGroupOperators } from "Src/Constant/helpData";
import CustomMultiSelect from "./CustomMultiSelect";
import { useRoutesContext } from "Src/Component/RoutesProvider";

const ConditionRow = ({
  property,
  operator,
  value,
  ruleRowValues,
  profileOptions,
  index,
  length,
  t,
  deleteRow,
  updateRow,
  fetchProducts,
}: {
  ruleRowValues: string[];
  index: number;
  length: number;
  t: TFunction<"translation", undefined>;
  profileOptions: { [key: string]: string[] };
  deleteRow: (index: number) => void;
  updateRow: (
    index: number,
    type: string,
    {
      key,
      value,
    }:
      | { key: "property" | "operator"; value: string }
      | { key: "value"; value: string[] }
  ) => void;
  fetchProducts: () => void;
} & RuleRowsI) => {
  const {mdUp} = useBreakpoints();
  const { error, saveError } = useProfileContext();
  const { routeData } = useRoutesContext();
  const propertyConditions = disabledRuleGroupOperators?.[property];
  const type: string =
    disabledRuleGroupOperators?.[property]?.field?.[operator] ?? "input";

  const options =
    profileOptions[property]?.reduce(
      (acc: { label: string; value: string }[], key) => {
        if (ruleRowValues.includes(key)) return acc;
        return [
          ...acc,
          {
            label: key,
            value: key,
          },
        ];
      },
      []
    ) ?? [];
  let field =
    type === "select" ? (
      <Select
        disabled={routeData.data.type === "view"}
        id={"value_" + index}
        error={error["value_" + index]}
        label=""
        options={[...options]}
        placeholder={t("PLEASE_SELECT")}
        value={value?.[0]}
        onChange={(selected) => {
          updateRow(index, "select", { key: "value", value: [selected] });
          if (error["value_" + index]) {
            const tmpError = { ...error };
            delete tmpError["value_" + index];
            saveError(tmpError);
          }
        }}
      />
    ) : type === "multi-select" ? (
      <CustomMultiSelect
        options={[...options]}
        onChange={(selected) => {
          updateRow(index, "multi-select", { key: "value", value: selected });
          if (error["value_" + index]) {
            const tmpError = { ...error };
            delete tmpError["value_" + index];
            saveError(tmpError);
          }
        }}
        value={value}
        error={error["value_" + index]}
      />
    ) : (
      <TextField
        disabled={routeData.data.type === "view"}
        id={"value_" + index}
        error={error["value_" + index]}
        autoComplete="off"
        label
        labelHidden
        value={value?.[0]}
        onBlur={() => {
          fetchProducts();
        }}
        onChange={(val) => {
          updateRow(index, "input", { key: "value", value: [val] });
          if (error["value_" + index]) {
            const tmpError = { ...error };
            delete tmpError["value_" + index];
            saveError(tmpError);
          }
        }}
      />
    );

  return (
    <InlineStack gap="200" wrap={mdUp ? false : true}>
      <Box width="100%">
        <InlineGrid columns={{md:3}} gap="200">
          <Select
            disabled={routeData.data.type === "view"}
            id={"property_" + index}
            error={error["property_" + index]}
            label=""
            placeholder={t("PLEASE_SELECT")}
            options={propertyOptions(t)}
            onChange={(selected) => {
              updateRow(index, type, { key: "property", value: selected });
              if (error["property_" + index]) {
                const tmpError = { ...error };
                delete tmpError["property_" + index];
                saveError(tmpError);
              }
            }}
            value={property}
          />
          <Select
            disabled={routeData.data.type === "view"}
            id={"operator_" + index}
            error={error["operator_" + index]}
            label=""
            placeholder={t("PLEASE_SELECT")}
            options={operationOptions(t, propertyConditions?.disabled)}
            onChange={(selected) => {
              updateRow(index, type, { key: "operator", value: selected });
              if (error["operator_" + index]) {
                const tmpError = { ...error };
                delete tmpError["operator_" + index];
                saveError(tmpError);
              }
            }}
            value={operator}
          />
          {field}
        </InlineGrid>
      </Box>
      <div style={{ paddingBlockStart: "5px" }} className="match_delete_btn">
        <Button
          disabled={length === 1 || routeData.data.type === "view"}
          icon={mdUp ? DeleteIcon : ''}
          children={mdUp ? "" :  'Delete'}
          variant="plain"
          tone="critical"
          onClick={() => {
            deleteRow(index);
          }}
        />
      </div>
    </InlineStack>
  );
};

export default ConditionRow;

import {
  BlockStack,
  Box,
  ChoiceList,
  DropZone,
  InlineStack,
  Link,
  Spinner,
  Thumbnail,
} from "@shopify/polaris";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { useProductEditContext } from "../ProductEditProvider";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { DIProps } from "Src/Interface/@core";
import { apiUrls } from "Src/Constant/url";
import { DI } from "Src/core";

interface PropsI extends DIProps {
  selected: imageOptionI[];
  setSelected: (param: imageOptionI[]) => void;
  isOpen: boolean;
  toggleGalleryModal: () => void;
}

const GalleryUploadModal = ({
  isOpen,
  selected,
  setSelected,
  toggleGalleryModal,
  request: { POST },
  t
}: PropsI) => {
  const { localProduct, orderedImages, saveOrderedImages, product } =
    useProductEditContext();

  const videoData = product?.shopify_videos ?? {};
  const videos = Object.keys(videoData);

  const [localSelected, setLocalSelected] = useState<imageOptionI[]>([]);
  const [availableImages, setAvailableImages] = useState<
    {
      label: ReactNode;
      value: string;
    }[]
  >([]);
  const [uploadImageState, setUploadImageState] = useState({ loading: false });

  useEffect(() => {
    setAvailableImages(
      orderedImages?.map((img: { url: string; alt: string }) => {
        return {
          label: <Thumbnail size="large" source={img?.url} alt={img?.alt} />,
          value: img?.url,
        };
      })
    );
  }, [orderedImages]);

  useEffect(() => {
    isOpen && setLocalSelected(selected);
  }, [isOpen]);

  const handleChange = (values: string[]) => {
    const selectedItems = values
      .map((value) => {
        const matchedImage = availableImages.find(
          (image) => image.value === value
        );
        return matchedImage
          ? { label: matchedImage.label, value: matchedImage.value }
          : null;
      })
      .filter((item) => item !== null) as {
        label: JSX.Element;
        value: string;
      }[];
    if (selectedItems.length > 10) {
      ToastAppBridge("You can select maximum 10 photos");
      return;
    }
    setLocalSelected(selectedItems);
  };

  const addNewImage = async (files: File[]) => {
    const result = { success: false, imgLinks: [] };
    setUploadImageState((prev) => ({ ...prev, loading: true }));
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`digital_upload[${index}]`, file);
    });
    formData.append("productId", localProduct?.product_id);
    const fileImages = files.map((file) => ({ url: file.name }));
    formData.append(
      "uploaded_files",
      [...orderedImages, ...fileImages]
        ?.map((img: any) => {
          const url = img?.url.split("?")?.[0];
          const urlParts = url.split("/");
          return urlParts[urlParts.length - 1];
        })
        .join(",")
    );
    formData.append("alreadyUploaded", "");
    await POST(apiUrls.uploadImage, formData, false, true)
      .then((res) => {
        if (res?.success) {
          Object.assign(result, {
            success: true,
            imgLinks: res?.data?.image_upload_key,
          });
        } else {
          ToastAppBridge(res?.message ?? res?.msg, { isError: true });
        }
      })
      .finally(() => {
        setUploadImageState((prev) => ({ ...prev, loading: false }));
      });
    return result;
    // after complete fetching
  };

  const handleDropZoneDrop = async (
    _dropFiles: File[],
    acceptedFiles: File[],
    _rejectedFiles: File[]
  ) => {
    if (orderedImages?.length >= 10) {
      return;
    }
    const allowedLength = 10 - orderedImages?.length ?? 0;
    if (acceptedFiles.length > allowedLength) {
      ToastAppBridge(`Choose ${allowedLength} items or less`);
      return;
    }
    if (acceptedFiles.length > 10) {
      ToastAppBridge("Maximum 10 items are allowed");
      return;
    }
    const { success, imgLinks } = await addNewImage(acceptedFiles);
    if (success) {
      const newGalleryItems = imgLinks.map((link) => ({
        label: <Thumbnail size="large" alt={link} source={link} />,
        value: link,
      }));
      const newOrderedImages = imgLinks.map((link) => ({ alt: "", url: link }));
      setAvailableImages((prevItems) => [...prevItems, ...newGalleryItems]);
      saveOrderedImages([...orderedImages, ...newOrderedImages]);
      if (localSelected.length < 10) {
        const tempSelected = localSelected.concat(
          newOrderedImages.map((img) => ({
            label: <Thumbnail size="large" source={img?.url} alt={img?.alt} />,
            value: img?.url,
          }))
        );
        setLocalSelected(tempSelected.slice(0, 10));
      }
    }
  };

  const fileUpload = (
    <DropZone.FileUpload actionHint={t("ACCEPTS_IMG_FORMAT")}
      actionTitle={t("ADD_FILE")}
    />
  );

  const handleSendData = () => {
    setSelected(localSelected);
    toggleGalleryModal();
    const topOrderUrls = localSelected.map((sel) => sel.value);
    const topOrderImages = orderedImages.filter((img) =>
      topOrderUrls.includes(img.url)
    );
    const bottomOrderedImages = orderedImages.filter(
      (img) => !topOrderUrls.includes(img.url)
    );
    const finalArr = topOrderImages.concat(bottomOrderedImages);
    saveOrderedImages([...finalArr]);
  };
  return (
    <ModalAppBridge
      open={isOpen}
      onHide={toggleGalleryModal}
      title={t("SELECT_FILE")}
      id="gallery-upload-modal"
      variant="large"
      body={
        <Box padding="400">
          <BlockStack gap="400">
            {uploadImageState.loading ? (
              <InlineStack align="center">
                <Spinner accessibilityLabel="Spinner example" size="small" />
              </InlineStack>
            ) : orderedImages.length < 10 ? (
              <DropZone
                type="image"
                onDrop={handleDropZoneDrop}
                variableHeight
                accept=".gif,.jpg,.png"
              >
                {fileUpload}
              </DropZone>
            ) : null}
            <BlockStack gap={"400"}>
              <div className="custom_image_gallery_thumbnails">

                <ChoiceList
                  allowMultiple
                  title={""}
                  choices={availableImages}
                  selected={localSelected.map((item) => item.value)}
                  onChange={handleChange}
                />

              </div>
              <InlineStack gap={"200"}>
                {videos?.map((item: any, index: any) => {
                  return <div className={'Thumbnail_wrapper video_thumbnail'}>
                    <div className="page_product_video_thumbnail_inner">
                      <Link url={item} target="_blank">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8V20C28 24.4183 24.4183 28 20 28H8C3.58172 28 0 24.4183 0 20V8Z" fill="black" fill-opacity="0.71" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.375 12.4852C20.5417 13.1587 20.5417 14.8427 19.375 15.5163L11.875 19.8464C10.7083 20.52 9.25 19.678 9.25 18.3308L9.25 9.67058C9.25 8.32343 10.7083 7.48146 11.875 8.15504L19.375 12.4852ZM18.625 14.2172C18.7917 14.121 18.7917 13.8804 18.625 13.7842L11.125 9.45407C10.9583 9.35785 10.75 9.47813 10.75 9.67058L10.75 18.3308C10.75 18.5233 10.9583 18.6436 11.125 18.5473L18.625 14.2172Z" fill="white" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                })}
              </InlineStack>
            </BlockStack>
          </BlockStack>
        </Box>
      }
      footer={
        <>
          <button tone="default" variant="primary" onClick={handleSendData}>
            {t("DONE")}
          </button>
          <button onClick={toggleGalleryModal}>{t("CANCEL")}</button>
        </>
      }
    />
  );
};

export default DI(GalleryUploadModal);

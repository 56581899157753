import {
  Banner,
  BlockStack,
  Card,
  Divider,
  IndexTable,
  InlineGrid,
  InlineStack,
  Layout,
  Tag,
  Text,
  Thumbnail,
  useBreakpoints,
} from "@shopify/polaris";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { getFormattedDateTime, isValidValue } from "Src/function";

const ShopifyProductView = ({
  hasBeenLoading,
  productData,
  productUpdates,
  t
}: DIProps & {
  productData: any;
  hasBeenLoading: boolean;
  productUpdates: any;
}) => {
  const {mdDown} = useBreakpoints();
  const shopifyProductData = productData?.data;
  const thumbnailImages = shopifyProductData?.images;
  const tags = shopifyProductData?.tags;
  const splitTags = tags?.split(", ");
  const variantDetails = productUpdates?.variants;
  const rowMarkupVariantDetails = variantDetails?.map(
    (
      {
        id,
        sku,
        price,
        inventory_quantity,
        option1,
        option2,
        option3,
        weight,
        weight_unit,
      }: any,
      index: number
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="medium" as="span">
            {sku}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{price}</IndexTable.Cell>
        <IndexTable.Cell>{inventory_quantity}</IndexTable.Cell>
        <IndexTable.Cell>
          {weight && weight_unit ? `${weight} ${weight_unit}` : "N/A"}
        </IndexTable.Cell>
        {shopifyProductData?.options ? (
          shopifyProductData?.options?.map((e: any, i: number) => {
            return (
              <IndexTable.Cell>
                {i === 0 ? option1 : i === 1 ? option2 : option3}
              </IndexTable.Cell>
            );
          })
        ) : (
          <></>
        )}
      </IndexTable.Row>
    )
  );

  const rowMarkupVariantTimeline = variantDetails?.map(
    (
      { id, sku, inventory_last_updated, updated_at_variant, updated_at }: any,
      index: number
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="medium" as="span">
            {sku}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {getFormattedDateTime(updated_at_variant, true)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          {getFormattedDateTime(updated_at, true)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          {getFormattedDateTime(inventory_last_updated, true)}
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const variantExtraHeadings =
    shopifyProductData?.options?.map((e: any) => {
      return {
        title: e.name,
      };
    }) ?? [];

  if (isValidValue(shopifyProductData?.errors)) {
    return (
      <Banner tone="critical">
        <Text as="p">{t("ERROR_FROM_SHOPIFY")} : {shopifyProductData?.errors ?? t("DETAILS_NOT_FOUND")}</Text>
      </Banner>
    );
  }

  if (!hasBeenLoading) {
    <>LOADING...</>;
  }

  return (
    <div className="product_view_page_layout">
      <Layout>
        <Layout.Section>
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  gap='100'
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Description:
                  </Text>
                  <div className="view-description">
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {shopifyProductData?.body_html ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: shopifyProductData?.body_html,
                        }}
                      />
                    ) : (
                      "N/A"
                    )}
                  </Text>
                  </div>
                </InlineGrid>
                <InlineGrid
                 gap='100'
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Product Tags /Keywords:
                  </Text>
                  {tags?.length > 0 ? (
                    <InlineStack gap={"200"}>
                      {splitTags?.map((item: any) => {
                        return <Tag>{item}</Tag>;
                      })}
                    </InlineStack>
                  ) : (
                    <Text
                      as={"strong"}
                      variant="headingMd"
                      fontWeight="regular"
                    >
                      N/A
                    </Text>
                  )}
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <Text as={"strong"} variant="headingMd" fontWeight="medium">
                  Images
                </Text>
                {mdDown && <Divider/>}
                <InlineStack gap={"200"}>
                  {thumbnailImages?.map((item: any) => {
                    return (
                      <Thumbnail
                      size={mdDown ? "medium" : "large"}
                        source={item.src}
                        alt="Black choker necklace"
                      />
                    );
                  })}
                </InlineStack>
              </BlockStack>
            </Card>

            <Card>
              <BlockStack gap={"300"}>
                <Text as={"strong"} variant="headingMd" fontWeight="medium">
                  Variant details
                </Text>
                {mdDown && <Divider/>}
                <Card padding={"0"} roundedAbove="xs">
                  <div className="variant-table_vertical_scroll">
                    <IndexTable
                      selectable={false}
                      itemCount={2}
                      headings={[
                        { title: "SKU" },
                        { title: "Price (USD)" },
                        { title: "Quantity" },
                        { title: "Weight" },
                        ...variantExtraHeadings,
                      ]}
                    >
                      {rowMarkupVariantDetails}
                    </IndexTable>
                  </div>
                </Card>
              </BlockStack>
            </Card>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <Text as={"strong"} variant="headingMd" fontWeight="medium">
                  Variant timeline
                </Text>
                {mdDown && <Divider/>}
                <Card padding={"0"} roundedAbove="xs">
                  <div className="variant-table_vertical_scroll">
                    <IndexTable
                      selectable={false}
                      itemCount={2}
                      headings={[
                        { title: "SKU" },
                        { title: "Updated at App" },
                        { title: "Updated at shopify" },
                        { title: "Inventory Update from app" },
                      ]}
                    >
                      {rowMarkupVariantTimeline}
                    </IndexTable>
                  </div>
                </Card>
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Product id:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {shopifyProductData?.id ? shopifyProductData?.id : "-"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Published at:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {getFormattedDateTime(
                      shopifyProductData?.published_at,
                      true
                    )}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Updated at:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {getFormattedDateTime(shopifyProductData?.updated_at, true)}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Product Type:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {shopifyProductData?.product_type
                      ? shopifyProductData?.product_type
                      : "-"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Product Vendor:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {shopifyProductData?.vendor
                      ? shopifyProductData?.vendor
                      : "-"}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </div>
  );
};

export default DI(ShopifyProductView);

import { BlockStack, Card, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'


const FaqSkeleton = () => {
  return (
    <BlockStack gap='500'>
      <div className='skeleton-height-32px' style={{ "width": "260px" }}>
        <SkeletonBodyText lines={1} />
      </div>

      <BlockStack gap='400'>
        {[1, 2, 3, 4, 5, 6].map((i) => (
            <Card key={i}>
              <InlineStack align='space-between' wrap={false} gap='200' blockAlign='center'>
                <div style={{ "width": "90%" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <div className='skeleton-height-20 ' style={{ "width": "20px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
              </InlineStack>
            </Card>
        ))}
      </BlockStack>
    </BlockStack>
  )
}

export default FaqSkeleton

import { Modal, TitleBar } from "@shopify/app-bridge-react";
import { AppProvider, Button, Icon, Text } from "@shopify/polaris";
import React from "react";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import enTranslations from "@shopify/polaris/locales/en.json";
import frTranslations from "@shopify/polaris/locales/fr.json";
import { DEFAULT_LANGUAGE } from "Src/Constants";
import ShopifyProvider from "Src/ShopifyAppBridgeProvider";
import { environment } from "Src/environment/environment";
import {
  XIcon
} from '@shopify/polaris-icons';
const { Api_key } = environment;
const translationsObj: any = {
  [DEFAULT_LANGUAGE]: enTranslations,
  fr: frTranslations,
};

const selectedLanguage =
  localStorage.getItem("language") ?? DEFAULT_LANGUAGE;

const ModalAppBridge = ({
  open,
  title,
  footer,
  body,
  id,
  variant,
  onHide,
  globalState: { get },
}: {
  open: boolean;
  title: string;
  footer?: React.ReactNode;
  body: React.ReactNode;
  id: string;
  variant?: "base" | "large" | "small" | "max" | undefined;
  onHide?: () => void;
} & DIProps) => {
  const isEmbedded = get("isEmbed") === "true";

  if (!isEmbedded) {
    return open ? (
      <div className={`not-embedded-modal`}>
        <div className={`not-embedded-wrapper ${variant ? variant : 'base'}`}>
          <div className="not-embedded-header"><Text as="h2" variant="bodyLg" fontWeight="semibold">{title}</Text> <Button variant="plain" onClick={onHide} icon={<Icon
            source={XIcon}
            tone="base"
          />} /></div>
          <div className="not-embedded-body"> {body}</div>
          <div className="not-embedded-footer">{footer}</div>
        </div>
      </div>
    ) : (
      <></>
    );
  }

  return open ? (
    <Modal id={id} open={open} variant={variant} onHide={onHide}>
      <AppProvider
        i18n={translationsObj?.[selectedLanguage] ?? enTranslations}
      >
        <ShopifyProvider apiKey={Api_key}>
          <div className="modal-body" id={id}>
            {body}
          </div>
        </ShopifyProvider>
      </AppProvider>
      <TitleBar title={title}>{footer}</TitleBar>
    </Modal>
  ) : (
    <></>
  );
};

export default DI(ModalAppBridge);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { plansData: Object } = {
  plansData: {},
};

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    getplansAPI: (state, action: PayloadAction<{ data: Object }>) => {
      state.plansData = action.payload.data;
    },
  },
});

export const { getplansAPI } = planSlice.actions;

export default planSlice.reducer;

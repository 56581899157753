import {
  BlockStack,
  Button,
  Card,
  Divider,
  InlineStack,
  RadioButton,
  Spinner,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { PlusIcon } from "@shopify/polaris-icons";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { useProfileContext } from "../ProfileProvider";
import ConditionRow from "./RuleGroupComponents/ConditionRow";
import ViewProducts from "./RuleGroupComponents/ViewProducts";
import { formatRuleGroup } from "../CreateProfileHelper";
import { apiUrls } from "Src/Constant/url";
import { RuleRowsI } from "Src/Interface/@Profile";
import { useRoutesContext } from "Src/Component/RoutesProvider";

const { viewProducts: viewProductsApi } = apiUrls;

const RuleGroup = (_props: DIProps) => {
  const {
    t,
    request: { POST },
  } = _props;
  const { routeData } = useRoutesContext();
  const {
    rule_group,
    profileData,
    updateRuleGroup,
    error,
    saveError,
    profile,
    totalProduct,
    updateTotalCount,
    updateIsDIGITAL,
    saveEditProductIds,
    saveEditProductIdsUpdated,
  } = useProfileContext();
  const { condition, ruleRows } = rule_group;
  const { mdUp } = useBreakpoints();

  const [countsLoader, setcountsLoader] = useState(false);

  const handleChange = useCallback((_: boolean, newValue: "||" | "&&") => {
    updateRuleGroup((prev) => {
      fetchProducts(ruleRows, newValue);
      return { ...prev, condition: newValue };
    });
  }, []);

  const [profileOptions, setProfileOptions] = useState<{
    [key: string]: string[];
  }>({});

  const [viewProducts, setViewProducts] = useState(false);

  useEffect(() => {
    const product_profile = profileData?.product_profile ?? {};
    setProfileOptions({
      product_type: Object.values(product_profile?.product_type ?? {}),
      vendor: Object.values(product_profile?.vendor ?? {}),
      tags: Object.values(product_profile?.tags_value ?? {}),
    });
  }, [profileData]);

  useEffect(() => {
    routeData.data.type === "edit" && fetchProducts();
  }, []);

  const fetchProducts = (
    ruleRowsCopy: RuleRowsI[] = ruleRows,
    conditionCopy: "||" | "&&" = condition
  ) => {
    if (
      ruleRows.some((row) => !row.property || !row.operator || !row.value?.[0])
    ) {
      updateTotalCount(null);
      setcountsLoader(false);
      return;
    }

    const payload = formatRuleGroup(ruleRowsCopy);

    setcountsLoader(true);
    POST(viewProductsApi, {
      triggerType: "automatic",
      product_profile: {
        condition: conditionCopy,
        ...payload,
      },
      Profile: {
        profile_code: profile.profile_code,
        profile_code_existing: profile.profile_code_existing,
        ProfileId: profile.ProfileId,
        overwrite: "",
      },
    })
      .then((res) => {
        if (res.success && res?.data?.totalProduct) {
          if (
            parseInt(res?.data?.totalProduct ?? "0") ===
            parseInt(res?.data?.digitalCount ?? "0")
          ) {
            updateIsDIGITAL(true);
          } else {
            updateIsDIGITAL(false);
          }
          updateTotalCount(res?.data?.totalProduct ?? null);
        } else {
          updateTotalCount(0);
        }
        if(routeData?.data?.type === "edit" ) {
          let selectedIds = [];
          if(res?.data?.total_products && typeof res?.data?.total_products === "string") {
            selectedIds = res?.data?.total_products?.split(",") ?? [];
          }
          saveEditProductIds(selectedIds);
          saveEditProductIdsUpdated(true);
          updateRuleGroup((prev) => {
            return { ...prev, update_on_shopify: selectedIds, select_all: Number(res?.data?.totalProduct) === selectedIds.length ? "1" : "0" };
          });
        }

      })
      .finally(() => {
        setcountsLoader(false);
      });
  };

  const updateRow = (
    index: number,
    type: string = "input",
    {
      key,
      value,
    }:
      | { key: "property" | "operator"; value: string }
      | { key: "value"; value: string[] }
  ) => {
    updateRuleGroup((prev) => {
      if (prev.ruleRows[index]) {
        switch (key) {
          case "property":
            prev.ruleRows[index] = {
              property: value,
              operator: "",
              value: [],
              type,
            };
            break;
          case "operator":
            prev.ruleRows[index] = {
              ...prev.ruleRows[index],
              operator: value,
              value: [],
              // ...(prev.ruleRows[index].type === type ? {} : { value: [] }),
              type,
            };
            break;
          case "value":
            prev.ruleRows[index] = {
              ...prev.ruleRows[index],
              value,
              type,
            };
        }
      }

      if (
        (key === "value" && type.includes("select")) ||
        (key === "operator" && prev.ruleRows[index].type === type)
      ) {
        fetchProducts(prev.ruleRows);
      } else {
        updateTotalCount(null);
        setcountsLoader(false);
      }

      return {
        ...prev,
        ruleRows: prev.ruleRows,
        update_on_shopify: [],
        select_all: "1",
      };
    });
  };

  const deleteRow = (index: number) => {
    updateRuleGroup((prev) => {
      prev.ruleRows.splice(index, 1);
      fetchProducts(prev.ruleRows);
      return { ...prev, update_on_shopify: [], select_all: "1" };
    });
    flushErrors();
  };

  const addRow = () => {
    updateRuleGroup((prev) => {
      prev.ruleRows.push({
        property: "",
        operator: "",
        value: [],
        type: "input",
      });
      return { ...prev, update_on_shopify: [], select_all: "1" };
    });
    flushErrors();
    updateTotalCount(null);
    setcountsLoader(false);
  };

  const flushErrors = () => {
    const tempError = { ...error };
    Object.keys(error).forEach((key) => {
      if (
        key.startsWith("value_") ||
        key.startsWith("property_") ||
        key.startsWith("operator_")
      ) {
        delete tempError[key];
      }
    });
    saveError({ ...tempError });
  };

  const error_in_row = ruleRows.some(
    (row) => !row.property || !row.operator || !row.value?.[0]
  );

  return (
    <React.Fragment>
      <BlockStack gap={"200"}>
        <BlockStack gap={"100"}>
          <div className="custom_required">
            <Text as="h6" variant="bodyMd">
              {t("CONDITIONS")}
            </Text>
          </div>
          <Text as="p" variant="bodyMd" tone="subdued">
            {t("PROFILING_CONDITIONS_HELPTXT")}
          </Text>
          <InlineStack gap={mdUp ? "300" : "100"} blockAlign="center" wrap>
            <Text as="span" variant="headingSm">
              {t("PRODUCTS_MUST_MATCH")} :
            </Text>
            <InlineStack gap={"800"} blockAlign="center">
              <RadioButton
                label={t("ANY_CONDITION")}
                disabled={routeData.data.type === "view"}
                // helpText={t("ANY_CONDITION_DESC")} //UNCOMMNENT TO ADD DESCRIPTION
                checked={condition === "||"}
                id="||"
                name="conditions"
                onChange={handleChange}
              />
              <RadioButton
                label={t("ALL_CONDITION")}
                disabled={routeData.data.type === "view"}
                // helpText={t("ALL_CONDITION_DESC")} //UNCOMMNENT TO ADD DESCRIPTION
                id="&&"
                name="conditions"
                checked={condition === "&&"}
                onChange={handleChange}
              />
            </InlineStack>
          </InlineStack>
        </BlockStack>
        <Card>
          <BlockStack gap="200">
            {ruleRows.map((row, index, ruleRows) => {
              return (
                <ConditionRow
                  {...row}
                  ruleRowValues={ruleRows.reduce(
                    (acc: string[], temprow, idx) => {
                      if (temprow.property === row.property && idx !== index) {
                        return [...acc, ...temprow.value];
                      }
                      return acc;
                    },
                    []
                  )}
                  t={t}
                  index={index}
                  key={index}
                  length={ruleRows.length}
                  profileOptions={profileOptions}
                  deleteRow={deleteRow}
                  updateRow={updateRow}
                  fetchProducts={fetchProducts}
                />
              );
            })}
            {routeData.data.type !== "view" && (
              <BlockStack gap="300">
                <Divider />
                <div className="products-conditions">
                  <InlineStack
                    align="space-between"
                    blockAlign="center"
                    gap="200"
                  >
                    <InlineStack gap={"300"} blockAlign="center">
                      {totalProduct !== null && (
                        <Text as="p" variant="bodyMd">
                          {t("TOTAL_PRODUCTS_IN_CONDITION")}{" "}
                          {countsLoader ? (
                            <Spinner size="small" />
                          ) : (
                            totalProduct
                          )}
                        </Text>
                      )}
                      {totalProduct !== null && (
                        <Button
                          variant="plain"
                          onClick={() => {
                            setViewProducts(true);
                          }}
                          disabled={error_in_row || totalProduct == 0}
                        >
                          {t("VIEW_PRODUCTS")}
                        </Button>
                      )}
                    </InlineStack>
                    <Button
                      key={`${ruleRows.length}`}
                      icon={PlusIcon}
                      onClick={() => {
                        addRow();
                      }}
                      disabled={error_in_row}
                      id="add_rule_condition"
                      role="add"
                    >
                      {t("ADD_CONDITION")}
                    </Button>
                  </InlineStack>
                </div>
              </BlockStack>
            )}
          </BlockStack>
        </Card>
      </BlockStack>
      {viewProducts && <ViewProducts open={viewProducts} setOpen={setViewProducts}/>}
    </React.Fragment>
  );
};

export default DI(RuleGroup);

import React from 'react'
import CreateProfileSkeleton from './CreateProfileSkeleton'
import { BlockStack, Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'

const WidthPageHeaderCreateProfileSkeleton = () => {
    const { mdDown , mdUp} = useBreakpoints()
    return (
        <div>
            <Box borderBlockEndWidth={mdUp ? '025' : '0'} borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className="custom_layout_width">
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>

                            {mdDown ? (
                                <BlockStack gap={"300"}>
                                    <InlineStack align='space-between' blockAlign='center'>
                                        <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>

                                        <InlineStack gap={"200"}>
                                            <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-height-32px' style={{ "width": "52px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </InlineStack>
                                    </InlineStack>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                </BlockStack>
                            ) : (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                    <InlineStack gap={"200"}>
                                        <div style={{ "width": "94px" }}>
                                            <SkeletonDisplayText size='small' />
                                        </div>
                                        <div style={{ "width": "52px" }}>
                                            <SkeletonDisplayText size='small' />
                                        </div>
                                    </InlineStack>
                                </InlineStack>
                            )}

                        </Page>
                    </div>
                </div>
            </Box>

            <CreateProfileSkeleton />

        </div>
    )
}

export default WidthPageHeaderCreateProfileSkeleton

import { BlockStack, Card, IndexTable, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const VariantTimelineSkeleton = () => {
    const gridSkeletonRow = {
        id: '1',
        sku: <div className='failed-order-skelton' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>,
        updatedAtApp: <div className='failed-order-skelton' style={{ 'width': '30px' }}><SkeletonBodyText lines={1} /></div>,
        updatedAtShopify: <div className='failed-order-skelton' style={{ 'width': '90px' }}><SkeletonBodyText lines={1} /></div>,
        inventoryUpdatedFromApp: <div className='failed-order-skelton' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>
    }
    const rowMarkup = Array.from({ length: 10 }, () => gridSkeletonRow);
    const rowMarkupUpdated = rowMarkup.map((item: any, index: any) => (
        <IndexTable.Row
            id={item.id}
            key={index}
            position={index}
        >
            <IndexTable.Cell>
                {item.sku}
            </IndexTable.Cell>
            <IndexTable.Cell>
                {item.updatedAtApp}
            </IndexTable.Cell>
            <IndexTable.Cell>{item.updatedAtShopify}</IndexTable.Cell>
            <IndexTable.Cell>{item.inventoryUpdatedFromApp}</IndexTable.Cell>
        </IndexTable.Row>
    ),
    );
    return (
        <Card>
            <BlockStack gap={"300"}>
                <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                </div>
                <Card padding={"0"}>
                    <div className="variant-table_vertical_scroll custom-table-skeleton">
                        <IndexTable
                            selectable={false}
                            itemCount={2}
                            headings={[
                                { title: "" },
                                { title: "" },
                                { title: "" },
                                { title: "" },
                            ]}
                        >
                            {rowMarkupUpdated}
                        </IndexTable>
                    </div>
                </Card>
            </BlockStack>
        </Card>
    )
}

export default VariantTimelineSkeleton

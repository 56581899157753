import React, { useEffect, useMemo, useState } from "react";
import {
  BlockStack,
  Box,
  Card,
  ChoiceList,
  Collapsible,
  Divider,
  Icon,
  InlineStack,
  RadioButton,
  Text,
  Thumbnail,
} from "@shopify/polaris";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { DI } from "Src/core";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { useParams } from "react-router-dom";
import { useProductEditContext } from "../ProductEditProvider";
import { CaretDownIcon, CaretUpIcon } from "@shopify/polaris-icons";

interface PropsI extends DIProps {
  isOpen: boolean;
  toggleImageLinkingModal: () => void;
}

const LinkPhotosModal = ({
  isOpen,
  toggleImageLinkingModal,
  request: { POST },
  t,
}: PropsI) => {
  const {
    shopifyAttributeNames,
    product,
    orderedImages,
    localProduct,
    updateLocalProduct,
  } = useProductEditContext();
  const [imageLinkingState, setImageLinkingState] = useState({
    loading: false,
  });
  const { productId } = useParams();
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [linkState, setLinkState] = useState<{
    variantOptions: string[];
    selectedImagesMap: ObjStrI;
    saveLoading: boolean;
  }>({
    variantOptions: [],
    selectedImagesMap: {},
    saveLoading: false,
  });
  const [activeAttr, setActiveAttr] = useState<string>("");

  const variantAttributesMap: ObjStrI = useMemo(() => {
    return shopifyAttributeNames.reduce(
      (prev, curr) => ({
        ...prev,
        [product?.cat_mapping?.variantAttributes?.[curr]]: curr,
      }),
      {}
    );
  }, [shopifyAttributeNames, product]);

  const getImageLinkingData = () => {
    setImageLinkingState((prev) => ({ ...prev, loading: true }));
    const optionsArr = shopifyAttributeNames.reduce(
      (prev, curr) => ({
        ...prev,
        [product?.cat_mapping?.variantAttributes?.[curr]]: curr,
      }),
      {}
    );
    const orderedImagesObj = {
      primary: orderedImages?.[0] ? [orderedImages[0]] : [],
      secondary: orderedImages.slice(1).reduce((prev, curr, index) => {
        return {
          ...prev,
          [index + 1]: {
            rank: index + 1,
            url: curr?.url,
            alt: curr?.alt ?? null,
          },
        };
      }, {}),
      variant_image_linking:
        localProduct?.ordered_images?.variant_image_linking ?? {},
    };
    const paylod = {
      product_id: productId,
      option: selectedAttribute,
      imageArr: orderedImages.map((img) => img?.url),
      optionsArr: optionsArr,
      ordered_images: orderedImagesObj,
      images: orderedImages,
    };
    POST(apiUrls.linkImageGrid, paylod)
      .then((res) => {
        if (res?.success) {
          const tempMap = Object.values(
            res?.data?.linked_images
          )?.[0] as ObjStrI;
          const optionArr: string[] = res?.data?.optionValuesArr ?? [];
          setLinkState((prev) => ({
            ...prev,
            variantOptions: optionArr,
            selectedImagesMap: tempMap,
          }));
        } else {
          ToastAppBridge(res?.message ?? res?.msg, { isError: true });
        }
      })
      .finally(() => {
        setImageLinkingState((prev) => ({ ...prev, loading: false }));
      });
  };

  const saveImageLinking = () => {
    setLinkState((prev) => ({ ...prev, saveLoading: true }));
    const linkedImageArr: string[] = linkState.variantOptions.map((item) => {
      return linkState?.selectedImagesMap?.[item];
    });
    const payload = {
      product_id: productId,
      optionValuesArr: linkState.variantOptions,
      linkedImageArr: linkedImageArr,
      selectedOptionId: selectedAttribute,
      imageArr: orderedImages.map((img) => img?.url),
    };
    POST(apiUrls.saveLinkedImages, payload)
      .then((res) => {
        if (res?.success) {
          const variant_image_linking = {
            [selectedAttribute]: payload?.optionValuesArr.reduce(
              (prev, curr, index) => {
                return { ...prev, [curr]: payload.linkedImageArr?.[index] };
              },
              {}
            ),
          };
          const ordered_images = {
            ...localProduct?.ordered_images,
            variant_image_linking,
          };
          updateLocalProduct({
            optionValuesArr: payload?.optionValuesArr,
            linkedImageArr: payload?.linkedImageArr,
            image_linking_option: payload?.selectedOptionId,
            ordered_images,
          });
          toggleImageLinkingModal();
        }
        ToastAppBridge(res?.message ?? res?.msg, { isError: !res?.success });
      })
      .finally(() => {
        setLinkState((prev) => ({ ...prev, saveLoading: false }));
      });
  };

  const changeVariantImage = (attrName: string, url: string) => {
    setLinkState((prev) => ({
      ...prev,
      selectedImagesMap: { ...prev.selectedImagesMap, [attrName]: url },
    }));
  };

  useEffect(() => {
    selectedAttribute && getImageLinkingData();
  }, [selectedAttribute]);

  useEffect(() => {
    if (Object.keys(variantAttributesMap).length > 0) {
      setSelectedAttribute(Object.keys(variantAttributesMap)[0]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ModalAppBridge
      open={isOpen}
      id="link-photos"
      onHide={toggleImageLinkingModal}
      variant="base"
      title={t("LINK_PHOTOS")}
      body={
        <Box padding="400">
          {imageLinkingState.loading ? (
            <>Loading...</>
          ) : (
            <BlockStack gap="300">
              <Text as="p">
                <Text as="span" fontWeight="medium">
                  {t("PRODUCT_TITLE")}:{" "}
                </Text>
                {product?.title}
              </Text>
              <Text as="p">
                {t("CHOOSE_VARIATION_TYPE_YOU_WANT_TO_LINK")}
              </Text>
              <Text as="p">
                {t("KEEP_IN_MIND_PHOTOS_HELP_SHOPPERS")}
              </Text>
              <InlineStack gap="200" blockAlign="center">
                <Text as="p">
                  {t("VARIANT_LINK_PHOTOS_BY")}
                  :</Text>
                {Object.entries(variantAttributesMap).map(([key, val]) => {
                  return (
                    <RadioButton
                      label={val}
                      checked={selectedAttribute === key}
                      onChange={() => setSelectedAttribute(key)}
                    />
                  );
                })}
              </InlineStack>
              <Divider />
              {linkState.variantOptions.map((option, index) => {
                return (
                  <Card>
                    <BlockStack gap="100">
                      <div
                        onClick={() => setActiveAttr(option)}
                        style={{ cursor: "pointer" }}
                      >
                        <InlineStack align="space-between">
                          <Text as="p">{option}</Text>{" "}
                          <Box>
                            <Icon
                              source={
                                activeAttr === option
                                  ? CaretUpIcon
                                  : CaretDownIcon
                              }
                            />
                          </Box>
                        </InlineStack>
                      </div>
                      <Collapsible
                        open={activeAttr === option}
                        id="basic-collapsible"
                        transition={{
                          duration: "500ms",
                          timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                      >
                        <div className="link-photo">
                          <ChoiceList
                            title="Company name"
                            titleHidden
                            choices={orderedImages.slice(0, 10).map((img) => ({
                              value: img.url,
                              label: (
                                <Thumbnail
                                  size="large"
                                  alt={img?.alt}
                                  source={img?.url}
                                />
                              ),
                            }))}
                            selected={
                              linkState.selectedImagesMap?.[option]
                                ? [linkState.selectedImagesMap?.[option]]
                                : []
                            }
                            onChange={(val) => {
                              changeVariantImage(option, val?.[0]);
                            }}
                          />
                        </div>
                      </Collapsible>
                    </BlockStack>
                  </Card>
                );
              })}
            </BlockStack>
          )}
        </Box>
      }
      footer={
        <>
          <button variant="primary" onClick={saveImageLinking}>
            {t("CONFIRM")}
          </button>
          <button onClick={toggleImageLinkingModal}> {t("CANCEL")}</button>
        </>
      }
    />
  );
};

export default DI(LinkPhotosModal);

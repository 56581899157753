import {
  BlockStack,
  IndexTable,
  Text,
  TextField,
  TextFieldProps,
} from "@shopify/polaris";
import React, { useEffect } from "react";
import { regexExpressions } from "Src/Constants";
import useDebounce from "Src/hooks/useDebounce";

interface PropsI {
  index: number;
  rowItem: any;
  updateRow: (index: number, key: string, value: string) => void;
  appliedAllValue: string;
  appliedAllTrigger: boolean;
  toggleAppliedAllTrigger: () => void;
  error: TextFieldProps["error"];
  updateRowError: (index: number, err: TextFieldProps["error"]) => void;
}

const LocationWiseRow = ({
  index,
  rowItem,
  updateRow,
  appliedAllValue,
  appliedAllTrigger,
  toggleAppliedAllTrigger,
  error,
  updateRowError,
}: PropsI) => {
  const { sku, title, available } = rowItem;
  const {
    debouncedValue: debounced_available,
    changeDebouncedValue: changeAvailable,
  } = useDebounce(
    (val:any) => {
      updateRow(index, "available", val);
    },
    800,
    available
  );

  const {
    debouncedValue: debouncedError,
    changeDebouncedValue: changeError,
  } = useDebounce(
    (val:any) => {
      updateRowError(index, val);
    },
    800,
    error
  );

  useEffect(() => {
    if (appliedAllTrigger) {
      changeAvailable(appliedAllValue);
      toggleAppliedAllTrigger();
    }
  }, [appliedAllTrigger]);
  return (
    <>
      <IndexTable.Cell>
        <BlockStack>
          <Text as="p" fontWeight="medium">
            Title : {title}
          </Text>
          <Text as="p" tone="subdued" fontWeight="medium">
            SKU : {sku}
          </Text>
        </BlockStack>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextField
          label="Inventory"
          labelHidden
          autoComplete=""
          value={debounced_available as string}
          error={debouncedError}
          onChange={(val) => {
            if (regexExpressions.NUMBER_WITHOUT_DOT.test(val) || val === "") {
              changeAvailable(val);
              changeError(Number(val) > 999);
            }
          }}
        />
      </IndexTable.Cell>
    </>
  );
};

export default LocationWiseRow;

import { BlockStack, Box, Text, TextField } from "@shopify/polaris";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { DI } from "Src/core";
import React, { useState } from "react";
import { useProfileGridContext } from "../ProfileGridProvider";
import { DIProps } from "Src/Interface/@core";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import CreateProfile from "../../CreateProfile/CreateProfile";
import { ProfileProvider } from "../../CreateProfile/ProfileProvider";
import { useRoutesContext } from "Src/Component/RoutesProvider";

const { deleteProfile, enableDisableProfile } = apiUrls;
const ProfileGridModals = ({
  request: { DELETE, POST },
  getProfile,
}: DIProps & { getProfile: () => void }) => {
  const {
    modal,
    updateModal,
    updateProfileGridData,
    profileGridData,
    setloading,
  } = useProfileGridContext();
  const [btnLoading, setBtnLoading] = useState(false);
  const [text, setText] = useState("");
  const EnableDisableProfile = () => {
    setBtnLoading(true);
    POST(enableDisableProfile, {
      id: modal.id,
      request: "disable",
    })
      .then((res) => {
        const { success, message } = res;
        if (success) {
          ToastAppBridge(message);
          getProfile();
          updateModal({ ...modal, open: false, id: "" });
          setText("");
        }
      })
      .finally(() => setBtnLoading(false));
  };
  const deleteProfileByID = () => {
    setloading(true);
    setBtnLoading(true);
    DELETE(deleteProfile, { id: modal.id })
      .then((res) => {
        const { success } = res;
        if (success) {
          ToastAppBridge("Profile Deleted Successfully");
          updateProfileGridData(
            profileGridData.map((e) => {
              if (e.profile_id === modal.id) {
                e.profile_status = "disable";
              }
              return e;
            })
          );
          getProfile();
          updateModal({ ...modal, open: false, id: "" });
          setText("");
        }
      })
      .finally(() => {
        setloading(false);
        setBtnLoading(false);
      });
  };

  if (modal.for === "delete") {
    return (
      <ModalAppBridge
        open={modal.open}
        id="delete-template"
        variant="base"
        onHide={() => {
          updateModal({
            ...modal,
            open: false,
            id: "",
          });
          setText("");
        }}
        body={
          <Box padding="400">
            <BlockStack gap="500">
              <Text variant="bodyMd" as="p">
                Are you sure you want to delete profile?
              </Text>
              <Text variant="bodyMd" as="p">
                If you delete this template, the profiles will be disabled and
                the products won’t be showing in the grid anymore.
              </Text>
              <TextField
                onChange={setText}
                value={text}
                label={
                  <Text variant="bodyMd" as="p">
                    You can't undo this action. To confirm, type{" "}
                    <Text tone="critical" as="span" variant="bodyMd">
                      delete
                    </Text>
                  </Text>
                }
                autoComplete=""
              />
            </BlockStack>
          </Box>
        }
        title="Delete profile"
        footer={
          <>
            <button
              loading={btnLoading && "true"}
              disabled={text.trim() !== "delete"}
              tone="critical"
              variant="primary"
              onClick={deleteProfileByID}
            >
              Delete
            </button>
            <button
              onClick={() => {
                updateModal({
                  ...modal,
                  open: false,
                  id: "",
                });
                setText("");
              }}
            >
              Cancel
            </button>
          </>
        }
      />
    );
  }
  if (modal.for === "disable") {
    return (
      <ModalAppBridge
        open={modal.open}
        id="disable-template"
        variant="base"
        onHide={() => {
          updateModal({
            ...modal,
            open: false,
            id: "",
          });
          setText("");
        }}
        body={
          <Box padding="400">
            <BlockStack gap="500">
              <Text variant="bodyMd" as="p">
                If you disable this profile, the products assigned in this
                profile would be showing under Profile disabled tab in the
                product grid.
              </Text>
              <Text variant="bodyMd" as="p">
                Are you sure you want to disable?
              </Text>
            </BlockStack>
          </Box>
        }
        title="Confirmation"
        footer={
          <>
            <button
              loading={btnLoading ?? "true"}
              tone="critical"
              variant="primary"
              onClick={EnableDisableProfile}
            >
              Confirm
            </button>
            <button
              onClick={() => {
                updateModal({
                  ...modal,
                  open: false,
                  id: "",
                });
                setText("");
              }}
            >
              Cancel
            </button>
          </>
        }
      />
    );
  }
  if (modal.for === "view") {
    return (
      <ModalAppBridge
        open={modal.open}
        id="View Profile"
        variant="large"
        onHide={() => {
          updateModal({
            ...modal,
            open: false,
            id: "",
          });
          setText("");
        }}
        body={
          <Box padding="400">
            <ProfileProvider>
              <CreateProfile />
            </ProfileProvider>
          </Box>
        }
        title="View Profile"
        footer={
          <>
            <button
              variant="primary"
              onClick={() => {
                updateModal({
                  ...modal,
                  open: false,
                  id: "",
                });
                setText("");
              }}
            >
              Close
            </button>
          </>
        }
      />
    );
  }
};

export default DI(ProfileGridModals);

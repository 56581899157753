import React from 'react'
import FullOrderGridSkeleton from './FullOrderGridSkeleton'
import { BlockStack, Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'

const WithHeaderOrderGridSkeleton = () => {
    const { mdDown } = useBreakpoints()
    return (
        <>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className='skeleton_page_header_spacing'>
                    <Page fullWidth>

                        {mdDown ? (
                            <InlineStack align='space-between' blockAlign='center'>
                                <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                <InlineStack gap={"200"}>

                                    <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineStack>
                            </InlineStack>
                        ) : (
                            <InlineStack align='space-between' blockAlign='center'>
                                <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                <InlineStack gap={"200"}>

                                    <div style={{ "width": "28px" }}>
                                        <SkeletonDisplayText size='small' />
                                    </div>
                                </InlineStack>
                            </InlineStack>
                        )}

                    </Page>
                </div>
            </Box>
            <Box>
                <Page fullWidth={true}>
                    <FullOrderGridSkeleton />
                </Page>
            </Box>
        </>
    )
}

export default WithHeaderOrderGridSkeleton

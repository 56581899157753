import { StateI } from "../Shipping";

const getEtsyShippingUpgradePayload = (callbackData: any) => {
  const EtsyShippingUpgrade: any = {
    upgrade_id: [],
    type: [],
    upgrade_name: [],
    shipping_carrier_id: [],
    mail_class: [],
    min_delivery_days: [],
    max_delivery_days: [],
    price: [],
    secondary_price: [],
  };
  callbackData.EtsyShippingUpgrade.forEach((e: any) => {
    EtsyShippingUpgrade.upgrade_name.push(e.upgrade_name);
    EtsyShippingUpgrade.upgrade_id.push(e.upgrade_id ?? "");
    EtsyShippingUpgrade.type.push(e.type);
    EtsyShippingUpgrade.shipping_carrier_id.push(e.shipping_carrier_id);
    EtsyShippingUpgrade.min_delivery_days.push(e.min_delivery_days);
    EtsyShippingUpgrade.max_delivery_days.push(e.max_delivery_days);
    EtsyShippingUpgrade.price.push(e.price);
    EtsyShippingUpgrade.mail_class.push(
      e.shipping_carrier_id === 0 ? null : e.mail_class
    );
    EtsyShippingUpgrade.secondary_price.push(e.secondary_price);
  });
  return EtsyShippingUpgrade;
};
const getShippingTemplateEntriesPayload = (callbackData: any) => {
  const ShippingTemplateEntries: any = {
    shipping_template_entry_id: [],
    destination_country_id: [],
    shipping_carrier_id: [],
    mail_class: [],
    min_delivery_days: [],
    max_delivery_days: [],
    shipping_cost_type: [],
    primary_cost: [],
    secondary_cost: [],
  };
  for (let i = 1; i < callbackData.ShippingTemplateEntries.length; i++) {
    const e = callbackData.ShippingTemplateEntries[i];
    ShippingTemplateEntries.destination_country_id.push(
      e.destination_country_id
    );
    ShippingTemplateEntries.shipping_carrier_id.push(e.shipping_carrier_id);
    ShippingTemplateEntries.shipping_template_entry_id.push(
      e.shipping_template_entry_id ?? ""
    );
    ShippingTemplateEntries.mail_class.push(e.mail_class);
    ShippingTemplateEntries.min_delivery_days.push(e.min_delivery_days);
    ShippingTemplateEntries.max_delivery_days.push(e.max_delivery_days);
    ShippingTemplateEntries.shipping_cost_type.push(e.shipping_cost_type);
    ShippingTemplateEntries.primary_cost.push(e.primary_cost);
    ShippingTemplateEntries.secondary_cost.push(e.secondary_cost);
  }

  return ShippingTemplateEntries;
};
export const Createpayload = (
  state: StateI,
  callbackData: any,
  editid: { id: undefined | string; shipping_template_id: undefined | string },
  deleteEntry: { delete_entry: string; delete_upgrade_entry: string }
) => {
  return {
    "delete-entry": deleteEntry.delete_entry,
    "delete-upgrade-entry": deleteEntry.delete_upgrade_entry,
    EtsyShippingTemplate: {
      shipping_template_id: editid.shipping_template_id ?? "",
      id: editid.id ?? "",
      title: state.title,
      weight_1: state.is_enable_weight === "1" ? state.weight_1 : undefined,
      weight_2: state.is_enable_weight === "1" ? state.weight_2 : undefined,
      weight_unit:
        state.is_enable_weight === "1" ? state.weight_unit : undefined,
      origin_country_id: state.origin_country_id,
      min_processing_days: state.min_processing_days,
      max_processing_days: state.max_processing_days ?? "1",
      processing_time_unit: state.processing_time_unit,
      origin_postal_code: state.origin_postal_code,
      destination_country_id: state.origin_country_id,
      shipping_carrier_id:
        callbackData.ShippingTemplateEntries[0].shipping_carrier_id,
      mail_class: callbackData.ShippingTemplateEntries[0].mail_class,
      min_delivery_days:
        callbackData.ShippingTemplateEntries[0].min_delivery_days,
      max_delivery_days:
        callbackData.ShippingTemplateEntries[0].max_delivery_days,
      shipping_cost_type:
        callbackData.ShippingTemplateEntries[0].shipping_cost_type,
      primary_cost: callbackData.ShippingTemplateEntries[0].primary_cost,
      secondary_cost: callbackData.ShippingTemplateEntries[0].secondary_cost,
    },
    is_enable_weight: state.is_enable_weight,
    processingTime: `${state.min_processing_days}-${state.max_processing_days??"1"}|${state.processing_time_unit}`,

    ShippingTemplateEntries: {
      ...getShippingTemplateEntriesPayload(callbackData),
    },
    EtsyShippingUpgrade: {
      ...getEtsyShippingUpgradePayload(callbackData),
    },
  };
};

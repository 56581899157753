import { combineReducers } from "redux";
import authReducer from "../Slices/authSlice";
import planReducer from "../Slices/planSlice";
import userSlice from "../Slices/userSlice";
import dashboardSlice from "../Slices/dashboardSlice";
import profileSlice from "../Slices/profileSlice";
import settingsSlice from "../Slices/settingsSlice";
import activity from "../Slices/activity";

const rootReducer: any = combineReducers({
  auth: authReducer,
  plan: planReducer,
  userDetails: userSlice,
  dashboard: dashboardSlice,
  profile: profileSlice,
  settings: settingsSlice,
  activity: activity,
});

export default rootReducer;

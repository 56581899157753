import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineStack,
  Text,
} from "@shopify/polaris";
import React, { useState } from "react";
import VariantLinkTable from "./VariantLinkTable";
import LocationWiseInventoryModal from "./LocationWiseInventoryModal";
import LinkPhotosModal from "./LinkPhotosModal";
import { useProductEditContext } from "../ProductEditProvider";
import VariantsMapping from "./VariantsMapping";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const VariationInformation = ({ t }: DIProps) => {
  const { product, multilocationEnabled } = useProductEditContext();
  const [locationWiseModalState, setLocationWiseModalState] = useState({
    isOpen: false,
  });
  const [isLinkPhotosModalOpen, setIsLinkPhotosModalOpen] = useState(false);

  const toggleLocationWiseModal = () => {
    setLocationWiseModalState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  const toggleImageLinkingModal = () => {
    setIsLinkPhotosModalOpen((prev) => !prev);
  };

  return (
    <BlockStack gap={"200"}>
      <Box
        paddingInlineEnd={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
        paddingInlineStart={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
      >
        <Text as="h2" variant="headingLg">
          {t("VARIATION_INFORMATION")}
        </Text>
      </Box>
      <Card>
        <BlockStack gap="400">
          <Text as="p" variant="bodyMd">
            {t("VARIATION_INFORMATION_DESC")}
          </Text>
          <Divider />
          {product?.type?.toLowerCase() === "variant" && (
            <>
              <VariantsMapping />
              <Divider />
            </>
          )}

          <BlockStack gap="100">
            <InlineStack align="space-between" gap="100">
              <Text as={"strong"} variant="headingMd" fontWeight="medium">
                {t("VARIANT_DETAILS")}
              </Text>
              <InlineStack align="space-between" gap="200">
                {multilocationEnabled && (
                  <Button onClick={toggleLocationWiseModal}>
                    {t("EDIT_LOCATION_WISE_INVENTORY")}
                  </Button>
                )}
                {product?.type?.toLowerCase() === "variant" && (
                  <Button onClick={toggleImageLinkingModal}>
                    {t("LINK_PHOTOS")}
                  </Button>
                )}
              </InlineStack>
            </InlineStack>
            <VariantLinkTable />
          </BlockStack>
        </BlockStack>
        {/* Modal */}
        {locationWiseModalState.isOpen ? (
          <LocationWiseInventoryModal
            isOpen={locationWiseModalState.isOpen}
            toggleLocationWiseModal={toggleLocationWiseModal}
          />
        ) : null}
        {isLinkPhotosModalOpen && (
          <LinkPhotosModal
            isOpen={isLinkPhotosModalOpen}
            toggleImageLinkingModal={toggleImageLinkingModal}
          />
        )}
      </Card>
    </BlockStack>
  );
};

export default DI(VariationInformation);

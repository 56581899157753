import {
  BlockStack,
  Box,
  Card,
  Checkbox,
  Divider,
  FormLayout,
  Page,
  Select,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import { isoCodeToCountryMap, postalCode } from "Src/Constant/helpData";
import { apiUrls } from "Src/Constant/url";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ShippingStandard from "./ShippingHelper/ShippingStandard";
import DeliveryUpgrade from "./ShippingHelper/DeliveryUpgrade";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { Createpayload } from "./ShippingHelper/Createpayload";
import { ProcessingTimeOptions, unitList } from "Src/Constant/SelectOptions";
import { scrollToSection } from "Src/Component/HelperComponents/Scroll";
import { ExtraModalFormTypeI } from "Src/Interface/@Profile";
import WithHeaderCreateTemplateSkeleton from "../Skeleton/WithHeaderCreateTemplateSkeleton";
import { regexExpressions } from "Src/Constants";

export interface StateI {
  title: string;
  weight_1: string;
  weight_2: string;
  weight_unit: string;
  origin_postal_code: string;
  min_processing_days: string;
  max_processing_days: string;
  processing_time_unit: string;
  destination_country_id: string;
  is_enable_weight: string;
  origin_country_id: string;
  shipping_carrier_id: number;
  mail_class: string;
  shipping_cost_type: string;
  min_delivery_days: string;
  max_delivery_days: string;
  secondary_cost: string;
  primary_cost: string;
}

const { getShippingTempateById, saveShippingTemplate } = apiUrls;
const Shipping = ({
  request: { GET, POST },
  t,
  navigate,
  type = "page",
  setSaveLoading,
  saveCall = false,
  setSaveCall,
  closeModal,
  setTemplateId,
  onCreation
}: ExtraModalFormTypeI & DIProps) => {
  const {mdUp} = useBreakpoints();
  const ReqMsg = "This is Required Field";
  const [state, setState] = useState<StateI>({
    title: "",
    weight_1: "",
    weight_2: "",
    weight_unit: "",
    origin_postal_code: "",
    min_processing_days: "",
    max_processing_days: "",
    processing_time_unit: "",
    destination_country_id: "",
    origin_country_id: "",
    is_enable_weight: "",
    shipping_carrier_id: 0,
    mail_class: "other",
    shipping_cost_type: "Fixed",
    max_delivery_days: "",
    min_delivery_days: "",
    secondary_cost: "",
    primary_cost: "",
  });
  const [shipping_entries, setShipping_entries] = useState<any>();
  const [shipping_upgrade, setShipping_upgrade] = useState<any>();

  const [processingTime, setProcessingTime] = useState("");
  const [delete_upgrade_entry, setDelete_upgrade_entry] = useState("");
  const [delete_entry, setDelete_entry] = useState("");
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const [callbackData, setCallbackData] = useState<any>({
    ShippingTemplateEntries: [],
    EtsyShippingUpgrade: [],
  });
  const { routeData } = useRoutesContext();
  useEffect(() => {
    if (routeData?.data?.shipping_template_id) {
      GET(getShippingTempateById, {
        id: routeData.data.shipping_template_id,
      }).then((res) => {
        const {
          success,
          data: { shipping_data, shipping_entries, shipping_upgrade },
        } = res;
        if (success) {
          const tempState = {
            title: shipping_data.title,
            is_enable_weight: shipping_data.additional_data ? "1" : "0",
            weight_2: shipping_data.additional_data?.weight_2,
            weight_1: shipping_data.additional_data?.weight_1,
            weight_unit: shipping_data.additional_data?.weight_unit,
            origin_country_id: shipping_data?.origin_country_id,
            processing_time_unit: shipping_data?.processing_time_unit,
            origin_postal_code: shipping_data?.origin_postal_code,
            min_processing_days: shipping_data?.min_processing_days?.toString(),
            max_processing_days: shipping_data?.max_processing_days?.toString(),
            shipping_carrier_id: shipping_data.shipping_carrier_id,
            mail_class: shipping_data?.mail_class,
            shipping_cost_type: shipping_data?.shipping_cost_type,
            min_delivery_days: shipping_data?.min_delivery_days?.toString(),
            max_delivery_days: shipping_data?.max_delivery_days?.toString(),
            secondary_cost: shipping_data?.secondary_cost,
            primary_cost: shipping_data?.primary_cost,
          };
          setState((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          setShipping_entries(shipping_entries);
          setShipping_upgrade(shipping_upgrade);

          const pro_time = `${shipping_data.min_processing_days}-${shipping_data.max_processing_days}`;
          const isExists = ProcessingTimeOptions().find(
            (e) => e.value === pro_time
          );

          setProcessingTime(
            shipping_data.processing_time_unit === "business_days" && isExists
              ? pro_time
              : "cstrange"
          );
          setHasBeenCalled(true);
        }
      });
    } else {
      setHasBeenCalled(true);
    }
  }, []);

  const handleProcessingChange = useCallback(
    (value: string) => {
      let min = "";
      let max = "";
      let unit = "business_days";
      if (value === "cstrange") {
        unit = "";
      } else {
        const [val1, val2] = value.split("-");
        min = val1;
        max = val2;
      }

      setError(error.filter((e) => e !== "processingTime"));

      setState((prevState) => ({
        ...prevState,
        min_processing_days: min,
        max_processing_days: max,
        processing_time_unit: unit,
      }));
      setProcessingTime(value);
    },
    [error]
  );

  const getOptionforProcessing = (code: 0 | 1) => {
    let option: { label: string; value: string; disabled: boolean }[] = [];
    for (let i = 0; i < 10; i++) {
      const isDisable = () => {
        if (
          code === 0 &&
          state.max_processing_days !== "" &&
          state.max_processing_days !== "0" &&
          parseInt(state.max_processing_days) - 1 < i
        ) {
          return true;
        }
        if (
          code === 1 &&
          state.min_processing_days !== "" &&
          parseInt(state.min_processing_days) - 1 > i
        ) {
          return true;
        }
        return false;
      };
      option.push({
        label: (i + 1).toString(),
        value: (i + 1).toString(),
        disabled: isDisable(),
      });
    }
    return option;
  };

  const CountryList = useMemo(() => {
    return Object.keys(isoCodeToCountryMap).map((code: string) => ({
      label: isoCodeToCountryMap[code] as string,
      value: code,
    }));
  }, []);

  const getReqProps = (
    code: keyof StateI,
    type?: undefined | string
  ): {
    value: string;
    onChange: (e: string) => void;
    autoComplete: "off";
  } => {
    return {
      value: state[code]?.toString(),
      onChange: (e) => {
        if (type === "number") {
          if (
            regexExpressions.NUMBER_CHECK_WITH_DOT.test(e) ||
            e.trim() === ""
          ) {
            setState((prevState) => ({
              ...prevState,
              [code]: e,
            }));
            if (error.includes(code)) {
              setError(error.filter((e) => e !== code));
            }
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            [code]: e,
          }));
          if (error.includes(code)) {
            setError(error.filter((e) => e !== code));
          }
        }
      },
      autoComplete: "off",
    };
  };

  const validateForm = (state: StateI) => {
    const validateRegexPostal = (value: string) => {
      const regex = postalCode?.[state.origin_country_id]?.[1];
      if (regex) {
        const newValidateRegex = new RegExp(regex);

        return newValidateRegex.test(value);
      }
      return true;
    };
    const tempErr: string[] = [];
    if (state.title.trim() === "") {
      tempErr.push("title");
    }
    if (state.is_enable_weight === "1") {
      if (
        parseInt(state.weight_1 as string) > parseInt(state.weight_2 as string)
      ) {
        tempErr.push("weight_1");
      }
      if (state.weight_1.trim() === "") {
        tempErr.push("weight_1");
      }
      if (state.weight_2.trim() === "") {
        tempErr.push("weight_2");
      }
      if (state.weight_unit.trim() === "") {
        tempErr.push("weight_unit");
      }
    }
    if (state.origin_country_id === "") {
      tempErr.push("origin_country_id");
    }
    if (
      (postalCode?.[state.origin_country_id]?.[0] !== undefined &&
        state.origin_postal_code.trim() === "") ||
      !validateRegexPostal(state.origin_postal_code)
    ) {
      tempErr.push("origin_postal_code");
    }
    if (processingTime === "") {
      tempErr.push("processingTime");
    }
    callbackData.EtsyShippingUpgrade.forEach(
      (single_upgrade: any, index: number) => {
        const checkPrice = () => {
          const price = parseFloat(single_upgrade.price ?? "0");
          const secondary_price = parseFloat(
            single_upgrade.secondary_price ?? "0"
          );
          return price < secondary_price;
        };
        if (single_upgrade.shipping_carrier_id === 0) {
          if (
            single_upgrade.min_delivery_days === undefined ||
            single_upgrade.min_delivery_days === ""
          ) {
            tempErr.push("min_delivery_days-" + index);
          }
          if (
            single_upgrade.max_delivery_days === undefined ||
            single_upgrade.max_delivery_days === ""
          ) {
            tempErr.push("max_delivery_days-" + index);
          }
        }
        if (single_upgrade.price === undefined || single_upgrade.price === "") {
          tempErr.push("price-" + index);
        }
        if (
          single_upgrade.secondary_price === undefined ||
          single_upgrade.secondary_price === "" ||
          checkPrice()
        ) {
          tempErr.push("secondary_price-" + index);
        }
      }
    );
    callbackData.ShippingTemplateEntries.forEach(
      (singleEntries: any, index: number) => {
        const checkPrice = () => {
          const price = parseFloat(singleEntries.primary_cost ?? "0");
          const secondary_price = parseFloat(
            singleEntries.secondary_cost ?? "0"
          );
          return price < secondary_price;
        };
        if (index !== 0 && singleEntries.destination_country_id === "") {
          tempErr.push("destination_country_id-" + index);
        }
        if (singleEntries.shipping_carrier_id === 0) {
          if (
            singleEntries.max_delivery_days === undefined ||
            singleEntries.max_delivery_days === ""
          ) {
            tempErr.push("max_delivery_days-" + index);
          }
          if (
            singleEntries.min_delivery_days === undefined ||
            singleEntries.min_delivery_days === ""
          ) {
            tempErr.push("min_delivery_days-" + index);
          }
        }
        if (singleEntries.shipping_cost_type !== "Free Shipping") {
          if (
            singleEntries.primary_cost === undefined ||
            singleEntries.primary_cost === ""
          ) {
            tempErr.push("primary_cost-" + index);
          }
          if (
            singleEntries.secondary_cost === undefined ||
            singleEntries.secondary_cost === "" ||
            checkPrice()
          ) {
            tempErr.push("secondary_cost-" + index);
          }
        }
      }
    );
    scrollToSection(tempErr?.[0] ?? "");
    setError(tempErr);
    return tempErr.length < 1;
  };
  const handleSave = () => {
    setLoading(true);
    setSaveLoading && setSaveLoading(true);
    const isValid = validateForm(state);
    if (!isValid) {
      setLoading(false);
      setSaveLoading && setSaveLoading(false);
      return;
    }

    const payload = Createpayload(
      state,
      callbackData,
      {
        id: routeData.data.id,
        shipping_template_id: routeData.data.shipping_template_id,
      },
      { delete_entry, delete_upgrade_entry }
    );

    POST(saveShippingTemplate, payload)
      .then((res) => {
        const { success, message } = res;
        if (success) {
          setTemplateId && setTemplateId(res?.template_id as string);
          res?.template_id && onCreation?.({[res?.template_id]: state.title})
          ToastAppBridge(message, { isError: false });
          if (closeModal) {
            closeModal();
          } else {
            navigate("/panel/template");
          }
        } else {
          ToastAppBridge(message, { isError: true });
        }
      })
      .finally(() => {
        setLoading(false);
        setSaveLoading?.(false);
      });
  };
  useEffect(() => {
    if (saveCall) {
      handleSave();
      setSaveCall?.(false);
    }
  }, [saveCall]);
  if (hasBeenCalled)
    return (
      <div>
        <Box borderBlockEndWidth={mdUp ? '025' : '0'} borderColor="border">
          {type !== "modal" && (
            <div className="custom_layout_width">
              <Page
                backAction={{
                  content: t("SHIPPING_TEMPLATE"),
                  onAction: () => {
                    navigate(-1);
                  },
                }}
                fullWidth
                title={t("SHIPPING_TEMPLATE")}
                primaryAction={{
                  content: t("SAVE"),
                  accessibilityLabel: "Save",
                  loading: loading,
                  onAction: handleSave,
                }}
                // secondaryActions={[
                //   { content: "Need Help ?", accessibilityLabel: "Need Help ?" },
                // ]}
              ></Page>
            </div>
          )}
        </Box>
        <div className="custom_layout_width">
          <Page fullWidth>
            <BlockStack gap={"400"}>
              <Card>
                <BlockStack gap={"200"}>
                  <BlockStack gap={"200"}>
                    <div className="custom_required">
                      <Text as="h6" variant="headingMd" fontWeight="semibold">
                        {t("TEMPLATE_NAME")}
                      </Text>
                    </div>
                    <Divider />
                  </BlockStack>
                  <TextField
                    {...getReqProps("title")}
                    id={"title"}
                    error={error.includes("title") ? ReqMsg : undefined}
                    label=""
                    placeholder={t("ENTER_TEMPLATE_NAME")}
                  />
                </BlockStack>
              </Card>
              <Card>
                <BlockStack gap={"200"}>
                  <BlockStack gap={"200"}>
                    <Text as="h6" variant="headingMd" fontWeight="semibold">
                      {t("PACKAGE_WEIGHT_OPTIONS")}
                    </Text>
                    <Divider />
                  </BlockStack>
                  <Checkbox
                    label={t("ENABLE_WEIGHT_OPTION")}
                    checked={state.is_enable_weight === "1"}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        is_enable_weight: e ? "1" : "0",
                      }))
                    }
                  />
                  {state.is_enable_weight === "1" && (
                    <Box paddingInlineStart={'600'}>
                      <FormLayout>
                        <FormLayout.Group condensed={mdUp ? true : false}>
                          <TextField
                            error={
                              error.includes("weight_1")
                                ? state.weight_1.trim() !== ""
                                  ? "Weight From can't be less than Weight To "
                                  : ReqMsg
                                : undefined
                            }
                            {...getReqProps("weight_1", "number")}
                            label={t("WEIGHT_FROM")}
                            placeholder={t("ENTER_VALUE")}
                          />
                          <TextField
                            label={t("WEIGHT_TO")}
                            placeholder={t("ENTER_VALUE")}
                            error={
                              error.includes("weight_2") ? ReqMsg : undefined
                            }
                            {...getReqProps("weight_2", "number")}
                          />
                          <Select
                            placeholder={t("SELECT_UNIT")}
                            label={t("UNIT")}
                            options={unitList()}
                            error={
                              error.includes("weight_unit") ? ReqMsg : undefined
                            }
                            {...getReqProps("weight_unit")}
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    </Box>
                  )}
                </BlockStack>
              </Card>

              <Card>
                <BlockStack gap={"300"}>
                  <BlockStack gap={"200"}>
                    <Text as="h6" variant="headingMd" fontWeight="semibold">
                      {t("SHIPPING_INFO")}
                    </Text>
                    <Divider />
                  </BlockStack>
                  <BlockStack gap={"300"}>
                    <BlockStack gap={"200"}>
                      <Select
                        requiredIndicator
                        placeholder={t("SELECT")}
                        label={t("DISPATCH_ORIGIN")}
                        options={CountryList}
                        {...getReqProps("origin_country_id")}
                        id={"origin_country_id"}
                        error={
                          error.includes("origin_country_id")
                            ? ReqMsg
                            : undefined
                        }
                        helpText={t("SELECT_LOCATION_FROM_WHERE_PRODUCT_DISPATCH")}
                      />
                      {state.origin_country_id !== "" && (
                        <BlockStack gap={"200"}>
                          <ShippingStandard
                            error={error}
                            setError={setError}
                            state={state}
                            setDelete_entry={setDelete_entry}
                            delete_entry={delete_entry}
                            shipping_entries={shipping_entries}
                            getdata={(e: any) => {
                              setCallbackData((prev: any) => ({
                                ...prev,
                                ShippingTemplateEntries: e.data,
                              }));
                            }}
                          />
                          <DeliveryUpgrade
                            error={error}
                            setError={setError}
                            state={state}
                            setDelete_upgrade_entry={setDelete_upgrade_entry}
                            delete_upgrade_entry={delete_upgrade_entry}
                            shipping_upgrade={shipping_upgrade}
                            getdata={(e: any) => {
                              setCallbackData((prev: any) => ({
                                ...prev,
                                EtsyShippingUpgrade: e.data,
                              }));
                            }}
                          />
                        </BlockStack>
                      )}
                    </BlockStack>
                    <Divider />
                    <Select
                      error={
                        error.includes("processingTime") ? ReqMsg : undefined
                      }
                      id="processingTime"
                      requiredIndicator
                      placeholder={t("SELECT")}
                      label={t("PROCESSING_TIME")}
                      options={ProcessingTimeOptions()}
                      value={processingTime}
                      onChange={handleProcessingChange}
                      helpText={t("SELECT_THE_TIME_TO_SHIP_ONE_ORDER")}
                    />
                    {processingTime === "cstrange" && (
                      <FormLayout>
                        <FormLayout.Group condensed={mdUp ? true : false}>
                          <Select
                            label={t("MIN_PROCESSING_TIME")}
                            placeholder={t("MIN_PROCESSING_TIME_PLACEHOLDER")}
                            options={getOptionforProcessing(0)}
                            {...getReqProps("min_processing_days")}
                          />
                          <Select
                            options={getOptionforProcessing(1)}
                            label={t("MAX_PROCESSING_TIME")}
                            placeholder={t("MAX_PROCESSING_TIME_PLACEHOLDER")}
                            {...getReqProps("max_processing_days")}
                          />
                          <Select
                            placeholder={t("SELECT_UNIT")}
                            label={t("UNIT")}
                            options={[
                              {
                                label: t("BUSINESS_DAYS"),
                                value: "business_days",
                              },
                              { label: t("WEEKS"), value: "weeks" },
                            ]}
                            {...getReqProps("processing_time_unit")}
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    )}
                    <Divider />
                    <TextField
                      id="origin_postal_code"
                      placeholder={
                        postalCode?.[state.origin_country_id]?.[0] ?? "####"
                      }
                      error={
                        error.includes("origin_postal_code")
                          ? state.origin_postal_code !== ""
                            ? t("INVALID_POST_CODE")
                            : ReqMsg
                          : undefined
                      }
                      requiredIndicator={
                        postalCode?.[state.origin_country_id]?.[0] !== undefined
                      }
                      label={t("ORIGIN_POST_CODE")}
                      {...getReqProps("origin_postal_code")}
                      helpText={t("ORIGIN_POST_CODE_HELPTEXT")}
                    />
                  </BlockStack>
                </BlockStack>
              </Card>
            </BlockStack>
          </Page>
        </div>
      </div>
    );
  return <WithHeaderCreateTemplateSkeleton />;
};

export default DI(Shipping);

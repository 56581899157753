import {
  BlockStack,
  Box,
  Card,
  Checkbox,
  InlineGrid,
  RadioButton,
  Text,
} from "@shopify/polaris";
import React from "react";
import { isConfigChecked, toggleArrElement } from "Src/function";
import { useProductEditContext } from "../ProductEditProvider";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const ProductSyncing = ({ t }: DIProps) => {
  const { localProduct, updateLocalProduct } = useProductEditContext();

  const customSettingOptions = [
    { label: t("TITLE"), val: "title" },
    { label: t("IMAGE"), val: "images" },
    { label: t("TAGS"), val: "tags" },
    { label: t("INVENTORY"), val: "inventory" },
    { label: t("WEIGHT"), val: "weight" },
    { label: t("PRICE"), val: "price" },
    { label: t("DESCRIPTION"), val: "description" },
    { label: t("IS_DIGITAL"), val: "requires_shipping" },
    { label: t("VIDEO"), val: "shopify_videos" },
  ];

  const toggleAttributeSync = (val: "global" | "custom") => {
    updateLocalProduct({ sync_fields: val === "global" ? null : "" });
  };

  const toggleCustomSyncOptions = (attribute: string) => {
    const result = toggleArrElement(
      Object.keys(localProduct?.sync_fields),
      attribute
    );
    const sync_fields = result.reduce((prev, curr) => {
      return { ...prev, [curr]: "1" };
    }, {});
    updateLocalProduct({
      sync_fields,
    });
  };

  return (
    <BlockStack gap={"200"}>
      <Box
        paddingInlineEnd={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
        paddingInlineStart={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
      >
        <Text as="h2" variant="headingLg">
          {t("PRODUCT_SYNCING")}
        </Text>
      </Box>
      <Card>
        <BlockStack gap={"400"}>
          <Text as={"strong"} variant="bodyMd">
            {t("MANAGE_THE_SYNCING_OF_PRODUCT_ATTRIBUTES")}
          </Text>
          <BlockStack gap={"050"}>
            <BlockStack gap={"100"}>
              <RadioButton
                label={t("GLOBAL_SETTINGS")}
                checked={localProduct?.sync_fields === null}
                id="globalSetting"
                name="accounts"
                onChange={() => toggleAttributeSync("global")}
              />
              <RadioButton
                label={t("CUSTOM_SETTINGS")}
                id="customSetting"
                name="accounts"
                checked={localProduct?.sync_fields !== null}
                onChange={() => toggleAttributeSync("custom")}
              />
            </BlockStack>
            <div className="pl-25">
              {localProduct?.sync_fields !== null && (
                <InlineGrid columns={["oneThird", "twoThirds"]} gap={"100"}>
                  {customSettingOptions.map((item, index) => {
                    return (
                      <Checkbox
                        label={item.label}
                        checked={isConfigChecked(
                          localProduct?.sync_fields?.[item.val]
                        )}
                        onChange={() => toggleCustomSyncOptions(item.val)}
                      />
                    );
                  })}
                </InlineGrid>
              )}
            </div>
          </BlockStack>
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(ProductSyncing);

import React, { useCallback, useState } from 'react'
import OrderGridSkeleton from './OrderGridSkeleton'
import { BlockStack, Card, ChoiceList, Divider, IndexFilters, IndexFiltersMode, IndexFiltersProps, InlineStack, RangeSlider, SkeletonDisplayText, TabProps, TextField, useSetIndexFiltersMode } from '@shopify/polaris'

const FullOrderGridSkeleton = () => {
  const searchField = <InlineStack gap={"200"}>
    <div style={{ 'width': '120px' }}>
      <SkeletonDisplayText size="small" />
    </div>
  </InlineStack>
  return (
    <Card padding={'0'}>
      <BlockStack>
        <div className='index-filter_skeleton'>
          <InlineStack align='space-between'>
            {searchField}
            <InlineStack gap={"200"}>
              <div style={{ 'width': '110px' }}>
                <SkeletonDisplayText size="small" />
              </div>
              <div style={{ 'width': '28px' }}>
                <SkeletonDisplayText size="small" />
              </div>
              <div style={{ 'width': '28px' }}>
                <SkeletonDisplayText size="small" />
              </div>
            </InlineStack>
          </InlineStack>
        </div>
        <Divider />
        <OrderGridSkeleton />
      </BlockStack>
    </Card>
  )
}

export default FullOrderGridSkeleton

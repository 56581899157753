import { BlockStack, Box, Card, InlineStack, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'

const GeneralInformationSkelton = () => {
    return (

        <BlockStack gap={"300"}>
            <Box paddingInlineEnd={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }} paddingInlineStart={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }}>
                <div style={{ "width": "150px" }} className='skeleton-height-20'>
                    <SkeletonBodyText lines={1} />
                </div>
            </Box>
            <Card>
                <BlockStack gap={"800"}>
                    <BlockStack gap={"300"}>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <div className='skeleton-width-100'>
                                <SkeletonDisplayText />
                            </div>
                        </BlockStack>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "50%" }}>
                                <SkeletonBodyText lines={1} />
                            </div>

                            {Array.from({ length: 5 }, (index: any) =>
                                <div style={{ "width": "40%" }}>
                                    <InlineStack wrap={false} gap={"200"}>
                                        <div style={{ "width": "8px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />
                                    </InlineStack>
                                </div>
                            )}

                        </BlockStack>
                    </BlockStack>
                    <BlockStack gap={"300"}>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <div className='skeleton-width-100'>
                                <SkeletonDisplayText />
                            </div>
                        </BlockStack>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "50%" }}>
                                <SkeletonBodyText lines={1} />
                            </div>

                            {Array.from({ length: 1 }, (index: any) =>
                                <div style={{ "width": "40%" }}>
                                    <InlineStack wrap={false} gap={"200"}>
                                        <div style={{ "width": "8px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />
                                    </InlineStack>
                                </div>
                            )}

                        </BlockStack>
                    </BlockStack>
                    <BlockStack gap={"300"}>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <div className='skeleton-width-100 skeleton-height-62'>
                                <SkeletonDisplayText />
                            </div>
                        </BlockStack>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "50%" }}>
                                <SkeletonBodyText lines={1} />
                            </div>

                            {Array.from({ length: 3 }, (index: any) =>
                                <div style={{ "width": "40%" }}>
                                    <InlineStack wrap={false} gap={"200"}>
                                        <div style={{ "width": "8px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />
                                    </InlineStack>
                                </div>
                            )}

                        </BlockStack>
                    </BlockStack>

                </BlockStack>
            </Card>
        </BlockStack>

    )
}

export default GeneralInformationSkelton

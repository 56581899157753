import { BlockStack, Card, Divider, Grid, InlineStack, Layout, Page, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'
import OnboardOverviewSkeleton from './component/OnboardOverviewSkeleton'
import TotalOrderSkeleton from './component/TotalOrderSkeleton'
import RevenueChartSkeleton from './component/RevenueChartSkeleton'
import TopSellingProductsSkeleton from './component/TopSellingProductsSkeleton'
import ConnectedShopSkeleton from './component/ConnectedShopSkeleton'
import LimitCardSkeleton from './component/LimitCardSkeleton'
import ActivityCardSkeleton from './component/ActivityCardSkeleton'
import AppExperienceSkeleton from './component/AppExperienceSkeleton'

const DashboardSkeleton = () => {
  return (
    <div className="custom_layout_width dashboard_width">
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <BlockStack gap={"500"}>
              <OnboardOverviewSkeleton />
              {/* Order analysis */}
              <Card>
                <BlockStack gap={"200"}>
                  <InlineStack align='space-between' blockAlign='center'>
                    <div style={{ 'width': "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ 'width': "100px" }}>
                      <SkeletonDisplayText size='small' />
                    </div>
                  </InlineStack>
                  <Divider />
                  <div className="custom_card_height_balance">
                    <Grid>
                      <Grid.Cell
                        columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                      >
                        <TotalOrderSkeleton />
                      </Grid.Cell>
                      <Grid.Cell
                        columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                      >
                        <RevenueChartSkeleton />
                      </Grid.Cell>
                    </Grid>
                  </div>
                </BlockStack>
                {/* <SkeletonBodyText /> */}
              </Card>
              <Card>
                <BlockStack gap={"200"}>
                  <InlineStack align='space-between' blockAlign='center'>
                    <div style={{ 'width': "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ 'width': "100px" }}>
                      <SkeletonDisplayText size='small' />
                    </div>
                  </InlineStack>
                  <Divider />
                  <div className="custom_card_height_balance">
                    <Grid>
                      <Grid.Cell
                        columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                      >
                        <TotalOrderSkeleton />
                      </Grid.Cell>
                      <Grid.Cell
                        columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                      >
                        <TopSellingProductsSkeleton />
                      </Grid.Cell>
                    </Grid>
                  </div>
                </BlockStack>
                {/* <SkeletonBodyText /> */}
              </Card>
            </BlockStack>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <BlockStack gap={"500"}>
              <ConnectedShopSkeleton />
              <ActivityCardSkeleton />
              <LimitCardSkeleton />
              <AppExperienceSkeleton />
            </BlockStack>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  )
}

export default DashboardSkeleton

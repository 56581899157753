import { BlockStack, Card, Divider, InlineStack, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from '@shopify/polaris'
import React from 'react'

const OnboardOverviewSkeleton = () => {
  return (
    <Card>
      <BlockStack gap={"400"}>
        <BlockStack gap={"300"}>
          <InlineStack align='space-between' blockAlign='center'>
            <div style={{ 'width': "100px" }}>
              <SkeletonBodyText lines={1} />
            </div>
            <SkeletonThumbnail size='extraSmall' />
          </InlineStack>
          <Divider />
        </BlockStack>
        <BlockStack gap={"400"}>
          <InlineStack gap={"200"} blockAlign='center'>
            <div style={{ "width": "12px" }} className='skeleton-height-11'>
              <SkeletonBodyText lines={1} />
            </div>
            <div style={{ "width": "100px" }}>
              <SkeletonBodyText lines={1} />
            </div>
          </InlineStack>
          <InlineStack gap={"200"} blockAlign='center'>
            <div style={{ "width": "12px" }} className='skeleton-height-11'>
              <SkeletonBodyText lines={1} />
            </div>
            <div style={{ "width": "100px" }}>
              <SkeletonBodyText lines={1} />
            </div>
          </InlineStack>
          <InlineStack gap={"200"} blockAlign='start'>
            <div style={{ "width": "12px" }} className='skeleton-height-11'>
              <SkeletonBodyText lines={1} />
            </div>
            <BlockStack gap={"300"}>
              <div style={{ "width": "100px" }}>
                <SkeletonBodyText lines={1} />
              </div>
              <div style={{ "width": "200px" }}>
                <SkeletonBodyText lines={1} />
              </div>
              <InlineStack gap={"200"}>
                <div className='skeleton-height-32px' style={{ "width": "100px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <div className='skeleton-height-32px' style={{ "width": "100px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
              </InlineStack>
            </BlockStack>
          </InlineStack>
        </BlockStack>
      </BlockStack>
      {/* <SkeletonBodyText /> */}
    </Card>
  )
}

export default OnboardOverviewSkeleton

import { Card, IndexTable, useIndexResourceState } from "@shopify/polaris";
import React, { useMemo } from "react";
import { useProductEditContext } from "../ProductEditProvider";
import VariantLinkRow from "./VariantLinkRow";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
const VariantLinkTable = () => {
  const { variants, localVariants, multilocationEnabled } =
    useProductEditContext();

  const { selectedResources, handleSelectionChange } =
    useIndexResourceState(localVariants);

  const extraHeadings = useMemo(() => {
    const headingsArr: IndexTableHeading[] = [];
    !multilocationEnabled && headingsArr.push({ title: "Inventory" });
    return headingsArr;
  }, [multilocationEnabled]);

  const rowMarkup = localVariants.map((item, index) => (
    <IndexTable.Row
      id={item?.id}
      key={item?.id}
      selected={selectedResources.includes(item?.id)}
      position={index}
    >
      <VariantLinkRow id={item?.id} rowItem={item} index={index} />
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <IndexTable
        itemCount={variants.length}
        selectable={false}
        onSelectionChange={handleSelectionChange}
        headings={[
          { title: "Image" },
          { title: "SKU" },
          { title: "Title" },
          { title: "Price (USD)" },
          ...extraHeadings,
          { title: "Disable Variant", alignment: "center" },
        ]}
      >
        {rowMarkup}
      </IndexTable>
    </Card>
  );
};

export default VariantLinkTable;

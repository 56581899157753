import { Banner, BlockStack, Box, Card, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'

const FullPageSkeletonProductImport = () => {
    return (
        <div>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className="custom_layout_width">
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>
                            <InlineStack align='space-between' blockAlign='center'>
                                <InlineStack gap={"200"} blockAlign='center'>
                                    <div style={{ "width": "20px" }} className='skeleton-height-20'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'>
                                        <SkeletonBodyText lines={1} />
                                    </div>

                                </InlineStack>
                                <InlineStack gap={"200"}>
                                    <div className='skeleton-height-32px' style={{ "width": "52px" }}>
                                        <SkeletonDisplayText size='small' />
                                    </div>
                                </InlineStack>
                            </InlineStack>
                        </Page>
                    </div>
                </div>
            </Box>
            <Box>
                <div className="custom_layout_width">
                    <Page fullWidth>
                        <BlockStack gap={"600"}>
                            <Card>
                                <BlockStack gap={"400"}>
                                    <div style={{ "width": "50%" }} className='skeleton-height-20'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <SkeletonBodyText lines={5} />
                                </BlockStack>
                            </Card>
                            <Card>
                                <BlockStack gap={"400"}>
                                    <div style={{ "width": "150px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <InlineStack gap={"200"} blockAlign='center'>
                                        <div style={{ "width": "20px" }} className='skeleton-height-20'>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div style={{ "width": "80px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </InlineStack>
                                    <div className='skeleton-width-100'>
                                        <SkeletonDisplayText />
                                    </div>
                                </BlockStack>
                            </Card>
                        </BlockStack>
                    </Page>
                </div>
            </Box>
        </div>
    )
}

export default FullPageSkeletonProductImport

import { RuleRowsI } from "Src/Interface/@Profile";

/**
 * Formats the template into an array of options for the Select component.
 *
 * @param {undefined | { [key: string]: string }} template - The template to format.
 * @returns {Array<{ label: string; value: string }>} - The formatted options.
 */
export const formatTemplateOptions = (
  template: undefined | { [key: string]: string }
): { label: string; value: string }[] => {
  if (template && Object.keys(template).length > 0) {
    return Object.entries(template)
      .map(([key, value]) => {
        return { label: value, value: key };
      })
      .filter((options) => options.value !== "");
  } else {
    return [];
  }
};

export const formatRuleGroup = (ruleRows: RuleRowsI[]) => {
  return ruleRows.reduce(
    (acc: { [key: string]: string[][] }, row, index) => {
      acc.Product_property_selected[index] = [row.property];
      acc.Operator_selected_for_property[index] = [row.operator];
      if (row.type.includes("select")) {
        acc.select_field_for_condition[index] = row.value;
      } else {
        acc.input_field_for_condition[index] = row.value;
      }
      return acc;
    },
    {
      Product_property_selected: [],
      Operator_selected_for_property: [],
      select_field_for_condition: [],
      input_field_for_condition: [],
    }
  );
};

import { BlockStack, Box, Card, InlineStack, SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris'
import React from 'react'

const ImageVideoSkeleton = () => {
    return (
        <BlockStack gap={"300"}>
            <Box paddingInlineEnd={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }} paddingInlineStart={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }}>
                <div style={{ "width": "150px" }} className='skeleton-height-20'>
                    <SkeletonBodyText lines={1} />
                </div>
            </Box>
            <Card>
                <BlockStack gap={"300"}>
                    <SkeletonBodyText lines={1} />
                    <InlineStack gap={"300"}>
                        {Array.from({ length: 5 }, (index: any) =>
                            <SkeletonThumbnail size='large' />
                        )}
                    </InlineStack>
                </BlockStack>
            </Card>
        </BlockStack>
    )
}

export default ImageVideoSkeleton

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  profileData: {},
};

const profileSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    saveProfileData: (state, action: PayloadAction<{ profileData: any }>) => {
      state.profileData = action.payload.profileData;
    },
  },
});

export const { saveProfileData } = profileSlice.actions;

export default profileSlice.reducer;

import React, { useState } from "react";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import Shipping from "Src/Component/Templates/Forms/Components/Shipping";
import { DI } from "Src/core";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { useProfileContext } from "../../ProfileProvider";
import Inventory from "Src/Component/Templates/Forms/Components/Inventory";
import PolicyForm from "Src/Component/Templates/Forms/Components/PolicyForm";
import Price from "Src/Component/Templates/Forms/Components/Price";

const GeneralCreateModal = ({ t }: DIProps) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveCall, setSaveCall] = useState(false);
  const {
    createModal,
    setCreateModal,
    saveTemplates,
    saveExtraOptions,
    extraTemplateOptions,
    removeError
  } = useProfileContext();

  const closeModal = (reload = false) => {
    setCreateModal({
      isOpen: false,
      type: "",
    });
  };

  let body = <></>;
  let title = "";
  const getprops = {
    type: "modal",
    setSaveLoading: setSaveLoading,
    saveCall: saveCall,
    setSaveCall: setSaveCall,
    closeModal: () => closeModal(true),
    setTemplateId: (e: string) => {
      if (createModal.type === "shipping") {
        removeError("shipping_templates")
        saveTemplates("shipping_templates", e.toString());
      }
      if (createModal.type === "inventory") {
        saveTemplates("inventory_templates", e.toString());
      }
      if (createModal.type === "policy") {
        removeError("policy_templates")
        saveTemplates("policy_templates", e.toString());
      }
      if (createModal.type === "price") {
        saveTemplates("price_templates", e.toString());
      }
    },
    onCreation: (obj: ObjStrI) => {
      if (createModal.type) {
        const prevOptions = extraTemplateOptions?.[createModal.type];
        const [key, val] = Object.entries(obj)?.[0];
        saveExtraOptions(createModal.type, [
          ...prevOptions,
          { label: val, value: key },
        ]);
      }
    },
  };
  if (createModal.type === "shipping") {
    title = t("CREATE_SHIPPING_TEMPLATE");
    body = <Shipping {...getprops} />;
  }
  if (createModal.type === "inventory") {
    title = t("CREATE_INVENTORY_TEMPLATE");
    body = <Inventory {...getprops} />;
  }
  if (createModal.type === "policy") {
    title = t("CREATE_POLICY_TEMPLATE");
    body = <PolicyForm {...getprops} />;
  }
  if (createModal.type === "price") {
    title = t("CREATE_PRICE_TEMPLATE");
    body = <Price {...getprops} />;
  }

  return (
    <ModalAppBridge
      body={body}
      id={title}
      variant="max"
      open={createModal.isOpen}
      title={title}
      footer={
        <>
          <button
            variant="primary"
            loading={saveLoading && "true"}
            onClick={() => {
              setSaveCall(true);
            }}
          >
            {t("SAVE")}
          </button>
        </>
      }
      onHide={() => closeModal()}
    />
  );
};

export default DI(GeneralCreateModal);

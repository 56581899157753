import { TFunction } from "i18next";
import { SelectGroup } from "@shopify/polaris";

export const ProcessingTimeOptions = () => [
  {
    label: "1 business days",
    value: "1",
  },
  {
    label: "1-2 business days",
    value: "1-2",
  },
  {
    label: "1-3 business days",
    value: "1-3",
  },
  {
    label: "3-5 business days",
    value: "3-5",
  },
  {
    label: "5-7 business days",
    value: "5-7",
  },
  {
    label: "Custom range",
    value: "cstrange",
  },
];

export const unitList = () => [
  { label: "Gram", value: "g" },
  { label: "Kilogram", value: "kg" },
  { label: "Pound", value: "lb" },
  { label: "Ounce", value: "oz" },
];

export const roundOffOptions = (t: TFunction) => [
  { label: t("WHOLE_NUMBER"), value: "WholeNumber" },
  { label: t("END_WITH", { number: "0.49" }), value: "EndWith0.49" },
  { label: t("END_WITH", { number: "0.59" }), value: "EndWith0.59" },
  { label: t("END_WITH", { number: "0.99" }), value: "EndWith0.99" },
];

export const roundOffOptionsGroups = (t: TFunction) => [
  {
    title: t("NEAREST_HIGHER"),
    options: roundOffOptions(t).map((option) => ({
      ...option,
      value: `higher${option.value}`,
    })),
  },
  {
    title: t("NEAREST_LOWER"),
    options: roundOffOptions(t).map((option) => ({
      ...option,
      value: `lower${option.value}`,
    })),
  },
  {
    title: t("NO_ROUNDING_OFF"),
    options: [{ label: t("NO_ROUND_OFF"), value: " " }],
  },
];

export const customPriceTypeOptions = [
  { label: "Decrease", value: "decreased_price" },
  { label: "Increase", value: "increased_price" },
];

export const amountInOptions = [
  { label: "Fixed", value: "fixed" },
  { label: "Percentage", value: "percentage" },
];

export const propertyOptions = (t: TFunction<"translation", undefined>) => {
  return [
    { label: t("PRODUCT_TITLE"), value: "title" },
    { label: t("PRODUCT_VENDOR"), value: "vendor" },
    { label: t("PRODUCT_TYPE"), value: "product_type" },
    { label: t("Tags"), value: "tags" },
  ];
};

export const returnDeadlineOptions = (t: TFunction) => [
  { label: `7 ${t("DAYS_OF_DELIVERY")}`, value: "7" },
  { label: `14 ${t("DAYS_OF_DELIVERY")}`, value: "14" },
  { label: `21 ${t("DAYS_OF_DELIVERY")}`, value: "21" },
  { label: `30 ${t("DAYS_OF_DELIVERY")}`, value: "30" },
  { label: `45 ${t("DAYS_OF_DELIVERY")}`, value: "45" },
  { label: `60 ${t("DAYS_OF_DELIVERY")}`, value: "60" },
  { label: `90 ${t("DAYS_OF_DELIVERY")}`, value: "90" },
];

export const operationOptions = (
  t: TFunction<"translation", undefined>,
  disabled?: string[]
) => {
  return [
    {
      label: t("Exist in"),
      value: "IN({operator})",
      disabled: !!disabled?.includes("IN({operator})"),
    },
    { label: t("Equals"), value: "=", disabled: !!disabled?.includes("=") },
    {
      label: t("Not equals"),
      value: "!=",
      disabled: !!disabled?.includes("!="),
    },
    {
      label: t("Starts with"),
      value: "LIKE {operator}%",
      disabled: !!disabled?.includes("LIKE {operator}%"),
    },
    {
      label: t("Ends with"),
      value: "LIKE %{operator}",
      disabled: !!disabled?.includes("LIKE %{operator}"),
    },
    {
      label: t("Contains"),
      value: "LIKE %{operator}%",
      disabled: !!disabled?.includes("LIKE %{operator}%"),
    },
    {
      label: t("Does not contains"),
      value: "NOT LIKE %{operator}%",
      disabled: !!disabled?.includes("NOT LIKE %{operator}%"),
    },
  ];
};
export const renewalOptions = [
  { label: "Automatic", value: "1" },
  { label: "Manual", value: "0" },
];

export const yesNoOptions = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];

export const whoMadeItOptions: SelectGroup[] = [
  {
    title: "Select a maker",
    options: [
      { label: "I did", value: "i_did" },
      { label: "A member of my shop", value: "collective" },
      { label: "Another company or person", value: "someone_else" },
    ],
  },
];

export const whatIsItOptions: SelectGroup[] = [
  {
    title: "Select a use",
    options: [
      { label: "A finished product", value: "0" },
      { label: "A supply or tool to make things", value: "1" },
    ],
  },
];

export const whenMadeOptions: SelectGroup[] = [
  {
    title: "Not yet made",
    options: [{ label: "made-to-order", value: "made_to_order" }],
  },
  {
    title: "Recently",
    options: [
      { label: "2020-2024", value: "2020_2024" },
      { label: "2010-2019", value: "2010_2019" },
      { label: "2005-2009", value: "2005_2009" },
    ],
  },
  {
    title: "Vintage",
    options: [
      { label: "before-2005", value: "before_2005" },
      { label: "2000-2004", value: "2000_2004" },
      { label: "1990s", value: "1990s" },
      { label: "1980s", value: "1980s" },
      { label: "1970s", value: "1970s" },
      { label: "1960s", value: "1960s" },
      { label: "1950s", value: "1950s" },
      { label: "1940s", value: "1940s" },
      { label: "1930s", value: "1930s" },
      { label: "1920s", value: "1920s" },
      { label: "1910s", value: "1910s" },
      { label: "1800s", value: "1800s" },
      { label: "1700s", value: "1700s" },
      { label: "before-1700", value: "before_1700" },
    ],
  },
];

export const settigsTabs = (t: TFunction) => [
  {
    _id: 0,
    label: t("GENERAL_ETSY_SETTINGS"),
    key: "generalEtsySettings",
  },
  {
    _id: 1,
    label: t("PRODUCT_MANAGEMENT"),
    key: "productManagement",
  },
  {
    _id: 2,
    label: t("ORDER_MANAGEMENT"),
    key: "orderManagement",
  },
  {
    _id: 3,
    label: t("SUBSCRIPTION"),
    key: "accountDetails",
  },
  {
    _id: 4,
    label: t("NOTIFICATIONS"),
    key: "notifications",
  },
  {
    _id: 5,
    label: t("SHOPIFY_DETAILS"),
    key: "shopDetails",
  },
];

export const times_preference = [
  { label: "0-2 am", value: "0-2 am" },
  { label: "2-4 am", value: "2-4 am" },
  { label: "4-6 am", value: "4-6 am" },
  { label: "6-8 am", value: "6-8 am" },
  { label: "8-10 am", value: "8-10 am" },
  { label: "10-12 am", value: "10-12 am" },
  { label: "12-2 pm", value: "12-2 pm" },
  { label: "2-4 pm", value: "2-4 pm" },
  { label: "4-6 pm", value: "4-6 pm" },
  { label: "6-8 pm", value: "6-8 pm" },
  { label: "8-10 pm", value: "8-10 pm" },
  { label: "10-12 pm", value: "10-12 pm" },
];

export const time_zones = [
  {
    title: "Africa",
    options: [
      { label: "Africa/Abidjan", value: "Africa/Abidjan" },
      { label: "Africa/Accra", value: "Africa/Accra" },
      { label: "Africa/Addis_Ababa", value: "Africa/Addis_Ababa" },
      { label: "Africa/Algiers", value: "Africa/Algiers" },
      { label: "Africa/Asmara", value: "Africa/Asmara" },
      { label: "Africa/Bamako", value: "Africa/Bamako" },
      { label: "Africa/Bangui", value: "Africa/Bangui" },
      { label: "Africa/Banjul", value: "Africa/Banjul" },
      { label: "Africa/Bissau", value: "Africa/Bissau" },
      { label: "Africa/Blantyre", value: "Africa/Blantyre" },
      { label: "Africa/Brazzaville", value: "Africa/Brazzaville" },
      { label: "Africa/Bujumbura", value: "Africa/Bujumbura" },
      { label: "Africa/Cairo", value: "Africa/Cairo" },
      { label: "Africa/Casablanca", value: "Africa/Casablanca" },
      { label: "Africa/Ceuta", value: "Africa/Ceuta" },
      { label: "Africa/Conakry", value: "Africa/Conakry" },
      { label: "Africa/Dakar", value: "Africa/Dakar" },
      { label: "Africa/Dar_es_Salaam", value: "Africa/Dar_es_Salaam" },
      { label: "Africa/Djibouti", value: "Africa/Djibouti" },
      { label: "Africa/Douala", value: "Africa/Douala" },
      { label: "Africa/El_Aaiun", value: "Africa/El_Aaiun" },
      { label: "Africa/Freetown", value: "Africa/Freetown" },
      { label: "Africa/Gaborone", value: "Africa/Gaborone" },
      { label: "Africa/Harare", value: "Africa/Harare" },
      { label: "Africa/Johannesburg", value: "Africa/Johannesburg" },
      { label: "Africa/Juba", value: "Africa/Juba" },
      { label: "Africa/Kampala", value: "Africa/Kampala" },
      { label: "Africa/Khartoum", value: "Africa/Khartoum" },
      { label: "Africa/Kigali", value: "Africa/Kigali" },
      { label: "Africa/Kinshasa", value: "Africa/Kinshasa" },
      { label: "Africa/Lagos", value: "Africa/Lagos" },
      { label: "Africa/Libreville", value: "Africa/Libreville" },
      { label: "Africa/Lome", value: "Africa/Lome" },
      { label: "Africa/Luanda", value: "Africa/Luanda" },
      { label: "Africa/Lubumbashi", value: "Africa/Lubumbashi" },
      { label: "Africa/Lusaka", value: "Africa/Lusaka" },
      { label: "Africa/Malabo", value: "Africa/Malabo" },
      { label: "Africa/Maputo", value: "Africa/Maputo" },
      { label: "Africa/Maseru", value: "Africa/Maseru" },
      { label: "Africa/Mbabane", value: "Africa/Mbabane" },
      { label: "Africa/Mogadishu", value: "Africa/Mogadishu" },
      { label: "Africa/Monrovia", value: "Africa/Monrovia" },
      { label: "Africa/Nairobi", value: "Africa/Nairobi" },
      { label: "Africa/Ndjamena", value: "Africa/Ndjamena" },
      { label: "Africa/Niamey", value: "Africa/Niamey" },
      { label: "Africa/Nouakchott", value: "Africa/Nouakchott" },
      { label: "Africa/Ouagadougou", value: "Africa/Ouagadougou" },
      { label: "Africa/Porto-Novo", value: "Africa/Porto-Novo" },
      { label: "Africa/Sao_Tome", value: "Africa/Sao_Tome" },
      { label: "Africa/Tripoli", value: "Africa/Tripoli" },
      { label: "Africa/Tunis", value: "Africa/Tunis" },
      { label: "Africa/Windhoek", value: "Africa/Windhoek" },
    ],
  },
  {
    title: "America",
    options: [
      { label: "America/Adak", value: "America/Adak" },
      { label: "America/Anchorage", value: "America/Anchorage" },
      { label: "America/Anguilla", value: "America/Anguilla" },
      { label: "America/Antigua", value: "America/Antigua" },
      { label: "America/Araguaina", value: "America/Araguaina" },
      {
        label: "America/Argentina/Buenos_Aires",
        value: "America/Argentina/Buenos_Aires",
      },
      {
        label: "America/Argentina/Catamarca",
        value: "America/Argentina/Catamarca",
      },
      {
        label: "America/Argentina/Cordoba",
        value: "America/Argentina/Cordoba",
      },
      { label: "America/Argentina/Jujuy", value: "America/Argentina/Jujuy" },
      {
        label: "America/Argentina/La_Rioja",
        value: "America/Argentina/La_Rioja",
      },
      {
        label: "America/Argentina/Mendoza",
        value: "America/Argentina/Mendoza",
      },
      {
        label: "America/Argentina/Rio_Gallegos",
        value: "America/Argentina/Rio_Gallegos",
      },
      { label: "America/Argentina/Salta", value: "America/Argentina/Salta" },
      {
        label: "America/Argentina/San_Juan",
        value: "America/Argentina/San_Juan",
      },
      {
        label: "America/Argentina/San_Luis",
        value: "America/Argentina/San_Luis",
      },
      {
        label: "America/Argentina/Tucuman",
        value: "America/Argentina/Tucuman",
      },
      {
        label: "America/Argentina/Ushuaia",
        value: "America/Argentina/Ushuaia",
      },
      { label: "America/Aruba", value: "America/Aruba" },
      { label: "America/Asuncion", value: "America/Asuncion" },
      { label: "America/Atikokan", value: "America/Atikokan" },
      { label: "America/Bahia", value: "America/Bahia" },
      { label: "America/Bahia_Banderas", value: "America/Bahia_Banderas" },
      { label: "America/Barbados", value: "America/Barbados" },
      { label: "America/Belem", value: "America/Belem" },
      { label: "America/Belize", value: "America/Belize" },
      { label: "America/Blanc-Sablon", value: "America/Blanc-Sablon" },
      { label: "America/Boa_Vista", value: "America/Boa_Vista" },
      { label: "America/Bogota", value: "America/Bogota" },
      { label: "America/Boise", value: "America/Boise" },
      { label: "America/Cambridge_Bay", value: "America/Cambridge_Bay" },
      { label: "America/Campo_Grande", value: "America/Campo_Grande" },
      { label: "America/Cancun", value: "America/Cancun" },
      { label: "America/Caracas", value: "America/Caracas" },
      { label: "America/Cayenne", value: "America/Cayenne" },
      { label: "America/Cayman", value: "America/Cayman" },
      { label: "America/Chicago", value: "America/Chicago" },
      { label: "America/Chihuahua", value: "America/Chihuahua" },
      { label: "America/Costa_Rica", value: "America/Costa_Rica" },
      { label: "America/Creston", value: "America/Creston" },
      { label: "America/Cuiaba", value: "America/Cuiaba" },
      { label: "America/Curacao", value: "America/Curacao" },
      { label: "America/Danmarkshavn", value: "America/Danmarkshavn" },
      { label: "America/Dawson", value: "America/Dawson" },
      { label: "America/Dawson_Creek", value: "America/Dawson_Creek" },
      { label: "America/Denver", value: "America/Denver" },
      { label: "America/Detroit", value: "America/Detroit" },
      { label: "America/Dominica", value: "America/Dominica" },
      { label: "America/Edmonton", value: "America/Edmonton" },
      { label: "America/Eirunepe", value: "America/Eirunepe" },
      { label: "America/El_Salvador", value: "America/El_Salvador" },
      { label: "America/Fort_Nelson", value: "America/Fort_Nelson" },
      { label: "America/Fortaleza", value: "America/Fortaleza" },
      { label: "America/Glace_Bay", value: "America/Glace_Bay" },
      { label: "America/Godthab", value: "America/Godthab" },
      { label: "America/Goose_Bay", value: "America/Goose_Bay" },
      { label: "America/Grand_Turk", value: "America/Grand_Turk" },
      { label: "America/Grenada", value: "America/Grenada" },
      { label: "America/Guadeloupe", value: "America/Guadeloupe" },
      { label: "America/Guatemala", value: "America/Guatemala" },
      { label: "America/Guayaquil", value: "America/Guayaquil" },
      { label: "America/Guyana", value: "America/Guyana" },
      { label: "America/Halifax", value: "America/Halifax" },
      { label: "America/Havana", value: "America/Havana" },
      { label: "America/Hermosillo", value: "America/Hermosillo" },
      {
        label: "America/Indiana/Indianapolis",
        value: "America/Indiana/Indianapolis",
      },
      { label: "America/Indiana/Knox", value: "America/Indiana/Knox" },
      { label: "America/Indiana/Marengo", value: "America/Indiana/Marengo" },
      {
        label: "America/Indiana/Petersburg",
        value: "America/Indiana/Petersburg",
      },
      {
        label: "America/Indiana/Tell_City",
        value: "America/Indiana/Tell_City",
      },
      { label: "America/Indiana/Vevay", value: "America/Indiana/Vevay" },
      {
        label: "America/Indiana/Vincennes",
        value: "America/Indiana/Vincennes",
      },
      { label: "America/Indiana/Winamac", value: "America/Indiana/Winamac" },
      { label: "America/Inuvik", value: "America/Inuvik" },
      { label: "America/Iqaluit", value: "America/Iqaluit" },
      { label: "America/Jamaica", value: "America/Jamaica" },
      { label: "America/Juneau", value: "America/Juneau" },
      {
        label: "America/Kentucky/Louisville",
        value: "America/Kentucky/Louisville",
      },
      {
        label: "America/Kentucky/Monticello",
        value: "America/Kentucky/Monticello",
      },
      { label: "America/Kralendijk", value: "America/Kralendijk" },
      { label: "America/La_Paz", value: "America/La_Paz" },
      { label: "America/Lima", value: "America/Lima" },
      { label: "America/Los_Angeles", value: "America/Los_Angeles" },
      { label: "America/Lower_Princes", value: "America/Lower_Princes" },
      { label: "America/Maceio", value: "America/Maceio" },
      { label: "America/Managua", value: "America/Managua" },
      { label: "America/Manaus", value: "America/Manaus" },
      { label: "America/Marigot", value: "America/Marigot" },
      { label: "America/Martinique", value: "America/Martinique" },
      { label: "America/Matamoros", value: "America/Matamoros" },
      { label: "America/Mazatlan", value: "America/Mazatlan" },
      { label: "America/Menominee", value: "America/Menominee" },
      { label: "America/Merida", value: "America/Merida" },
      { label: "America/Metlakatla", value: "America/Metlakatla" },
      { label: "America/Mexico_City", value: "America/Mexico_City" },
      { label: "America/Miquelon", value: "America/Miquelon" },
      { label: "America/Moncton", value: "America/Moncton" },
      { label: "America/Monterrey", value: "America/Monterrey" },
      { label: "America/Montevideo", value: "America/Montevideo" },
      { label: "America/Montserrat", value: "America/Montserrat" },
      { label: "America/Nassau", value: "America/Nassau" },
      { label: "America/New_York", value: "America/New_York" },
      { label: "America/Nipigon", value: "America/Nipigon" },
      { label: "America/Nome", value: "America/Nome" },
      { label: "America/Noronha", value: "America/Noronha" },
      {
        label: "America/North_Dakota/Beulah",
        value: "America/North_Dakota/Beulah",
      },
      {
        label: "America/North_Dakota/Center",
        value: "America/North_Dakota/Center",
      },
      {
        label: "America/North_Dakota/New_Salem",
        value: "America/North_Dakota/New_Salem",
      },
      { label: "America/Ojinaga", value: "America/Ojinaga" },
      { label: "America/Panama", value: "America/Panama" },
      { label: "America/Pangnirtung", value: "America/Pangnirtung" },
      { label: "America/Paramaribo", value: "America/Paramaribo" },
      { label: "America/Phoenix", value: "America/Phoenix" },
      { label: "America/Port-au-Prince", value: "America/Port-au-Prince" },
      { label: "America/Port_of_Spain", value: "America/Port_of_Spain" },
      { label: "America/Porto_Velho", value: "America/Porto_Velho" },
      { label: "America/Puerto_Rico", value: "America/Puerto_Rico" },
      { label: "America/Punta_Arenas", value: "America/Punta_Arenas" },
      { label: "America/Rainy_River", value: "America/Rainy_River" },
      { label: "America/Rankin_Inlet", value: "America/Rankin_Inlet" },
      { label: "America/Recife", value: "America/Recife" },
      { label: "America/Regina", value: "America/Regina" },
      { label: "America/Resolute", value: "America/Resolute" },
      { label: "America/Rio_Branco", value: "America/Rio_Branco" },
      { label: "America/Santarem", value: "America/Santarem" },
      { label: "America/Santiago", value: "America/Santiago" },
      { label: "America/Santo_Domingo", value: "America/Santo_Domingo" },
      { label: "America/Sao_Paulo", value: "America/Sao_Paulo" },
      { label: "America/Scoresbysund", value: "America/Scoresbysund" },
      { label: "America/Sitka", value: "America/Sitka" },
      { label: "America/St_Barthelemy", value: "America/St_Barthelemy" },
      { label: "America/St_Johns", value: "America/St_Johns" },
      { label: "America/St_Kitts", value: "America/St_Kitts" },
      { label: "America/St_Lucia", value: "America/St_Lucia" },
      { label: "America/St_Thomas", value: "America/St_Thomas" },
      { label: "America/St_Vincent", value: "America/St_Vincent" },
      { label: "America/Swift_Current", value: "America/Swift_Current" },
      { label: "America/Tegucigalpa", value: "America/Tegucigalpa" },
      { label: "America/Thule", value: "America/Thule" },
      { label: "America/Thunder_Bay", value: "America/Thunder_Bay" },
      { label: "America/Tijuana", value: "America/Tijuana" },
      { label: "America/Toronto", value: "America/Toronto" },
      { label: "America/Tortola", value: "America/Tortola" },
      { label: "America/Vancouver", value: "America/Vancouver" },
      { label: "America/Whitehorse", value: "America/Whitehorse" },
      { label: "America/Winnipeg", value: "America/Winnipeg" },
      { label: "America/Yakutat", value: "America/Yakutat" },
      { label: "America/Yellowknife", value: "America/Yellowknife" },
    ],
  },
  {
    title: "Antarctica",
    options: [
      { label: "Antarctica/Casey", value: "Antarctica/Casey" },
      { label: "Antarctica/Davis", value: "Antarctica/Davis" },
      {
        label: "Antarctica/DumontDUrville",
        value: "Antarctica/DumontDUrville",
      },
      { label: "Antarctica/Macquarie", value: "Antarctica/Macquarie" },
      { label: "Antarctica/Mawson", value: "Antarctica/Mawson" },
      { label: "Antarctica/McMurdo", value: "Antarctica/McMurdo" },
      { label: "Antarctica/Palmer", value: "Antarctica/Palmer" },
      { label: "Antarctica/Rothera", value: "Antarctica/Rothera" },
      { label: "Antarctica/Syowa", value: "Antarctica/Syowa" },
      { label: "Antarctica/Troll", value: "Antarctica/Troll" },
      { label: "Antarctica/Vostok", value: "Antarctica/Vostok" },
    ],
  },
  {
    title: "Asia",
    options: [
      { label: "Asia/Aden", value: "Asia/Aden" },
      { label: "Asia/Almaty", value: "Asia/Almaty" },
      { label: "Asia/Amman", value: "Asia/Amman" },
      { label: "Asia/Anadyr", value: "Asia/Anadyr" },
      { label: "Asia/Aqtau", value: "Asia/Aqtau" },
      { label: "Asia/Aqtobe", value: "Asia/Aqtobe" },
      { label: "Asia/Ashgabat", value: "Asia/Ashgabat" },
      { label: "Asia/Atyrau", value: "Asia/Atyrau" },
      { label: "Asia/Baghdad", value: "Asia/Baghdad" },
      { label: "Asia/Bahrain", value: "Asia/Bahrain" },
      { label: "Asia/Baku", value: "Asia/Baku" },
      { label: "Asia/Bangkok", value: "Asia/Bangkok" },
      { label: "Asia/Barnaul", value: "Asia/Barnaul" },
      { label: "Asia/Beirut", value: "Asia/Beirut" },
      { label: "Asia/Bishkek", value: "Asia/Bishkek" },
      { label: "Asia/Brunei", value: "Asia/Brunei" },
      { label: "Asia/Chita", value: "Asia/Chita" },
      { label: "Asia/Choibalsan", value: "Asia/Choibalsan" },
      { label: "Asia/Colombo", value: "Asia/Colombo" },
      { label: "Asia/Damascus", value: "Asia/Damascus" },
      { label: "Asia/Dhaka", value: "Asia/Dhaka" },
      { label: "Asia/Dili", value: "Asia/Dili" },
      { label: "Asia/Dubai", value: "Asia/Dubai" },
      { label: "Asia/Dushanbe", value: "Asia/Dushanbe" },
      { label: "Asia/Famagusta", value: "Asia/Famagusta" },
      { label: "Asia/Gaza", value: "Asia/Gaza" },
      { label: "Asia/Hebron", value: "Asia/Hebron" },
      { label: "Asia/Ho_Chi_Minh", value: "Asia/Ho_Chi_Minh" },
      { label: "Asia/Hong_Kong", value: "Asia/Hong_Kong" },
      { label: "Asia/Hovd", value: "Asia/Hovd" },
      { label: "Asia/Irkutsk", value: "Asia/Irkutsk" },
      { label: "Asia/Jakarta", value: "Asia/Jakarta" },
      { label: "Asia/Jayapura", value: "Asia/Jayapura" },
      { label: "Asia/Jerusalem", value: "Asia/Jerusalem" },
      { label: "Asia/Kabul", value: "Asia/Kabul" },
      { label: "Asia/Kamchatka", value: "Asia/Kamchatka" },
      { label: "Asia/Karachi", value: "Asia/Karachi" },
      { label: "Asia/Kathmandu", value: "Asia/Kathmandu" },
      { label: "Asia/Khandyga", value: "Asia/Khandyga" },
      { label: "Asia/Kolkata", value: "Asia/Kolkata" },
      { label: "Asia/Krasnoyarsk", value: "Asia/Krasnoyarsk" },
      { label: "Asia/Kuala_Lumpur", value: "Asia/Kuala_Lumpur" },
      { label: "Asia/Kuching", value: "Asia/Kuching" },
      { label: "Asia/Kuwait", value: "Asia/Kuwait" },
      { label: "Asia/Macau", value: "Asia/Macau" },
      { label: "Asia/Magadan", value: "Asia/Magadan" },
      { label: "Asia/Makassar", value: "Asia/Makassar" },
      { label: "Asia/Manila", value: "Asia/Manila" },
      { label: "Asia/Muscat", value: "Asia/Muscat" },
      { label: "Asia/Nicosia", value: "Asia/Nicosia" },
      { label: "Asia/Novokuznetsk", value: "Asia/Novokuznetsk" },
      { label: "Asia/Novosibirsk", value: "Asia/Novosibirsk" },
      { label: "Asia/Omsk", value: "Asia/Omsk" },
      { label: "Asia/Oral", value: "Asia/Oral" },
      { label: "Asia/Phnom_Penh", value: "Asia/Phnom_Penh" },
      { label: "Asia/Pontianak", value: "Asia/Pontianak" },
      { label: "Asia/Pyongyang", value: "Asia/Pyongyang" },
      { label: "Asia/Qatar", value: "Asia/Qatar" },
      { label: "Asia/Qyzylorda", value: "Asia/Qyzylorda" },
      { label: "Asia/Riyadh", value: "Asia/Riyadh" },
      { label: "Asia/Sakhalin", value: "Asia/Sakhalin" },
      { label: "Asia/Samarkand", value: "Asia/Samarkand" },
      { label: "Asia/Seoul", value: "Asia/Seoul" },
      { label: "Asia/Shanghai", value: "Asia/Shanghai" },
      { label: "Asia/Singapore", value: "Asia/Singapore" },
      { label: "Asia/Srednekolymsk", value: "Asia/Srednekolymsk" },
      { label: "Asia/Taipei", value: "Asia/Taipei" },
      { label: "Asia/Tashkent", value: "Asia/Tashkent" },
      { label: "Asia/Tbilisi", value: "Asia/Tbilisi" },
      { label: "Asia/Tehran", value: "Asia/Tehran" },
      { label: "Asia/Thimphu", value: "Asia/Thimphu" },
      { label: "Asia/Tokyo", value: "Asia/Tokyo" },
      { label: "Asia/Tomsk", value: "Asia/Tomsk" },
      { label: "Asia/Ulaanbaatar", value: "Asia/Ulaanbaatar" },
      { label: "Asia/Urumqi", value: "Asia/Urumqi" },
      { label: "Asia/Ust-Nera", value: "Asia/Ust-Nera" },
      { label: "Asia/Vientiane", value: "Asia/Vientiane" },
      { label: "Asia/Vladivostok", value: "Asia/Vladivostok" },
      { label: "Asia/Yakutsk", value: "Asia/Yakutsk" },
      { label: "Asia/Yangon", value: "Asia/Yangon" },
      { label: "Asia/Yekaterinburg", value: "Asia/Yekaterinburg" },
      { label: "Asia/Yerevan", value: "Asia/Yerevan" },
    ],
  },
  {
    title: "Atlantic",
    options: [
      { label: "Atlantic/Azores", value: "Atlantic/Azores" },
      { label: "Atlantic/Bermuda", value: "Atlantic/Bermuda" },
      { label: "Atlantic/Canary", value: "Atlantic/Canary" },
      { label: "Atlantic/Cape_Verde", value: "Atlantic/Cape_Verde" },
      { label: "Atlantic/Faroe", value: "Atlantic/Faroe" },
      { label: "Atlantic/Madeira", value: "Atlantic/Madeira" },
      { label: "Atlantic/Reykjavik", value: "Atlantic/Reykjavik" },
      { label: "Atlantic/South_Georgia", value: "Atlantic/South_Georgia" },
      { label: "Atlantic/St_Helena", value: "Atlantic/St_Helena" },
      { label: "Atlantic/Stanley", value: "Atlantic/Stanley" },
    ],
  },
  {
    title: "Australia",
    options: [
      { label: "Australia/Adelaide", value: "Australia/Adelaide" },
      { label: "Australia/Brisbane", value: "Australia/Brisbane" },
      { label: "Australia/Broken_Hill", value: "Australia/Broken_Hill" },
      { label: "Australia/Currie", value: "Australia/Currie" },
      { label: "Australia/Darwin", value: "Australia/Darwin" },
      { label: "Australia/Eucla", value: "Australia/Eucla" },
      { label: "Australia/Hobart", value: "Australia/Hobart" },
      { label: "Australia/Lindeman", value: "Australia/Lindeman" },
      { label: "Australia/Lord_Howe", value: "Australia/Lord_Howe" },
      { label: "Australia/Melbourne", value: "Australia/Melbourne" },
      { label: "Australia/Perth", value: "Australia/Perth" },
      { label: "Australia/Sydney", value: "Australia/Sydney" },
    ],
  },
  {
    title: "Europe",
    options: [
      { label: "Europe/Amsterdam", value: "Europe/Amsterdam" },
      { label: "Europe/Andorra", value: "Europe/Andorra" },
      { label: "Europe/Astrakhan", value: "Europe/Astrakhan" },
      { label: "Europe/Athens", value: "Europe/Athens" },
      { label: "Europe/Belgrade", value: "Europe/Belgrade" },
      { label: "Europe/Berlin", value: "Europe/Berlin" },
      { label: "Europe/Bratislava", value: "Europe/Bratislava" },
      { label: "Europe/Brussels", value: "Europe/Brussels" },
      { label: "Europe/Bucharest", value: "Europe/Bucharest" },
      { label: "Europe/Budapest", value: "Europe/Budapest" },
      { label: "Europe/Busingen", value: "Europe/Busingen" },
      { label: "Europe/Chisinau", value: "Europe/Chisinau" },
      { label: "Europe/Copenhagen", value: "Europe/Copenhagen" },
      { label: "Europe/Dublin", value: "Europe/Dublin" },
      { label: "Europe/Gibraltar", value: "Europe/Gibraltar" },
      { label: "Europe/Guernsey", value: "Europe/Guernsey" },
      { label: "Europe/Helsinki", value: "Europe/Helsinki" },
      { label: "Europe/Isle_of_Man", value: "Europe/Isle_of_Man" },
      { label: "Europe/Istanbul", value: "Europe/Istanbul" },
      { label: "Europe/Jersey", value: "Europe/Jersey" },
      { label: "Europe/Kaliningrad", value: "Europe/Kaliningrad" },
      { label: "Europe/Kiev", value: "Europe/Kiev" },
      { label: "Europe/Kirov", value: "Europe/Kirov" },
      { label: "Europe/Lisbon", value: "Europe/Lisbon" },
      { label: "Europe/Ljubljana", value: "Europe/Ljubljana" },
      { label: "Europe/London", value: "Europe/London" },
      { label: "Europe/Luxembourg", value: "Europe/Luxembourg" },
      { label: "Europe/Madrid", value: "Europe/Madrid" },
      { label: "Europe/Malta", value: "Europe/Malta" },
      { label: "Europe/Mariehamn", value: "Europe/Mariehamn" },
      { label: "Europe/Minsk", value: "Europe/Minsk" },
      { label: "Europe/Monaco", value: "Europe/Monaco" },
      { label: "Europe/Moscow", value: "Europe/Moscow" },
      { label: "Europe/Oslo", value: "Europe/Oslo" },
      { label: "Europe/Paris", value: "Europe/Paris" },
      { label: "Europe/Podgorica", value: "Europe/Podgorica" },
      { label: "Europe/Prague", value: "Europe/Prague" },
      { label: "Europe/Riga", value: "Europe/Riga" },
      { label: "Europe/Rome", value: "Europe/Rome" },
      { label: "Europe/Samara", value: "Europe/Samara" },
      { label: "Europe/San_Marino", value: "Europe/San_Marino" },
      { label: "Europe/Sarajevo", value: "Europe/Sarajevo" },
      { label: "Europe/Saratov", value: "Europe/Saratov" },
      { label: "Europe/Simferopol", value: "Europe/Simferopol" },
      { label: "Europe/Skopje", value: "Europe/Skopje" },
      { label: "Europe/Sofia", value: "Europe/Sofia" },
      { label: "Europe/Stockholm", value: "Europe/Stockholm" },
      { label: "Europe/Tallinn", value: "Europe/Tallinn" },
      { label: "Europe/Tirane", value: "Europe/Tirane" },
      { label: "Europe/Ulyanovsk", value: "Europe/Ulyanovsk" },
      { label: "Europe/Uzhgorod", value: "Europe/Uzhgorod" },
      { label: "Europe/Vaduz", value: "Europe/Vaduz" },
      { label: "Europe/Vatican", value: "Europe/Vatican" },
      { label: "Europe/Vienna", value: "Europe/Vienna" },
      { label: "Europe/Vilnius", value: "Europe/Vilnius" },
      { label: "Europe/Volgograd", value: "Europe/Volgograd" },
      { label: "Europe/Warsaw", value: "Europe/Warsaw" },
      { label: "Europe/Zagreb", value: "Europe/Zagreb" },
      { label: "Europe/Zaporozhye", value: "Europe/Zaporozhye" },
      { label: "Europe/Zurich", value: "Europe/Zurich" },
    ],
  },
  {
    title: "Indian",
    options: [
      { label: "Indian/Antananarivo", value: "Indian/Antananarivo" },
      { label: "Indian/Chagos", value: "Indian/Chagos" },
      { label: "Indian/Christmas", value: "Indian/Christmas" },
      { label: "Indian/Cocos", value: "Indian/Cocos" },
      { label: "Indian/Comoro", value: "Indian/Comoro" },
      { label: "Indian/Kerguelen", value: "Indian/Kerguelen" },
      { label: "Indian/Mahe", value: "Indian/Mahe" },
      { label: "Indian/Maldives", value: "Indian/Maldives" },
      { label: "Indian/Mauritius", value: "Indian/Mauritius" },
      { label: "Indian/Mayotte", value: "Indian/Mayotte" },
      { label: "Indian/Reunion", value: "Indian/Reunion" },
    ],
  },
  {
    title: "Pacific",
    options: [
      { label: "Pacific/Apia", value: "Pacific/Apia" },
      { label: "Pacific/Auckland", value: "Pacific/Auckland" },
      { label: "Pacific/Bougainville", value: "Pacific/Bougainville" },
      { label: "Pacific/Chatham", value: "Pacific/Chatham" },
      { label: "Pacific/Chuuk", value: "Pacific/Chuuk" },
      { label: "Pacific/Easter", value: "Pacific/Easter" },
      { label: "Pacific/Efate", value: "Pacific/Efate" },
      { label: "Pacific/Enderbury", value: "Pacific/Enderbury" },
      { label: "Pacific/Fakaofo", value: "Pacific/Fakaofo" },
      { label: "Pacific/Fiji", value: "Pacific/Fiji" },
      { label: "Pacific/Funafuti", value: "Pacific/Funafuti" },
      { label: "Pacific/Galapagos", value: "Pacific/Galapagos" },
      { label: "Pacific/Gambier", value: "Pacific/Gambier" },
      { label: "Pacific/Guadalcanal", value: "Pacific/Guadalcanal" },
      { label: "Pacific/Guam", value: "Pacific/Guam" },
      { label: "Pacific/Honolulu", value: "Pacific/Honolulu" },
      { label: "Pacific/Kiritimati", value: "Pacific/Kiritimati" },
      { label: "Pacific/Kosrae", value: "Pacific/Kosrae" },
      { label: "Pacific/Kwajalein", value: "Pacific/Kwajalein" },
      { label: "Pacific/Majuro", value: "Pacific/Majuro" },
      { label: "Pacific/Marquesas", value: "Pacific/Marquesas" },
      { label: "Pacific/Midway", value: "Pacific/Midway" },
      { label: "Pacific/Nauru", value: "Pacific/Nauru" },
      { label: "Pacific/Niue", value: "Pacific/Niue" },
      { label: "Pacific/Norfolk", value: "Pacific/Norfolk" },
      { label: "Pacific/Noumea", value: "Pacific/Noumea" },
      { label: "Pacific/Pago_Pago", value: "Pacific/Pago_Pago" },
      { label: "Pacific/Palau", value: "Pacific/Palau" },
      { label: "Pacific/Pitcairn", value: "Pacific/Pitcairn" },
      { label: "Pacific/Pohnpei", value: "Pacific/Pohnpei" },
      { label: "Pacific/Port_Moresby", value: "Pacific/Port_Moresby" },
      { label: "Pacific/Rarotonga", value: "Pacific/Rarotonga" },
      { label: "Pacific/Saipan", value: "Pacific/Saipan" },
      { label: "Pacific/Tahiti", value: "Pacific/Tahiti" },
      { label: "Pacific/Tarawa", value: "Pacific/Tarawa" },
      { label: "Pacific/Tongatapu", value: "Pacific/Tongatapu" },
      { label: "Pacific/Wake", value: "Pacific/Wake" },
      { label: "Pacific/Wallis", value: "Pacific/Wallis" },
    ],
  },
];

export const orderStatusOptions = [
  { label: "Unpaid", value: "3" },
  { label: "Paid", value: "10" },
  { label: "Completed", value: "4" },
  { label: "Cancelled", value: "9" },
  { label: "Partially refunded", value: "12" },
  { label: "Fully refunded", value: "13" },
  { label: "Archived", value: "14" },
  { label: "Failed", value: "6" },
  { label: "Others", value: "11" },
]

export const etsyStatusOptions = [
  { label: "Not Published", value: "1" },
  { label: "Active", value: "2" },
  { label: "Draft", value: "3" },
  { label: "Inactive", value: "4" },
  { label: "Sold_out", value: "5" },
  { label: "Expired", value: "7" },
  { label: "Others", value: "12" },
  { label: "Custom Inactive", value: "13" },
];

export const shopifyStatusOptions = [
  { label: "ACTIVE", value: "active" },
  { label: "ARCHIVED", value: "archived" },
  { label: "DRAFT", value: "draft" },
];

export const profileFilterOptions = [
  { label: "ASSIGNED_PROFILE", value: "profile assigned" },
  { label: "UNASSIGNED_PROFILE", value: "Not profiled" },
  { label: "DISABLED_PROFILE", value: "profile disabled" },
  { label: "DELETED_FROM_SHOPIFY", value: "shopify deleted" },  
];

export const dimensionUnitOptions = [
  { label: "Inch", value: "in" },
  { label: "Feet", value: "ft" },
  { label: "Millimeters", value: "mm" },
  { label: "Centimeters", value: "cm" },
  { label: "Meters", value: "m" },
  { label: "Yards", value: "yd" },
  { label: "Inches", value: "inches" },
];

export const weightUnitOptions = [
  { label: "g", value: "g" },
  { label: "kg", value: "kg" },
  { label: "lb", value: "lb" },
  { label: "oz", value: "oz" },
];

export const processingTimeOptions = [
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
];

export const reviewAppOptions = [
  { label: "Judgeme", value: "judgeme" },
  { label: "Yotpo", value: "yotpo" },
];

export const etsyShipingMethodOptions = [
  {
    label: "Standard Shipping",
    value: "Standard Shipping",
  },
  {
    label: "USPS Priority Mail International",
    value: "USPS Priority Mail International",
  },
  {
    label: "Free shipping (USPS First Class Package Services)",
    value: "Free shipping (USPS First Class Package Services)",
  },
  {
    label: "USPS Priority Mail",
    value: "USPS Priority Mail",
  },
  {
    label: "USPS First Class International Package Service",
    value: "USPS First Class International Package Service",
  },
  {
    label: "USPS First Class Package Services",
    value: "USPS First Class Package Services",
  },
  {
    label: "Free shipping (USPS Priority Mail)",
    value: "Free shipping (USPS Priority Mail)",
  },
  {
    label: "USPS Priority Mail Express",
    value: "USPS Priority Mail Express",
  },
  {
    label: "Express",
    value: "Express",
  },
  {
    label: "International Express",
    value: "International Express",
  },
  {
    label: "UPS Next Day",
    value: "UPS Next Day",
  },
  {
    label: "Priority (3-4 Business Days)",
    value: "Priority (3-4 Business Days)",
  },
  {
    label: "USPS 2-3 Day Priority",
    value: "USPS 2-3 Day Priority",
  },
  {
    label: "USPS Priority Mail Express International",
    value: "USPS Priority Mail Express International",
  },
  {
    label: "US Express Mail Shipping",
    value: "US Express Mail Shipping",
  },
  {
    label: "2-DAY FedEx",
    value: "2-DAY FedEx",
  },
  {
    label: "Australia Express Post",
    value: "Australia Express Post",
  },
  {
    label: "Overnight (Purchase by 2:00 PM CST)",
    value: "Overnight (Purchase by 2:00 PM CST)",
  },
  {
    label: "Gallery Wrapped United States Shipping fee",
    value: "Gallery Wrapped United States Shipping fee",
  },
  {
    label: "Free shipping (USPS First Class International Package Service)",
    value: "Free shipping (USPS First Class International Package Service)",
  },
  {
    label: "Free Shipping",
    value: "Free Shipping",
  },
  {
    label: "USPS First Class Mail",
    value: "USPS First Class Mail",
  },
  {
    label: "Free shipping (USPS Parcel Select Ground)",
    value: "Free shipping (USPS Parcel Select Ground)",
  },
  {
    label: "1 to 2 day express",
    value: "1 to 2 day express",
  },
  {
    label: "3 to 4 day priority",
    value: "3 to 4 day priority",
  },
  {
    label: "United States Only  Priority",
    value: "United States Only  Priority",
  },
  {
    label: "USA ONLY PRIORITY",
    value: "USA ONLY PRIORITY",
  },
  {
    label: "1 Day",
    value: "1 Day",
  },
  {
    label: "Free shipping (USPS First Class Mail)",
    value: "Free shipping (USPS First Class Mail)",
  },
  {
    label: "Overnight (Purchase by 2:00 PM CST) Mon-Fri Only",
    value: "Overnight (Purchase by 2:00 PM CST) Mon-Fri Only",
  },
  {
    label: "Express Order 10-14 Days Delivery",
    value: "Express Order 10-14 Days Delivery",
  },
  {
    label: "Standard US",
    value: "Standard US",
  },
  {
    label: "With Tracking",
    value: "With Tracking",
  },
  {
    label: "Express mail",
    value: "Express mail",
  },
  {
    label: "FedEx International Expedited",
    value: "FedEx International Expedited",
  },
  {
    label: "First class",
    value: "First class",
  },
  {
    label: "Free Delivery (USPS Priority Mail)",
    value: "Free Delivery (USPS Priority Mail)",
  },
  {
    label: "CANADA  XPRESSPOST",
    value: "CANADA  XPRESSPOST",
  },
  {
    label: "Free Delivery (USPS First Class Mail)",
    value: "Free Delivery (USPS First Class Mail)",
  },
  {
    label: "First Class - Tracking",
    value: "First Class - Tracking",
  },
  {
    label: "Express 5-8 business days",
    value: "Express 5-8 business days",
  },
  {
    label: "USPS First Class - Tracking",
    value: "USPS First Class - Tracking",
  },
  {
    label: "Expedited Shipping (1-3 Business Days)",
    value: "Expedited Shipping (1-3 Business Days)",
  },
  {
    label: "Standard Shipping Method ( Tracking Service )",
    value: "Standard Shipping Method ( Tracking Service )",
  },
  {
    label: "Tracking Number",
    value: "Tracking Number",
  },
  {
    label: "First Class",
    value: "First Class",
  },
  {
    label: "Tracked 24",
    value: "Tracked 24",
  },
  {
    label: "Priority Mail - 1-3 days - $100 Insurance",
    value: "Priority Mail - 1-3 days - $100 Insurance",
  },
  {
    label: "EXPRESS",
    value: "EXPRESS",
  },
  {
    label: "ePacket",
    value: "ePacket",
  },
  {
    label: "ePacket - Tracking Number",
    value: "ePacket - Tracking Number",
  },
  {
    label: "UK With Tracking",
    value: "UK With Tracking",
  },
  {
    label: "Expedited Parcel™",
    value: "Expedited Parcel™",
  },
  {
    label: "DHL",
    value: "DHL",
  },
  {
    label: "BC ONLY XPRESSPOST",
    value: "BC ONLY XPRESSPOST",
  },
  {
    label: "Express (Receive within 3-4 business days)",
    value: "Express (Receive within 3-4 business days)",
  },
  {
    label: "Priority (Receive within 5-7 business days)",
    value: "Priority (Receive within 5-7 business days)",
  },
  {
    label: "Express (Receive within 2 business days)",
    value: "Express (Receive within 2 business days)",
  },
  {
    label: "USA only Priority",
    value: "USA only Priority",
  },
  {
    label: "International Courier (FedEx/UPS/DHL)",
    value: "International Courier (FedEx/UPS/DHL)",
  },
  {
    label: "Express Post",
    value: "Express Post",
  },
  {
    label: "Free Delivery (Expedited Parcel™)",
    value: "Free Delivery (Expedited Parcel™)",
  },
  {
    label: "Priority Mail - 1-3 Days - $100 Insurance",
    value: "Priority Mail - 1-3 Days - $100 Insurance",
  },
  {
    label: "International Tracked & Signed",
    value: "International Tracked & Signed",
  },
  {
    label: "Priority",
    value: "Priority",
  },
  {
    label: "USPS Priority Mail (1-3 days)",
    value: "USPS Priority Mail (1-3 days)",
  },
  {
    label: "Priority Mail",
    value: "Priority Mail",
  },
  {
    label: "Express Order 10 - 14 Days Delivery",
    value: "Express Order 10 - 14 Days Delivery",
  },
  {
    label: "Heated Shipping",
    value: "Heated Shipping",
  },
  {
    label: "USPS Priority Mail 1-3 Business Days",
    value: "USPS Priority Mail 1-3 Business Days",
  },
  {
    label: "International Tracked",
    value: "International Tracked",
  },
  {
    label: "China Post Tracking Number",
    value: "China Post Tracking Number",
  },
  {
    label: "International Express Order 10 - 14 Days Delivery",
    value: "International Express Order 10 - 14 Days Delivery",
  },
  {
    label: "USPS Parcel Select Ground",
    value: "USPS Parcel Select Ground",
  },
  {
    label: "Express Order",
    value: "Express Order",
  },
  {
    label: "International Express Order",
    value: "International Express Order",
  },
  {
    label: "Standard Delivery",
    value: "Standard Delivery",
  },
  {
    label: "無料配送（USPS First Class Mail）",
    value: "無料配送（USPS First Class Mail）",
  },
  {
    label: "Priority Mail 1-3 days delivery $100 Insurance",
    value: "Priority Mail 1-3 days delivery $100 Insurance",
  },
  {
    label: "Express Order (Please Send Phone Number)",
    value: "Express Order (Please Send Phone Number)",
  },
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Special Delivery",
    value: "Special Delivery",
  },
  {
    label: "Free shipping (Expedited Parcel™)",
    value: "Free shipping (Expedited Parcel™)",
  },
  {
    label: "Before Christmas Delivery",
    value: "Before Christmas Delivery",
  },
  {
    label: "Expedited Mail",
    value: "Expedited Mail",
  },
  {
    label: "FedEx Nexd Day Air",
    value: "FedEx Nexd Day Air",
  },
  {
    label: "NextDay (Mon-Fri by 2:00 P.M. CST) No Saturday",
    value: "NextDay (Mon-Fri by 2:00 P.M. CST) No Saturday",
  },
  {
    label: "Before Christmas (Dec 24th)",
    value: "Before Christmas (Dec 24th)",
  },
  {
    label: "RUSH - 1-3 Business Days",
    value: "RUSH - 1-3 Business Days",
  },
  {
    label: "Small Packet™ - International Surface",
    value: "Small Packet™ - International Surface",
  },
  {
    label: "Small Packet™ - USA Air",
    value: "Small Packet™ - USA Air",
  },
  {
    label: "USPS Priority Express 1-2 Business Days",
    value: "USPS Priority Express 1-2 Business Days",
  },
  {
    label: "Express shipping 1~2 days",
    value: "Express shipping 1~2 days",
  },
  {
    label: "Tracked Packet™ - USA",
    value: "Tracked Packet™ - USA",
  },
  {
    label: "Free Delivery (USPS Parcel Select Ground)",
    value: "Free Delivery (USPS Parcel Select Ground)",
  },
  {
    label: "Free shipping (USPS Media Mail)",
    value: "Free shipping (USPS Media Mail)",
  },
  {
    label: "BC ONLY XPRESSPOST (Receive within 4 days)",
    value: "BC ONLY XPRESSPOST (Receive within 4 days)",
  },
  {
    label: "Priority (I need my order in less than 7 days)",
    value: "Priority (I need my order in less than 7 days)",
  },
  {
    label: "CANADA EXPRESS (Receive within 5 business days)",
    value: "CANADA EXPRESS (Receive within 5 business days)",
  },
  {
    label: "Economy",
    value: "Economy",
  },
  {
    label: "BC RESIDENTS ONLY EXPRESS (Receive within 4 days)",
    value: "BC RESIDENTS ONLY EXPRESS (Receive within 4 days)",
  },
  {
    label: "Express (I need my order in less than 4 days)",
    value: "Express (I need my order in less than 4 days)",
  },
  {
    label: "Overnight",
    value: "Overnight",
  },
  {
    label: "Expedited",
    value: "Expedited",
  },
  {
    label: "USPS Express (1-2 days)",
    value: "USPS Express (1-2 days)",
  },
  {
    label: "Xpresspost™ - USA",
    value: "Xpresspost™ - USA",
  },
  {
    label: "USPS Priority (2-3 days)",
    value: "USPS Priority (2-3 days)",
  },
  {
    label: "UPS Ground 1-5 Day",
    value: "UPS Ground 1-5 Day",
  },
  {
    label: "Two-Day Shipping",
    value: "Two-Day Shipping",
  },
  {
    label: "US With Tracking",
    value: "US With Tracking",
  },
  {
    label: "Priority Shipping",
    value: "Priority Shipping",
  },
  {
    label: "1-2 days Express USA Canada Europe",
    value: "1-2 days Express USA Canada Europe",
  },
  {
    label: "Envío gratis (USPS Priority Mail)",
    value: "Envío gratis (USPS Priority Mail)",
  },
  {
    label: "Express Production & Shipping 3-5 Days Delivery",
    value: "Express Production & Shipping 3-5 Days Delivery",
  },
  {
    label: "Small Packet™ - International Air",
    value: "Small Packet™ - International Air",
  },
  {
    label: "Expedited Shipping",
    value: "Expedited Shipping",
  },
  {
    label: "Express Production & Shipping 4-7 Days Delivery",
    value: "Express Production & Shipping 4-7 Days Delivery",
  },
  {
    label: "Upgraded Shipping Method ( + Tracking Service )",
    value: "Upgraded Shipping Method ( + Tracking Service )",
  },
  {
    label: "Express Shipping ( 3 to 7 working days)",
    value: "Express Shipping ( 3 to 7 working days)",
  },
  {
    label: "Express Shipping ( 1 to 4 days)",
    value: "Express Shipping ( 1 to 4 days)",
  },
  {
    label: "Xpresspost™",
    value: "Xpresspost™",
  },
  {
    label: "Priority™",
    value: "Priority™",
  },
  {
    label: "Fed Ex",
    value: "Fed Ex",
  },
  {
    label: "Livraison gratuite (USPS First Class Mail)",
    value: "Livraison gratuite (USPS First Class Mail)",
  },
  {
    label: "EXPRESS SHIPPING (1-2 bus. days after despatch)",
    value: "EXPRESS SHIPPING (1-2 bus. days after despatch)",
  },
  {
    label: "Fast-track + prioritisation",
    value: "Fast-track + prioritisation",
  },
  {
    label: "Express service 10-30 days",
    value: "Express service 10-30 days",
  },
  {
    label: "Faster Delivery to the US via EMS",
    value: "Faster Delivery to the US via EMS",
  },
  {
    label: "Free Delivery (USPS Priority Mail International)",
    value: "Free Delivery (USPS Priority Mail International)",
  },
  {
    label: "Priority Express",
    value: "Priority Express",
  },
  {
    label: "Free Delivery (Xpresspost™)",
    value: "Free Delivery (Xpresspost™)",
  },
  {
    label: "Express 10-30",
    value: "Express 10-30",
  },
  {
    label: "3- 5 Days Delivery",
    value: "3- 5 Days Delivery",
  },
  {
    label: "Royal Mail Next Day Before 1pm - Track & Sign",
    value: "Royal Mail Next Day Before 1pm - Track & Sign",
  },
  {
    label: "4-7 Days Delivery",
    value: "4-7 Days Delivery",
  },
  {
    label: "Express International",
    value: "Express International",
  },
  {
    label: "Express Domestic",
    value: "Express Domestic",
  },
  {
    label: "With Insurance",
    value: "With Insurance",
  },
  {
    label: "Express INT",
    value: "Express INT",
  },
  {
    label: "Delivery in 3- 5 Days",
    value: "Delivery in 3- 5 Days",
  },
  {
    label: "Standard with Tracking",
    value: "Standard with Tracking",
  },
  {
    label: "8 Business Days Delivery",
    value: "8 Business Days Delivery",
  },
  {
    label: "Priority mail",
    value: "Priority mail",
  },
  {
    label: "Delivery in 4-7 Days",
    value: "Delivery in 4-7 Days",
  },
  {
    label: "Standard with Tracking INT",
    value: "Standard with Tracking INT",
  },
  {
    label: "Free Delivery (USPS First Class International Package Service)",
    value: "Free Delivery (USPS First Class International Package Service)",
  },
  {
    label: "RUSH PRODUCTION (Not Rush Shipping)",
    value: "RUSH PRODUCTION (Not Rush Shipping)",
  },
  {
    label: "Express Delivery- United States-",
    value: "Express Delivery- United States-",
  },
  {
    label: "Tracked & Signed",
    value: "Tracked & Signed",
  },
  {
    label:
      "Livraison gratuite (USPS First Class International Package Service)",
    value:
      "Livraison gratuite (USPS First Class International Package Service)",
  },
  {
    label: "Tracked",
    value: "Tracked",
  },
  {
    label: "Urgent Dispatch",
    value: "Urgent Dispatch",
  },
  {
    label: "Priority mail- Only applies to CARDS",
    value: "Priority mail- Only applies to CARDS",
  },
  {
    label: "Expedited - 3 business days",
    value: "Expedited - 3 business days",
  },
  {
    label: "Envío gratis (USPS First Class Mail)",
    value: "Envío gratis (USPS First Class Mail)",
  },
  {
    label: "FEDEX",
    value: "FEDEX",
  },
  {
    label: "Standard International",
    value: "Standard International",
  },
  {
    label: "Express Shipping ( 1 to 4 working days)",
    value: "Express Shipping ( 1 to 4 working days)",
  },
  {
    label: "Express Shipping ( 3 to 7 days)",
    value: "Express Shipping ( 3 to 7 days)",
  },
  {
    label: "UPS Ground",
    value: "UPS Ground",
  },
  {
    label: "Expedited shipping",
    value: "Expedited shipping",
  },
  {
    label: "USP Ground Priority (1-5 Days)",
    value: "USP Ground Priority (1-5 Days)",
  },
  {
    label: "Express W. Europe & USA",
    value: "Express W. Europe & USA",
  },
  {
    label: "Fastship",
    value: "Fastship",
  },
  {
    label: "Registered (1-3 days arrival)",
    value: "Registered (1-3 days arrival)",
  },
  {
    label: "USPS Media Mail",
    value: "USPS Media Mail",
  },
  {
    label: "Two-Day Delivery - must place order by 11am PST",
    value: "Two-Day Delivery - must place order by 11am PST",
  },
  {
    label: "Express DHL 3-5 Day",
    value: "Express DHL 3-5 Day",
  },
  {
    label: "OTHER PROVINCES XPRESS",
    value: "OTHER PROVINCES XPRESS",
  },
  {
    label: "Tracking provided",
    value: "Tracking provided",
  },
  {
    label: "Prioritisation",
    value: "Prioritisation",
  },
  {
    label: "Free Delivery (USPS Media Mail)",
    value: "Free Delivery (USPS Media Mail)",
  },
  {
    label: "Framed Print 4-5 days",
    value: "Framed Print 4-5 days",
  },
  {
    label: "DHL Express Shipping",
    value: "DHL Express Shipping",
  },
  {
    label: "Royal Ma 2nd Class Signed For",
    value: "Royal Ma 2nd Class Signed For",
  },
  {
    label: "UPS express shipping",
    value: "UPS express shipping",
  },
  {
    label: "Express shipping by UPS",
    value: "Express shipping by UPS",
  },
  {
    label: "Express & Tracked Shipping",
    value: "Express & Tracked Shipping",
  },
  {
    label: "Registered post",
    value: "Registered post",
  },
  {
    label: "1st Class",
    value: "1st Class",
  },
  {
    label: "1st class",
    value: "1st class",
  },
  {
    label: "USPS PRIORITY MAIL",
    value: "USPS PRIORITY MAIL",
  },
  {
    label: "USPS Priority ( 2 - 4 Business Days)",
    value: "USPS Priority ( 2 - 4 Business Days)",
  },
  {
    label: "Free shipping (Small Packet™ - USA Air)",
    value: "Free shipping (Small Packet™ - USA Air)",
  },
  {
    label: "Express (phone number required in notes)",
    value: "Express (phone number required in notes)",
  },
  {
    label: "7-9 Biz day to Canada",
    value: "7-9 Biz day to Canada",
  },
  {
    label: "3-5 biz days to Canada",
    value: "3-5 biz days to Canada",
  },
  {
    label: "4-6 Biz days to USA",
    value: "4-6 Biz days to USA",
  },
  {
    label: "10-12 biz days to Canada",
    value: "10-12 biz days to Canada",
  },
  {
    label: "6-9 Biz days to USA",
    value: "6-9 Biz days to USA",
  },
  {
    label: "Priorty",
    value: "Priorty",
  },
  {
    label: "USPS Priority Mail Express (1-2 days)",
    value: "USPS Priority Mail Express (1-2 days)",
  },
  {
    label: "Kostenloser Versand (Standard International)",
    value: "Kostenloser Versand (Standard International)",
  },
  {
    label: "UPS 3 Day Select",
    value: "UPS 3 Day Select",
  },
  {
    label: "Express ( Please Send Phone Number)",
    value: "Express ( Please Send Phone Number)",
  },
  {
    label: "USPS 2-3 Days Priority",
    value: "USPS 2-3 Days Priority",
  },
  {
    label: "Royal Mail 1st Class (order before 12pm Mon-Fri)",
    value: "Royal Mail 1st Class (order before 12pm Mon-Fri)",
  },
  {
    label: "Chronopost (en relais colis) 48h",
    value: "Chronopost (en relais colis) 48h",
  },
  {
    label: "Silicon Stopper ONLY",
    value: "Silicon Stopper ONLY",
  },
  {
    label: "Free Delivery (Standard International)",
    value: "Free Delivery (Standard International)",
  },
  {
    label: "Express (Fastest Possible)",
    value: "Express (Fastest Possible)",
  },
  {
    label: "UPS",
    value: "UPS",
  },
  {
    label: "Royal Mail Tracked 24 (order before 2pm Mon-Fri)",
    value: "Royal Mail Tracked 24 (order before 2pm Mon-Fri)",
  },
  {
    label: "Express + Tracking",
    value: "Express + Tracking",
  },
  {
    label: "UK - Royal Mail Tracked Option",
    value: "UK - Royal Mail Tracked Option",
  },
  {
    label: "Express 20-35",
    value: "Express 20-35",
  },
  {
    label: "DHL Shipping Service",
    value: "DHL Shipping Service",
  },
  {
    label: "International Tracked Shipment",
    value: "International Tracked Shipment",
  },
  {
    label: "Expedited (Ships in 5 days or less)",
    value: "Expedited (Ships in 5 days or less)",
  },
  {
    label: "Priority Mail International",
    value: "Priority Mail International",
  },
  {
    label: "Spedizione gratuita (USPS Priority Mail International)",
    value: "Spedizione gratuita (USPS Priority Mail International)",
  },
  {
    label: "Livraison gratuite (Standard International)",
    value: "Livraison gratuite (Standard International)",
  },
  {
    label: "Free shipping (Standard International)",
    value: "Free shipping (Standard International)",
  },
  {
    label: "Free shipping (USPS Priority Mail International)",
    value: "Free shipping (USPS Priority Mail International)",
  },
  {
    label: "First Class - Tracking $50 Insurance",
    value: "First Class - Tracking $50 Insurance",
  },
  {
    label: "Express Delivery",
    value: "Express Delivery",
  },
  {
    label: "Priority Next Day",
    value: "Priority Next Day",
  },
  {
    label: "Express post international & tracking number",
    value: "Express post international & tracking number",
  },
  {
    label: "Internation Express & Tracked Shipping",
    value: "Internation Express & Tracked Shipping",
  },
  {
    label: "DHL Express",
    value: "DHL Express",
  },
  {
    label: "USPS First Class",
    value: "USPS First Class",
  },
  {
    label: "Royal Mail UK Signed For Service",
    value: "Royal Mail UK Signed For Service",
  },
  {
    label: "Express Tracked",
    value: "Express Tracked",
  },
  {
    label: "Priority Mail (1-3 business days)",
    value: "Priority Mail (1-3 business days)",
  },
  {
    label: "Xpresspost",
    value: "Xpresspost",
  },
  {
    label: "UK Express & Tracked Shipping",
    value: "UK Express & Tracked Shipping",
  },
  {
    label: "Express Courier (UPS/FedEx)",
    value: "Express Courier (UPS/FedEx)",
  },
  {
    label: "Priority before Dec 24th",
    value: "Priority before Dec 24th",
  },
  {
    label: "USPS Priority Shipping (1-4 Days Estimated*)",
    value: "USPS Priority Shipping (1-4 Days Estimated*)",
  },
  {
    label: "Priority Mail 2-5 Days",
    value: "Priority Mail 2-5 Days",
  },
  {
    label: "Expedited - 4 business days",
    value: "Expedited - 4 business days",
  },
  {
    label: "UPS 2 days",
    value: "UPS 2 days",
  },
  {
    label: "USA Expresspost",
    value: "USA Expresspost",
  },
  {
    label: "Arrives before Xmas",
    value: "Arrives before Xmas",
  },
  {
    label: "Arrival before Xmas",
    value: "Arrival before Xmas",
  },
  {
    label: "Upgrade UPS Ground - RECOMMENDED during holiday",
    value: "Upgrade UPS Ground - RECOMMENDED during holiday",
  },
  {
    label: "Envío gratis (USPS Parcel Select Ground)",
    value: "Envío gratis (USPS Parcel Select Ground)",
  },
  {
    label: "UPS NEXT DAY AIR",
    value: "UPS NEXT DAY AIR",
  },
  {
    label: "ESPRESSA NAZIONALE",
    value: "ESPRESSA NAZIONALE",
  },
  {
    label: "INTERNSTIONAL EXPRESS",
    value: "INTERNSTIONAL EXPRESS",
  },
  {
    label: "TRACKED - Express UK",
    value: "TRACKED - Express UK",
  },
  {
    label: "Royal Mail 24 Hrs",
    value: "Royal Mail 24 Hrs",
  },
  {
    label: "3 Days",
    value: "3 Days",
  },
  {
    label: "UPS Next Day Air",
    value: "UPS Next Day Air",
  },
  {
    label: "Portugal Post with Tracking nº",
    value: "Portugal Post with Tracking nº",
  },
  {
    label: "Expedited Production & Shipping (ships in 5 days)",
    value: "Expedited Production & Shipping (ships in 5 days)",
  },
  {
    label: "Canada Post Expedited Parcel (Tracked)",
    value: "Canada Post Expedited Parcel (Tracked)",
  },
  {
    label: "Star Track Premium",
    value: "Star Track Premium",
  },
  {
    label: "UPS Ground (1-3 Days)",
    value: "UPS Ground (1-3 Days)",
  },
  {
    label: "Express (1-3 days)",
    value: "Express (1-3 days)",
  },
  {
    label: "Express (1-2 days)",
    value: "Express (1-2 days)",
  },
  {
    label: "FedEx",
    value: "FedEx",
  },
  {
    label: "EXPRESS SHIPPING DOM",
    value: "EXPRESS SHIPPING DOM",
  },
  {
    label: "Premium",
    value: "Premium",
  },
  {
    label: "Livraison gratuite (Expedited Parcel™)",
    value: "Livraison gratuite (Expedited Parcel™)",
  },
  {
    label: "Express (No PO Boxes Please Send Phone Number)",
    value: "Express (No PO Boxes Please Send Phone Number)",
  },
  {
    label: "Upgraded Shipping ( + Faster & Tracking Service )",
    value: "Upgraded Shipping ( + Faster & Tracking Service )",
  },
  {
    label: "2 Days",
    value: "2 Days",
  },
  {
    label: "Express: European Union",
    value: "Express: European Union",
  },
  {
    label: "RUSH MAIL",
    value: "RUSH MAIL",
  },
  {
    label: "Xpresspost™ - International",
    value: "Xpresspost™ - International",
  },
  {
    label: "USPS Priority",
    value: "USPS Priority",
  },
  {
    label: "Envío gratis (Standard International)",
    value: "Envío gratis (Standard International)",
  },
  {
    label: "UPS 2 Day",
    value: "UPS 2 Day",
  },
  {
    label: "Spedizione gratuita (Standard International)",
    value: "Spedizione gratuita (Standard International)",
  },
  {
    label: "Gratis verzending (Standard International)",
    value: "Gratis verzending (Standard International)",
  },
  {
    label: "Priority Mail Express Flat Rate Envelope",
    value: "Priority Mail Express Flat Rate Envelope",
  },
  {
    label: "INTERNATIONAL EXPRESS",
    value: "INTERNATIONAL EXPRESS",
  },
  {
    label: "Expedited Tracked - International",
    value: "Expedited Tracked - International",
  },
  {
    label: "無料配送（USPS Priority Mail）",
    value: "無料配送（USPS Priority Mail）",
  },
  {
    label: "Priority 2 Day Shipping",
    value: "Priority 2 Day Shipping",
  },
  {
    label: "Tracked 24 (order before 2pm Mon-Fri)",
    value: "Tracked 24 (order before 2pm Mon-Fri)",
  },
  {
    label: "Overnight Shipping",
    value: "Overnight Shipping",
  },
  {
    label: "Expedited 2-day shipping",
    value: "Expedited 2-day shipping",
  },
  {
    label: "Expedited Tracked - UK",
    value: "Expedited Tracked - UK",
  },
  {
    label: "1st Class Signed For",
    value: "1st Class Signed For",
  },
  {
    label: "Priority Shipping (2-3 days)",
    value: "Priority Shipping (2-3 days)",
  },
  {
    label: "Upgrade UPS Ground (1-5 Days in Transit)",
    value: "Upgrade UPS Ground (1-5 Days in Transit)",
  },
  {
    label: "EXPRESS SHIPPING",
    value: "EXPRESS SHIPPING",
  },
  {
    label: "Tracked Packet™ - International",
    value: "Tracked Packet™ - International",
  },
  {
    label: "signed for",
    value: "signed for",
  },
  {
    label: "Air Shipping",
    value: "Air Shipping",
  },
  {
    label: "Expedited Parcel™ - USA",
    value: "Expedited Parcel™ - USA",
  },
  {
    label: "sneakers express",
    value: "sneakers express",
  },
  {
    label: "Signed For",
    value: "Signed For",
  },
  {
    label: "UPS Ground (1-5 Days)",
    value: "UPS Ground (1-5 Days)",
  },
  {
    label: "Design Time Gifts and Craft",
    value: "Design Time Gifts and Craft",
  },
  {
    label: "Special delivery guaranteed (fully tracked)",
    value: "Special delivery guaranteed (fully tracked)",
  },
  {
    label: "Express 1-2 Days",
    value: "Express 1-2 Days",
  },
  {
    label: "Free Delivery (Small Packet™ - International Air)",
    value: "Free Delivery (Small Packet™ - International Air)",
  },
  {
    label: "UPS Ground  (1-5 Days)",
    value: "UPS Ground  (1-5 Days)",
  },
  {
    label: "Dhl Express: 3 to 7 days",
    value: "Dhl Express: 3 to 7 days",
  },
  {
    label: "DHL&FedEx 2-5 Business Days",
    value: "DHL&FedEx 2-5 Business Days",
  },
  {
    label: "International Express & Tracked Shipping",
    value: "International Express & Tracked Shipping",
  },
  {
    label: "Express Next Day",
    value: "Express Next Day",
  },
  {
    label: "Track&Trace",
    value: "Track&Trace",
  },
  {
    label: "One-Day Shipping",
    value: "One-Day Shipping",
  },
  {
    label: "Expedited Order",
    value: "Expedited Order",
  },
  {
    label: "Royal Mail 1nd Class",
    value: "Royal Mail 1nd Class",
  },
  {
    label: "USPS Priority mail",
    value: "USPS Priority mail",
  },
  {
    label: "Accéléré 1 à 4 jours ouvrables au Canada",
    value: "Accéléré 1 à 4 jours ouvrables au Canada",
  },
  {
    label: "Royal Mail 1st Class",
    value: "Royal Mail 1st Class",
  },
  {
    label: "Tracked Express UK",
    value: "Tracked Express UK",
  },
  {
    label: "Fastest Shipping 1-2 Day",
    value: "Fastest Shipping 1-2 Day",
  },
  {
    label: "UPS 2 Day Shipping",
    value: "UPS 2 Day Shipping",
  },
  {
    label: "Expedite",
    value: "Expedite",
  },
  {
    label: "DHL Priority",
    value: "DHL Priority",
  },
  {
    label: "Standard + TRACKING",
    value: "Standard + TRACKING",
  },
  {
    label: "FedEx Express",
    value: "FedEx Express",
  },
  {
    label: "Priority Mail Upgrade",
    value: "Priority Mail Upgrade",
  },
  {
    label: "Free shipping (Small Packet™ - International Surface)",
    value: "Free shipping (Small Packet™ - International Surface)",
  },
  {
    label: "Canadian Shipping Fee for Framed Orders",
    value: "Canadian Shipping Fee for Framed Orders",
  },
  {
    label: "US Priority Mail",
    value: "US Priority Mail",
  },
  {
    label: "2 Day",
    value: "2 Day",
  },
  {
    label: "3 weeks for delivery *estimated, not guaranteed*",
    value: "3 weeks for delivery *estimated, not guaranteed*",
  },
  {
    label: "Royal Mail International TRACKED",
    value: "Royal Mail International TRACKED",
  },
  {
    label: "EXPRESS POST",
    value: "EXPRESS POST",
  },
  {
    label: "Priority 3-4 Days",
    value: "Priority 3-4 Days",
  },
  {
    label: "FedEx Standard Tracked",
    value: "FedEx Standard Tracked",
  },
  {
    label: "Express Shipping",
    value: "Express Shipping",
  },
  {
    label: "Free shipping (USPS Priority Mail Express)",
    value: "Free shipping (USPS Priority Mail Express)",
  },
  {
    label: "UPS Express",
    value: "UPS Express",
  },
  {
    label: "FedEx 2 Day",
    value: "FedEx 2 Day",
  },
  {
    label:
      "Priority Shipping: 2-3 Business Days (must order by 2pm EST Mon-Fri)",
    value:
      "Priority Shipping: 2-3 Business Days (must order by 2pm EST Mon-Fri)",
  },
  {
    label: "Next Day",
    value: "Next Day",
  },
  {
    label: "Express Shipping: 1 Business Day (must order by 2pm EST Mon-Fri)",
    value: "Express Shipping: 1 Business Day (must order by 2pm EST Mon-Fri)",
  },
  {
    label: "Express Int.",
    value: "Express Int.",
  },
  {
    label: "International Parcel Surface",
    value: "International Parcel Surface",
  },
  {
    label: "UPS/Purolator",
    value: "UPS/Purolator",
  },
  {
    label: "Free Delivery (Small Packet™ - International Surface)",
    value: "Free Delivery (Small Packet™ - International Surface)",
  },
  {
    label: "無料配送（Standard International）",
    value: "無料配送（Standard International）",
  },
  {
    label: "DHL Shipping",
    value: "DHL Shipping",
  },
  {
    label: "Kostenloser Versand (Small Packet™ - International Surface)",
    value: "Kostenloser Versand (Small Packet™ - International Surface)",
  },
  {
    label: "Bezpłatna wysyłka (Small Packet™ - International Surface)",
    value: "Bezpłatna wysyłka (Small Packet™ - International Surface)",
  },
  {
    label:
      "GLS Flex (GLS informiert den Empfänger per E-Mail über die Lieferung)",
    value:
      "GLS Flex (GLS informiert den Empfänger per E-Mail über die Lieferung)",
  },
  {
    label: "3 Day",
    value: "3 Day",
  },
  {
    label: "USPS First-Class Mail",
    value: "USPS First-Class Mail",
  },
  {
    label: "Free shipping (USPS First-Class Mail)",
    value: "Free shipping (USPS First-Class Mail)",
  },
  {
    label: "Royal Mail 1st class",
    value: "Royal Mail 1st class",
  },
  {
    label: "Royal Mail TRACKED Delivery",
    value: "Royal Mail TRACKED Delivery",
  },
  {
    label: "1-3 Day USPS Priority Mail",
    value: "1-3 Day USPS Priority Mail",
  },
  {
    label: "Free Delivery (USPS First-Class Mail)",
    value: "Free Delivery (USPS First-Class Mail)",
  },
  {
    label: "UPS 1 Day",
    value: "UPS 1 Day",
  },
  {
    label: "Domestic Express",
    value: "Domestic Express",
  },
  {
    label: "Free shipping (International Parcel Surface)",
    value: "Free shipping (International Parcel Surface)",
  },
  {
    label: "Kostenloser Versand (USPS Priority Mail)",
    value: "Kostenloser Versand (USPS Priority Mail)",
  },
  {
    label: "UPS Shipping",
    value: "UPS Shipping",
  },
  {
    label: "Luis Cacho",
    value: "Luis Cacho",
  },
  {
    label: "3 weeks for delivery (estimated, not guaranteed)",
    value: "3 weeks for delivery (estimated, not guaranteed)",
  },
  {
    label: "Free shipping (Expedited Parcel™ - USA)",
    value: "Free shipping (Expedited Parcel™ - USA)",
  },
  {
    label: "NEXT DAY - Order before 2pm",
    value: "NEXT DAY - Order before 2pm",
  },
  {
    label: "Tracked international",
    value: "Tracked international",
  },
  {
    label: "RM24",
    value: "RM24",
  },
  {
    label: "UPS Express Saver",
    value: "UPS Express Saver",
  },
  {
    label: "flat rate express",
    value: "flat rate express",
  },
  {
    label: "Second Day Air UPS",
    value: "Second Day Air UPS",
  },
  {
    label: "DPD NEXT DAY - before 2pm",
    value: "DPD NEXT DAY - before 2pm",
  },
  {
    label: "PRIVATE COURIER",
    value: "PRIVATE COURIER",
  },
  {
    label: "Бесплатная доставка (USPS First-Class Mail)",
    value: "Бесплатная доставка (USPS First-Class Mail)",
  },
  {
    label: "Express International Shipping",
    value: "Express International Shipping",
  },
  {
    label: "Special Delivery by 1pm (excluding dispatch time)",
    value: "Special Delivery by 1pm (excluding dispatch time)",
  },
  {
    label: "Standard Tracked",
    value: "Standard Tracked",
  },
  {
    label: "TNT Express",
    value: "TNT Express",
  },
  {
    label: "US Priority Mail 2-4 Day",
    value: "US Priority Mail 2-4 Day",
  },
  {
    label: "USPS 1st Class Mail",
    value: "USPS 1st Class Mail",
  },
  {
    label: "Express 24 Hours - Royal Mail",
    value: "Express 24 Hours - Royal Mail",
  },
  {
    label: "1-4 days Priority Mail Upgrade",
    value: "1-4 days Priority Mail Upgrade",
  },
  {
    label: "Tracked and signed",
    value: "Tracked and signed",
  },
  {
    label: "Express Shipping ( 7 to 12 days)",
    value: "Express Shipping ( 7 to 12 days)",
  },
  {
    label: "1-4 Day Shipping",
    value: "1-4 Day Shipping",
  },
  {
    label: "Fastest Shipping",
    value: "Fastest Shipping",
  },
  {
    label: "colissimo Export",
    value: "colissimo Export",
  },
  {
    label: "Expedited Parcel",
    value: "Expedited Parcel",
  },
  {
    label: "Tracked Packet USA",
    value: "Tracked Packet USA",
  },
  {
    label: "無料配送（USPS First-Class Mail）",
    value: "無料配送（USPS First-Class Mail）",
  },
  {
    label:
      "FedEx International Priority 48 hours to the US, 2-5 days anywhere else",
    value:
      "FedEx International Priority 48 hours to the US, 2-5 days anywhere else",
  },
  {
    label: "Standard Post & Signature On Delivery",
    value: "Standard Post & Signature On Delivery",
  },
  {
    label: "Express 3 day",
    value: "Express 3 day",
  },
  {
    label: "Hillary Ainsworth",
    value: "Hillary Ainsworth",
  },
  {
    label: "DHL Go Green",
    value: "DHL Go Green",
  },
  {
    label: "Envío gratis (USPS First-Class Mail)",
    value: "Envío gratis (USPS First-Class Mail)",
  },
  {
    label: "USA Faster with Tracking and Insurance",
    value: "USA Faster with Tracking and Insurance",
  },
  {
    label: "Signature Confirmation",
    value: "Signature Confirmation",
  },
  {
    label: "Standard Shipping (Faster)",
    value: "Standard Shipping (Faster)",
  },
  {
    label: "USPS First Class Package WITH Tracking",
    value: "USPS First Class Package WITH Tracking",
  },
  {
    label: "USA- Shipping  2-3 Days",
    value: "USA- Shipping  2-3 Days",
  },
  {
    label: "Express Tracked Parcel",
    value: "Express Tracked Parcel",
  },
  {
    label: "Express Post - Approx 4 Business Days",
    value: "Express Post - Approx 4 Business Days",
  },
  {
    label: "2Day",
    value: "2Day",
  },
];

export const shopifyCarrierOptions = [
  {
    title: "Australia",
    options: [
      {
        label: "Australia Post",
        value: "Australia Post",
      },
      {
        label: "Sendle",
        value: "Sendle",
      },
      {
        label: "Aramex Australia",
        value: "Aramex Australia",
      },
      {
        label: "TNT Australia",
        value: "TNT Australia",
      },
      {
        label: "Hunter Express",
        value: "Hunter Express",
      },
      {
        label: "Couriers Please",
        value: "Couriers Please",
      },
      {
        label: "Bonds",
        value: "Bonds",
      },
      {
        label: "Allied Express",
        value: "Allied Express",
      },
      {
        label: "Direct Couriers",
        value: "Direct Couriers",
      },
      {
        label: "Northline",
        value: "Northline",
      },
      {
        label: "GO Logistics",
        value: "GO Logistics",
      },
    ],
  },
  {
    title: "Austria",
    options: [
      {
        label: "Österreichische Post",
        value: "Österreichische Post",
      },
    ],
  },
  {
    title: "Bulgaria",
    options: [
      {
        label: "Speedy",
        value: "Speedy",
      },
    ],
  },
  {
    title: "Canada",
    options: [
      {
        label: "Intelcom",
        value: "Intelcom",
      },
      {
        label: "BoxKnight",
        value: "BoxKnight",
      },
      {
        label: "Loomis",
        value: "Loomis",
      },
      {
        label: "GLS",
        value: "GLS",
      },
    ],
  },
  {
    title: "China",
    options: [
      {
        label: "China Post",
        value: "China Post",
      },
      {
        label: "DHL eCommerce Asia",
        value: "DHL eCommerce Asia",
      },
      {
        label: "WanbExpress",
        value: "WanbExpress",
      },
      {
        label: "YunExpress",
        value: "YunExpress",
      },
      {
        label: "Anjun Logistics",
        value: "Anjun Logistics",
      },
      {
        label: "SFC Fulfillment",
        value: "SFC Fulfillment",
      },
      {
        label: "FSC",
        value: "FSC",
      },
    ],
  },
  {
    title: "Czechia",
    options: [
      {
        label: "Zásilkovna",
        value: "Zásilkovna",
      },
    ],
  },
  {
    title: "Germany",
    options: [
      {
        label: "Deutsche Post (DE)",
        value: "Deutsche Post (DE)",
      },
      {
        label: "Deutsche Post (EN)",
        value: "Deutsche Post (EN)",
      },
      {
        label: "DHL",
        value: "DHL",
      },
      {
        label: "DHL Express",
        value: "DHL Express",
      },
      {
        label: "Swiship",
        value: "Swiship",
      },
      {
        label: "Hermes",
        value: "Hermes",
      },
      {
        label: "GLS",
        value: "GLS",
      },
    ],
  },
  {
    title: "Spain",
    options: [
      {
        label: "SEUR",
        value: "SEUR",
      },
    ],
  },
  {
    title: "France",
    options: [
      {
        label: "Colissimo",
        value: "Colissimo",
      },
      {
        label: "Mondial Relay",
        value: "Mondial Relay",
      },
      {
        label: "Colis Privé",
        value: "Colis Privé",
      },
      {
        label: "GLS",
        value: "GLS",
      },
    ],
  },
  {
    title: "United Kingdom",
    options: [
      {
        label: "Evri",
        value: "Evri",
      },
      {
        label: "DPD UK",
        value: "DPD UK",
      },
      {
        label: "Parcelforce",
        value: "Parcelforce",
      },
      {
        label: "Yodel",
        value: "Yodel",
      },
      {
        label: "DHL Parcel",
        value: "DHL Parcel",
      },
      {
        label: "Tuffnells",
        value: "Tuffnells",
      },
    ],
  },
  {
    title: "Greece",
    options: [
      {
        label: "ACS Courier",
        value: "ACS Courier",
      },
    ],
  },
  {
    title: "Hong Kong SAR",
    options: [
      {
        label: "SF Express",
        value: "SF Express",
      },
    ],
  },
  {
    title: "Ireland",
    options: [
      {
        label: "Fastway",
        value: "Fastway",
      },
      {
        label: "DPD Ireland",
        value: "DPD Ireland",
      },
    ],
  },
  {
    title: "India",
    options: [
      {
        label: "DTDC",
        value: "DTDC",
      },
      {
        label: "India Post",
        value: "India Post",
      },
      {
        label: "Delhivery",
        value: "Delhivery",
      },
      {
        label: "Gati KWE",
        value: "Gati KWE",
      },
      {
        label: "Professional Couriers",
        value: "Professional Couriers",
      },
      {
        label: "XpressBees",
        value: "XpressBees",
      },
      {
        label: "Ecom Express",
        value: "Ecom Express",
      },
      {
        label: "Ekart",
        value: "Ekart",
      },
      {
        label: "Shadowfax",
        value: "Shadowfax",
      },
      {
        label: "Bluedart",
        value: "Bluedart",
      },
    ],
  },
  {
    title: "Italy",
    options: [
      {
        label: "BRT",
        value: "BRT",
      },
      {
        label: "GLS Italy",
        value: "GLS Italy",
      },
    ],
  },
  {
    title: "Japan",
    options: [
      {
        label: "エコ配, 西濃運輸",
        value: "エコ配, 西濃運輸",
      },
      {
        label: "西濃スーパーエキスプレス",
        value: "西濃スーパーエキスプレス",
      },
      {
        label: "福山通運",
        value: "福山通運",
      },
      {
        label: "日本通運",
        value: "日本通運",
      },
      {
        label: "名鉄運輸",
        value: "名鉄運輸",
      },
      {
        label: "第一貨物",
        value: "第一貨物",
      },
    ],
  },
  {
    title: "Netherlands",
    options: [
      {
        label: "DHL Parcel",
        value: "DHL Parcel",
      },
      {
        label: "DPD",
        value: "DPD",
      },
    ],
  },
  {
    title: "Norway",
    options: [
      {
        label: "Bring",
        value: "Bring",
      },
    ],
  },
  {
    title: "Poland",
    options: [
      {
        label: "Inpost",
        value: "Inpost",
      },
    ],
  },
  {
    title: "Turkey",
    options: [
      {
        label: "PTT",
        value: "PTT",
      },
      {
        label: "Yurtiçi Kargo",
        value: "Yurtiçi Kargo",
      },
      {
        label: "Aras Kargo",
        value: "Aras Kargo",
      },
      {
        label: "Sürat Kargo",
        value: "Sürat Kargo",
      },
    ],
  },
  {
    title: "United States",
    options: [
      {
        label: "GLS",
        value: "GLS",
      },
      {
        label: "Alliance Air Freight",
        value: "Alliance Air Freight",
      },
      {
        label: "Pilot Freight",
        value: "Pilot Freight",
      },
      {
        label: "LSO",
        value: "LSO",
      },
      {
        label: "Old Dominion",
        value: "Old Dominion",
      },
      {
        label: "R+L Carriers",
        value: "R+L Carriers",
      },
      {
        label: "Southwest Air Cargo",
        value: "Southwest Air Cargo",
      },
    ],
  },
  {
    title: "South Africa",
    options: [
      {
        label: "Fastway",
        value: "Fastway",
      },
      {
        label: "Skynet",
        value: "Skynet",
      },
    ],
  },
  {
    title: "Others",
    options: [
      {
        label: "4PX",
        value: "4PX",
      },
      {
        label: "AGS",
        value: "AGS",
      },
      {
        label: "Amazon Logistics UK",
        value: "Amazon Logistics UK",
      },
      {
        label: "Amazon Logistics US",
        value: "Amazon Logistics US",
      },
      {
        label: "An Post",
        value: "An Post",
      },
      {
        label: "Anjun Logistics",
        value: "Anjun Logistics",
      },
      {
        label: "APC",
        value: "APC",
      },
      {
        label: "Asendia USA",
        value: "Asendia USA",
      },
      {
        label: "Australia Post",
        value: "Australia Post",
      },
      {
        label: "Bonshaw",
        value: "Bonshaw",
      },
      {
        label: "BPost",
        value: "BPost",
      },
      {
        label: "BPost International",
        value: "BPost International",
      },
      {
        label: "Canada Post",
        value: "Canada Post",
      },
      {
        label: "Canpar",
        value: "Canpar",
      },
      {
        label: "CDL Last Mile",
        value: "CDL Last Mile",
      },
      {
        label: "China Post",
        value: "China Post",
      },
      {
        label: "Chronopost",
        value: "Chronopost",
      },
      {
        label: "Chukou1",
        value: "Chukou1",
      },
      {
        label: "Colissimo",
        value: "Colissimo",
      },
      {
        label: "Comingle",
        value: "Comingle",
      },
      {
        label: "Coordinadora",
        value: "Coordinadora",
      },
      {
        label: "Correios",
        value: "Correios",
      },
      {
        label: "Correos",
        value: "Correos",
      },
      {
        label: "CTT",
        value: "CTT",
      },
      {
        label: "CTT Express",
        value: "CTT Express",
      },
      {
        label: "Cyprus Post",
        value: "Cyprus Post",
      },
      {
        label: "Delnext",
        value: "Delnext",
      },
      {
        label: "Deutsche Post",
        value: "Deutsche Post",
      },
      {
        label: "DHL eCommerce",
        value: "DHL eCommerce",
      },
      {
        label: "DHL eCommerce Asia",
        value: "DHL eCommerce Asia",
      },
      {
        label: "DHL Express",
        value: "DHL Express",
      },
      {
        label: "DoorDash",
        value: "DoorDash",
      },
      {
        label: "DPD",
        value: "DPD",
      },
      {
        label: "DPD Local",
        value: "DPD Local",
      },
      {
        label: "DPD UK",
        value: "DPD UK",
      },
      {
        label: "DTD Express",
        value: "DTD Express",
      },
      {
        label: "DX",
        value: "DX",
      },
      {
        label: "Eagle",
        value: "Eagle",
      },
      {
        label: "Estes",
        value: "Estes",
      },
      {
        label: "Evri",
        value: "Evri",
      },
      {
        label: "FedEx",
        value: "FedEx",
      },
      {
        label: "First Global Logistics",
        value: "First Global Logistics",
      },
      {
        label: "First Line",
        value: "First Line",
      },
      {
        label: "FSC",
        value: "FSC",
      },
      {
        label: "Fulfilla",
        value: "Fulfilla",
      },
      {
        label: "GLS",
        value: "GLS",
      },
      {
        label: "Guangdong Weisuyi Information Technology (WSE)",
        value: "Guangdong Weisuyi Information Technology (WSE)",
      },
      {
        label: "Heppner Internationale Spedition GmbH & Co.",
        value: "Heppner Internationale Spedition GmbH & Co.",
      },
      {
        label: "Iceland Post",
        value: "Iceland Post",
      },
      {
        label: "IDEX",
        value: "IDEX",
      },
      {
        label: "Israel Post",
        value: "Israel Post",
      },
      {
        label: "Japan Post (EN)",
        value: "Japan Post (EN)",
      },
      {
        label: "Japan Post (JA)",
        value: "Japan Post (JA)",
      },
      {
        label: "La Poste",
        value: "La Poste",
      },
      {
        label: "Lasership",
        value: "Lasership",
      },
      {
        label: "Latvia Post",
        value: "Latvia Post",
      },
      {
        label: "Lietuvos Paštas",
        value: "Lietuvos Paštas",
      },
      {
        label: "Logisters",
        value: "Logisters",
      },
      {
        label: "Lone Star Overnight",
        value: "Lone Star Overnight",
      },
      {
        label: "M3 Logistics",
        value: "M3 Logistics",
      },
      {
        label: "Meteor Space",
        value: "Meteor Space",
      },
      {
        label: "Mondial Relay",
        value: "Mondial Relay",
      },
      {
        label: "New Zealand Post",
        value: "New Zealand Post",
      },
      {
        label: "NinjaVan",
        value: "NinjaVan",
      },
      {
        label: "North Russia Supply Chain (Shenzhen) Co.",
        value: "North Russia Supply Chain (Shenzhen) Co.",
      },
      {
        label: "OnTrac",
        value: "OnTrac",
      },
      {
        label: "Packeta",
        value: "Packeta",
      },
      {
        label: "Pago Logistics",
        value: "Pago Logistics",
      },
      {
        label: "Ping An Da Tengfei Express",
        value: "Ping An Da Tengfei Express",
      },
      {
        label: "Pitney Bowes",
        value: "Pitney Bowes",
      },
      {
        label: "Portal PostNord",
        value: "Portal PostNord",
      },
      {
        label: "Poste Italiane",
        value: "Poste Italiane",
      },
      {
        label: "PostNL",
        value: "PostNL",
      },
      {
        label: "PostNord DK",
        value: "PostNord DK",
      },
      {
        label: "PostNord NO",
        value: "PostNord NO",
      },
      {
        label: "PostNord SE",
        value: "PostNord SE",
      },
      {
        label: "Purolator",
        value: "Purolator",
      },
      {
        label: "Qyun Express",
        value: "Qyun Express",
      },
      {
        label: "Royal Mail",
        value: "Royal Mail",
      },
      {
        label: "Royal Shipments",
        value: "Royal Shipments",
      },
      {
        label: "Sagawa (EN)",
        value: "Sagawa (EN)",
      },
      {
        label: "Sagawa (JA)",
        value: "Sagawa (JA)",
      },
      {
        label: "Sendle",
        value: "Sendle",
      },
      {
        label: "SF Express",
        value: "SF Express",
      },
      {
        label: "SFC Fulfillment",
        value: "SFC Fulfillment",
      },
      {
        label: "SHREE NANDAN COURIER",
        value: "SHREE NANDAN COURIER",
      },
      {
        label: "Singapore Post",
        value: "Singapore Post",
      },
      {
        label: "Southwest Air Cargo",
        value: "Southwest Air Cargo",
      },
      {
        label: "StarTrack",
        value: "StarTrack",
      },
      {
        label: "Step Forward Freight",
        value: "Step Forward Freight",
      },
      {
        label: "Swiss Post",
        value: "Swiss Post",
      },
      {
        label: "TForce Final Mile",
        value: "TForce Final Mile",
      },
      {
        label: "Tinghao",
        value: "Tinghao",
      },
      {
        label: "TNT",
        value: "TNT",
      },
      {
        label: "Toll IPEC",
        value: "Toll IPEC",
      },
      {
        label: "United Delivery Service",
        value: "United Delivery Service",
      },
      {
        label: "UPS",
        value: "UPS",
      },
      {
        label: "USPS",
        value: "USPS",
      },
      {
        label: "Venipak",
        value: "Venipak",
      },
      {
        label: "We Post",
        value: "We Post",
      },
      {
        label: "Whistl",
        value: "Whistl",
      },
      {
        label: "Wizmo",
        value: "Wizmo",
      },
      {
        label: "WMYC",
        value: "WMYC",
      },
      {
        label: "Xpedigo",
        value: "Xpedigo",
      },
      {
        label: "XPO Logistics",
        value: "XPO Logistics",
      },
      {
        label: "Yamato (EN)",
        value: "Yamato (EN)",
      },
      {
        label: "Yamato (JA)",
        value: "Yamato (JA)",
      },
      {
        label: "YiFan Express",
        value: "YiFan Express",
      },
      {
        label: "YunExpress",
        value: "YunExpress",
      },
    ],
  },
];

export const etsyCarrierOptions = [
  { value: "4PX Worldwide Express", label: "4PX Worldwide Express" },
  { value: "A1Post", label: "A1Post" },
  { value: "ABF Freight", label: "ABF Freight" },
  { value: "ACS Courier", label: "ACS Courier" },
  { value: "AeroFlash", label: "AeroFlash" },
  { value: "Afghan Post", label: "Afghan Post" },
  { value: "Amazon Logistics UK", label: "Amazon Logistics UK" },
  { value: "Amazon Logistics US", label: "Amazon Logistics US" },
  { value: "An Post", label: "An Post" },
  { value: "Anguilla Postal Service", label: "Anguilla Postal Service" },
  { value: "APC Postal Logistics", label: "APC Postal Logistics" },
  { value: "Aramex", label: "Aramex" },
  { value: "Asendia UK", label: "Asendia UK" },
  { value: "Asendia USA", label: "Asendia USA" },
  { value: "Australia Post", label: "Australia Post" },
  { value: "Austrian Post", label: "Austrian Post" },
  { value: "Austrian Post Registered", label: "Austrian Post Registered" },
  { value: "Bahrain Post", label: "Bahrain Post" },
  { value: "Bangladesh Post Office", label: "Bangladesh Post Office" },
  { value: "Belgium Post Domestic", label: "Belgium Post Domestic" },
  { value: "Belgium Post International", label: "Belgium Post International" },
  { value: "Belposhta", label: "Belposhta" },
  { value: "BH Posta", label: "BH Posta" },
  { value: "Blue Dart", label: "Blue Dart" },
  { value: "BotswanaPost", label: "BotswanaPost" },
  { value: "Brunei Postal Services", label: "Brunei Postal Services" },
  { value: "Bulgarian Posts", label: "Bulgarian Posts" },
  { value: "Cambodia Post", label: "Cambodia Post" },
  { value: "Canada Post", label: "Canada Post" },
  { value: "Canpar Courier", label: "Canpar Courier" },
  { value: "Ceska Posta", label: "Ceska Posta" },
  { value: "China EMS", label: "China EMS" },
  { value: "China Post", label: "China Post" },
  { value: "Chit Chats", label: "Chit Chats" },
  { value: "Chronopost France", label: "Chronopost France" },
  { value: "Chronopost Portugal", label: "Chronopost Portugal" },
  { value: "Chunghwa Post", label: "Chunghwa Post" },
  { value: "City Link", label: "City Link" },
  { value: "Colissimo", label: "Colissimo" },
  { value: "Collect+", label: "Collect+" },
  { value: "Correios de Brasil", label: "Correios de Brasil" },
  { value: "Correios de Macau", label: "Correios de Macau" },
  { value: "Correios de Portugal (CTT)", label: "Correios de Portugal (CTT)" },
  { value: "Correo Argentino Domestic", label: "Correo Argentino Domestic" },
  {
    value: "Correo Argentino International",
    label: "Correo Argentino International",
  },
  { value: "Correo Uruguayo", label: "Correo Uruguayo" },
  { value: "Correos - Espana", label: "Correos - Espana" },
  { value: "Correos Chile", label: "Correos Chile" },
  { value: "Correos De Mexico", label: "Correos De Mexico" },
  { value: "Correos de Costa Rica", label: "Correos de Costa Rica" },
  { value: "Correos del Ecuador", label: "Correos del Ecuador" },
  { value: "Courier Post", label: "Courier Post" },
  { value: "Couriers Please", label: "Couriers Please" },
  { value: "Cyprus Post", label: "Cyprus Post" },
  { value: "Deltec Courier", label: "Deltec Courier" },
  { value: "Deutsche Post", label: "Deutsche Post" },
  { value: "DHL Benelux", label: "DHL Benelux" },
  { value: "DHL Express", label: "DHL Express" },
  { value: "DHL Germany", label: "DHL Germany" },
  { value: "DHL Global Mail", label: "DHL Global Mail" },
  { value: "DHL Netherlands", label: "DHL Netherlands" },
  { value: "DHL Parcel NL", label: "DHL Parcel NL" },
  { value: "DHL Polska", label: "DHL Polska" },
  { value: "DHL Spain Domestic", label: "DHL Spain Domestic" },
  { value: "DHL eCommerce", label: "DHL eCommerce" },
  { value: "Direct Link", label: "Direct Link" },
  { value: "DPD", label: "DPD" },
  { value: "DPD Germany", label: "DPD Germany" },
  { value: "DPD Polska", label: "DPD Polska" },
  { value: "DPD UK", label: "DPD UK" },
  { value: "DTDC India", label: "DTDC India" },
  { value: "EC-Firstclass", label: "EC-Firstclass" },
  { value: "Egypt Post", label: "Egypt Post" },
  { value: "El Correo", label: "El Correo" },
  { value: "Elta Courier", label: "Elta Courier" },
  { value: "Empost", label: "Empost" },
  {
    value: "Empresa de Correos de Bolivia",
    label: "Empresa de Correos de Bolivia",
  },
  { value: "Estafeta", label: "Estafeta" },
  { value: "Estes", label: "Estes" },
  { value: "Estonian Post", label: "Estonian Post" },
  { value: "Ethiopian Postal Service", label: "Ethiopian Postal Service" },
  { value: "Evergreen", label: "Evergreen" },
  { value: "Fastway Australia", label: "Fastway Australia" },
  { value: "Fastway Couriers", label: "Fastway Couriers" },
  { value: "Fastway New Zealand", label: "Fastway New Zealand" },
  {
    value: "Fastways Couriers South Africa",
    label: "Fastways Couriers South Africa",
  },
  { value: "FedEx", label: "FedEx" },
  { value: "Fedex UK (Domestic)", label: "Fedex UK (Domestic)" },
  { value: "First Flight Couriers", label: "First Flight Couriers" },
  { value: "Flash Courier", label: "Flash Courier" },
  {
    value: "Freightquote by C. H. Robinson",
    label: "Freightquote by C. H. Robinson",
  },
  { value: "GATI-KWE", label: "GATI-KWE" },
  { value: "Ghana Post", label: "Ghana Post" },
  { value: "Globegistics", label: "Globegistics" },
  { value: "GLS", label: "GLS" },
  { value: "Greyhound", label: "Greyhound" },
  { value: "Guernsey Post", label: "Guernsey Post" },
  { value: "Hay Post", label: "Hay Post" },
  { value: "Hellenic Post", label: "Hellenic Post" },
  { value: "Hermes", label: "Hermes" },
  { value: "Hermes Italy", label: "Hermes Italy" },
  { value: "Hermes UK", label: "Hermes UK" },
  { value: "Hong Kong Post", label: "Hong Kong Post" },
  { value: "Hrvatska Posta", label: "Hrvatska Posta" },
  { value: "i-parcel", label: "i-parcel" },
  { value: "India Post", label: "India Post" },
  { value: "India Post International", label: "India Post International" },
  { value: "Interlink Express", label: "Interlink Express" },
  { value: "International Seur", label: "International Seur" },
  { value: "Ipostel", label: "Ipostel" },
  { value: "Iran Post", label: "Iran Post" },
  { value: "Islandspostur", label: "Islandspostur" },
  { value: "Isle of Man Post Office", label: "Isle of Man Post Office" },
  { value: "Israel Post", label: "Israel Post" },
  { value: "Israel Post Domestic", label: "Israel Post Domestic" },
  { value: "Jamaica Post", label: "Jamaica Post" },
  { value: "Japan Post", label: "Japan Post" },
  { value: "Jersey Post", label: "Jersey Post" },
  { value: "Jordan Post", label: "Jordan Post" },
  { value: "Kazpost", label: "Kazpost" },
  { value: "Korea Post", label: "Korea Post" },
  { value: "Korea Post EMS", label: "Korea Post EMS" },
  { value: "Kuehne + Nagel", label: "Kuehne + Nagel" },
  { value: "La Poste", label: "La Poste" },
  { value: "La Poste Monaco", label: "La Poste Monaco" },
  { value: "La Poste du Senegal", label: "La Poste du Senegal" },
  { value: "La Poste Tunisienne", label: "La Poste Tunisienne" },
  { value: "Landmark Global", label: "Landmark Global" },
  { value: "LaserShip", label: "LaserShip" },
  { value: "Latvijas Pasts", label: "Latvijas Pasts" },
  { value: "LibanPost", label: "LibanPost" },
  { value: "Lietuvos Pastas", label: "Lietuvos Pastas" },
  { value: "Magyar Posta", label: "Magyar Posta" },
  { value: "Makedonska Posta", label: "Makedonska Posta" },
  { value: "Malaysia Pos Daftar", label: "Malaysia Pos Daftar" },
  { value: "Maldives Post", label: "Maldives Post" },
  { value: "MaltaPost", label: "MaltaPost" },
  { value: "Mauritius Post", label: "Mauritius Post" },
  { value: "Mondial Relay", label: "Mondial Relay" },
  { value: "MRW", label: "MRW" },
  { value: "Multipack", label: "Multipack" },
  { value: "myHermes UK", label: "myHermes UK" },
  { value: "Nacex", label: "Nacex" },
  { value: "New Zealand Post", label: "New Zealand Post" },
  { value: "Nexive", label: "Nexive" },
  {
    value: "Nieuwe Post Nederlandse Antillen (PNA)",
    label: "Nieuwe Post Nederlandse Antillen (PNA)",
  },
  { value: "Nigerian Postal Service", label: "Nigerian Postal Service" },
  { value: "Nova Poshta", label: "Nova Poshta" },
  { value: "OCA", label: "OCA" },
  { value: "OPEK", label: "OPEK" },
  { value: "Oman Post", label: "Oman Post" },
  { value: "OnTrac", label: "OnTrac" },
  { value: "OPT", label: "OPT" },
  { value: "OPT de Nouvelle-Caledonie", label: "OPT de Nouvelle-Caledonie" },
  { value: "Pakistan Post", label: "Pakistan Post" },
  { value: "Parcelforce Worldwide", label: "Parcelforce Worldwide" },
  { value: "Poczta Polska", label: "Poczta Polska" },
  { value: "Pos Indonesia", label: "Pos Indonesia" },
  {
    value: "Pos Indonesia International",
    label: "Pos Indonesia International",
  },
  { value: "Pos Malaysia", label: "Pos Malaysia" },
  { value: "Post Aruba", label: "Post Aruba" },
  { value: "Post Fiji", label: "Post Fiji" },
  { value: "Post Luxembourg", label: "Post Luxembourg" },
  { value: "PostNL Domestic", label: "PostNL Domestic" },
  { value: "PostNL International", label: "PostNL International" },
  { value: "PostNL International 3S", label: "PostNL International 3S" },
  { value: "PostNord", label: "PostNord" },
  { value: "PostNord Logistics", label: "PostNord Logistics" },
  { value: "Posta", label: "Posta" },
  { value: "Posta Kenya", label: "Posta Kenya" },
  { value: "Posta Moldovei", label: "Posta Moldovei" },
  { value: "Posta Romana", label: "Posta Romana" },
  { value: "Posta Shqiptare", label: "Posta Shqiptare" },
  { value: "Posta Slovenije", label: "Posta Slovenije" },
  { value: "Posta Srbije", label: "Posta Srbije" },
  { value: "Posta Uganda", label: "Posta Uganda" },
  { value: "Poste Italiane", label: "Poste Italiane" },
  { value: "Poste Italiane Paccocelere", label: "Poste Italiane Paccocelere" },
  { value: "Poste Maroc", label: "Poste Maroc" },
  { value: "Posten AB", label: "Posten AB" },
  { value: "Posten Norge", label: "Posten Norge" },
  { value: "Posti", label: "Posti" },
  { value: "Postmates", label: "Postmates" },
  { value: "PTT Posta", label: "PTT Posta" },
  { value: "Purolator", label: "Purolator" },
  { value: "Qatar Post", label: "Qatar Post" },
  { value: "Red Express", label: "Red Express" },
  { value: "Redpack", label: "Redpack" },
  { value: "Royal Mail", label: "Royal Mail" },
  { value: "RL Carriers", label: "RL Carriers" },
  { value: "RPX Indonesia", label: "RPX Indonesia" },
  { value: "Russian Post", label: "Russian Post" },
  { value: "S.F International", label: "S.F International" },
  { value: "Safexpress", label: "Safexpress" },
  { value: "Sagawa", label: "Sagawa" },
  { value: "Saudi Post", label: "Saudi Post" },
  { value: "SDA Express Courier", label: "SDA Express Courier" },
  { value: "Selektvracht", label: "Selektvracht" },
  { value: "Senda Express", label: "Senda Express" },
  { value: "Sendle", label: "Sendle" },
  { value: "Serpost", label: "Serpost" },
  { value: "SEUR Espana (Domestico)", label: "SEUR Espana (Domestico)" },
  { value: "SEUR Portugal (Domestico)", label: "SEUR Portugal (Domestico)" },
  { value: "SF Express", label: "SF Express" },
  { value: "Singapore Post", label: "Singapore Post" },
  { value: "Singapore SpeedPost", label: "Singapore SpeedPost" },
  { value: "Siodemka", label: "Siodemka" },
  { value: "Skynet Malaysia", label: "Skynet Malaysia" },
  { value: "Skynet Worldwide Express", label: "Skynet Worldwide Express" },
  { value: "Slovenska posta", label: "Slovenska posta" },
  { value: "South Africa Post Office", label: "South Africa Post Office" },
  { value: "Stallion Express", label: "Stallion Express" },
  { value: "StarTrack", label: "StarTrack" },
  { value: "Swiss Post", label: "Swiss Post" },
  { value: "TA-Q-BIN Hong Kong", label: "TA-Q-BIN Hong Kong" },
  { value: "TA-Q-BIN Japan", label: "TA-Q-BIN Japan" },
  { value: "TA-Q-BIN Malaysia", label: "TA-Q-BIN Malaysia" },
  { value: "TA-Q-BIN Singapore", label: "TA-Q-BIN Singapore" },
  { value: "TGX", label: "TGX" },
  { value: "Thailand Post", label: "Thailand Post" },
  { value: "TNT", label: "TNT" },
  { value: "TNT Australia", label: "TNT Australia" },
  { value: "TNT France", label: "TNT France" },
  { value: "TNT Italia", label: "TNT Italia" },
  { value: "TNT UK", label: "TNT UK" },
  { value: "Toll Global Express", label: "Toll Global Express" },
  { value: "Toll Priority", label: "Toll Priority" },
  { value: "TTPost", label: "TTPost" },
  { value: "UK Mail", label: "UK Mail" },
  { value: "UkrPoshta", label: "UkrPoshta" },
  { value: "UPS", label: "UPS" },
  { value: "UPS Freight", label: "UPS Freight" },
  { value: "uShip", label: "uShip" },
  { value: "USPS", label: "USPS" },
  { value: "Vanuatu Post", label: "Vanuatu Post" },
  { value: "Vietnam Post", label: "Vietnam Post" },
  { value: "Vietnam Post EMS", label: "Vietnam Post EMS" },
  { value: "Whistl", label: "Whistl" },
  { value: "Xend", label: "Xend" },
  { value: "Yakit", label: "Yakit" },
  { value: "Yanwen", label: "Yanwen" },
  { value: "Yemen Post", label: "Yemen Post" },
  { value: "Yodel", label: "Yodel" },
  { value: "Yodel International", label: "Yodel International" },
  { value: "YRC Freight", label: "YRC Freight" },
  { value: "Zampost", label: "Zampost" },
  { value: "Zimpost", label: "Zimpost" },
];

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActivityInitialStateI } from "Src/Interface/@core";

const initialState: ActivityInitialStateI = {
  activityData: undefined,
  announcementData: undefined,
  newNotificationAvailable: false,
};

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    getActivitySlice: (state, action: PayloadAction<{ data: Object }>) => {
      state.activityData = action.payload.data;
    },
    getAnouncementSlice: (state, action: PayloadAction<{ data: Object }>) => {
      state.announcementData = action.payload.data;
    },
    toggleNewNotification: (state, action: PayloadAction<boolean>) => {
      state.newNotificationAvailable = action.payload;
    },
  },
});

export const { getActivitySlice, getAnouncementSlice, toggleNewNotification } =
  activitySlice.actions;

export default activitySlice.reducer;

import { createContext, useContext, useMemo, useState } from "react";
import { AttributesObjI, SelectOptionI } from "Src/Interface/@core";
import {
  BasicMappingKeys,
  CreateModalI,
  InitialI,
  ProfilenameI,
  ProfileValI,
  RuleGroupI,
  TemplatesI,
  VariationAttributeStateI,
} from "Src/Interface/@Profile";

const initialvalue: InitialI = {
  editProductIds:[],
  saveEditProductIds:(ids:string[])=>{},
  editProductIdsUpdated:false,
  saveEditProductIdsUpdated:(isUpdated:boolean)=>{},
  setCreateModal: (e: CreateModalI) => {},
  createModal: {
    isOpen: false,
    type: "",
  },
  basicMapping: {
    categoryId: "",
    categoryTree: {},
    image_linking_option: "",
    production_partner_ids: [],
    should_auto_renew: "",
    shopSectionId: "",
    is_personalizable: "0",
    personalization_is_required: "1",
    personalization_char_count_max: "256",
    personalization_instructions: "",
    materials: [],
    styles: [],
    whoMade: "",
    isSupply: "",
    whenMade: "",
    variantAttributes_shopify: [],
    variantAttributes_etsy: [],
    recommendedAttributes: {},
    recommendedAttributesEdit: {},
    requiredAttributes: [],
    option_name_selected: {},
    option_name_selected_edit: {},
    variantAttributesEdit: {},
  },
  profile: {
    overwrite: "",
    profile_code: "",
    profile_code_existing: "",
    ProfileId: "",
  },
  save_loading: false,
  error: {},
  rule_group: {
    condition: "||",
    ruleRows: [
      {
        property: "",
        operator: "",
        value: [],
        type: "input",
      },
    ],
    select_all: "1",
    update_on_shopify: [],
  },
  totalProduct: null,
  isDIGITAL: false,
  profileData: {},
  templates: {
    shipping_templates: "",
    policy_templates: "",
    inventory_templates: "",
    price_templates: "",
  },
  variationAttributesState: [],
  extraTemplateOptions: { shipping: [], inventory: [], policy: [], price: [] },
  saveError: (error) => {},
  removeError: () => {},
  setsave_loading: (loading: boolean) => {},
  addNewProfileData: (profileData: any) => {},
  updateProfileData: (changes:any) => {},
  updateBasicMapping: (key: BasicMappingKeys, val: string) => {},
  updateVariationState: (param: VariationAttributeStateI[]) => {},
  saveTemplates: (key: keyof TemplatesI, value: string) => {},
  variationAttributes: [],
  requiredAttributes: [],
  updateRuleGroup: (ruleGroupCallback: (prev: RuleGroupI) => RuleGroupI) => {},
  saveAttributesData: () => {},
  updateProfile: (key: keyof ProfilenameI, value: string) => {},
  shopifyAttributes: [],
  simpleAttributes: [],
  updateIsDIGITAL: (isDIGITAL: boolean) => {},
  updateTotalCount: (totalProduct: number | null) => {},
  saveBasicMapping: (basicMappingObj: {
    [keys in BasicMappingKeys]: any;
  }) => {},
  saveExtraOptions: (key: string, options: SelectOptionI[]) => {},
};

const ProfileContext = createContext<InitialI>(initialvalue);

const ProfileProvider = ({ children }: any) => {
  const [profileContextVal, setProfileContextVal] = useState<ProfileValI>({
    ...initialvalue,
    rule_group: JSON.parse(JSON.stringify(initialvalue.rule_group)),
  });

  const setsave_loading = (save_loading: boolean) => {
    setProfileContextVal((prevState) => ({ ...prevState, save_loading }));
  };

  const addNewProfileData = (profileData: any) => {
    setProfileContextVal((prevState) => ({ ...prevState, profileData }));
  };

  const updateProfileData = (changes: any) => {
    setProfileContextVal((prevState) => ({
      ...prevState,
      profileData: { ...prevState.profileData, ...changes },
    }));
  };

  const updateRuleGroup = (
    ruleGroupCallback: (prev: RuleGroupI) => RuleGroupI
  ) => {
    setProfileContextVal((prevState) => ({
      ...prevState,
      rule_group: ruleGroupCallback(prevState.rule_group),
    }));
  };

  const updateBasicMapping = (
    key: BasicMappingKeys,
    value: any,
    basicMappingObj?: { [keys in BasicMappingKeys]: any }
  ) => {
    if (basicMappingObj) {
      setProfileContextVal((prev) => ({
        ...prev,
        basicMapping: basicMappingObj,
      }));
    } else {
      setProfileContextVal((prev) => ({
        ...prev,
        basicMapping: { ...prev.basicMapping, [key]: value },
      }));
    }
  };
  const updateProfile = (key: keyof ProfilenameI, value: string) => {
    setProfileContextVal((prev) => ({
      ...prev,
      profile: { ...prev.profile, [key]: value },
    }));
  };

  const saveAttributesData = (attributes: {
    variationAttributes?: AttributesObjI[];
    requiredAttributes?: AttributesObjI[];
    shopifyAttributes?: string[];
    simpleAttributes?: AttributesObjI[];
  }) => {
    setProfileContextVal((prev) => ({
      ...prev,
      ...attributes,
    }));
  };
  const saveTemplates = (key: keyof TemplatesI, value: string) => {
    setProfileContextVal((prev) => ({
      ...prev,
      templates: { ...prev.templates, [key]: value },
    }));
  };
  const saveError = (error: { [key: string]: string }) => {
    setProfileContextVal((prev) => ({ ...prev, error }));
  };
  const removeError = (errorKey: string) => {
    if (profileContextVal.error?.[errorKey]) {
      const tempError = { ...profileContextVal.error };
      delete tempError[errorKey];
      setProfileContextVal((prev) => ({ ...prev, error: { ...tempError } }));
    }
  };
  const updateVariationState = (updatedState: VariationAttributeStateI[]) => {
    setProfileContextVal((prev) => ({
      ...prev,
      variationAttributesState: updatedState,
    }));
  };
  const saveBasicMapping = (basicMappingObj: {
    [keys in BasicMappingKeys]: any;
  }) => {
    setProfileContextVal((prev) => ({
      ...prev,
      basicMapping: basicMappingObj,
    }));
  };

  const updateTotalCount = (totalProduct: number | null) => {
    setProfileContextVal((prev) => ({ ...prev, totalProduct }));
  };
  const setCreateModal = (createModal: CreateModalI) => {
    setProfileContextVal((prev) => ({ ...prev, createModal }));
  };

  const saveExtraOptions = (key: string, options: SelectOptionI[]) => {
    setProfileContextVal((prev) => ({
      ...prev,
      extraTemplateOptions: { ...prev.extraTemplateOptions, [key]: options },
    }));
  };

  const saveEditProductIds = (ids: string[]) => {
    setProfileContextVal((prev) => ({ ...prev, editProductIds: ids }));
  };

  const saveEditProductIdsUpdated = (isUpdated:boolean) => {
    setProfileContextVal((prev) => ({
      ...prev,
      editProductIdsUpdated: isUpdated,
    }));
  }

  const updateIsDIGITAL = (isDIGITAL: boolean) => {
    setProfileContextVal((prev) => ({ ...prev, isDIGITAL }));
  };
  const value: InitialI = useMemo(
    () => ({
      ...profileContextVal,
      setCreateModal,
      saveError,
      removeError,
      setsave_loading,
      addNewProfileData,
      updateProfileData,
      updateBasicMapping,
      updateRuleGroup,
      saveTemplates,
      saveAttributesData,
      updateProfile,
      updateVariationState,
      updateTotalCount,
      updateIsDIGITAL,
      saveBasicMapping,
      saveExtraOptions,
      saveEditProductIds,
      saveEditProductIdsUpdated
    }),
    [profileContextVal]
  );
  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

const useProfileContext = () => {
  return useContext<InitialI>(ProfileContext);
};
export { ProfileProvider, useProfileContext };

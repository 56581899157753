import React from "react";

import { EmptyState, Text } from "@shopify/polaris";
import NoTopSellingProducts from "../../assets/FallBackImages/NoTopSellingProducts.svg";
import NoRevenew from "../../assets/FallBackImages/NoRevenue.svg";
import NoNotificationImg from "../../assets/FallBackImages/NoNotification.svg";
import NoAnnouncement from "../../assets/FallBackImages/NoAnnouncements.svg";
// Products
import NoProduct from "../../assets/FallBackImages/NoProducts.svg";
import NoProductSearch from "../../assets/FallBackImages/NoproductsSearch.svg";
// NotLInked
import NoNotLinked from "../../assets/FallBackImages/NoNotLinked.svg";
import NoNotLinkedSearch from "../../assets/FallBackImages/NoNotLinkedSearch.svg";
// Linked
import NoLinked from "../../assets/FallBackImages/NoLinkedProduct.svg";
import NoLinkedSearch from "../../assets/FallBackImages/NoLinkedProductSearch.svg";
// Order
import NoOrder from "../../assets/FallBackImages/NoOrders.svg";
import NoOrderSearch from "../../assets/FallBackImages/NoOrdersSearch.svg";
// Templates
import NoShippingTemplate from "../../assets/FallBackImages/NoShippingTemplate.svg";
import NoInventoryTemplate from "../../assets/FallBackImages/NoInventoryTemplate.svg";
import NoPricingTemplate from "../../assets/FallBackImages/NoPricingTemplate.svg";
import NoPolicyTemplate from "../../assets/FallBackImages/NoPolicyTemplate.svg";
import NoShopSelection from "../../assets/FallBackImages/NoShopSelectionTemplate.svg";
import NoProductionPartnerTemp from "../../assets/FallBackImages/NoProductionPartnerTemplate.svg";

import NoShippingSearchTemplate from "../../assets/FallBackImages/NoShippingTemplateSearch.svg";
import NoInventorySearchTemplate from "../../assets/FallBackImages/NoInventoryTemplateSearch.svg";
import NoPricingSearchTemplate from "../../assets/FallBackImages/NoPriceTemplateSearch.svg";
import NoPolicySearchTemplate from "../../assets/FallBackImages/NoPolicyTemplateSearch.svg";
import NoShopSelectionSearch from "../../assets/FallBackImages/NoShopSelectionSearch.svg";
import NoProductionPartnerSearchTemp from "../../assets/FallBackImages/NoProductionPartnerTemplate.svg";

// Profile
import NoProfile from "../../assets/FallBackImages/NoProfileFound.svg";
import NoProfileSearch from "../../assets/FallBackImages/NoProfileSearch.svg";
// Review
import NoReview from "../../assets/FallBackImages/NoReview.svg";
import NoReviewSearch from "../../assets/FallBackImages/NoReviewSearch.svg";

import PageNotFound from "../../assets/FallBackImages/404PageNotFound.svg";
import NoInternet from "../../assets/FallBackImages/NoInternet.svg";
import BrokenPage from "../../assets/FallBackImages/BrokenPage.svg";

import "./emptystate.css";
import { useTranslation } from "react-i18next";

// Review
export const NoReviewFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoReview}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_REVIEWS")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoReviewSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoReviewSearch}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
// Profile
export const NoProfileFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoProfile}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_PROFILE_CREATED")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoProfileSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoProfileSearch}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

//Linked
export const NoLinkedFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoLinked}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoLinkedSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoLinkedSearch}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

// Not Linked
export const NoNotLinkedFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoNotLinked}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_PRODUCTS_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoNotLinkedSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoNotLinkedSearch}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
// Order
export const NoOrderFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoOrder}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_ORDERS_YET")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoOrderSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoOrderSearch}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_ORDERS_FOUND_IN_SEARCH")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
// Products
export const NoProductsFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoProduct}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_PRODUCTS_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoProductsSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoProductSearch}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_PRODUCTS_FOUND_IN_SEARCH")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

// Shipping Template
export const NoShippingTemplateFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoShippingTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_TEMPLATES_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoShippingTemplateSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoShippingSearchTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
// Inventory template
export const NoInventoryTemplateFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoInventoryTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_TEMPLATES_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoInventoryTemplateSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoInventorySearchTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

// Price template
export const NoPriceTemplateFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoPricingTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_PRICE_TEMPLATE_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoPriceTemplateSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoPricingSearchTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
// Policy template
export const NoPolicyTemplateFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoPolicyTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_POLICY_TEMPLATE_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoPolicyTemplateSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoPolicySearchTemplate}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
// Shop section
export const NoShopSectionTempFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoShopSelection}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SHOP_SECTION_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoShopSectionTempSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoShopSelectionSearch}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

// Production partner
export const NoProductionPartnerFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoProductionPartnerTemp}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_PRODUCTION_PARTNER_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoProductionPartnerSearchFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoProductionPartnerSearchTemp}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_SEARCH_RESULTS_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

// activity page
export const NoActivityFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoNotificationImg}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_ACTIVITY_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoAnnouncementFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoAnnouncement}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_ANNOUNCEMENTS")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const NoGenralNotificationFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoNotificationImg}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_GENERAL_NOTIFICATIONS")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

export const NoTopSellingFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoTopSellingProducts}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_TOP_SELLING_PRODUCTS")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

export const NoRevenewFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback">
      <EmptyState image={NoRevenew}>
        <div className="empty-mt-12 description_fallback">
          <Text as="p" variant="bodyMd" tone="disabled">
            {t("NO_REVENUE_TO_SHOW")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

export const PageNotFound404 = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback fullpage">
      <EmptyState
        image={PageNotFound}
        action={{
          content: "Go back to the Home Page",
        }}
      >
        <div className="empty-mt-12 description_fallback">
          <Text as="h6" variant="headingLg">
            {t("PAGE_NOT_FOUND")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

export const NoInternetPage = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback fullpage">
      <EmptyState image={NoInternet}>
        <div className="empty-mt-12 description_fallback">
          <Text as="h6" variant="headingLg">
            {t("NO_INTERNET_CONNECTION_AVAILABLE")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};
export const BrokenPageU = () => {
  const { t } = useTranslation();
  return (
    <div className="custom_spacing_fallback fullpage">
      <EmptyState image={BrokenPage}>
        <div className="empty-mt-12 description_fallback">
          <Text as="h6" variant="headingLg">
            {t("WEBPAGE_APPEARS_TO_BE_BROKEN")}
          </Text>
        </div>
      </EmptyState>
    </div>
  );
};

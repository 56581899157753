import React, { useEffect, useState } from "react";
import { BlockStack, Box, Card, Divider, InlineGrid, Text, useBreakpoints } from "@shopify/polaris";
import { useProfileContext } from "../ProfileProvider";
import Attribute from "./Attribute";
import { AttributesObjI, DIProps, ObjStrI } from "../../../../Interface/@core";
import { BasicMappingKeys } from "../../../../Interface/@Profile";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { DI } from "Src/core";

interface PropsI extends DIProps {
  title: string;
  description: string;
  attributes: AttributesObjI[];
  updateKey: BasicMappingKeys;
  isRequired?: boolean;
  errorsObj: ObjStrI;
  removeError: (param: string) => void;
}

const AttributesGroup = ({
  title,
  description,
  attributes,
  updateKey,
  errorsObj,
  isRequired = false,
  removeError,
  t,
  redux: {
    settings: { metafields, customSettings },
  },
  
}: PropsI) => {
  const {mdUp,mdDown} = useBreakpoints();
  const { basicMapping, updateBasicMapping } = useProfileContext();
  const [attributesState, setAttributesState] = useState<
    { value: string | string[]; scaleValue?: string; metaKeys?: string }[]
  >([]);
  const { routeData } = useRoutesContext();

  const updateAttributeValue = (
    index: number,
    key: "value" | "scaleValue" | "metaKeys",
    val: string | string[]
  ) => {
    const tempAttributeState = [...attributesState];
    if (key === "scaleValue" || key === "metaKeys") {
      tempAttributeState[index][key] = val as string;
    } else {
      tempAttributeState[index].value = val;
      tempAttributeState[index].scaleValue =
        attributes[index]?.scales?.[0]?.scale_id?.toString() ?? undefined;
    }
    setAttributesState(tempAttributeState);
  };

  useEffect(() => {
    const isEdit = ["edit", "clone", "view"].includes(routeData.data.type);
    setAttributesState(
      attributes.map((attr) => {
        const tempObj: {
          value: string | string[];
          scaleValue?: string;
          metaKeys?: string;
        } = {
          value: "",
          scaleValue: attr?.scales?.[0]?.scale_id?.toString() ?? "",
        };
        if (isEdit) {
          const editValues =
            basicMapping?.recommendedAttributesEdit?.[attr.property_id]?.values;
          if (editValues) {
            if (attr.is_multivalued === "1") {
              tempObj.value = editValues
                .map((values: string) => {
                  try {
                    const parsedValues = JSON.parse(values);
                    const [id, name] = Object.entries(parsedValues)[0];
                    return `{CedDQuote@#${id}CedDQuote@#:CedDQuote@#${name}CedDQuote@#}`;
                  } catch (e) {
                    return null;
                  }
                })
                .filter((val: any) => val !== null);
            } else {
              if (attr.possible_values) {
                const parsedValues = JSON.parse(editValues);
                const [id, name] = Object.entries(parsedValues)?.[0];
                tempObj.value = `{CedDQuote@#${id}CedDQuote@#:CedDQuote@#${name}CedDQuote@#}`;
              } else {
                tempObj.value = editValues;
              }
            }
          }
          tempObj.scaleValue =
            basicMapping?.recommendedAttributesEdit?.[
              attr.property_id
            ]?.scale_id;
          if (customSettings?.isMetafieldEnabled) {
            tempObj.metaKeys =
              basicMapping?.recommendedAttributesEdit?.[
                attr.property_id
              ]?.meta_keys;
          }
        } else {
          const valueIdNameMap: ObjStrI =
            attr.possible_values?.reduce(
              (prev, curr) => ({ ...prev, [curr.value_id]: curr.name }),
              {}
            ) ?? {};
          if (attr.is_multivalued === "1") {
            tempObj.value =
              attr?.selected_values && attr?.selected_values.length > 0
                ? attr.selected_values?.map((val) =>
                    valueIdNameMap[val?.value_id]
                      ? `{CedDQuote@#${val?.value_id}CedDQuote@#:CedDQuote@#${
                          valueIdNameMap[val?.value_id ?? ""]
                        }CedDQuote@#}`
                      : ""
                  )
                : [];
          } else {
            tempObj.value = attr.selected_values?.[0]
              ? `{CedDQuote@#${
                  attr.selected_values?.[0]?.value_id
                }CedDQuote@#:CedDQuote@#${
                  valueIdNameMap[attr.selected_values?.[0]?.value_id ?? ""]
                }CedDQuote@#}`
              : "";
          }
          if (customSettings?.isMetafieldEnabled) {
            tempObj.metaKeys = "";
          }
        }
        return tempObj;
      })
    );
  }, [attributes, basicMapping.recommendedAttributesEdit]);

  useEffect(() => {
    const requiredAttributesData: any = {};

    attributes.forEach((attr, index) => {
      const obj = { values: attributesState?.[index]?.["value"] };

      if (attr.scales) {
        Object.assign(obj, {
          scale_id: attributesState?.[index]?.["scaleValue"],
        });
      }

      if (customSettings?.isMetafieldEnabled) {
        Object.assign(obj, {
          meta_keys: attributesState?.[index]?.["metaKeys"],
        });
      }

      Object.assign(requiredAttributesData, {
        [attr.property_id]: { ...obj },
      });
    });
    updateBasicMapping(updateKey, requiredAttributesData);
  }, [attributes, attributesState]);

  return (
    <BlockStack gap={"200"}>
      <BlockStack gap={"100"}>
        <div className={isRequired ? "custom_required" : ""}>
          <Text as="h6" variant="bodyMd">
            {title}
          </Text>
        </div>
        <Text as="p" variant="bodyMd" tone="subdued">
          {description}
        </Text>
      </BlockStack>
      <Card>
        <BlockStack gap={mdUp ? "200" : "0"}>
        {mdUp &&  <InlineGrid columns={["oneHalf", "oneHalf"]}>
            <Text as="h6" variant="headingMd" fontWeight="semibold">
              {t("ATTRIBUTES")}
            </Text>
            {customSettings?.isMetafieldEnabled ? (
              <InlineGrid columns={["oneHalf", "oneHalf"]}>
                <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("VALUE")}
                </Text>

                <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("SHOPIFY")}
                </Text>
              </InlineGrid>
            ) : (
              <Text as="h6" variant="headingMd" fontWeight="semibold">
                {t("VALUE")}
              </Text>
            )}
          </InlineGrid>}

          {attributes.map((attr, index) => {
            return (
              <InlineGrid columns={mdUp ? ["oneHalf", "oneHalf"]: 1} gap={mdDown ? "100" : "0"}>
                {mdDown &&   <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("ATTRIBUTES")}
                </Text>}

                <Text as="span" variant="bodyMd">
                  {attr.display_name}
                </Text>

                    {mdDown &&   <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("VALUE")}
                  </Text>}

                <Attribute
                  id={`${updateKey}_${index}`}
                  attrObj={attr}
                  value={attributesState?.[index]?.value}
                  onChange={(value) => {
                    removeError(`${updateKey}_${index}`);
                    updateAttributeValue(index, "value", value);
                  }}
                  scaleValue={attributesState?.[index]?.scaleValue}
                  onScaleChange={(value) => {
                    updateAttributeValue(index, "scaleValue", value);
                  }}
                  metaKeys={attributesState?.[index]?.metaKeys}
                  onMetaKeysChange={(value: string) => {
                    updateAttributeValue(index, "metaKeys", value);
                  }}
                  isMetafieldEnabled={
                    customSettings?.isMetafieldEnabled ?? false
                  }
                  metafields={metafields}
                  error={errorsObj[`${updateKey}_${index}`]}
                />
                {mdDown  && index !== attributes.length - 1 && (<Box paddingBlockStart='300' paddingBlockEnd="200"><Divider/></Box>)}
              </InlineGrid>
            );
          })}
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(AttributesGroup);

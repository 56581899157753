import React from 'react'
import DashboardSkeleton from './DashboardSkeleton'
import { Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'

const WithHeaderDashboardSkeleton = () => {
    const { mdDown } = useBreakpoints()
    return (
        <div>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'200'}>
                <div className="custom_layout_width dashboard_width">
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>
                            {mdDown ? (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                    <InlineStack gap={"200"}>

                                        <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </InlineStack>
                                </InlineStack>
                            ) : (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                    <InlineStack gap={"200"}>

                                        <div style={{ "width": "28px" }}>
                                            <SkeletonDisplayText size='small' />
                                        </div>
                                    </InlineStack>
                                </InlineStack>
                            )}
                        </Page>
                    </div>
                </div>
            </Box>
            <DashboardSkeleton />
        </div>
    )
}

export default WithHeaderDashboardSkeleton

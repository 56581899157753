import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { EmailIcon } from "@shopify/polaris-icons";
import { ReadEmailIcon } from "../../../assets/icons/Icons";
import { GetNotificationAnnouncementData } from "../Helper";
import { NoAnnouncementFallback } from "Src/Component/Fallbacks/FallBackPages";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { getFormattedDateTime } from "Src/function";

const Announcements = ({
  popover,
  AllAnnouncement,
  request: { POST, GET },
  dispatch,
  redux,
}: {
  AllAnnouncement: any;
  popover: boolean;
} & DIProps) => {
  const renderAnnouncement = () => {
    if (AllAnnouncement?.etsynotification?.length > 0) {
      return AllAnnouncement?.etsynotification?.map(
        (item: any, index: number) => (
          <div className="notification-item" key={index}>
            <Box borderRadius="200">
              <InlineStack wrap={false} gap="200" blockAlign="start">
                <Box>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 9.75058C3 8.34799 4.09572 7.18971 5.49615 7.1119L9.2887 6.90121C9.46413 6.89146 9.63389 6.83567 9.78089 6.73945L14.3273 3.76359C14.9924 3.32827 15.875 3.80541 15.875 4.60029V7.78528C16.8003 7.96081 17.5 8.77379 17.5 9.75018C17.5 10.7266 16.8003 11.5395 15.875 11.7151V14.9009C15.875 15.6957 14.9924 16.1729 14.3273 15.7376L10.1381 12.9955L9.84876 15.4546C9.74508 16.336 8.99815 17.0002 8.11075 17.0002H7.75C6.7835 17.0002 6 16.2167 6 15.2502V12.4172L5.49615 12.3893C4.09572 12.3115 3 11.1532 3 9.75058ZM5.57936 8.60959C4.9738 8.64324 4.5 9.14409 4.5 9.75058C4.5 10.3571 4.9738 10.8579 5.57936 10.8916L9.37191 11.1023C9.81047 11.1266 10.2349 11.2661 10.6024 11.5067L14.375 13.976V5.52515L10.6024 7.9945C10.2349 8.23505 9.81047 8.37453 9.37191 8.3989L5.57936 8.60959ZM7.5 12.5006V15.2502C7.5 15.3882 7.61193 15.5002 7.75 15.5002H8.11075C8.23752 15.5002 8.34423 15.4053 8.35904 15.2794L8.67826 12.566L7.5 12.5006Z"
                      fill="#4A4A4A"
                    />
                  </svg>
                </Box>
                <div style={{ flex: 1 }}>
                  <BlockStack gap="200">
                    <InlineStack align="space-between" wrap={false} gap="200">
                      <Text
                        as="p"
                        variant="bodyLg"
                        fontWeight={!item.is_viewed ? "medium" : "regular"}
                      >
                        {item.heading}
                      </Text>

                      <InlineStack gap="100" wrap={false}>
                        <Button
                          variant="monochromePlain"
                          onClick={() => {
                            !item.is_viewed &&
                              POST("activities/set-read-notification", {
                                unread_notification_id: item.id,
                              }).finally(() =>
                                GetNotificationAnnouncementData(
                                  GET,
                                  dispatch,
                                  redux,
                                  true
                                )
                              );
                          }}
                          icon={item.is_viewed ? ReadEmailIcon : EmailIcon}
                        ></Button>
                      </InlineStack>
                    </InlineStack>
                    <BlockStack gap="100" inlineAlign="start">
                      <Text
                        as="p"
                        variant="bodyMd"
                        tone="subdued"
                        fontWeight={!item.is_viewed ? "medium" : "regular"}
                      >
                        {item.html_content}
                      </Text>
                    </BlockStack>
                    <Text as="p" variant="bodySm" tone="subdued">
                      {getFormattedDateTime(item.from_date)}
                    </Text>
                  </BlockStack>
                </div>
              </InlineStack>
            </Box>
          </div>
        )
      );
    }
    return (
      <Card roundedAbove="xs">
        <NoAnnouncementFallback />
      </Card>
    );
  };
  return (
    <Box paddingBlockStart={popover ? "400" : "500"}>
      <BlockStack gap={popover ? "0" : "0"}>{renderAnnouncement()}</BlockStack>
    </Box>
  );
};

export default DI(Announcements);

import { BlockStack, Box, Card, Divider, Grid, IndexTable, InlineGrid, InlineStack, Layout, SkeletonBodyText, Text, useBreakpoints } from '@shopify/polaris'
import React from 'react'

const ShopifyOrderViewSkeleton = () => {
    const {mdUp} = useBreakpoints();

    const returnOrdersItems = [
        {
            id: "1020",
            title: <div style={{ "width": "250px" }}>
                <BlockStack gap={"300"}>
                    <SkeletonBodyText lines={1} />
                    <div style={{ "width": "100px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <InlineStack gap={"200"}>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                    <InlineStack gap={"200"}>
                        <div style={{ "width": "70px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                </BlockStack>
            </div>,
            sellingPrice: <div style={{ 'width': "80px" }}><SkeletonBodyText lines={1} /></div>,
            discount: <div style={{ 'width': "80px" }}><SkeletonBodyText lines={1} /></div>,
            finalPrice: <div style={{ "width": "50px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>,
            lineItemStatus: <div style={{ 'width': "100px" }}><SkeletonBodyText lines={1} /></div>,
        },
    ];
    const rowMarkupOrderItem = returnOrdersItems.map(
        ({ id, title, sellingPrice, discount, finalPrice, lineItemStatus }: any, index: number) => (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>
                    {title}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {sellingPrice}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {discount}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {finalPrice}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {lineItemStatus}
                </IndexTable.Cell>
            </IndexTable.Row>
        )
    );

    const rowMarkupOrderItemMobile = returnOrdersItems.map(
        ({ id, title, sellingPrice, discount, finalPrice, lineItemStatus }: any, index: number) => (
            <Box id={id} key={id} >
            <Box padding="300" >
              <BlockStack gap="200">
                <BlockStack gap={"100"}>
                <div className='custom-height_skel' style={{ "width": "150px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
    
                    <div className='custom-height_skel' style={{ "width": "100px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div className='custom-height_skel' style={{ "width": "80px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </BlockStack>
    
                <Divider />
    
                <InlineGrid columns={2}>
                <div className='custom-height_skel' style={{ "width": "60px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div className='custom-height_skel' style={{ "width": "125px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </InlineGrid>
    
                <InlineGrid columns={2}>
                <div className='custom-height_skel' style={{ "width": "70px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div className='custom-height_skel' style={{ "width": "115px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </InlineGrid>
    
                <InlineGrid columns={2}>
                <div className='custom-height_skel' style={{ "width": "80px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div className='custom-height_skel' style={{ "width": "110px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </InlineGrid>
    
                <Divider />
    
                <InlineGrid columns={2}>
                <div className='custom-height_skel' style={{ "width": "100px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div className='custom-height_skel' style={{ "width": "120px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </InlineGrid>
              </BlockStack>
              </Box>
              <Box borderBlockEndWidth='025' borderColor="border"></Box>
          </Box>
        )
    );

    const rowMarkupOrderItemUpdated = Array.from({ length: 1 }, () => rowMarkupOrderItem);



 

    return (
        <Layout>
            <Layout.Section>
                <BlockStack gap={"300"}>
                    <Card>
                        <BlockStack gap={"300"}>
                            <InlineStack gap={"200"} blockAlign="center">
                                <div className='skeleton-height-20' style={{ "width": "100px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div  style={{ "width": "50px" }} className='custom-height_skel'>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                            <Divider />
                            <BlockStack gap={"200"}>
                                <div className='custom-height_skel' style={{ "width": "150px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <Card padding={"0"} roundedAbove='xs'>
                                  {mdUp ?     <div className="custom-table-skeleton"><IndexTable
                                        selectable={false}
                                        itemCount={2}
                                        headings={[
                                            { title: "" },
                                            { title: "" },
                                            { title: "" },
                                            { title: "" },
                                            { title: "" },
                                        ]}
                                    >
                                        {rowMarkupOrderItemUpdated}
                                    </IndexTable></div>: rowMarkupOrderItemMobile}
                                </Card>
                            </BlockStack>
                        </BlockStack>
                    </Card>

                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "50px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <BlockStack gap={"500"}>
                                {Array.from({ length: 6 }, (_,i) =>
                                    <InlineStack align="space-between" blockAlign="center" key={i}>
                                        <div style={{ "width": "150px" }} ><SkeletonBodyText lines={1} /></div>
                                        <div style={{ "width": "120px" }} ><SkeletonBodyText lines={1} /></div>
                                    </InlineStack>
                                )}

                                <Divider />
                                <InlineStack align="space-between" blockAlign="center">
                                    <div style={{ "width": "90px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                                    <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                                </InlineStack>
                            </BlockStack>
                        </BlockStack>
                    </Card>
                </BlockStack>
            </Layout.Section>
            <Layout.Section variant="oneThird">
                <BlockStack gap={"300"}>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                                <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                            </Grid>
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <BlockStack gap={"500"}>
                                {Array.from({ length: 4 }, (_,i) =>
                                    <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }} key={i}>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                    </Grid>
                                )}
                            </BlockStack>

                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <BlockStack gap={"500"}>
                                {Array.from({ length: 2 }, (_,i) =>
                                    <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }} key={i}>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                    </Grid>
                                )}
                            </BlockStack>
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <BlockStack gap={"200"}>
                                <SkeletonBodyText lines={2} />
                            </BlockStack>
                        </BlockStack>
                    </Card>
                </BlockStack>
            </Layout.Section>
        </Layout>
    )
}

export default ShopifyOrderViewSkeleton

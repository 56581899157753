import { createContext, useContext, useMemo, useState } from "react";
type filterTypeI = string | { to: string | number; from: string | number };
interface InitialI {
  filters: {
    [name: string]: filterTypeI;
  };
  profileGridData: any[];
  updateFilters: (e: { [name: string]: filterTypeI }) => void;
  updateProfileGridData: (e: any[]) => void;
  loading: boolean;
  setloading: (e: boolean) => void;
  modal: { open: boolean; for: "delete" | "disable" | "view"; id: string };
  updateModal: (modal: {
    open: boolean;
    for: "delete" | "disable" | "view";
    id: string;
  }) => void;
}

const initialvalue: InitialI = {
  loading: false,
  modal: { open: false, for: "delete", id: "" },
  setloading: (e: boolean) => {},
  filters: {},
  profileGridData: [],
  updateFilters: (e: { [name: string]: filterTypeI }) => {},
  updateProfileGridData: (e: any[]) => {},
  updateModal: (e: {
    open: boolean;
    for: "delete" | "disable" | "view";
    id: string;
  }) => {},
};

const ProfileGridContext = createContext(initialvalue);

const ProfileGridProvider = ({ children }: any) => {
  const [profileGridContextVal, setProfileGridContextVal] =
    useState<InitialI>(initialvalue);
  const updateFilters = (filters: { [name: string]: filterTypeI }) => {
    setProfileGridContextVal((prevState) => ({ ...prevState, filters }));
  };
  const updateProfileGridData = (profileGridData: any[]) => {
    setProfileGridContextVal((prevState) => ({
      ...prevState,
      profileGridData,
    }));
  };
  const setloading = (loading: boolean) => {
    setProfileGridContextVal((prevState) => ({ ...prevState, loading }));
  };
  const updateModal = (modal: {
    open: boolean;
    for: "delete" | "disable" | "view";
    id: string;
  }) => {
    setProfileGridContextVal((prevState) => ({ ...prevState, modal }));
  };
  const value = useMemo(
    () => ({
      ...profileGridContextVal,
      updateFilters,
      setloading,
      updateProfileGridData,
      updateModal,
    }),
    [profileGridContextVal]
  );
  return (
    <ProfileGridContext.Provider value={value}>
      {children}
    </ProfileGridContext.Provider>
  );
};

const useProfileGridContext = () => {
  return useContext(ProfileGridContext);
};
export { ProfileGridProvider, useProfileGridContext };

import React from "react";
import {
  BlockStack,
  Box,
  Checkbox,
  IndexTable,
  InlineStack,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import { LinkIcon } from "@shopify/polaris-icons";
import { isConfigChecked } from "Src/function";
import { useProductEditContext } from "../ProductEditProvider";
import useDebounce from "Src/hooks/useDebounce";
import { regexExpressions } from "Src/Constants";
import ThumbnailWithFallback from "Src/Component/HelperComponents/ThumbnailWithFallback";

interface PropsI {
  id: string;
  rowItem: any;
  index: number;
}
const VariantLinkRow = ({ rowItem, index }: PropsI) => {
  const { updateLocalVariants, localVariants, multilocationEnabled } =
    useProductEditContext();

  const {
    image,
    sku,
    price,
    is_taxable,
    title,
    requires_shipping,
    disable_variants,
    inventory,
  } = rowItem;

  const {
    debouncedValue: debounced_is_taxable,
    changeDebouncedValue: changeIsTaxable,
  } = useDebounce(
    (val) => updateLocalVariants(index, "is_taxable", val),
    800,
    is_taxable
  );

  const { debouncedValue: debounced_price, changeDebouncedValue: changePrice } =
    useDebounce(
      (val) => {
        updateLocalVariants(index, "price", val);
      },
      800,
      price
    );

  const {
    debouncedValue: debounced_disable_variants,
    changeDebouncedValue: changeDisableVariants,
  } = useDebounce(
    (val) => updateLocalVariants(index, "disable_variants", val),
    800,
    disable_variants
  );

  const {
    debouncedValue: debounced_inventory,
    changeDebouncedValue: changeInventory,
  } = useDebounce(
    (val) => {
      updateLocalVariants(index, "inventory", val);
    },
    800,
    inventory
  );

  return (
    <>
      <IndexTable.Cell className="image-cell">
        <div className="image-link" onClick={() => {}}>
          <ThumbnailWithFallback
            source={image ?? ""}
            size="small"
            alt="Black choker necklace"
          />
          <LinkIcon fill="#fff" width="20" />
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <BlockStack>
          <Text variant="bodyMd" fontWeight="medium" as="p">
            {sku}
          </Text>
          <Text tone="subdued" variant="bodyMd" fontWeight="medium" as="p">
            Physical product :
            {isConfigChecked(requires_shipping) ? "Yes" : "No"}
          </Text>
        </BlockStack>
      </IndexTable.Cell>
      <IndexTable.Cell>{title}</IndexTable.Cell>
      <IndexTable.Cell className="tax-cell">
        <InlineStack blockAlign="end" gap="200" wrap={false}>
          <Box width="126px">
            <Select
              label
              options={[
                { label: "Taxable", value: "1" },
                { label: "Non-Taxable", value: "0" },
              ]}
              value={debounced_is_taxable}
              onChange={(val) => {
                changeIsTaxable(val);
              }}
            />
          </Box>
          <TextField
            label
            labelHidden
            autoComplete=""
            value={debounced_price}
            onChange={(val) => {
              if (
                regexExpressions?.NUMBER_CHECK_WITH_DOT.test(val) ||
                val === ""
              ) {
                changePrice(val);
              }
            }}
          />
        </InlineStack>
      </IndexTable.Cell>
      {!multilocationEnabled && (
        <IndexTable.Cell>
          <TextField
            label
            labelHidden
            autoComplete=""
            value={debounced_inventory}
            onChange={(val) => {
              if (
                regexExpressions?.NUMBER_WITHOUT_DOT.test(val) ||
                val === ""
              ) {
                changeInventory(val);
              }
            }}
          />
        </IndexTable.Cell>
      )}
      <IndexTable.Cell>
        <InlineStack align="center">
          <div className="inte-custom__switcher">
            <Checkbox
              disabled={localVariants.length === 1}
              label
              checked={isConfigChecked(debounced_disable_variants)}
              onChange={() => {
                changeDisableVariants(
                  isConfigChecked(debounced_disable_variants) ? "0" : "1"
                );
              }}
            />
          </div>
        </InlineStack>
      </IndexTable.Cell>
    </>
  );
};

export default VariantLinkRow;

import { BlockStack, Card, Layout, Page } from '@shopify/polaris'
import React from 'react'
import FaqSkeleton from './components/FaqSkeleton'
import PricingSkeleton from './components/PricingSkeleton'

const PricingPlanSkeleton = () => {
  return (
    <div className="custom_layout_width">
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card padding="600">
              <BlockStack gap="800">
                <PricingSkeleton />
                <FaqSkeleton />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  )
}

export default PricingPlanSkeleton

import { BlockStack, Card, Divider, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const LimitCardSkeleton = () => {
    return (
        <Card>
            <BlockStack gap={"600"}>
                <BlockStack gap={"300"}>
                    <InlineStack gap={"200"} align='space-between'>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                    <SkeletonBodyText lines={2} />
                </BlockStack>
                <Card>
                    <BlockStack gap={"500"}>
                        <BlockStack gap={"400"}>
                            <InlineStack gap={"200"} align='space-between'>
                                <div style={{ "width": "50px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "50px" }} className='custom-height_skel'>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                            <BlockStack gap={"300"}>
                                <SkeletonBodyText lines={1} />
                                <div style={{ "width": "60px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </BlockStack>
                        </BlockStack>
                        <Divider />
                        <BlockStack gap={"400"}>
                            <InlineStack gap={"200"} blockAlign='center' align='space-between'>
                                <InlineStack gap={"200"} blockAlign='center'>
                                    <div style={{ "width": "20px" }} className='custom-height_skel'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ "width": "50px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineStack>
                                <div style={{ "width": "80px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>

                            <InlineStack gap={"200"} blockAlign='center' align='space-between'>
                                <InlineStack gap={"200"} blockAlign='center'>
                                    <div style={{ "width": "20px" }} className='custom-height_skel'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ "width": "50px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineStack>
                                <div style={{ "width": "80px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                        </BlockStack>
                        <SkeletonBodyText lines={1} />
                    </BlockStack>
                </Card>
            </BlockStack>
        </Card>
    )
}

export default LimitCardSkeleton

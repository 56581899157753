import React, { useEffect } from "react";
import { DI } from "../../core";
import { DIProps } from "../../Interface/@core";
import { useSearchParams } from "react-router-dom";

const EtsyRedirect = (_props: DIProps) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const messageObj = {
      from: "etsy_redirect",
      success: searchParams.get("success"),
      message: searchParams.get("message"),
    };
    // dispatching a message object for parent window
    window.opener.postMessage(messageObj, window.origin);
  }, []);

  return <></>;
};

export default DI(EtsyRedirect);

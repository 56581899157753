import { BlockStack, Card, InlineStack, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'

const AppExperienceSkeleton = () => {
    return (
        <Card>
            <BlockStack gap={"300"}>
                <div className='skeleton-height-114'>
                    <SkeletonBodyText lines={1} />
                </div>
                <div className='skeleton-height-11'>
                    <SkeletonBodyText lines={1} />
                </div>
                <SkeletonBodyText lines={2} />
                <InlineStack gap={"200"}>
                    <div style={{ "width": "60px" }}>
                        <SkeletonDisplayText />
                    </div>
                    <div style={{ "width": "60px" }}>
                        <SkeletonDisplayText />
                    </div>
                </InlineStack>
            </BlockStack>
        </Card>
    )
}

export default AppExperienceSkeleton

import React from "react";
import {
  BlockStack,
  Box,
  Card,
  InlineGrid,
  InlineStack,
  Select,
  SelectGroup,
  Text,
} from "@shopify/polaris";
import { useProfileContext } from "../ProfileProvider";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { useRoutesContext } from "Src/Component/RoutesProvider";
const AboutListing = ({ t }: DIProps) => {
  const { basicMapping, updateBasicMapping, error, removeError } =
    useProfileContext();
  const { routeData } = useRoutesContext();
  const whoMadeItOptions: SelectGroup[] = [
    {
      title: t("SELECT_A_MAKER"),
      options: [
        { label: t("I_DID"), value: "i_did" },
        { label: t("A_MEMBER_OF_MY_SHOP"), value: "collective" },
        { label: t("ANOTHER_COMPANY_OR_PERSON"), value: "someone_else" },
      ],
    },
  ];

  const whatIsItOptions: SelectGroup[] = [
    {
      title: t("SELECT_A_USE"),
      options: [
        { label: t("A_FINISHED_PRODUCT"), value: "0" },
        { label: t("A_SUPPLY_OR_TOOL"), value: "1" },
      ],
    },
  ];

  const whenMadeOptions: SelectGroup[] = [
    {
      title: t("NOT_YET_MADE"),
      options: [{ label: t("MADE_TO_ORDER"), value: "made_to_order" }],
    },
    {
      title: t("RECENTLY"),
      options: [
        { label: "2020-2024", value: "2020_2024" },
        { label: "2010-2019", value: "2010_2019" },
        { label: "2005-2009", value: "2005_2009" },
      ],
    },
    {
      title: t("VINTAGE"),
      options: [
        { label: `${t("BEFORE")}-2005`, value: "before_2005" },
        { label: "2000-2004", value: "2000_2004" },
        { label: "1990s", value: "1990s" },
        { label: "1980s", value: "1980s" },
        { label: "1970s", value: "1970s" },
        { label: "1960s", value: "1960s" },
        { label: "1950s", value: "1950s" },
        { label: "1940s", value: "1940s" },
        { label: "1930s", value: "1930s" },
        { label: "1920s", value: "1920s" },
        { label: "1910s", value: "1910s" },
        { label: "1800s", value: "1800s" },
        { label: "1700s", value: "1700s" },
        { label: `${t("BEFORE")}-1700`, value: "before_1700" },
      ],
    },
  ];

  return (
    <BlockStack gap={"200"}>
      <BlockStack gap={"100"}>
        <div className="custom_required">
          <Text as="h6" variant="bodyMd">
            {t("ABOUT_THE_LISTING")}
          </Text>
        </div>
        <Text as="p" variant="bodyMd" tone="subdued">
          {t("ABOUT_THE_LISTING_DESC")}
        </Text>
      </BlockStack>
      <Card>
        <BlockStack gap="200">
          <InlineStack gap="200" wrap={false}>
            <Box width="100%">
              <InlineGrid columns={{md:3}} gap="200">
                <Select
                  disabled={routeData.data.type === "view"}
                  label={t("WHO_MADE_IT")}
                  placeholder={t("SELECT")}
                  options={whoMadeItOptions}
                  onChange={(val) => {
                    removeError("whoMade");
                    updateBasicMapping("whoMade", val);
                  }}
                  value={basicMapping.whoMade}
                  error={error?.["whoMade"]}
                  id="whoMade"
                />
                <Select
                  disabled={routeData.data.type === "view"}
                  label={t("WHAT_IS_IT")}
                  placeholder={t("SELECT")}
                  options={whatIsItOptions}
                  onChange={(val) => {
                    removeError("isSupply");
                    updateBasicMapping("isSupply", val);
                  }}
                  value={basicMapping.isSupply}
                  error={error?.["isSupply"]}
                  id="isSupply"
                />
                <Select
                  disabled={routeData.data.type === "view"}
                  label={t("WHEN_MDID_YOU_MAKE_IT")}
                  placeholder={t("SELECT")}
                  options={whenMadeOptions}
                  onChange={(val) => {
                    removeError("whenMade");
                    updateBasicMapping("whenMade", val);
                  }}
                  value={basicMapping.whenMade}
                  error={error?.["whenMade"]}
                  id="whenMade"
                />
              </InlineGrid>
            </Box>
          </InlineStack>
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(AboutListing);

import { BlockStack, Card, InlineStack, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from '@shopify/polaris'
import React from 'react'

const TotalOrderSkeleton = () => {
    return (
        <Card>
            <BlockStack gap={"1000"}>
                <BlockStack gap={"400"}>
                    <SkeletonDisplayText size='small' />
                    <div style={{ "width": "100px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </BlockStack>
                <div className="dashboard_custom_chart">
                    <div className="chart_wrap">
                        <SkeletonThumbnail size="large" />
                    </div>
                    <div className="status_wrap">
                        <BlockStack gap={"400"}>
                            {
                                Array.from({ length: 4 }, (_,i) => <InlineStack key={i} align="space-between">
                                    <div style={{ "width": "40px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ "width": "40px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineStack>)
                            }
                        </BlockStack>
                    </div>
                </div>
            </BlockStack>
        </Card>
    )
}

export default TotalOrderSkeleton

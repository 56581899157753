import { Box, Card, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'
import React from 'react'
import FullReviewGridSkeleton from './FullReviewGridSkeleton'

const WithPageHeaderGridSkeleton = () => {
    const { mdDown } = useBreakpoints()
    return (
        <div className="review_page skeleton">
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className='skeleton_page_header_spacing'>
                    <Page fullWidth>

                        {mdDown ? (
                            <InlineStack align='space-between' blockAlign='center'>
                                <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                <InlineStack gap={"200"}>
                                    <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineStack>
                            </InlineStack>
                        ) : (
                            <InlineStack align='space-between' blockAlign='center'>
                                <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                <InlineStack gap={"200"}>
                                    <div style={{ "width": "80px" }}>
                                        <SkeletonDisplayText size='small' />
                                    </div>
                                    <div style={{ "width": "28px" }}>
                                        <SkeletonDisplayText size='small' />
                                    </div>
                                </InlineStack>
                            </InlineStack>
                        )}

                    </Page>
                </div>
            </Box>
            <Page fullWidth>
                <Card padding="0">
                    <FullReviewGridSkeleton />
                </Card>
            </Page>
        </div>
    )
}

export default WithPageHeaderGridSkeleton

import {
  BlockStack,
  Box,
  IndexTable,
  InlineStack,
  SkeletonBodyText,
  SkeletonDisplayText,
  useBreakpoints,
  useIndexResourceState,
} from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import { convertToStartCase } from "Src/function";
// import './skeleton.css'

export default function TemplateGridSkeleton(headings?: any) {
  const { mdDown } = useBreakpoints();

  const { t } = useTranslation();
  const gridSkeletonRow = {
    id: "1",
    templateName: (
      <div className="failed-order-skelton" style={{ width: "170px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    dispatchOrigin: (
      <div className="failed-order-skelton" style={{ width: "100px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    shippingCost: (
      <div className="failed-order-skelton" style={{ width: "90px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    processingTime: (
      <div className="failed-order-skelton" style={{ width: "100px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    profileType: (
      <div className="failed-order-skelton" style={{ width: "70px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),

    Action: headings.tabs !== 5 ?  <InlineStack gap={"200"} wrap={false}>
        <div style={{ width: "28px", height:"30px" }}>
          <SkeletonDisplayText size="small" />
        </div>
        <div style={{ width: "28px", height:"30px" }}>
          <SkeletonDisplayText size="small" />
        </div>
        {headings.tabs === 3 &&
          <div style={{ width: "28px", height:"30px" }}>
            <SkeletonDisplayText size="small" />
          </div>
        }
      </InlineStack> : <div className="failed-order-skelton" style={{ width: "170px" }}>
        <SkeletonBodyText lines={1} />
      </div>
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkupData = Array.from({ length: 10 }, () => gridSkeletonRow);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(rowMarkupData);

  const rowMarkup = rowMarkupData.map((item, index) => (
    <IndexTable.Row id={item.id} key={index} position={index}>
      {headings?.headings?.map((head: any, index: number) => {
        return (
          <IndexTable.Cell key={head.title} className="h-32">
            {index + 1 === headings.headings.length
              ? item.Action
              : item.templateName}
          </IndexTable.Cell>
        );
      })}
    </IndexTable.Row>
  ));

  const SkeletonList = ({ tabsLength }:any) => {

       const count = tabsLength === 0 ? 5 : (tabsLength % 2 === 0 ? 4 : 3);

    const skeletons = Array.from({ length: count }, (_, index) => ({
      width: `${270 - index * 20}px`,
    }));
  
    return (
      <BlockStack gap="200">
      {skeletons.map((skeleton, index) => (
        <div
          key={index}
          className={`failed-order-skelton ${index === 0 ? "skeleton-height-20" : "custom-height_skel"}`}
          style={{ width: skeleton.width }}
        >
          <SkeletonBodyText lines={1} />
        </div>
      ))}
    </BlockStack>
    );
  };
  

  const rowMarkupMobile = rowMarkupData.map((item, index) => (
    <Box padding='300' id={item.id} key={index} borderBlockEndWidth={'025'} borderColor="border">
      <InlineStack wrap={false} gap='200' align="space-between" blockAlign="start">
        <SkeletonList tabsLength={headings.tabs} />
       {headings.tabs !== 5 && <div style={{ 'width': '32px' }} className='skeleton-height-32'>
          <SkeletonBodyText lines={1} />
        </div>}
      </InlineStack>
    </Box>

  ));


  const initialRowMarkUp = rowMarkupData.map((item, index) => (
    <IndexTable.Row
    id={item.id}
    key={index}
    position={index}
>
    <IndexTable.Cell>
        {item.templateName}
    </IndexTable.Cell>
    <IndexTable.Cell>
        {item.dispatchOrigin}
    </IndexTable.Cell>
    <IndexTable.Cell>{item.shippingCost}</IndexTable.Cell>
    <IndexTable.Cell>{item.processingTime}</IndexTable.Cell>
    <IndexTable.Cell>{item.profileType}</IndexTable.Cell>
    <IndexTable.Cell>{item.Action}</IndexTable.Cell>
</IndexTable.Row>
  ));


  return (
    <>
      {mdDown ? rowMarkupMobile : <IndexTable
        selectable={false}
        resourceName={resourceName}
        itemCount={rowMarkupData.length}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        headings={
          headings.headings || [
            { title: t("TEMPLATE_NAME") },
            { title: t("DISPATCH_ORIGIN") },
            {
              title: t("SHIPPING_COST_WITH_SUFFIX", {
                currency: "$",
              }),
            },
            { title: convertToStartCase(t("PROCESSING_TIME")) },
            { title: convertToStartCase(t("PROFILE_TYPE")) },
            { title: t("ACTIONS") },
          ]
        }
      >
        {headings.headings ? rowMarkup : initialRowMarkUp }
      </IndexTable>}
    </>
  );
}

import { createContext, useContext, useMemo, useState } from "react";

interface RouteDataI {
  from: undefined | string;
  to: undefined | string;
  data: any;
}
interface InitialI {
  routeData: RouteDataI;
  setRouteData: (data: RouteDataI) => void;
}

const initialvalue: InitialI = {
  routeData: {
    from: undefined,
    to: undefined,
    data: {},
  },
  setRouteData: (data: RouteDataI) => {},
};

const RoutesContext = createContext(initialvalue);

const RoutesProvider = ({ children }: any) => {
  const [routesContextVal, setRoutesContextVal] = useState<InitialI>({
    routeData: {
      from: undefined,
      to: undefined,
      data: {},
    },
    setRouteData: () => {},
  });
  const setRouteData = (routeData: RouteDataI) => {
    setRoutesContextVal((prevState) => ({ ...prevState, routeData }));
  };
  const value = useMemo(
    () => ({ ...routesContextVal, setRouteData }),
    [routesContextVal]
  );
  return (
    <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>
  );
};

const useRoutesContext = () => {
  return useContext(RoutesContext);
};
export { RoutesProvider, useRoutesContext };

// src/encryptTransform.ts
import { Transform } from "redux-persist";
import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY as string;

if (!secretKey) {
  throw new Error(
    "Secret key is not defined. Please set the REACT_APP_SECRET_KEY environment variable."
  );
}

const encryptTransform: Transform<any, any> = {
  in: (inboundState) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      secretKey
    ).toString();
  },
  out: (outboundState) => {
    const bytes = CryptoJS.AES.decrypt(outboundState, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  },
};

export default encryptTransform;

import { BlockStack, InlineGrid, Select, Text, TextField, useBreakpoints } from "@shopify/polaris";
import React, { useMemo } from "react";
import { AttributesObjI, DIProps, SelectOptionI } from "Src/Interface/@core";
import CustomMultiSelect from "./CustomMultiSelect";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { isValidNumericVal } from "Src/function";
import { DI } from "Src/core";

interface PropsI extends DIProps {
  id?: string;
  attrObj: AttributesObjI;
  value: string | string[];
  onChange: (param: string | string[]) => void;
  scaleValue?: string;
  onScaleChange?: (param: string) => void;
  metaKeys?: string;
  onMetaKeysChange?: (param: string) => void;
  error?: string;
  disabled?: boolean;
  isMetafieldEnabled: boolean;
  metafields: string[];
}
const Attribute = ({
  id,
  attrObj,
  value,
  onChange,
  scaleValue,
  onScaleChange,
  metaKeys,
  onMetaKeysChange,
  isMetafieldEnabled,
  metafields,
  error,
  disabled,
  redux,
  t,
}: PropsI) => {
  const {mdUp,mdDown} = useBreakpoints();
  const { routeData } = useRoutesContext();
  const options: SelectOptionI[] = useMemo(
    () =>
      attrObj.possible_values?.map((value) => ({
        label: value.name,
        value: `{CedDQuote@#${value.value_id}CedDQuote@#:CedDQuote@#${value.name}CedDQuote@#}`,
      })) ?? [],
    [attrObj.possible_values]
  );

  const isNumericValue = useMemo(() => {
    const numericScaleArr = [
      "Centimeters",
      "Feet",
      "Inches",
      "Meters",
      "Millimeters",
      "Yards",
    ].sort();
    const scalesLabels = attrObj?.scales
      ?.map((scale) => scale?.display_name)
      .sort();
    return numericScaleArr.join(",") === scalesLabels?.join(",");
  }, [attrObj?.scales]);

  const metafieldOptions =
    metafields?.map((metafield) => ({
      label: metafield,
      value: metafield,
    })) ?? [];

  const inputMarkup = (
    <>
      <BlockStack gap="100">
        {attrObj.is_multivalued === "1" ? (
          <CustomMultiSelect
            id={id}
            placeholder={t("SELECT_OPTION")}
            helpText={
              attrObj?.max_values_allowed
                ? `${t("MAX_VALUE_ALLOWED_FOR")} ${attrObj?.display_name}: ${
                    attrObj?.max_values_allowed
                  }`
                : ""
            }
            options={options}
            onChange={onChange}
            value={value as string[]}
            noCustomValue
            validationObject={
              attrObj.max_values_allowed
                ? { maxLength: parseInt(attrObj?.max_values_allowed) }
                : undefined
            }
            hideExtraOptions
            error={error}
            disabled={disabled}
          />
        ) : attrObj.possible_values ? (
          <Select
            id={id}
            disabled={disabled || routeData.data.type === "view"}
            label=""
            placeholder={t("SELECT_OPTION")}
            labelHidden
            options={options}
            onChange={onChange}
            value={value as string}
            error={error}
          />
        ) : (
          <TextField
            id={id}
            disabled={disabled || routeData.data.type === "view"}
            label=""
            placeholder={t("ENTER_VALUE")}
            labelHidden
            onChange={(val) => {
              if (isNumericValue && !isValidNumericVal(val, true)) {
                return;
              }
              onChange(val);
            }}
            value={value as string}
            error={error}
            autoComplete="off"
          />
        )}
        {attrObj.scales ? (
          <Select
            id={`${id}_scale_value`}
            disabled={disabled || routeData.data.type === "view"}
            label=""
            labelHidden
            value={scaleValue}
            options={
              attrObj?.scales?.map((scale) => ({
                label: scale?.display_name,
                value: scale.scale_id?.toString(),
              })) ?? []
            }
            onChange={(val) => {
              onScaleChange?.(val);
            }}
          />
        ) : null}
      </BlockStack>
      {isMetafieldEnabled ? (
        <>
        { mdDown &&  <Text as="h6" variant="headingMd" fontWeight="semibold">
          {t("SHOPIFY")}
        </Text>}
        <Select
          id={`${id}_scale_value`}
          placeholder={t("SELECT")}
          disabled={disabled || routeData.data.type === "view"}
          label=""
          labelHidden
          value={metaKeys}
          options={metafieldOptions}
          onChange={(val) => {
            onMetaKeysChange?.(val);
          }}
        />
        </>
      ) : null}
    </>
  );
  return isMetafieldEnabled ? (
    <InlineGrid gap="100" columns={mdUp ? 2 : 1}>
      {inputMarkup}
    </InlineGrid>
  ) : (
    inputMarkup
  );
};

export default DI(Attribute);

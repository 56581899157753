import {
  Banner,
  BlockStack,
  Box,
  Card,
  Divider,
  Grid,
  IndexTable,
  InlineGrid,
  InlineStack,
  Layout,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { DI } from "Src/core";
import React from "react";

import { GetBadgeForStatus } from "../components/AllOrders";
import EtsyOrderViewSkeleton from "./Skeleton/EtsyOrderViewSkeleton";
import { getFormattedDateTime, isValidValue } from "Src/function";
import { ConvertTimestamp } from "Src/Component/Products/View/EtsyProductView";

const EtsyView = ({
  hasBeenLoading,
  orderData,
  location,
  t,
}: DIProps & { orderData: any; hasBeenLoading: boolean }) => {
  const { mdUp } = useBreakpoints();
  const { etsy_order_data } = orderData;

  const rowMarkup = etsy_order_data?.transactions?.map(
    (
      {
        receipt_id,
        title,
        shipping_cost,
        price,
        quantity,
        listing_id,
        sku,
      }: any,
      index: number
    ) => (
      <IndexTable.Row id={receipt_id} key={receipt_id} position={index}>
        <IndexTable.Cell>
          <BlockStack gap={"0"}>
            <Text variant="bodyMd" fontWeight="medium" as="span">
              {title}
            </Text>
            <Text variant="bodyMd" fontWeight="regular" as="span">
              {t("LISTING_ID")}: {listing_id}
            </Text>
            <Text variant="bodyMd" fontWeight="regular" as="span">
              {t("SKU")}: {sku || "-"}
            </Text>
          </BlockStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {shipping_cost.currency_code +
            " " +
            shipping_cost.amount / shipping_cost.divisor}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {price.currency_code + " " + price.amount / price.divisor} *{" "}
          {quantity}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {price.currency_code +
            " " +
            shipping_cost.amount / shipping_cost.divisor +
            (price.amount / price.divisor) * quantity}
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const rowMarkupMobile = etsy_order_data?.transactions?.map(
    (
      {
        receipt_id,
        title,
        shipping_cost,
        price,
        quantity,
        listing_id,
        sku,
      }: any,
      index: number
    ) => (
      <IndexTable.Row id={receipt_id} key={receipt_id} position={index}>
        <Box padding="300">
          <BlockStack gap="200">
            <BlockStack gap={"0"}>
              <Text variant="bodyMd" fontWeight="medium" as="span">
                {title}
              </Text>
              <Text variant="bodyMd" fontWeight="regular" as="span">
                {t("LISTING_ID")}: {listing_id}
              </Text>
              <Text variant="bodyMd" fontWeight="regular" as="span">
                {t("SKU")}: {sku || "-"}
              </Text>
            </BlockStack>

            <Divider />

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {" "}
                {t("SHIPPING_COST")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p" tone="subdued">
                {shipping_cost.currency_code +
                  " " +
                  shipping_cost.amount / shipping_cost.divisor}
              </Text>
            </InlineGrid>

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {" "}
                {t("PRODUCT_COST")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p" tone="subdued">
                {price.currency_code + " " + price.amount / price.divisor} *{" "}
                {quantity}
              </Text>
            </InlineGrid>

            <Divider />

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" fontWeight="medium">
                {" "}
                {t("TOTAL_COST")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p">
                {price.currency_code +
                  " " +
                  shipping_cost.amount / shipping_cost.divisor +
                  (price.amount / price.divisor) * quantity}
              </Text>
            </InlineGrid>
          </BlockStack>
        </Box>
      </IndexTable.Row>
    )
  );

  const getAmountToShow = (amtObj: {
    amount: number;
    divisor: number;
    currency_code: string;
  }) => {
    return isValidValue(amtObj?.amount) && isValidValue(amtObj?.divisor)
      ? `${amtObj?.currency_code} ${amtObj?.amount / amtObj?.divisor}`
      : "--";
  };

  const totalItems = etsy_order_data?.transactions?.length;
  const status = etsy_order_data?.status;

  const rowMarkupReturn = etsy_order_data?.refunds?.map(
    (
      { amount, created_timestamp, reason, note_from_issuer, status }: any,
      index: number
    ) => (
      <IndexTable.Row
        id={created_timestamp}
        key={created_timestamp}
        position={index}
      >
        <IndexTable.Cell>
          <BlockStack gap={"0"}>
            <Text variant="bodyMd" fontWeight="medium" as="span">
              {reason ?? "--"}
            </Text>
          </BlockStack>
        </IndexTable.Cell>
        <IndexTable.Cell>{note_from_issuer ?? "--"}</IndexTable.Cell>
        <IndexTable.Cell>{status ?? "--"}</IndexTable.Cell>
        <IndexTable.Cell>
          {amount?.amount && amount?.divisor
            ? parseInt(amount?.amount) / parseInt(amount?.divisor)
            : "--"}
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const rowMarkupReturnMobile = etsy_order_data?.refunds?.map(
    (
      { amount, created_timestamp, reason, note_from_issuer, status }: any,
      index: number
    ) => (
      <IndexTable.Row
        id={created_timestamp}
        key={created_timestamp}
        position={index}
      >
        <Box padding="300">
          <BlockStack gap="100">
            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {" "}
                {t("REASON")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p">
                {reason ?? "--"}
              </Text>
            </InlineGrid>

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {t("NOTE_FROM_ISSUER")}:{" "}
              </Text>
              {note_from_issuer ?? "--"}
            </InlineGrid>

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {t("STATUS")}:{" "}
              </Text>
              {status ?? "--"}
            </InlineGrid>
            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {t("AMOUNT")}:{" "}
              </Text>
              {amount ?? "--"}
            </InlineGrid>
          </BlockStack>
        </Box>
      </IndexTable.Row>
    )
  );

  if (isValidValue(etsy_order_data?.exception?.last_response)) {
    return (
      <Banner tone="critical">
        <Text as="p">
          {t("ERROR_FROM_ETSY")} :{" "}
          {etsy_order_data?.exception?.last_response ?? t("DETAILS_NOT_FOUND")}
        </Text>
      </Banner>
    );
  }

  if (!hasBeenLoading) {
    return <EtsyOrderViewSkeleton />;
  }
  return (
    <div className={`${mdUp ? "" : "mobile_grid_no_bulk_actions"}`}>
      <Layout>
        <Layout.Section>
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                <InlineStack gap={"200"} blockAlign="center">
                  <Text as="strong" variant="bodySm" fontWeight="medium">
                    {location?.state?.receipt_id}
                  </Text>
                  {GetBadgeForStatus(status, t)}
                </InlineStack>
                <Divider />
                <BlockStack gap={"200"}>
                  <Text as="strong" variant="headingSm" fontWeight="bold">
                    {t("ORDERED_PRODUCT")} (
                    {etsy_order_data?.transactions?.length})
                  </Text>
                  <Card padding={"0"} roundedAbove="xs">
                    <IndexTable
                      selectable={false}
                      // condensed={useBreakpoints().smDown}
                      // resourceName={resourceName}
                      itemCount={2}
                      headings={[
                        { title: t("TITLE") },
                        { title: t("SHIPPING_COST") },
                        { title: t("PRODUCT_COST") },
                        { title: t("TOTAL_COST") },
                      ]}
                    >
                      {mdUp ? rowMarkup : rowMarkupMobile}
                    </IndexTable>
                  </Card>
                </BlockStack>
              </BlockStack>
            </Card>
            {etsy_order_data?.refunds?.length > 0 ? (
              <Card>
                <BlockStack gap={mdUp ? "400" : "300"}>
                  <Text as="strong" variant="headingSm" fontWeight="bold">
                    Refund
                  </Text>
                  <Card padding={"0"} roundedAbove="xs">
                    <IndexTable
                      selectable={false}
                      itemCount={2}
                      headings={[
                        { title: t("REASON") },
                        { title: t("NOTE_FROM_ISSUER") },
                        { title: t("STATUS") },
                        { title: t("AMOUNT") },
                      ]}
                    >
                      {mdUp ? rowMarkupReturn : rowMarkupReturnMobile}
                    </IndexTable>
                  </Card>
                </BlockStack>
              </Card>
            ) : null}
            <Card>
              <BlockStack gap={mdUp ? "400" : "300"}>
                <Text as="strong" variant="headingSm" fontWeight="bold">
                  {t("ORDER_TOTAL")}
                </Text>
                {mdUp ? (
                  <BlockStack gap={"200"}>
                    <InlineStack align="space-between" blockAlign="center">
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("TOTAL_ITEMS")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {t("OTY")} {totalItems}
                      </Text>
                    </InlineStack>
                    <InlineStack align="space-between" blockAlign="center">
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("TOTAL_AMOUNT")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {getAmountToShow(etsy_order_data?.total_price)}
                      </Text>
                    </InlineStack>
                    <InlineStack align="space-between" blockAlign="center">
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("TOTAL_TAX")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {getAmountToShow(etsy_order_data?.total_tax_cost)}
                      </Text>
                    </InlineStack>

                    <InlineStack align="space-between" blockAlign="center">
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("TOTAL_SHIPPING_COST")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {getAmountToShow(etsy_order_data?.total_shipping_cost)}
                      </Text>
                    </InlineStack>
                    <InlineStack align="space-between" blockAlign="center">
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("TOTAL_VAT_COST")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {getAmountToShow(etsy_order_data?.total_vat_cost)}
                      </Text>
                    </InlineStack>
                    <InlineStack align="space-between" blockAlign="center">
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("DISCOUNT")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {getAmountToShow(etsy_order_data?.discount_amt)}
                      </Text>
                    </InlineStack>
                    <Divider />
                    <InlineStack align="space-between" blockAlign="center">
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("TOTAL")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {getAmountToShow(etsy_order_data?.grandtotal)}
                      </Text>
                    </InlineStack>
                  </BlockStack>
                ) : (
                  <>
                    <Divider />
                    <BlockStack gap={"100"}>
                      <InlineGrid columns={2}>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("TOTAL_ITEMS")}:
                        </Text>
                        <Text as="span" variant="bodyMd" fontWeight="regular">
                          {t("OTY")} {totalItems}
                        </Text>
                      </InlineGrid>
                      <InlineGrid columns={2}>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("TOTAL_AMOUNT")}:
                        </Text>
                        <Text as="span" variant="bodyMd" fontWeight="regular">
                          {getAmountToShow(etsy_order_data?.total_price)}
                        </Text>
                      </InlineGrid>
                      <InlineGrid columns={2}>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("TOTAL_TAX")}:
                        </Text>
                        <Text as="span" variant="bodyMd" fontWeight="regular">
                          {getAmountToShow(etsy_order_data?.total_tax_cost)}
                        </Text>
                      </InlineGrid>

                      <InlineGrid columns={2}>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("TOTAL_SHIPPING_COST")}:
                        </Text>
                        <Text as="span" variant="bodyMd" fontWeight="regular">
                          {getAmountToShow(
                            etsy_order_data?.total_shipping_cost
                          )}
                        </Text>
                      </InlineGrid>
                      <InlineGrid columns={2}>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("TOTAL_VAT_COST")}:
                        </Text>
                        <Text as="span" variant="bodyMd" fontWeight="regular">
                          {getAmountToShow(etsy_order_data?.total_vat_cost)}
                        </Text>
                      </InlineGrid>
                      <InlineGrid columns={2}>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("DISCOUNT")}:
                        </Text>
                        <Text as="span" variant="bodyMd" fontWeight="regular">
                          {getAmountToShow(etsy_order_data?.discount_amt)}
                        </Text>
                      </InlineGrid>
                    </BlockStack>
                    <Divider />
                    <InlineGrid columns={2}>
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {t("TOTAL")}:
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {getAmountToShow(etsy_order_data?.grandtotal)}
                      </Text>
                    </InlineGrid>
                  </>
                )}
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={mdUp ? "400" : "300"}>
                <Text as="strong" variant="headingSm" fontWeight="bold">
                  {t("ORDER_INFO")}
                </Text>
                {!mdUp && <Divider />}
                <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                  <Text as="strong" variant="headingSm" fontWeight="medium">
                    {t("ORDER_DATE")}:
                  </Text>
                  <Text as="span" variant="bodyMd" fontWeight="regular">
                    {getFormattedDateTime(
                      ConvertTimestamp(etsy_order_data?.create_timestamp),
                      true
                    )}
                  </Text>
                </Grid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={mdUp ? "400" : "300"}>
                <Text as="strong" variant="headingSm" fontWeight="bold">
                  {t("COUSTOMER_INFO")}
                </Text>
                {!mdUp && <Divider />}
                <BlockStack gap={"200"}>
                  <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                    <Text as="strong" variant="headingSm" fontWeight="medium">
                      {t("CUSTOMER_NAME")}:
                    </Text>
                    <Text as="span" variant="bodyMd" fontWeight="regular">
                      {etsy_order_data?.name}
                    </Text>
                  </Grid>
                  <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                    <Text as="strong" variant="headingSm" fontWeight="medium">
                      {t("EMAIL")}:
                    </Text>
                    <Text as="span" variant="bodyMd" fontWeight="regular">
                      {etsy_order_data?.buyer_email}
                    </Text>
                  </Grid>
                  <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                    <Text as="strong" variant="headingSm" fontWeight="medium">
                      {t("ADDRESS")}:
                    </Text>
                    <Text as="span" variant="bodyMd" fontWeight="regular">
                      {etsy_order_data?.city}
                    </Text>
                  </Grid>
                  <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                    <Text as="strong" variant="headingSm" fontWeight="medium">
                      {t("GIFT_ORDER")}:
                    </Text>
                    <Text as="span" variant="bodyMd" fontWeight="regular">
                      {etsy_order_data?.is_gift ? "Yes" : "No"}
                    </Text>
                  </Grid>
                </BlockStack>
              </BlockStack>
            </Card>
            {etsy_order_data?.shipments?.length > 0 ? (
              <Card>
                <BlockStack gap={mdUp ? "400" : "300"}>
                  <Text as="strong" variant="headingSm" fontWeight="bold">
                    {t("TRACKING_DETAILS")}
                  </Text>
                  {!mdUp && <Divider />}
                  {etsy_order_data?.shipments?.map(
                    (shipment: ObjStrI, index: number) => (
                      <>
                        <BlockStack gap={"200"}>
                          <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                            <Text
                              as="strong"
                              variant="headingSm"
                              fontWeight="medium"
                            >
                              {t("CARRIER_NAME")}:
                            </Text>
                            <Text
                              as="span"
                              variant="bodyMd"
                              fontWeight="regular"
                            >
                              {shipment?.carrier_name ?? "N/A"}
                            </Text>
                          </Grid>
                          <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                            <Text
                              as="strong"
                              variant="headingSm"
                              fontWeight="medium"
                            >
                              {t("TRACKING_NUMBER")}:
                            </Text>
                            <Text
                              as="span"
                              variant="bodyMd"
                              fontWeight="regular"
                            >
                              {shipment?.tracking_code ?? "N/A"}
                            </Text>
                          </Grid>
                        </BlockStack>
                        {index + 1 < etsy_order_data?.shipments?.length ? (
                          <Divider />
                        ) : null}
                      </>
                    )
                  )}
                </BlockStack>
              </Card>
            ) : null}
            {etsy_order_data.message_from_buyer &&
              etsy_order_data.message_from_buyer.trim() !== "" && (
                <Card>
                  <BlockStack gap={"400"}>
                    <Text as="strong" variant="headingSm" fontWeight="bold">
                      {t("BUYYER_MESSGAGE")}
                    </Text>
                    <BlockStack gap={"200"}>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {etsy_order_data.message_from_buyer}
                      </Text>
                    </BlockStack>
                  </BlockStack>
                </Card>
              )}
          </BlockStack>
        </Layout.Section>
      </Layout>
    </div>
  );
};

export default DI(EtsyView);

import { Box, InlineStack } from "@shopify/polaris";
import { Trans } from "react-i18next";

export const TableData: any = [
  {
    heading: [
      { title:  <InlineStack gap='200'><Box minWidth="35px"></Box>
      <Trans i18nKey={'PROFILE_NAME'}/></InlineStack> },
      { title: <Trans i18nKey={'CREATED_ON'}/> },
      { title: <Trans i18nKey={'STATUS'}/> },
      { title: <Trans i18nKey={'PRODUCT_ASSIGNED'}/> },
      { title: <Trans i18nKey={'PROFILE_TYPE'}/> },
      { title: <Trans i18nKey={'ACTIONS'}/> },
    ],
    rowData: [
      {
        id: "1020",
        profile_name: "Profile_name_1",
        created_on: "21st Feb, 2023 03:00:39 PM IST",
        status: "Complete",
        product_assigned: 20,
        profile_type: "Non-digital",
      },
      {
        id: "1021",
        profile_name: "Profile_name_2",
        created_on: "21st Feb, 2023 03:00:39 PM IST",
        status: "Complete",
        product_assigned: 44,
        profile_type: "Non-digital",
      },
      {
        id: "1022",
        profile_name: "Profile_name_3",
        created_on: "21st Feb, 2023 03:00:39 PM IST",
        status: "Incomplete",
        product_assigned: 90,
        profile_type: "Non-digital",
      },
    ],
  }
];

import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { removeInvalidKeys } from "Src/function";
import { ObjStrI, PaginationI, SelectOptionI } from "Src/Interface/@core";

interface InportExportModalI {
  isOpen: boolean;
  type: "export" | "import" | "";
}
interface EtsyActionModalI {
  isOpen: boolean;
  type: "delete-selected" | "inactive-selected" | "";
  ids: string[];
}
interface ActionModalI {
  isOpen: boolean;
  type?:
    | "profile_unassign"
    | "publish-product"
    | "price-inventory"
    | "image-update"
    | "product-active"
    | "product-inactive"
    | "product-deactivate"
    | "profile_assignment"
    | "unlink-listing"
    | "delete-product-on-app-and-mp"
    | "product-delete"
    | "delete-product-on-app"
    | "";
  product_ids?: string[];
}

interface HelperModalI {
  isOpen: boolean;
  type: "error" | "variant" | "assign-profile" | "";
  index: number;
  product_name?: string;
}
interface LinkModal {
  isOpen: boolean;
  data?: any;
}
interface InitialStateI {
  selectedTabIndex: number;
  products: any[];
  loading: boolean;
  visibleColumns: string[];
  filters: ObjStrI;
  importExportModal: InportExportModalI;
  actionModal: ActionModalI;
  profileOptions: SelectOptionI[] | null;
  linkcreateModal: LinkModal;
  etsyActionModal: EtsyActionModalI;
}

interface viewProductModalI {
  isOpen: boolean;
  id: string;
  listingId: string;
  tab: number;
}
interface ProductsProviderI extends InitialStateI {
  saveProducts: (products: any[]) => void;
  changeTab: (tabIndex: number) => void;
  toggleLoading: () => void;
  gridHelperModalState: HelperModalI;
  viewModalState: viewProductModalI;
  pagination: PaginationI;
  setPagination: Dispatch<SetStateAction<PaginationI>>;
  updateFilters: (param: ObjStrI, removeAll?: boolean) => void;
  updateInportExportModal: (e: InportExportModalI) => void;
  updateActionModal: (e: ActionModalI) => void;
  updateGridHelperModalState: (param: HelperModalI) => void;
  saveProfileOptions: (param: SelectOptionI[]) => void;
  setLinkcreateModal: (e: LinkModal) => void;
  toggleViewModal: (e: viewProductModalI) => void;
  updateEtsyActionModal: (e: EtsyActionModalI) => void;
}

const initialvalue: ProductsProviderI = {
  selectedTabIndex: 0,
  products: [],
  loading: false,
  visibleColumns: [],
  filters: {},
  gridHelperModalState: { isOpen: false, index: -1, type: "" },
  importExportModal: { isOpen: false, type: "" },
  actionModal: { isOpen: false, type: "", product_ids: [] },
  profileOptions: [],
  linkcreateModal: { isOpen: false },
  viewModalState: { isOpen: false, id: "", listingId: "", tab: 0 },
  etsyActionModal: { isOpen: false, ids: [], type: "" },
  pagination: {
    perPage: 25,
    page: 1,
    totalCount: 0,
  },
  setPagination: () => {},
  setLinkcreateModal: (e: LinkModal) => {},
  updateInportExportModal: (e: InportExportModalI) => {},
  updateActionModal: (e: ActionModalI) => {},
  updateGridHelperModalState: (param: HelperModalI) => {},
  saveProducts: () => {},
  changeTab: () => {},
  toggleLoading: () => {},
  updateFilters: (param: ObjStrI, removeAll?: boolean) => {},
  saveProfileOptions: () => {},
  toggleViewModal: (e: viewProductModalI) => {},
  updateEtsyActionModal: (e: EtsyActionModalI) => {},
};

const ProductsContext = createContext(initialvalue);

const ProductsProvider = ({ children }: any) => {
  const [state, setState] = useState<InitialStateI>({
    selectedTabIndex: 0,
    importExportModal: { isOpen: false, type: "" },
    actionModal: { isOpen: false, type: "", product_ids: [] },
    products: [],
    loading: false,
    visibleColumns: [],
    filters: { sort: "title" },
    profileOptions: null,
    linkcreateModal: { isOpen: false },
    etsyActionModal: { isOpen: false, ids: [], type: "" },
  });
  const [pagination, setPagination] = useState({
    perPage: 25,
    page: 1,
    totalCount: 0,
  });
  const [gridHelperModalState, setGridHelperModalState] =
    useState<HelperModalI>({
      isOpen: false,
      index: -1,
      type: "",
    });
  const [viewModalState, setViewModalState] = useState({
    isOpen: false,
    id: "",
    listingId: "",
    tab: 0,
  });

  const changeTab = (tabIndex: number) => {
    setState((prev) => ({ ...prev, selectedTabIndex: tabIndex }));
  };

  const saveProducts = (products: any[]) => {
    setState((prev) => ({ ...prev, products }));
  };

  const toggleLoading = () => {
    setState((prev) => ({ ...prev, loading: !prev.loading }));
  };

  const updateGridHelperModalState = (modalState: HelperModalI) => {
    setGridHelperModalState({ ...modalState });
  };

  const updateFilters = (filtersObj: ObjStrI, removeAll?: boolean) => {
    if (removeAll) {
      setState((prev) => ({ ...prev, filters: removeInvalidKeys(filtersObj) }));
    } else {
      const validFilters = removeInvalidKeys({
        ...state.filters,
        ...filtersObj,
      });
      setState((prev) => ({
        ...prev,
        filters: { ...validFilters },
      }));
    }
  };

  const updateInportExportModal = (importExportModal: InportExportModalI) => {
    setState((prev) => ({
      ...prev,
      importExportModal,
    }));
  };
  const updateActionModal = (actionModal: ActionModalI) => {
    setState((prev) => ({
      ...prev,
      actionModal,
    }));
  };
  const saveProfileOptions = (profileOptions: SelectOptionI[]) => {
    setState((prev) => ({ ...prev, profileOptions }));
  };
  const setLinkcreateModal = (linkcreateModal: LinkModal) => {
    setState((prev) => ({ ...prev, linkcreateModal }));
  };
  const updateEtsyActionModal = (etsyActionModal: EtsyActionModalI) => {
    setState((prev) => ({ ...prev, etsyActionModal }));
  };

  const toggleViewModal = ({
    isOpen,
    id,
    listingId,
    tab,
  }: viewProductModalI) => {
    setViewModalState((prev) => ({
      ...prev,
      isOpen: isOpen ?? !prev.isOpen,
      id: id ?? "",
      listingId: listingId ?? "",
      tab: tab ?? 0,
    }));
  };

  const value = useMemo(
    () => ({
      ...state,
      pagination,
      setPagination,
      gridHelperModalState,
      viewModalState,
      changeTab,
      saveProducts,
      toggleLoading,
      updateFilters,
      updateInportExportModal,
      updateActionModal,
      updateGridHelperModalState,
      saveProfileOptions,
      setLinkcreateModal,
      toggleViewModal,
      updateEtsyActionModal
    }),
    [state, gridHelperModalState, viewModalState]
  );

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};

const useProductsContext = () => useContext(ProductsContext);
export { ProductsProvider, useProductsContext };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ObjI, UserInitialStateI } from "Src/Interface/@core";

const initialState: UserInitialStateI = {
  isOnboarded: false,
  etsyShop: undefined,
  shopifyShop: undefined,
};

const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    saveUserDetails: (
      state,
      action: PayloadAction<{ isOnboarded: boolean }>
    ) => {
      state.isOnboarded = action.payload.isOnboarded;
    },
    saveEtsyShopDetails: (state, action: PayloadAction<{ shop: ObjI }>) => {
      state.etsyShop = action.payload.shop;
    },
    saveShopifyShopDetails: (state, action: PayloadAction<{ shop: ObjI }>) => {
      state.shopifyShop = action.payload.shop;
    },
  },
});

export const { saveUserDetails, saveEtsyShopDetails, saveShopifyShopDetails } =
  userSlice.actions;

export default userSlice.reducer;

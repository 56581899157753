import React, { useState } from "react";
import { Badge, Button, Card, Icon, IndexTable, Text } from "@shopify/polaris";
import { ViewIcon } from "@shopify/polaris-icons";
import PaymentHistoryModal from "./PaymentHistoryModal";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { getStatusBadge } from "Src/Component/HelperComponents/GlobalFunctions";
import { getFormattedDateTime } from "Src/function";
import { t } from "i18next";

const PaymentHistory = (_props: DIProps) => {
  const {
    redux: {
      settings: {
        accountDetails: { paymentHistory },
      },
    },
  } = _props;
  const payments = Object.values(paymentHistory);

  const [viewPayment, setViewPayment] = useState({
    open: false,
    payment_id: "",
  });

  const rowMarkup = payments.map(
    ({ id, plan_name, status, price, created_at, currency }, index) => {
      const { tone, updatedStatus } = getStatusBadge(status);
      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="medium" as="span">
              {plan_name}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge tone={tone}>{updatedStatus}</Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {price} {currency}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {getFormattedDateTime(created_at, true)}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Button
              onClick={() =>
                setViewPayment((prev) => ({
                  ...prev,
                  open: !prev.open,
                  payment_id: id,
                }))
              }
              icon={<Icon source={ViewIcon} tone="base" />}
            />
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  return (
    <Card padding="0" roundedAbove="xs">
      <IndexTable
        selectable={false}
        itemCount={payments.length}
        headings={[
          { title: t("NAME") },
          { title: t("STATUS") },
          { title: t("PRICE") },
          { title: t("CREATED_ON") },
          { title: t("ACTION") },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      {viewPayment.open && (
        <PaymentHistoryModal
          viewPayment={viewPayment}
          setViewPayment={setViewPayment}
        />
      )}
    </Card>
  );
};

export default DI(PaymentHistory);

import { BlockStack, Card, InlineStack, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'

const RevenueChartSkeleton = () => {
    return (
        <Card>
            <BlockStack gap={"600"}>
                <InlineStack gap={"200"} blockAlign="center" align="space-between">
                    <BlockStack gap={"400"}>
                        <SkeletonDisplayText size='small' />
                        <div style={{ "width": "100px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </BlockStack>
                    <div style={{ "width": "40px" }}>
                        <SkeletonDisplayText size='medium' />
                    </div>
                </InlineStack>
                <SkeletonBodyText lines={4} />
            </BlockStack>
        </Card>
    )
}

export default RevenueChartSkeleton

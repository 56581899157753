/**
 * Smoothly scrolls to a specific section of the page by its id.
 *
 * @param {string} id - The id of the section to scroll to.
 */
export const scrollToSection = (id: string, topOffset: number = 0) => {
  const section = document.getElementById(id);

  if (section) {
    // Get the element's position relative to the viewport and page
    const elementRect = section.getBoundingClientRect();
    const elementPosition = elementRect.top + window.scrollY;

    // Scroll the element to a position relative to the viewport
    window.scrollTo({
      top: elementPosition - topOffset,
      behavior: "smooth",
    });
  } else {
    console.warn(`Element with id "${id}" not found.`);
  }
};

import React from "react";
import {
  AutoSelection,
  Combobox,
  InlineStack,
  Listbox,
  Tag,
} from "@shopify/polaris";
import { useState } from "react";
import { DIProps, SelectOptionI } from "Src/Interface/@core";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { DI } from "Src/core";

interface PropsI extends DIProps {
  options: SelectOptionI[];
  value: string[];
  onChange: (selected: string[]) => void;
  error: string;
}

const CustomMultiSelect = ({ options, value, onChange, error, t }: PropsI) => {
  const [input, setInput] = useState("");
  const tagsSelected: string[] = value ?? [];
  const { routeData } = useRoutesContext();
  const updateTags = (selected: string) => {
    const nextSelectedTags = new Set([...tagsSelected]);
    let tempSelected = selected;
    if (options.length > 0) {
      tempSelected =
        options.find((option) => option.value === selected)?.label ?? "";
    }
    if (options.length > 0 && tagsSelected.includes(tempSelected)) {
      nextSelectedTags.delete(tempSelected);
    } else {
      nextSelectedTags.add(tempSelected);
    }
    onChange(Array.from(nextSelectedTags));
  };

  const removeTag = (tagToBeDeleted: string) => {
    onChange(tagsSelected.filter((tag) => tag !== tagToBeDeleted));
  };

  const filteredOptions = options.filter((opt) =>
    opt.value.toLowerCase().includes(input.toLowerCase())
  );

  const tagsOptionMarkup =
    filteredOptions.length > 0 ? (
      <React.Fragment>
        {filteredOptions.map((option) => {
          return (
            <Listbox.Option
              key={option.value}
              value={option.value}
              selected={tagsSelected.includes(option.label)}
              accessibilityLabel={option.label}
            >
              <Listbox.TextOption
                selected={tagsSelected.includes(option.label)}
              >
                {option.label}
              </Listbox.TextOption>
            </Listbox.Option>
          );
        })}
      </React.Fragment>
    ) : (
      <Listbox.Header>{t("NO_RESULT_FOUND")}</Listbox.Header>
    );

  return (
    <Combobox
      allowMultiple
      activator={
        <Combobox.TextField
          autoFocus
          disabled={routeData.data.type === "view"}
          autoComplete="off"
          label={t("SEARCH_TAGS")}
          labelHidden
          value={input}
          error={error}
          verticalContent={
            tagsSelected.length > 0 ? (
              <InlineStack gap="100">
                {tagsSelected.map((tag) => (
                  <Tag
                    key={`option-${tag}`}
                    onRemove={() => removeTag(tag)}
                    disabled={routeData.data.type === "view"}
                  >
                    {tag}
                  </Tag>
                ))}
              </InlineStack>
            ) : null
          }
          onChange={(tag) => {
            setInput(tag);
          }}
        />
      }
    >
      <Listbox
        autoSelection={AutoSelection.None}
        onSelect={(tag) => {
          updateTags(tag);
        }}
      >
        {tagsOptionMarkup}
      </Listbox>
    </Combobox>
  );
};

export default DI(CustomMultiSelect);

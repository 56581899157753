import React from "react";
import {
  Bleed,
  BlockStack,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  ProgressBar,
  Text,
} from "@shopify/polaris";
import PaymentHistory from "./AccountDetailsComponents/PaymentHistory";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { getFormattedDateTime } from "Src/function";
import { useShopify } from "Src/ShopifyAppBridgeProvider";
import { t } from "i18next";

const AccountDetails = (_props: DIProps) => {
  const {
    redux: {
      dashboard: { accountInfo },
    },
    navigate,
    AppBridgeExternalRoute,
  } = _props;

  const product_progress =
    (accountInfo.productsUsed /
      parseInt(accountInfo?.clientInfo?.product_count ?? "1")) *
    100;
  const order_progress =
    (accountInfo.ordersUsed / accountInfo.orderLimit) * 100;
  const { app } = useShopify();

  return (
    <Card>
      <BlockStack gap="400">
        <BlockStack gap="200">
          <Text as="h2" variant="headingSm">
            {t("SUBSCRIPTION")}
          </Text>
          <Text as="p" variant="bodyMd">
            {t("REVIEW_AND_MANGE_YOUR_SUBSCRIPTION_FEATURES")}
          </Text>
        </BlockStack>
        <Bleed marginInlineStart="400" marginInlineEnd="400">
          <Divider />
        </Bleed>

        <BlockStack gap="300">
          <InlineStack align="space-between">
            <Text variant="headingSm" as="p">
              {t("PLAN_DETAILS")}
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                AppBridgeExternalRoute(app, "/panel/pricing");
                navigate("/panel/pricing");
              }}
            >
              {t("UPGRADE_PLAN")}
            </Button>
          </InlineStack>

          <InlineGrid
            columns={{ xs: 2, sm: 3, md: 4 }}
            gap="600"
            alignItems="center"
          >
            <BlockStack gap="100">
              <Text variant="bodySm" as="p" tone="subdued">
                {t("PRICING_PLAN")}
              </Text>
              <Text variant="bodyLg" as="p" fontWeight="semibold">
                {accountInfo.activePlan}
              </Text>
            </BlockStack>

            <BlockStack gap="100">
              <Text variant="bodySm" as="p" tone="subdued">
                {t("COST")}
              </Text>
              <Text variant="bodyLg" as="p" fontWeight="semibold">
                {accountInfo.planAmt}/{accountInfo.duration.charAt(0)}
              </Text>
            </BlockStack>
            <BlockStack gap="100">
              <Text variant="bodySm" as="p" tone="subdued">
                {t("PURCHASE_DATE")}
              </Text>
              <Text variant="bodyLg" as="p" fontWeight="semibold">
                {getFormattedDateTime(accountInfo.activated_on)}
                {/* {accountInfo.activated_on
                  ? moment(accountInfo.activated_on, "YYYY-MM-DD").format(
                      "Do MMMM YYYY"
                    )
                  : ""} */}
              </Text>
            </BlockStack>
            <BlockStack gap="100">
              <Text variant="bodySm" as="p" tone="subdued">
                {t("NEW_BILLING_CYCLE")}
              </Text>
              <Text variant="bodyLg" as="p" fontWeight="semibold">
                {getFormattedDateTime(accountInfo.billingDate)}
                {/* {accountInfo.billingDate
                  ? moment(accountInfo.billingDate, "YYYY-MM-DD").format(
                      "Do MMMM YYYY"
                    )
                  : ""} */}
              </Text>
            </BlockStack>
          </InlineGrid>
        </BlockStack>

        <InlineGrid columns={{ xs: 1, sm: 2 }} gap="300">
          <Card roundedAbove="xs">
            <BlockStack gap="400">
              <InlineStack align="space-between">
                <Text variant="headingMd" as="p">
                  {t("PRODUCT_LIMIT")}
                </Text>
                <Text variant="bodyLg" as="p">
                  {accountInfo.productsUsed}/
                  {accountInfo?.clientInfo?.product_count} {t("PRODUCTS")}
                </Text>
              </InlineStack>
              <ProgressBar
                progress={product_progress}
                size="small"
                tone={product_progress > 90 ? "critical" : "primary"}
              />
            </BlockStack>
          </Card>
          <Card roundedAbove="xs">
            <BlockStack gap="400">
              <InlineStack align="space-between">
                <Text variant="headingMd" as="p">
                  {t("ORDER_LIMIT")}
                </Text>
                <Text variant="bodyLg" as="p">
                  {accountInfo.ordersUsed}/{accountInfo.orderLimit} {t("ORDERS")}
                </Text>
              </InlineStack>
              <ProgressBar
                progress={order_progress}
                size="small"
                tone={order_progress > 90 ? "critical" : "primary"}
              />
            </BlockStack>
          </Card>
        </InlineGrid>
        <Bleed marginInlineStart="400" marginInlineEnd="400">
          <Divider />
        </Bleed>

        <BlockStack gap="200">
          <Text variant="headingSm" as="p">
            {t("PAYMENT_HISTORY")}
          </Text>
          <PaymentHistory />
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default DI(AccountDetails);

import { BlockStack, Card, Divider, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const AvtivitySkeleton = () => {
    return (
        <Card>
            <BlockStack gap={"600"}>
                <BlockStack gap={"300"}>

                    <div style={{ "width": "100px" }} className="skeleton-height-11">
                        <SkeletonBodyText lines={1} />
                    </div>
                    <Divider />
                </BlockStack>

                <BlockStack gap={"600"}>
                    {Array.from({ length: 4 }, () =>
                        <div className='activity_item_skeleton'>
                            <BlockStack gap={"300"}>
                                <div style={{ "width": "80%" }} >
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "100%" }} >
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "50%" }} >
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "65%" }} >
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </BlockStack>
                        </div>
                    )}
                </BlockStack>

            </BlockStack>
        </Card>
    )
}

export default AvtivitySkeleton

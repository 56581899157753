import React, { useEffect, useState } from "react";
import {
  IndexTable,
  Card,
  Box,
  Text,
  InlineStack,
  Select,
  Pagination,
  useBreakpoints,
} from "@shopify/polaris";
import Filter from "./Filter";
import { TableData } from "./TableData";
import Profiles from "./Profiles";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { apiUrls } from "Src/Constant/url";
import { useProfileGridContext } from "../ProfileGridProvider";
import ProfileGridModals from "./ProfileGridModals";
import { perPageOptions } from "Src/Constant/helpData";
import { getPaginationLabel } from "Src/function";
import {
  NoProfileFallback,
  NoProfileSearchFallback,
} from "Src/Component/Fallbacks/FallBackPages";
import ProfileGridSkeleton from "../../Skeleton/ProfileGridSkeleton";
const { getProfileData } = apiUrls;
const ProfilingTable = ({ request: { GET }, t }: DIProps) => {
  const {  mdUp } = useBreakpoints();
  const {
    updateProfileGridData,
    loading,
    filters,
    profileGridData,
    setloading,
  } = useProfileGridContext();
  const [pagination, setPagination] = useState({
    perPage: 25,
    page: 1,
    totalCount: 0,
  });

  const getProfile = () => {
    setloading(true);
    const Query: { [name: string]: string } = {};
    if (Object.keys(filters).length > 0) {
      Object.keys(filters).forEach((fil: string) => {
        const temp = filters[fil];
        if (typeof temp === "string") {
          Query[`EtsyProfileManagementSearch[${fil}]`] = filters[fil] as string;
        } else {
          if ((temp["from"] as string).trim() !== "")
            Query[`EtsyProfileManagementSearch[${fil}][from]`] = temp[
              "from"
            ] as string;
          if ((temp["to"] as string).trim() !== "")
            Query[`EtsyProfileManagementSearch[${fil}][to]`] = temp[
              "to"
            ] as string;
        }
      });
    }
    Query[`per-page`] = pagination.perPage.toString();
    Query[`page`] = pagination.page.toString();
    GET(getProfileData, {
      ...Query,
      sort: (filters["sort"] as string) ?? "-created_at",
    })
      .then((res) => {
        const { success, data, totalCount } = res;
        if (success) {
          updateProfileGridData(data);
          setPagination({ ...pagination, totalCount: totalCount });
        }
      })
      .finally(() => setloading(false));
  };

  useEffect(() => {
    getProfile();
  }, [filters, pagination.page, pagination.perPage]);
  const isInitial = () => {
    return Object.keys(filters).filter((e) => e !== "sort").length === 0;
  };
  return profileGridData.length !== 0 ||
    (profileGridData.length === 0 && !isInitial()) ||
    loading ? (
      <div className={`${mdUp ? "" : "mobile_grid_no_bulk_actions"}`}>
      <Card padding="0">
        <Filter />
        {loading ? (
          <ProfileGridSkeleton />
        ) : (
          <IndexTable
            
            selectable={false}
            // loading={loading}
            emptyState={<NoProfileSearchFallback />}
            itemCount={profileGridData.length}
            headings={TableData[0].heading}
          >
            <Profiles getProfile={getProfile} />
          </IndexTable>
        )}
        <Box
          paddingBlock="200"
          paddingInline="300"
          borderBlockStartWidth="025"
          borderColor="border"
        >
          <InlineStack align="space-between" blockAlign="center" gap="200">
            <Text variant="bodyMd" as="p">
              {getPaginationLabel({
                ...pagination,
                length: profileGridData.length,
              })}
            </Text>
            <InlineStack wrap={false} gap="200">
              {mdUp && 
                <>
              <Text variant="bodyMd" as="span">
                {t("PER_PAGE")}
              </Text>
           
              </>
              }
                 <Select
                label="Per Page"
                labelHidden
                options={perPageOptions}
                value={pagination.perPage.toString()}
                onChange={(val) => {
                  setPagination((prev) => ({
                    ...prev,
                    perPage: parseInt(val),
                    page: 1,
                  }));
                }}
              />
              <Pagination
                hasPrevious={pagination.page > 1}
                hasNext={
                  pagination.page <
                  Math.ceil(pagination.totalCount / pagination.perPage)
                }
                onNext={() => {
                  setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
                }}
                onPrevious={() => {
                  setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
                }}
              />
            </InlineStack>
          </InlineStack>
        </Box>

        {/* Policy modal to be shown on policy grid */}
        <ProfileGridModals getProfile={getProfile} />
      </Card>
    </div>
  ) : (
    <Card>
      <NoProfileFallback />
    </Card>
  );
};

export default DI(ProfilingTable);

import React from 'react'
import TabSkeleton from './Component/TabSkeleton'
import { BlockStack } from '@shopify/polaris'
import GeneralInformationSkelton from './Component/GeneralInformationSkelton'
import ProductSyncingSkeleton from './Component/ProductSyncingSkeleton'
import EtsyAttributeSkeleton from './Component/EtsyAttributeSkeleton'
import ImageVideoSkeleton from './Component/ImageVideoSkeleton'

const ProductEditSkeleton = () => {
  return (
    <div className="inte-Tab-outer settings-tab">
      <TabSkeleton />
      <div className='tab-content'>
        <BlockStack gap={"600"}>
          <GeneralInformationSkelton />
          <ProductSyncingSkeleton />
          <EtsyAttributeSkeleton />
          <ImageVideoSkeleton />
        </BlockStack>
      </div>
    </div>
  )
}

export default ProductEditSkeleton

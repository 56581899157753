import {
  Autocomplete,
  BlockStack,
  Card,
  FormLayout,
  Icon,
  Text,
  InlineGrid,
  Select,
  TextField,
  Divider,
  InlineStack,
  Button,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SearchIcon, PlusIcon } from "@shopify/polaris-icons";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { StateI } from "../Shipping";
import {
  isoCodeToCountryMap,
  shipping_carrier_by_country,
} from "Src/Constant/helpData";

interface ShippingStandardI extends DIProps {
  state: StateI;
  shipping_entries: any;
  getdata: (e: any) => void;
  delete_entry: string;
  setDelete_entry: (e: string) => void;
  error: string[];
  setError: (e: string[]) => void;
}

const ShippingStandard = ({
  state,
  shipping_entries,
  getdata,
  delete_entry,
  setDelete_entry,
  error,
  setError,
  redux: { userDetails },
  t
}: ShippingStandardI) => {
  const {mdUp} = useBreakpoints();

  const [rows, setRows] = useState<any>([
    {
      id: 0,
      destination_country_id: state.origin_country_id,
      destination_country_id_text: isoCodeToCountryMap[state.origin_country_id],
      shipping_carrier_id: state.shipping_carrier_id ?? 0,
      mail_class: state.mail_class ?? "",
      shipping_cost_type: state.shipping_cost_type ?? "Fixed",
      min_delivery_days: state?.min_delivery_days ?? "",
      max_delivery_days: state?.max_delivery_days ?? "",
    },
  ]);
  const addRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        destination_country_id: "",
        destination_country_id_text: "",
        shipping_carrier_id: 0,
        mail_class: "",
        shipping_cost_type: "Fixed",
        min_delivery_days: "",
        max_delivery_days: "",
      },
    ]);
  };

  useEffect(() => {
    const label = countryList.find(
      (e) => e.value === state.origin_country_id
    )?.label;

    const tempRows = structuredClone(rows);
    tempRows[0].destination_country_id = state.origin_country_id;
    tempRows[0].destination_country_id_text = label;
    setRows(tempRows);
  }, [state.origin_country_id]);
  useEffect(() => {
    const tempRows = structuredClone(rows);
    tempRows[0] = {
      id: 0,
      destination_country_id: state.origin_country_id,
      destination_country_id_text: isoCodeToCountryMap[state.origin_country_id],
      shipping_carrier_id: state.shipping_carrier_id ?? 0,
      mail_class: state.mail_class ?? "",
      shipping_cost_type: state.shipping_cost_type ?? "Fixed",
      min_delivery_days: state.min_delivery_days ?? "",
      max_delivery_days: state.max_delivery_days ?? "",
      secondary_cost: state.secondary_cost,
      primary_cost: state.primary_cost,
    };
    shipping_entries?.forEach((e: any, index: number) => {
      const label = countryList.find(
        (c) => c.value === e.destination_country_id
      )?.label;
      tempRows[index + 1] = {
        id: index + 1,
        destination_country_id: e.destination_country_id,
        destination_country_id_text: label,
        shipping_carrier_id: e.shipping_carrier_id ?? 0,
        mail_class: e.mail_class ?? "",
        shipping_cost_type: e.shipping_cost_type ?? "Fixed",
        min_delivery_days: e.min_delivery_days?.toString() ?? "",
        max_delivery_days: e.max_delivery_days?.toString() ?? "",
        shipping_template_entry_id: e.shipping_template_entry_id,
        shipping_template_id: e.shipping_template_id,
        secondary_cost: e.secondary_cost,
        primary_cost: e.primary_cost,
      };
    });
    setRows(tempRows);
  }, [shipping_entries]);
  useEffect(() => {
    getdata({ code: "shippingStandard", data: rows });
  }, [rows]);
  const countryList = useMemo(() => {
    return [
      { label: "Everywhere Else", value: "Everywhere Else" },
      { label: "European Region", value: "eu" },
      { label: "Non European Region", value: "non_eu" },
      ...Object.keys(isoCodeToCountryMap).map((code: string) => ({
        label: isoCodeToCountryMap[code] as string,
        value: code,
      })),
    ];
  }, []);

  const updateText = useCallback(
    (value: string, index: number) => {
      const tempRows = structuredClone(rows);
      tempRows[index].destination_country_id_text = value;
      error.includes("destination_country_id-" + index) &&
        setError([
          ...error.filter((e) => e !== "destination_country_id-" + index),
        ]);
      setRows(tempRows);
    },
    [rows]
  );

  const updateSelection = useCallback(
    (selected: string[], index: number) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = countryList.find((countryOptions) => {
          return countryOptions.value === selectedItem;
        });
        return matchedOption;
      });
      const tempRows = structuredClone(rows);
      if (selectedValue.length > 0) {
        tempRows[index].destination_country_id_text = selectedValue[0]?.label;
        tempRows[index].destination_country_id = selectedValue[0]?.value;
        tempRows[index].shipping_carrier_id = 0;
        tempRows[index].mail_class = "";
        setRows(tempRows);
      }
      error.includes("destination_country_id-" + index) &&
        setError([
          ...error.filter((e) => e !== "destination_country_id-" + index),
        ]);
    },
    [countryList, rows, error]
  );

  const deleteRow = (id: any, shipping_template_entry_id: any) => {
    const filteredRow = rows.filter((item: any) => item.id !== id);
    let add = shipping_template_entry_id;

    if (delete_entry !== "") {
      add = delete_entry + "," + add;
    }
    shipping_template_entry_id && setDelete_entry(add);
    setRows(filteredRow);
  };

  const textField = (index: number) => (
    <Autocomplete.TextField
      error={
        error.includes("destination_country_id-" + index) ? t("CST_REQUIRED_MSG") : undefined
      }
      id={"destination_country_id-" + index}
      onChange={(e) => updateText(e, index)}
      label={t("SHIPPING_COUNTRY")}
      disabled={index === 0}
      value={rows[index]?.destination_country_id_text}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder={t("SELECT_COUNTRY")}
      autoComplete="off"
    />
  );
  const shippingStandardLists = (index: number) => {
    const curr_row = rows[index];
    const destination_country_id = curr_row?.destination_country_id;
    const getShippingCarrier = () => {
      if (!shipping_carrier_by_country?.[state.origin_country_id]) {
        return [{ label: t("OTHER"), value: 0 }];
      }
      return [
        { label: t("OTHER"), value: 0 },
        ...shipping_carrier_by_country?.[state.origin_country_id]
          ?.map((SC: any) => ({
            label: SC.name,
            value: SC.shipping_carrier_id,
            isDomestic: SC.domestic_classes.length,
          }))
          .filter((e: any) => e.isDomestic > 0),
      ];
    };
    const getMailClass = (index: number) => {
      const isExist = shipping_carrier_by_country[state.origin_country_id]?.find(
        (e: any) => curr_row?.shipping_carrier_id === e.shipping_carrier_id
      );
      const options: { label: string; value: string }[] = [];
      if (isExist) {
        isExist.domestic_classes.forEach((element: any) => {
          options.push({ label: element.name, value: element?.mail_class_key });
        });
      }
      return [{ label: "Other", value: "" }, ...options];
    };
    const getMinMaxDelDaysOptions = (code: 0 | 1) => {
      let option: { label: string; value: string; disabled: boolean }[] = [];
      for (let i = 0; i < 45; i++) {
        const isDisable = () => {
          if (code === 0 && parseInt(curr_row.max_delivery_days) - 1 < i) {
            return true;
          }
          if (code === 1 && parseInt(curr_row.min_delivery_days) - 1 > i) {
            return true;
          }
          return false;
        };
        option.push({
          label: (i + 1).toString(),
          value: (i + 1).toString(),
          disabled: isDisable(),
        });
      }
      return option;
    };
    const minDelDays = getMinMaxDelDaysOptions(0);
    const getCountryList = () => {
      const disableProps = rows
        .map((e: any, i: number) => {
          if (index === i) {
            return undefined;
          } else {
            return e.destination_country_id;
          }
        })
        .filter((e: string | undefined) => e !== undefined);

      return countryList
        .map((obj) => ({
          ...obj,
          disabled: disableProps.includes(obj.value),
        }))
        .filter((e) => {
          if (curr_row.destination_country_id_text === "") {
            return e;
          } else if (
            e.label.toLowerCase().includes(curr_row.destination_country_id_text)
          )
            return e;
        });
    };
    
    return (
      <InlineGrid gap={"400"} columns={mdUp ? ["oneThird", "twoThirds"]: 1}>
        <Autocomplete
          options={getCountryList()}
          selected={destination_country_id}
          onSelect={(e) => updateSelection(e, index)}
          textField={textField(index)}
        />
        <FormLayout>
          <FormLayout.Group condensed={mdUp ? true : false}>
            <Select
              placeholder={t("SELECT")}
              label={t("SHIPPING_CARRIER")}
              options={getShippingCarrier()}
              value={curr_row?.shipping_carrier_id}
              onChange={(e) => {
                const tempRows = structuredClone(rows);
                tempRows[index].shipping_carrier_id = parseInt(e);
                tempRows[index].mail_class = "";
                tempRows[index].min_delivery_days = "";
                tempRows[index].max_delivery_days = "";
                setRows(tempRows);
              }}
            />
            {curr_row?.shipping_carrier_id !== 0 && (
              <Select
                placeholder={t("SELECT")}
                label={t("MAIL_CLASS")}
                options={getMailClass(index)}
                value={curr_row?.mail_class ?? ""}
                onChange={(e) => {
                  const tempRows = structuredClone(rows);
                  tempRows[index].mail_class = e;
                  setRows(tempRows);
                }}
              />
            )}
            {curr_row?.shipping_carrier_id === 0 && (
              <Select
                error={
                  error.includes("min_delivery_days-" + index)
                    ? t("CST_REQUIRED_MSG")
                    : undefined
                }
                placeholder={t("SELECT")}
                label={t("MIN_DELIVERY_DAYS")}
                options={minDelDays}
                value={curr_row.min_delivery_days}
                onChange={(e) => {
                  const tempRows = structuredClone(rows);
                  tempRows[index].min_delivery_days = e;
                  error.includes("min_delivery_days-" + index) &&
                    setError([
                      ...error.filter(
                        (e) => e !== "min_delivery_days-" + index
                      ),
                    ]);
                  setRows(tempRows);
                }}
              />
            )}
            {curr_row?.shipping_carrier_id === 0 && (
              <Select
                error={
                  error.includes("max_delivery_days-" + index)
                    ? t("CST_REQUIRED_MSG")
                    : undefined
                }
                placeholder={t("SELECT")}
                label={t("MAX_DELIVERY_DAYS")}
                options={getMinMaxDelDaysOptions(1)}
                value={curr_row.max_delivery_days}
                onChange={(e) => {
                  const tempRows = structuredClone(rows);
                  tempRows[index].max_delivery_days = e;
                  error.includes("max_delivery_days-" + index) &&
                    setError([
                      ...error.filter(
                        (e) => e !== "max_delivery_days-" + index
                      ),
                    ]);
                  setRows(tempRows);
                }}
              />
            )}
          </FormLayout.Group>
          <FormLayout.Group condensed={mdUp ? true : false}>
            <Select
              placeholder={t("SELECT")}
              label={t("WHAT_YOULL_CHARGE")}
              options={[
                { label: t("FIXED"), value: "Fixed" },
                { label: t("FREE_SHIPPING"), value: "Free Shipping" },
              ]}
              value={curr_row?.shipping_cost_type}
              onChange={(e) => {
                const tempRows = structuredClone(rows);
                tempRows[index].shipping_cost_type = e;
                setRows(tempRows);
              }}
            />

            {curr_row?.shipping_cost_type === "Fixed" && (
              <TextField
                error={
                  error.includes("primary_cost-" + index) ? t("CST_REQUIRED_MSG") : undefined
                }
                id={"primary_cost-" + index}
                placeholder="0.00"
                label={t("ONE_ITEM")}
                type="number"
                value={curr_row.primary_cost}
                onChange={(e) => {
                  const numberRegex = /^[0-9]+(\.[0-9]+)?$/;
                  if (e !== "" && !numberRegex.test(e)) {
                    return;
                  }
                  const tempRows = structuredClone(rows);
                  tempRows[index].primary_cost = e;
                  error.includes("primary_cost-" + index) &&
                    setError([
                      ...error.filter((e) => e !== "primary_cost-" + index),
                    ]);
                  setRows(tempRows);
                }}
                prefix={userDetails?.shopifyShop?.currency}
                autoComplete="off"
              />
            )}
            {curr_row?.shipping_cost_type === "Fixed" && (
              <TextField
                error={
                  error.includes("secondary_cost-" + index)
                    ? ["", undefined].includes(curr_row.secondary_cost)
                      ? t("CST_REQUIRED_MSG")
                      : t("ADDITIONAL_ITEM_CANT_BE_MORE_THAN_ONE")
                    : undefined
                }
                id={"secondary_cost-" + index}
                placeholder="0.00"
                label={t("ADDITIONAL_ITEM")}
                type="number"
                value={curr_row.secondary_cost}
                onChange={(e) => {
                  const numberRegex = /^[0-9]+(\.[0-9]+)?$/;
                  if (e !== "" && !numberRegex.test(e)) {
                    return;
                  }
                  const tempRows = structuredClone(rows);
                  tempRows[index].secondary_cost = e;
                  error.includes("secondary_cost-" + index) &&
                    setError([
                      ...error.filter((e) => e !== "secondary_cost-" + index),
                    ]);
                  setRows(tempRows);
                }}
                prefix={userDetails?.shopifyShop?.currency}
                autoComplete="off"
              />
            )}
          </FormLayout.Group>
        </FormLayout>
      </InlineGrid>
    );
  };

  return (
    <Card>
      <BlockStack gap={"300"}>
        <BlockStack gap={"300"}>
          <div className="custom_required">
            <Text as="h6" variant="headingSm" fontWeight="medium">
              {t("STANDARD_SHIPPING")}
            </Text>
          </div>
          <Divider />
        </BlockStack>

        {rows?.map((row: any, index: any) => (
          <BlockStack key={"Standard" + index} gap={"300"}>
            {index !== 0 && <Divider />}
            {shippingStandardLists(index)}
            {index !== 0 && (
              <InlineStack align="end">
                <Button
                  variant="plain"
                  tone="critical"
                  onClick={() =>
                    deleteRow(row.id, row.shipping_template_entry_id)
                  }
                >
                 {t("DELETE_LOCATION")}
                </Button>
              </InlineStack>
            )}
          </BlockStack>
        ))}
        <Divider />
        <InlineStack align="start">
          <Button
            onClick={addRow}
            icon={<Icon source={PlusIcon} tone="base" />}
          >
            {t("ADD_NEW_LOCATION")}
          </Button>
        </InlineStack>
      </BlockStack>
    </Card>
  );
};

export default DI(ShippingStandard);

import React from 'react'

import { BlockStack, InlineGrid, Layout, Page } from '@shopify/polaris'
import TabSkeletn from './component/TabSkeletn'
import GeneralSettingSkelton from './component/GeneralSettingSkelton'

const SettingSkeleton = () => {
    return (
        <div className="custom_layout_width">
            <Page fullWidth>

                <Layout>
                    <Layout.Section>
                        <div className="inte-Tab-outer settings-tab">
                            <TabSkeletn />
                            <div className='tab-content'>
                                <BlockStack gap={"600"}>
                                    <GeneralSettingSkelton />
                                </BlockStack>
                            </div>
                        </div>
                    </Layout.Section>
                </Layout>

            </Page>
        </div>
    )
}

export default SettingSkeleton

import { BlockStack, Card, IndexTable, InlineGrid, InlineStack, Layout, SkeletonBodyText, SkeletonThumbnail, Tag, Text } from '@shopify/polaris';

import VariantDetailsSkeleton from './VariantDetailsSkeleton';
import VariantTimelineSkeleton from './VariantTimelineSkeleton';

const ShopifyViewSkeleton = () => {

  return (
    <div className="product_view_page_layout">
      <Layout>
        <Layout.Section>
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                  <SkeletonBodyText lines={4} />
                </InlineGrid>
                <InlineGrid
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>

                  <InlineStack gap={"200"}>
                    {Array.from({ length: 10 }, () =>
                      <div style={{ "width": "60px" }} className='skeleton-height-20'>
                        <SkeletonBodyText />
                      </div>
                    )}
                  </InlineStack>

                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <div style={{ "width": "150px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <InlineStack gap={"200"}>
                  {Array.from({ length: 10 }, () =>
                    <SkeletonThumbnail size='large' />
                  )}
                </InlineStack>
              </BlockStack>
            </Card>
            <VariantDetailsSkeleton />
            <VariantTimelineSkeleton />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                {Array.from({ length: 3 }, () =>
                  <InlineGrid
                    columns={{
                      xl: ["oneThird", "twoThirds"],
                      lg: ["oneThird", "twoThirds"],
                      md: ["oneHalf", "oneHalf"],
                    }}
                  >
                    <div style={{ "width": "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <SkeletonBodyText lines={1} />
                  </InlineGrid>
                )}
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                {Array.from({ length: 3 }, () =>
                  <InlineGrid
                    columns={{
                      xl: ["oneThird", "twoThirds"],
                      lg: ["oneThird", "twoThirds"],
                      md: ["oneHalf", "oneHalf"],
                    }}
                  >
                    <div style={{ "width": "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ "width": "150px" }}>
                      <SkeletonBodyText />
                    </div>
                  </InlineGrid>
                )}
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </div>
  )
}

export default ShopifyViewSkeleton

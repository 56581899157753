import { Tone } from "@shopify/polaris/build/ts/src/components/Badge";
import { emailPattern } from "Src/Constant/helpData";

export function replaceLastOccurrence(
  str: string,
  target: string,
  replacement: string
) {
  const lastIndex = str.lastIndexOf(target);
  if (lastIndex === -1) {
    return str; // Target string not found, return the original string
  }
  const beforeTarget = str.substring(0, lastIndex);
  const afterTarget = str.substring(lastIndex + target.length);
  return beforeTarget + replacement + afterTarget;
}

export const getStatusBadge = (
  status: string
): { tone: Tone; updatedStatus: string } => {
  let tone: Tone = "success";
  switch (status) {
    case "cancelled":
      tone = "attention";
      break;
    case "declined":
      tone = "critical";
      break;
    case "frozen":
      tone = "info";
      break;
    case "pending":
      tone = "warning";
      break;
    default:
      tone = "success";
  }
  return {
    tone,
    updatedStatus: capitalizeFirstChar(status),
  };
};

export function capitalizeFirstChar(str: string) {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeWords(input: string): string {
  return input
    .split("_") // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words back together
}

export function avatarInitials(input: string) {
  if (typeof input !== "string") {
    return "";
  }

  const words = input.trim().split(" ");

  if (words.length > 1) {
    // If there are spaces, return the first characters of the first two words in uppercase
    return (words[0][0] + words[1][0]).toUpperCase();
  } else {
    // If there are no spaces, return the first three characters of the first word with the first letter capitalized
    const firstWord = words[0];
    return firstWord.charAt(0).toUpperCase() + firstWord.slice(1, 3);
  }
}

export const emailValidation = (email: string) => {
  if (!email) {
    return {
      success: false,
      msg: "Please enter email address",
    };
  } else if (!emailPattern.test(email)) {
    return {
      success: false,
      msg: "Please enter a valid email address",
    };
  } else {
    return {
      success: true,
      msg: "",
    };
  }
};

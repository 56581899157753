import React, { useCallback, useEffect, useState } from "react";
import EtsyView from "./view/EtsyView";
import { BlockStack, Box, Page, Tabs, useBreakpoints } from "@shopify/polaris";
import Activator from "../Notification/Activator";
import ShopifyView from "./view/ShopifyView";
import { apiUrls } from "Src/Constant/url";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import "./orders.css";
import { isValidValue } from "Src/function";
const { viewOrder } = apiUrls;

const OrderView = ({ request: { GET }, location, navigate }: DIProps) => {
  const {mdUp} = useBreakpoints();
  const [selected, setSelected] = useState(0);
  const [hasBeenLoading, setHasBeenLoading] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const getOrderView = () => {
    GET(viewOrder, {
      receipt_id: location.state.receipt_id ?? "",
      shopify_order_id: location.state.shopify_order_id ?? "",
    })
      .then((e) => {
        setOrderData(e);
      })
      .finally(() => {
        setHasBeenLoading(true);
      });
  };
  useEffect(() => {
    getOrderView();
  }, []);
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    []
  );
  const tabs = [{
    id: "etsy-view",
    content: "Etsy",
  }];
  if (
    location.state.shopify_order_id
  ) {
    tabs[1] = {
      id: "shpify-view",
      content: "Shopify",
    };
  }
  return (
    <div>
      <Box borderBlockEndWidth={mdUp ? '025' : '0'} borderColor="border">
        <div className="custom_layout_width">
          <Page
            fullWidth
            title={"Order View"}
            backAction={{
              content: "",
              onAction: () => {
                navigate(-1);
              },
            }}
            secondaryActions={<Activator />}
          ></Page>
        </div>
      </Box>
      <div className="custom_layout_width">
        <Page fullWidth>
          <BlockStack gap={"200"}>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />

            {selected === 0 ? (
              <EtsyView orderData={orderData} hasBeenLoading={hasBeenLoading} />
            ) : (
              <ShopifyView
                hasBeenLoading={hasBeenLoading}
                orderData={orderData}
              />
            )}
          </BlockStack>
        </Page>
      </div>
    </div>
  );
};

export default DI(OrderView);

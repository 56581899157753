import { Box } from "@shopify/polaris";

import React from "react";
import { error, success, warning } from "Src/assets/svg";

const Status = ({ type }: any) => {
  const checkNotificationType: { [key: string]: React.ReactNode } = {
    warning: warning,
    danger: error,
    success: success,
  };
  const typeNotify = type && checkNotificationType[type];

  return <Box>{typeNotify}</Box>;
};

export default Status;

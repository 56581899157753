import React, { useState } from "react";
import {
  ActionList,
  Badge,
  BlockStack,
  Box,
  Button,
  Checkbox,
  Icon,
  IndexTable,
  InlineStack,
  Link,
  Popover,
  Text,
  Tooltip,
  useBreakpoints,
} from "@shopify/polaris";
import {
  EditIcon,
  DeleteIcon,
  DuplicateIcon,
  MenuVerticalIcon,
  ViewIcon,
} from "@shopify/polaris-icons";

import { DI } from "Src/core";
import { useProfileGridContext } from "../ProfileGridProvider";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { DIProps } from "Src/Interface/@core";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { getFormattedDateTime } from "Src/function";
import { getTrimLength } from "Src/Component/CharacterLimitSet";

const { enableDisableProfile } = apiUrls;
const Profiles = ({
  request: { POST },
  navigate,
  t
}: DIProps & { getProfile: () => void }) => {
  const { mdDown } = useBreakpoints();
  const [popoverActive, setPopoverActive] = useState('');
  const { profileGridData, updateModal, updateProfileGridData, setloading } =
    useProfileGridContext();
  const { setRouteData } = useRoutesContext();
  const badgeColor = (status: any) => {
    let badgeTone: any = "default";
    if (status === "Complete") {
      badgeTone = "success";
    } else {
      badgeTone = "default";
    }
    return badgeTone;
  };


  const desktopMarkUp = profileGridData.map(
    (
      {
        profile_status,
        id,
        profile_code,
        created_at,
        product_count,
        is_digital_profile,
        profile_id,
        shipping_template_id,
        category_data,
      }: any,
      index: any
    ) => {
      const getStatus = () => {
        if (
          is_digital_profile === "0" &&
          (!shipping_template_id ||
            Object.keys(category_data).length === 0 ||
            !category_data?.policy)
        )
          return t("INCOMPLETE");
        return t("COMPLETE");
      };
      const profileNmae: any = getTrimLength(profile_code, 50, "regular", "span", "bodyMd")
      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>
            <InlineStack gap="300" wrap={false}>
              <div style={{"display":'none'}}>
                <Link dataPrimaryLink></Link>
              </div>
              <div className="inte-custom__switcher">
                <Checkbox
                  label
                  checked={profile_status === "enable"}
                  onChange={() => {
                    if (profile_status === "enable") {
                      updateModal({
                        for: "disable",
                        open: true,
                        id: profile_id,
                      });
                    } else {
                      setloading(true);
                      POST(enableDisableProfile, {
                        id: profile_id,
                        request: "enable",
                      })
                        .then((res) => {
                          const { success, message } = res;
                          if (success) {
                            updateProfileGridData(
                              profileGridData.map((e) => {
                                if (e.profile_id === profile_id) {
                                  e.profile_status = "enable";
                                }
                                return e;
                              })
                            );
                          }
                          ToastAppBridge(message, { isError: !success });
                        })
                        .finally(() => setloading(false));
                    }
                  }}
                />
              </div>
              <div
                style={{ "cursor": "pointer" }}
                className="receipt-view"
                onClick={(e) => {
                  e.stopPropagation();
                  setRouteData({
                    from: "profile-grid",
                    to: "profile",
                    data: {
                      type: "view",
                      initial: { profile_id: profile_id },
                    },
                  });
                  updateModal({ for: "view", open: true, id: profile_id });
                }}
              >
                <InlineStack gap="100" wrap={false}>
                  <Button variant="monochromePlain" onClick={() => { }}>

                    {profileNmae}

                  </Button>
                  <ViewIcon width={20} fill="#4a4a4a" />
                </InlineStack>
              </div>
            </InlineStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {getFormattedDateTime(created_at, true)}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge tone={badgeColor(getStatus())}>{getStatus()}</Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>{product_count}</IndexTable.Cell>
          <IndexTable.Cell>
            {is_digital_profile === "0" ? "Non-digital" : "Digital"}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <InlineStack gap="200" wrap={false}>
              <Tooltip content={t("EDIT")}>
                <Button
                  onClick={() => {
                    setRouteData({
                      data: { initial: profileGridData[index], type: "edit" },
                      to: "edit-profile",
                      from: "profile",
                    });
                    navigate("create");
                  }}
                  icon={<Icon source={EditIcon} tone="base" />}
                />
              </Tooltip>
              <Tooltip content="Clone">
                <Button
                  onClick={() => {
                    setRouteData({
                      data: { initial: profileGridData[index], type: "clone" },
                      to: "edit-profile",
                      from: "profile",
                    });
                    navigate("create");
                  }}
                  icon={<Icon source={DuplicateIcon} tone="base" />}
                />
              </Tooltip>
              <Tooltip content="Delete">
                <Button
                  tone="critical"
                  onClick={() =>
                    updateModal({ for: "delete", open: true, id: profile_id })
                  }
                  icon={<Icon source={DeleteIcon} tone="critical" />}
                />
              </Tooltip>
            </InlineStack>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );


  const mobileMarkUp  = profileGridData.map(
    (
      {
        profile_status,
        id,
        profile_code,
        created_at,
        product_count,
        is_digital_profile,
        profile_id,
        shipping_template_id,
        category_data,
      }: any,
      index: any
    ) => {
      const getStatus = () => {
        if (
          is_digital_profile === "0" &&
          (!shipping_template_id ||
            Object.keys(category_data).length === 0 ||
            !category_data?.policy)
        )
          return "Incomplete";
        return "Complete";
      };
      const profileNmae: any = getTrimLength(profile_code, 50, "regular", "span", "bodyMd")
      return (
        <IndexTable.Row id={id} key={id} position={index}>
              <Box padding='300'>
              <InlineStack wrap={false} gap='200' align="space-between" blockAlign="start">
                  <InlineStack wrap={false} gap='200' blockAlign="start">
                      <InlineStack gap="300" wrap={false}>
                        <div style={{"display":'none'}}>
                          <Link dataPrimaryLink></Link>
                        </div>
                        <div className="inte-custom__switcher">
                          <Checkbox
                            label
                            checked={profile_status === "enable"}
                            onChange={() => {
                              if (profile_status === "enable") {
                                updateModal({
                                  for: "disable",
                                  open: true,
                                  id: profile_id,
                                });
                              } else {
                                setloading(true);
                                POST(enableDisableProfile, {
                                  id: profile_id,
                                  request: "enable",
                                })
                                  .then((res) => {
                                    const { success, message } = res;
                                    if (success) {
                                      updateProfileGridData(
                                        profileGridData.map((e) => {
                                          if (e.profile_id === profile_id) {
                                            e.profile_status = "enable";
                                          }
                                          return e;
                                        })
                                      );
                                    }
                                    ToastAppBridge(message, { isError: !success });
                                  })
                                  .finally(() => setloading(false));
                              }
                            }}
                          />
                        </div>
                      </InlineStack>

                      <BlockStack gap='100'>
                        <InlineStack gap='200' blockAlign="start" align="start">
                          <div
                            style={{ "cursor": "pointer" }}
                            className="receipt-view"
                            onClick={(e) => {
                              e.stopPropagation();
                              setRouteData({
                                from: "profile-grid",
                                to: "profile",
                                data: {
                                  type: "view",
                                  initial: { profile_id: profile_id },
                                },
                              });
                              updateModal({ for: "view", open: true, id: profile_id });
                            }}
                          >
                        
                              <Button textAlign="left" variant="monochromePlain" onClick={() => { }}>

                                {profileNmae}

                              </Button>
                          
                          </div>
                          <Badge tone={badgeColor(getStatus())}>{getStatus()}</Badge>
                          </InlineStack>
                  
                        <Text variant="bodyMd" as="p">  Products: {product_count}</Text>
                
                        <Text variant="bodyMd" as="p">  Profile Type: {is_digital_profile === "0" ? "Non-digital" : "Digital"} </Text>
                        <Text variant="bodyMd" as="p" tone="subdued"> Created on: {getFormattedDateTime(created_at, true)}</Text>
                      </BlockStack>
                    </InlineStack>
                    
                      <Popover
                  active={popoverActive === profile_id}
                  activator={
                    <div onClick={(e) => e.stopPropagation()}>
                      <Button
                        icon={<Icon source={MenuVerticalIcon} tone="base" />}
                        onClick={() =>
                          setPopoverActive(popoverActive === profile_id ? '' : profile_id)
                        }
                      />
                    </div>
                  }
                  autofocusTarget="first-node"
                  onClose={() => setPopoverActive('')}
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    <ActionList
                      actionRole="menuitem"
                      items={[
                        {
                          content: t("EDIT"),
                          onAction: () => {
                            setRouteData({
                              data: { initial: profileGridData[index], type: "edit" },
                              to: "edit-profile",
                              from: "profile",
                            });
                            navigate("create");
                          }
                        },
                        {
                          content: 'Clone',
                          onAction: () => { 
                              setRouteData({
                                data: { initial: profileGridData[index], type: "clone" },
                                to: "edit-profile",
                                from: "profile",
                              });
                              navigate("create");
                          },
                        },
                        {
                          content: 'Delete',
                          onAction: () => { 
                            updateModal({ for: "delete", open: true, id: profile_id });
                            setPopoverActive('');
                          },
                        },
                      ]}
                    />
                  </div>
                </Popover>
                </InlineStack>
            </Box>
         
        </IndexTable.Row>
      );
    }
  );


  return mdDown ? mobileMarkUp : desktopMarkUp
};

export default DI(Profiles);

import { Box, TextField } from "@shopify/polaris";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { DI } from "Src/core";
import React, { useCallback, useState } from "react";

const AltTextUpdateModal = ({
  isOpen,
  toggleAltTextModal,
  currAltText,
  updateAltText,
  t
}: any) => {
  const [altText, setAltText] = useState(currAltText);

  const changeAltText = useCallback((value: string) => setAltText(value), []);
  return (
    <ModalAppBridge
      open={isOpen}
      onHide={toggleAltTextModal}
      footer={
        <>
          <button
            tone="default"
            variant="primary"
            onClick={() => updateAltText(altText)}
          >
            {t("CONFIRM")}
          </button>
          <button onClick={toggleAltTextModal}>{t("CANCEL")}</button>
        </>
      }
      id="update-alt-text"
      variant="large"
      body={
        <Box padding="400">
          <TextField
            label={t(("ENTER_ALT_TEXT"))}
            type="text"
            value={altText}
            onChange={changeAltText}
            helpText={t(("ALT_TEXT_HELPTEXT"))}
            autoComplete=""
          />
        </Box>
      }
      title={t("ALT_TEXT")}
    />
  );
};

export default DI(AltTextUpdateModal);

import { BlockStack, Box, Card, Divider, InlineGrid, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";

interface PropsI extends DIProps {
    isOnboarding?: boolean
}


const PricingCardsSkeleton = ({ isOnboarding }: PropsI) => {
    return (
        <>
            {[1, 2, 3].map((index) => (
                <Card key={index} roundedAbove='xs'>
                    <BlockStack gap='200'>
                    <BlockStack gap='100'>
                        <InlineStack
                            align="space-between"
                            gap="200"
                            blockAlign="center"
                        >
                            <div className='plan-name'>
                                <div style={{ "width": "100px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                            </div>
                            {isOnboarding && <div style={{ "width": "150px" }} className='skeleton-height-32px'><SkeletonBodyText lines={1} /></div>}
                        </InlineStack>

                        <div style={{ "width": "150px" }} className='skeleton-height-40'><SkeletonBodyText lines={1} /></div>
                        <div style={{ "width": "200px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                      
                            <div className='skeleton-height-56'><SkeletonBodyText lines={1} /></div>
                        </BlockStack>
                        <Divider />
                        <div style={{ "width": "70px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                        <InlineGrid gap='200' columns={isOnboarding ? { md: '2' } : 1}>
                            <div style={{ "width": "40%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                            <div style={{ "width": "50%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                            <div style={{ "width": "60%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                            <div style={{ "width": "70%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                        </InlineGrid>

                        {!isOnboarding && <> <Divider />
                            <div className='skeleton-height-32px'><SkeletonBodyText lines={1} /></div></>}
                    </BlockStack>
                </Card>


            ))}
        </>
    )
}


export default DI(PricingCardsSkeleton);

import {
  Banner,
  BlockStack,
  Box,
  Card,
  Divider,
  InlineStack,
  Page,
  RadioButton,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { apiUrls } from "Src/Constant/url";
import { regexExpressions } from "Src/Constants";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { ExtraModalFormTypeI } from "Src/Interface/@Profile";

interface ErrorStateI {
  name: string;
  min_inventory_value: string;
  default_inventory_value: string;
}

interface FormDataI {
  name: string;
  min_inventory_value: string;
  default_inventory_value: string;
  send_continue_qty: string;
}

const Inventory = ({
  request: { POST },
  type = "page",
  setSaveLoading,
  saveCall = false,
  setSaveCall,
  closeModal,
  setTemplateId,
  onCreation,
  t,
}: ExtraModalFormTypeI & DIProps) => {
  const {mdUp} = useBreakpoints();
  const navigate = useNavigate();
  const {
    routeData: { data: editData },
    setRouteData,
  } = useRoutesContext();
  const { saveTemplateData } = apiUrls;
  const [formData, setFormData] = useState<FormDataI>({
    name: editData?.template_name ?? `inventory_${Date.now()}`,
    min_inventory_value: editData?.threshold_inventory ?? "",
    default_inventory_value: editData?.max_inventory ?? "",
    send_continue_qty: editData?.send_continue_qty ?? "send_99",
  });
  const [errorState, setErrorState] = useState<ErrorStateI>({
    name: "",
    default_inventory_value: "",
    min_inventory_value: "",
  });
  const [loading, setLoading] = useState(false);

  const changeHandlerFormData = (key: keyof FormDataI, value: string) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    if (key in errorState) {
      setErrorState((prev) => ({ ...prev, [key]: "" }));
    }
  };
  /**
   * updates the error messages require on blur
   * @param key name of error object key
   */
  const blurHandler = (key: keyof FormDataI) => {
    const tempErrorState = { ...errorState };
    if (key === "name" && !formData.name) {
      tempErrorState.name = "Template name cannot be empty";
    } else if (
      key === "min_inventory_value" ||
      key === "default_inventory_value"
    ) {
      let maxValError = false;
      if (formData[key] && parseInt(formData[key]) > 999) {
        maxValError = true;
        tempErrorState[key] = t("INVENTORY_CANNOT_BE_GREATER_THAN_999");
      } else {
        tempErrorState[key] = "";
      }

      if (!maxValError) {
        const rangeError =
          parseInt(formData?.min_inventory_value) >=
          parseInt(formData?.default_inventory_value);

        if (
          formData.default_inventory_value &&
          formData.min_inventory_value &&
          rangeError
        ) {
          tempErrorState[key] =
            key === "min_inventory_value"
              ? t("MINIMUM_THRESHOLD_VALUE_MUST_BE_LESS_THAN_MAX")
              : t("MAXIMUM_THRESHOLD_VALUE_MUST_BE_MORE_THAN_MIN");
        } else {
          tempErrorState["default_inventory_value"] =
            parseInt(formData.default_inventory_value) > 999
              ? t("INVENTORY_CANNOT_BE_GREATER_THAN_999")
              : "";
          tempErrorState["min_inventory_value"] =
            parseInt(formData.min_inventory_value) > 999
              ? t("INVENTORY_CANNOT_BE_GREATER_THAN_999")
              : "";
        }
      }
    }

    setErrorState({ ...tempErrorState });
  };
  // initiates api call to create or edit inventory template
  const createInventoryTemplate = () => {
    if (Object.values(errorState).some((val) => val !== "")) {
      return;
    }
    const { name, ...inventory_management } = formData;
    const payload = {
      inventory_management: {
        ...inventory_management,
        min_inventory_value: inventory_management.min_inventory_value ?? "0",
      },
      template_type: "inventory",
      if_case_of_edit: editData?.id ?? "",
      name,
    };
    setLoading(true);
    POST(saveTemplateData, payload)
      .then((res) => {
        if (res?.success) {
          setTemplateId && setTemplateId(res?.template_id as string);
          res?.template_id && onCreation?.({[res?.template_id]: formData.name})
          if (closeModal) {
            closeModal();
          } else {
            setRouteData({
              data: { selected: 1 },
              from: "inventory-form",
              to: "template",
            });
            navigate("/panel/template");
          }
        }
        ToastAppBridge(res?.message, { isError: !res?.success });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (saveCall) {
      createInventoryTemplate();
      setSaveCall?.(false);
    }
  }, [saveCall]);
  return (
    <div>
      <Box borderBlockEndWidth={mdUp ? '025' : '0'} borderColor="border">
        {type !== "modal" && (
          <div className="custom_layout_width">
            <Page
              backAction={{
                content: t("INVENTORY_TEMPLATE"),
                onAction: () => {
                  setRouteData({
                    data: { selected: 1 },
                    from: "inventory-form",
                    to: "template",
                  });
                  navigate(-1);
                },
              }}
              fullWidth
              title={t("INVENTORY_TEMPLATE")}
              primaryAction={{
                content: t("SAVE"),
                accessibilityLabel: "Save",
                onAction: createInventoryTemplate,
                loading: loading,
              }}
              // secondaryActions={[
              //   { content: "Need Help ?", accessibilityLabel: "Need Help ?" },
              // ]}
            ></Page>
          </div>
        )}
      </Box>
      <div className="custom_layout_width">
        <Page fullWidth>
          <BlockStack gap={"400"}>
            {/* <Banner>
              <p>
                Since Etsy allows a maximum inventory of 999, you cannot assign a
                value more than 999.
              </p>
            </Banner> */}
            <Card>
              <BlockStack gap={"200"}>
                <BlockStack gap={"200"}>
                  <Text as="h6" variant="headingMd" fontWeight="semibold">
                    {t("TEMPLATE_NAME")}
                  </Text>
                  <Divider />
                </BlockStack>
                <TextField
                  label=""
                  placeholder={t("ENTER_A_NAME_FOR_THE_TEMPLATE")}
                  value={formData.name}
                  onChange={(val) => {
                    const regex = /^[a-zA-Z0-9_ ]+$/;
                    if (regex.test(val) || val.trim() === "")
                      changeHandlerFormData("name", val);
                  }}
                  autoComplete="off"
                  error={errorState.name}
                  onBlur={() => blurHandler("name")}
                />
              </BlockStack>
            </Card>

            <Card>
              <BlockStack gap={"300"}>
                <BlockStack gap={"200"}>
                  <Text as="h6" variant="headingMd" fontWeight="semibold">
                    {t("INVENTORY_RULES")}
                  </Text>
                  <Divider />
                </BlockStack>
                <BlockStack gap={"300"}>
                  <TextField
                    placeholder="0"
                    label={t("MINIMUM_THRESHOLD_VALUE")}
                    value={formData.min_inventory_value}
                    onChange={(val) => {
                      if (
                        regexExpressions.NUMBER_WITHOUT_DOT.test(val) ||
                        val.trim() === ""
                      ) {
                        changeHandlerFormData("min_inventory_value", val);
                      }
                    }}
                    onBlur={() => blurHandler("min_inventory_value")}
                    autoComplete="off"
                    helpText={t("MINIMUM_THRESHOLD_VALUE_HELPTEXT")}
                    error={errorState.min_inventory_value}
                  />
                  <Divider />
                  <TextField
                    placeholder={t("ENTER_VALUE")}
                    label={t("MAXIMUM_INVENTORY_LEVEL")}
                    value={formData.default_inventory_value}
                    onChange={(val) => {
                      if (
                        regexExpressions.NUMBER_WITHOUT_DOT.test(val) ||
                        val.trim() === ""
                      ) {
                        changeHandlerFormData("default_inventory_value", val);
                      }
                    }}
                    onBlur={() => blurHandler("default_inventory_value")}
                    error={errorState.default_inventory_value}
                    autoComplete="off"
                    helpText={t("MAXIMUM_INVENTORY_LEVEL_HELPTEXT")}
                  />
                  <Divider />
                  <BlockStack gap={"100"}>
                    <Text as="p" fontWeight="regular" variant="bodyMd">
                      {t("MAXIMUM_INVENTORY_ON_ETSY")}
                    </Text>
                    <Text as="p" variant="bodyMd" tone="subdued">
                      {t("MAXIMUM_INVENTORY_ON_ETSY_DESC")}
                    </Text>
                    <InlineStack gap={mdUp ? '800' : '200'}>
                      <RadioButton
                        label={t("SEND_MAXIMUM_INVENTORY_VALUE")}
                        checked={formData.send_continue_qty === "send_99"}
                        name="send_continue_qty"
                        onChange={() =>
                          changeHandlerFormData("send_continue_qty", "send_99")
                        }
                      />
                      <RadioButton
                        label={t("SEND_SHOPIFY_INVENTORY")}
                        name="send_continue_qty"
                        checked={formData.send_continue_qty === "send_shopify"}
                        onChange={() =>
                          changeHandlerFormData(
                            "send_continue_qty",
                            "send_shopify"
                          )
                        }
                      />
                    </InlineStack>
                  </BlockStack>
                </BlockStack>
              </BlockStack>
            </Card>
          </BlockStack>
        </Page>
      </div>
    </div>
  );
};

export default DI(Inventory);

import { Box, InlineGrid, InlineStack, SkeletonBodyText } from "@shopify/polaris";

export const gridSkeletonRow = {
    id: 1,
    title:  <InlineStack gap='200'  blockAlign="center" wrap={false}>
    <div style={{ width: "24px"}} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
    <div style={{ width: "60%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
  </InlineStack>,
    tags:   <Box paddingInline='300'>
    <InlineStack align='start' gap='100' blockAlign='center'>
      <div style={{ width: "30%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
      <div style={{ width: "30%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
      <div style={{ width: "30%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
    </InlineStack>
  </Box>,
    dimensions:   <Box paddingInline='300'>
    <InlineStack align='start' gap='100' blockAlign='center' wrap={false}>
      <div style={{ width: "90%" }}>
        <InlineGrid columns='3' gap='100' >
          <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
          <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
          <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
        </InlineGrid>
      </div>
      <div style={{ width: "50px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
    </InlineStack>

  </Box>,
    vendor:   <Box paddingInline='300'>
    <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
  </Box>,
    shop_section:  <Box paddingInline='300'>
    <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
  </Box>,
    inventory:    <Box paddingInline='300'>
    <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
  </Box>,
    price:  <Box paddingInline='300'>
    <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
  </Box>,
    weight:  <Box paddingInline='300'>
    <div style={{ width: "90%" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
  </Box>,
  }
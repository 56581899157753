import {  IndexTable } from '@shopify/polaris'
import React from 'react'
import BulkSkeletonRowMarkup from './BulkSkeletonRowMarkup';

const BulkEditGridSkeleton = () => {


  return (
    <div className="bulk-edit">
      <IndexTable
        selectable={false}
        itemCount={10}
        headings={[
          { title: "Title" },
          { title: "Tags" },
          { title: "Dimensions" },
          { title: "Vendor" },
          { title: "Shop Section" },
          { title: "Inventory" },
          { title: "Price"},
          { title: "Weight" },
        ]}
      >
        <BulkSkeletonRowMarkup/>
      </IndexTable>
    </div>
  )
}

export default BulkEditGridSkeleton

import {
  BlockStack,
  Button,
  Card,
  Divider,
  InlineStack,
  Select,
  Text,
} from "@shopify/polaris";
import { DI } from "Src/core";
import React, { useMemo } from "react";
import { useProfileContext } from "../ProfileProvider";
import { formatTemplateOptions } from "../CreateProfileHelper";
import { DIProps } from "Src/Interface/@core";
import { useRoutesContext } from "Src/Component/RoutesProvider";

const AdvanceMapping = ({ t }: DIProps) => {
  const {
    profileData,
    templates,
    saveTemplates,
    setCreateModal,
    extraTemplateOptions,
  } = useProfileContext();
  const { routeData } = useRoutesContext();
  const inventoryTemplates = profileData.profile_template?.[2] as
    | undefined
    | { [key: string]: string };

  const priceTemplates = profileData.profile_template?.[1] as
    | undefined
    | { [key: string]: string };

  const getInvTemplateOptions: { label: string; value: string }[] = useMemo(
    () => [
      ...formatTemplateOptions(inventoryTemplates),
      ...extraTemplateOptions.inventory,
    ],
    [inventoryTemplates,extraTemplateOptions.inventory]
  );
  const getPriceTemplateOptions: { label: string; value: string }[] = useMemo(
    () => [
      ...formatTemplateOptions(priceTemplates),
      ...extraTemplateOptions.price,
    ],
    [priceTemplates,extraTemplateOptions.price]
  );

  return (
    <Card>
      <BlockStack gap={"300"}>
        <BlockStack gap={"200"}>
          <Text as="h6" variant="headingMd" fontWeight="semibold">
            {t("ADVANCE_MAPPING")}
          </Text>
          <Divider />
        </BlockStack>

        <Select
          label={t("INV_TEMPLATE")}
          placeholder={t("SELECT")}
          disabled={routeData?.data?.type === "view"}
          onChange={(e: string) => {
            saveTemplates("inventory_templates", e);
          }}
          helpText={
            routeData?.data?.type !== "view" && (
              <InlineStack blockAlign="center" gap={"050"}>
                <Text as="p" variant="bodyMd">
                  {t("CHOOSE_EXISTING_INV_TEMPLATE")}
                </Text>
                <Button
                  onClick={() => {
                    setCreateModal({
                      isOpen: true,
                      type: "inventory",
                    });
                  }}
                  variant="plain"
                  external
                >
                  {t("CREATE_NEW_ONE")}
                </Button>
              </InlineStack>
            )
          }
          options={getInvTemplateOptions}
          value={templates.inventory_templates.toString()}
        />
        <Divider />
        <Select
          label={t("PRICE_TEMPLATE")}
          placeholder={t("SELECT")}
          disabled={routeData?.data?.type === "view"}
          onChange={(e: string) => {
            saveTemplates("price_templates", e);
          }}
          helpText={
            routeData?.data?.type !== "view" && (
              <InlineStack blockAlign="center" gap={"050"}>
                <Text as="p" variant="bodyMd">
                  {t("CHOOSE_EXISTING_PRICE_TEMPLATE")}
                </Text>
                <Button
                  onClick={() => {
                    setCreateModal({
                      isOpen: true,
                      type: "price",
                    });
                  }}
                  variant="plain"
                  external
                >
                  {t("CREATE_NEW_ONE")}
                </Button>
              </InlineStack>
            )
          }
          options={getPriceTemplateOptions}
          value={templates.price_templates.toString()}
        />
      </BlockStack>
    </Card>
  );
};

/**
 * Component for advanced mapping of inventory and price templates.
 *
 * @param {DIProps} props - Dependency Injection props containing various utilities and state.
 * @returns {JSX.Element} The rendered component.
 */
export default DI(AdvanceMapping);

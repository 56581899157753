import {
  Bleed,
  BlockStack,
  Button,
  Card,
  ChoiceList,
  Divider,
  InlineStack,
  Text,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { apiUrls } from "Src/Constant/url";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { saveNotifications } from "Src/Redux/Slices/settingsSlice";
import { useSettingsContext } from "../SettingsProvider";

const { saveShop } = apiUrls;
const emailSubscriptionsValues = {
  failed_order: "Failed Order Notification",
  subscription_plan: "Subscription Plan",
  order_threshold: "Order Notification",
};
const orderThresholdValues = [
  "On_Exceeding_80",
  "On_Exceeding_95",
  "On_Exceeding_100",
];

const Notifications = (_props: DIProps) => {
  const {
    request: { POST },
    dispatch,
    redux: {
      settings: { notifications },
    },
    t,
  } = _props;
  const { isChange, setIsChange } = useSettingsContext();
  const [emailSubscriptions, setEmailSubscription] = useState<string[]>([]);
  const [orderThreshold, setOrderThreshold] = useState<string[]>([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSave = () => {
    setSaveLoading(true);
    POST(saveShop, {
      subscriptions: {
        ...Object.entries(emailSubscriptionsValues).reduce(
          (acc, [key, value]) => {
            return {
              ...acc,
              [value]: emailSubscriptions.includes(key) ? "1" : "0",
            };
          },
          {}
        ),
        order_threshold: {
          ...orderThresholdValues.reduce((acc, key) => {
            return { ...acc, [key]: orderThreshold.includes(key) ? "1" : "0" };
          }, {}),
        },
      },
    })
      .then((res) => {
        if (res.success) {
          setIsChange(false);
          dispatch(
            saveNotifications({
              failed_order: emailSubscriptions.includes("failed_order")
                ? "1"
                : "0",
              order: emailSubscriptions.includes("order") ? "1" : "0",
              subscription_plan: emailSubscriptions.includes(
                "subscription_plan"
              )
                ? "1"
                : "0",
              order_threshold: {
                On_Exceeding_100: orderThreshold.includes("On_Exceeding_100")
                  ? "1"
                  : "0",
                On_Exceeding_95: orderThreshold.includes("On_Exceeding_95")
                  ? "1"
                  : "0",
                On_Exceeding_80: orderThreshold.includes("On_Exceeding_80")
                  ? "1"
                  : "0",
              },
            })
          );
          ToastAppBridge(res?.message ?? "Settings updated successfully!");
        } else {
          ToastAppBridge(res?.message ?? "Something went wrong!", {
            isError: true,
          });
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  useEffect(() => {
    const tempEmailSubscription: string[] = [];
    const tempOrderThreshold: string[] = [];
    Object.entries(notifications).forEach(([key, value]) => {
      if (value === "1" && key !== "order_threshold") {
        tempEmailSubscription.push(key);
      } else if (key === "order_threshold") {
        Object.entries(value).forEach(([sub_key, sub_value]) => {
          if (sub_value === "1") tempOrderThreshold.push(sub_key);
        });
        tempOrderThreshold.length > 0 &&
          tempEmailSubscription.push("order_threshold");
      }
    });
    setEmailSubscription(tempEmailSubscription);
    setOrderThreshold(tempOrderThreshold);
  }, []);

  const renderChildren = useCallback(
    (isSelected: boolean) =>
      isSelected && (
        <ChoiceList
          allowMultiple
          title="While the customer is checking out"
          titleHidden
          choices={[
            {
              label: t("ON_EXCEEDING_PERCENT", { percent: 80 }),
              value: "On_Exceeding_80",
            },
            {
              label: t("ON_EXCEEDING_PERCENT", { percent: 95 }),
              value: "On_Exceeding_95",
            },
            {
              label: t("ON_EXCEEDING_PERCENT", { percent: 100 }),
              value: "On_Exceeding_100",
            },
          ]}
          selected={orderThreshold}
          onChange={(value) => {
            !isChange && setIsChange(true);
            setOrderThreshold(value);
            if (value.length === 0) {
              setEmailSubscription((prev) =>
                prev.filter((key) => key !== "order_threshold")
              );
            }
          }}
        />
      ),
    [orderThreshold]
  );

  return (
    <BlockStack gap="300">
      <Card>
        <BlockStack gap="200">
          <BlockStack gap="400">
            <BlockStack gap="200">
              <Text as="h2" variant="headingSm">
                {t("NOTIFICATIONS")}
              </Text>
              <Text as="p" variant="bodyMd">
                {t("CHOOSE_OPTIONS_FOR_WHICH_YOU_RECEIVE_NOTIFICATIONS")}
              </Text>
            </BlockStack>
            <Bleed marginInlineStart="400" marginInlineEnd="400">
              <Divider />
            </Bleed>

            <Text variant="bodyMd" as="h3" fontWeight="medium">
              {t("EMAIL_SUBSCRIPTION")}
            </Text>
          </BlockStack>
          <ChoiceList
            allowMultiple
            title={t("WHILE_CUSTOMER_IS_CHECKING_OUT")}
            titleHidden
            choices={[
              {
                label: t("FAILED_ORDER_NOTIFICATION"),
                value: "failed_order",
              },
              {
                label: t("SUBSCRIPTION_PLAN"),
                value: "subscription_plan",
              },
              {
                label: t("ORDER_NOTIFICATION"),
                value: "order",
              },
              {
                label: t("ALERT_FOR_EXCEEDING_LIMIT"),
                value: "order_threshold",
                helpText: t("RECEIVE_ALERT_AFTER_CERTAIN_ORDER_LIMIT"),
                renderChildren,
              },
            ]}
            selected={emailSubscriptions}
            onChange={(value) => {
              !isChange && setIsChange(true);
              setEmailSubscription(value);
              if (
                value.includes("order_threshold") &&
                !emailSubscriptions.includes("order_threshold")
              ) {
                setOrderThreshold([
                  "On_Exceeding_80",
                  "On_Exceeding_95",
                  "On_Exceeding_100",
                ]);
              }
            }}
          />
        </BlockStack>
      </Card>
      <InlineStack align="end">
        <Button
          variant="primary"
          loading={saveLoading}
          onClick={() => {
            handleSave();
          }}
        >
          {t("SAVE")}
        </Button>
      </InlineStack>
    </BlockStack>
  );
};

export default DI(Notifications);

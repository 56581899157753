import { deepCloneObj, getAllCapitalWords } from "Src/function";
import { ObjStrI } from "Src/Interface/@core";

export const validateGeneralInformmation = (obj: ObjStrI) => {
  const errorObj: ObjStrI = {};
  Object.entries(obj).forEach(([key, val]) => {
    switch (key) {
      case "title": {
        const moreThanOnceRegex = /^(?!.*[%&:].*[%&:]).*$/;
        const excludeRegex = /^[^$^]*$/;
        const isInvalid =
          val.length > 140 ||
          getAllCapitalWords(val).length > 3 ||
          !moreThanOnceRegex.test(val) ||
          !excludeRegex.test(val);
        Object.assign(errorObj, {
          [key]: isInvalid,
        });
        break;
      }
    }
  });
  return errorObj;
};

export const getProductEditErrors = (product: any, errorsObj: ObjStrI) => {
  const errors = deepCloneObj(errorsObj);

  const generalInfoErrors = validateGeneralInformmation({
    title: product?.title,
  });
  if (generalInfoErrors?.title || !product?.title) {
    Object.assign(errors, {
      title: generalInfoErrors?.title || !product?.title,
    });
  }
  const requiredKeys = [
    "description",
    "who_made",
    "when_made",
    "is_supply",
    "shipping_template_id",
    "policy",
  ];

  requiredKeys.forEach((key) => {
    if (!product?.[key]) {
      Object.assign(errors, { [key]: "This field is required" });
    }
  });
  const requiredShippingKeys = ["processing_min", "processing_max","processing_unit"];
  let checkShipping = false;
  const shippingErrors={}
  requiredShippingKeys.forEach((key) => {
    if(product?.shipping_data?.[key] && product?.shipping_data?.[key]!=="" && !checkShipping) {
      checkShipping=true;
    }
    if (!product?.shipping_data?.[key]) {
      Object.assign(shippingErrors, { [key]: "This field is required" });
    }
  });
  if(checkShipping) {
    Object.assign(errors,{...shippingErrors});
  }
  const shippingData= product?.shipping_data;
  if (parseInt(shippingData?.processing_min) > parseInt(shippingData?.processing_max)) {
    Object.assign(errors, {
      processing_max:
        "Maximum processing time must be greater than minimum processing time",
    });
  }
  const requiredDimensionKeys = ["length", "width", "height", "unit"];
  const dimensionsFilled = requiredDimensionKeys.some((key) => {
    return product?.dimensions?.[key] && product?.dimensions?.[key] !== "";
  });
  if (dimensionsFilled) {
    const { length, width, height } = product?.dimensions;
    requiredDimensionKeys.forEach((key) => {
      if (!product?.dimensions?.[key]) {
        Object.assign(errors, { [key]: "This field is required" });
      }
    });
    const lowerThanLength = [width, height];
    const notLowerThanLength = lowerThanLength.some(
      (val) => parseFloat(val) > parseFloat(length)
    );
    if (notLowerThanLength) {
      Object.assign(errors, {
        length: "Length should be the greatest value among the dimensions.",
      });
    }
  }
  return errors;
};

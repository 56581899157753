import React from 'react'
import AvtivitySkeleton from './AvtivitySkeleton'
import { BlockStack, Box, Card, Divider, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonTabs, SkeletonThumbnail, useBreakpoints } from '@shopify/polaris'
import GeneralNotificationSkeleton from './GeneralNotificationSkeleton'

const WithPageHeaderActivitySkeleton = () => {
    const { mdDown } = useBreakpoints()
    return (
        <>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className="custom_layout_width">
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>

                            {mdDown ? (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                    <InlineStack gap={"200"}>

                                        <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </InlineStack>
                                </InlineStack>
                            ) : (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                    <InlineStack gap={"200"}>

                                        <div style={{ "width": "28px" }}>
                                            <SkeletonDisplayText size='small' />
                                        </div>
                                    </InlineStack>
                                </InlineStack>
                            )}

                        </Page>
                    </div>
                </div>
            </Box>
            <div className="custom_layout_width">
                <Page fullWidth>
                    <BlockStack gap={"600"}>
                        <Card>
                            <InlineStack blockAlign='center' gap={"300"}>
                                <SkeletonThumbnail size='extraSmall' />
                                <div style={{ "width": "150px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                        </Card>
                        <AvtivitySkeleton />
                        <Card>
                            <BlockStack gap={"400"}>
                                <BlockStack gap={"100"}>
                                    <BlockStack gap={"500"}>
                                        <div style={{ "width": "150px" }} className='skeleton-height-11'>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <Divider />

                                    </BlockStack>
                                    <SkeletonTabs />
                                </BlockStack>
                                <GeneralNotificationSkeleton />
                            </BlockStack>
                        </Card>
                    </BlockStack>
                </Page>
            </div>
        </>
    )
}

export default WithPageHeaderActivitySkeleton

import { Badge } from "@shopify/polaris";
import { Tone } from "@shopify/polaris/build/ts/src/components/Badge";
import { TFunction } from "i18next";
import { defaultBadgeStatuses } from "Src/Constant/helpData";
import { convertToStartCase, getTranslationKey } from "Src/function";

/**
 * creates a price string to display product price
 * @param product product object
 * @returns price string for any product
 */
export const getProductPrice = (product: any) => {
  if (product?.min_price && product?.max_price) {
    return `${product.min_price} - ${product.max_price}`;
  } else if (product?.max_price) {
    return product.maxprice;
  }
  return "N/A";
};

export const getShopifyBadgeTone = (badgeVal: string) => {
  let badgeTone: Tone = "new";
  switch (badgeVal?.toLowerCase()) {
    case "draft":
      badgeTone = "attention";
      break;
    case "active":
      badgeTone = "success";
      break;
    case "canceled":
      badgeTone = "warning";
      break;
    case "refunded":
      badgeTone = "attention-strong";
      break;
  }
  return badgeTone;
};

export const getEtsyBadgeTone = (badgeVal: string) => {
  let badgeTone: Tone = "new";
  switch (badgeVal) {
    case "1":
    case "not published":
      badgeTone = "info";
      break;
    case "2":
    case "active":
      badgeTone = "success";
      break;
    case "3":
    case "draft":
      badgeTone = "attention";
      break;
    case "5":
    case "sold_out":
      badgeTone = "critical";
      break;
    case "7":
      badgeTone = "critical";
      break;
  }
  return badgeTone;
};

export const getEtsyBadgeText = (badgeVal: string, t: TFunction) => {
  let badgeTxt: string = "";
  switch (badgeVal) {
    case "1": // NOT_UPLOADED_STATUS
      return t("NOT_PUBLISHED");
    case "2": // ACTIVE_STATUS
      return t("ACTIVE");
    case "3": // DRAFT_STATUS
      return t("DRAFT");
    case "4": // EDIT_STATUS
      return t("INACTIVE");
    case "5": // SOLDOUT_STATUS
      return t("SOLD_OUT");
    case "7": // EXPIRED_STATUS
      return t("EXPIRED");
    case "12": // OTHER_STATUS
      return t("OTHERS");
    case "13": // CUSTOM_INACTIVE_STATUS
      return t("CUSTOM_INACTIVE");
    case "active": // ACTIVE_STATUS
      return t("ACTIVE");
    case "draft": // DRAFT_STATUS
      return t("DRAFT");
    case "edit": // EDIT_STATUS
      return t("INACTIVE");
    case "sold_out": // SOLDOUT_STATUS
      return t("SOLD_OUT");
    case "expired": // EXPIRED_STATUS
      return t("EXPIRED");
    case "other": // OTHER_STATUS
      return t("OTHERS");
    case "custom_inactive": // CUSTOM_INACTIVE_STATUS
      return t("CUSTOM_INACTIVE");
  }
  return badgeTxt;
};

export const getShopifyBadge = (status: string, t: TFunction) => {
  return defaultBadgeStatuses.includes(status) ? (
    <Badge>{status ? t(getTranslationKey(status)) : status}</Badge>
  ) : (
    <Badge tone={getShopifyBadgeTone(status)}>
      {status ? t(getTranslationKey(status)) : status}
    </Badge>
  );
};

export const getEtsyBadge = (status: string, t: TFunction) => {
  return defaultBadgeStatuses.includes(status) ? (
    <Badge>{getEtsyBadgeText(status, t)}</Badge>
  ) : (
    <Badge tone={getEtsyBadgeTone(status)}>{getEtsyBadgeText(status, t)}</Badge>
  );
};

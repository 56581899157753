import { createContext, useContext, useMemo, useState } from "react";
import { removeInvalidKeys } from "Src/function";
import { ObjStrI } from "Src/Interface/@core";

interface InitialStateI {
  reviews: any[];
  reviewsLoading: boolean;
  filters: ObjStrI;
}

interface ReviewProviderI extends InitialStateI {
  saveReviews: (reviews: any[]) => void;
  toggleLoading: () => void;
  updateFilters: (filters: ObjStrI) => void;
  saveFilters: (filters: ObjStrI) => void;
}

const initialvalue: ReviewProviderI = {
  reviews: [],
  reviewsLoading: false,
  filters: {},
  saveReviews: () => {},
  toggleLoading: () => {},
  updateFilters: () => {},
  saveFilters: () => {},
};

const ReviewContext = createContext(initialvalue);

const ReviewProvider = ({ children }: any) => {
  const [state, setState] = useState<InitialStateI>({
    reviews: [],
    reviewsLoading: false,
    filters: {},
  });

  const saveReviews = (reviews: any[]) => {
    setState((prev) => ({ ...prev, reviews }));
  };

  const toggleLoading = () => {
    setState((prev) => ({ ...prev, reviewsLoading: !prev.reviewsLoading }));
  };

  const updateFilters = (filtersObj: ObjStrI) => {
    const validFilters = removeInvalidKeys({ ...state.filters, ...filtersObj });
    setState((prev) => ({
      ...prev,
      filters: { ...validFilters },
    }));
  };

  const saveFilters = (filters: ObjStrI) => {
    setState((prev) => ({ ...prev, filters: { ...filters } }));
  };

  const value: ReviewProviderI = useMemo(
    () => ({
      ...state,
      saveReviews,
      toggleLoading,
      updateFilters,
      saveFilters,
    }),
    [state]
  );

  return (
    <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>
  );
};

const useReviewContext = () => useContext(ReviewContext);
export { ReviewProvider, useReviewContext };

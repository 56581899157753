import { BlockStack, Box, Card, Collapsible, Divider, InlineGrid, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'



const OrderAddOnPageSkeleton = () => {
    return (
        <Card padding='600'>
            <BlockStack gap='400'>

                {[1, 2, 3, 4, 5].map((item) => (
                    <Card key={item} roundedAbove='xs'>
                        <InlineStack align='space-between'>
                            <div style={{ flex: 1 }}>
                                <InlineGrid columns={{ sm: 4 }} gap='600'>
                                    <div className='skeleton-height-20' style={{ "width": "90%" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <BlockStack gap='100'>
                                        <div className='skeleton-height-20' style={{ "width": "80%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className='skeleton-height-20' style={{ "width": "60%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </BlockStack>
                                    <BlockStack gap='100'>
                                        <div className='skeleton-height-20' style={{ "width": "80%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className='skeleton-height-20' style={{ "width": "90%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </BlockStack>
                                    <BlockStack gap='100'>
                                        <div className='skeleton-height-20' style={{ "width": "80%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className='skeleton-height-20' style={{ "width": "60%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </BlockStack>

                                </InlineGrid>
                            </div>
                            <div className='skeleton-height-20 ' style={{ "width": "20px" }}>
                                <SkeletonBodyText lines={1} />
                            </div>
                        </InlineStack>


                        <Collapsible
                            open={item === 1}
                            id={`order-item-${item}`}
                        >

                            <Box paddingBlockStart='300'>
                                <BlockStack gap='200'>
                                    <Divider />
                                    <InlineStack align='space-between' blockAlign='center' gap='200'>
                                        <BlockStack gap='200'>
                                            <div className='skeleton-height-20' style={{ "width": "100px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='custom-height_skel' style={{ "width": "260px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                        <div className='skeleton-height-20' style={{ "width": "100px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </InlineStack>

                                </BlockStack>
                            </Box>

                        </Collapsible>
                    </Card>

                ))}


            </BlockStack>
        </Card>
    )
}

export default OrderAddOnPageSkeleton

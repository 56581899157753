import {
  Banner,
  BlockStack,
  Box,
  Button,
  Layout,
  Link,
  Page,
  useBreakpoints,
} from "@shopify/polaris";
import ProfilingTable from "./components/ProfilingTable";
import { useNavigate } from "react-router-dom";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { useState } from "react";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { Trans } from "react-i18next";
import Activator from "Src/Component/Notification/Activator";

const Profiling = ({ t }: DIProps) => {
  const {mdUp} = useBreakpoints();
  const navigate = useNavigate();
  const [bannerState, setBannerState] = useState(true);
  const { setRouteData } = useRoutesContext();
  return (
    <>
          <Box borderBlockEndWidth={mdUp ? '025' : '0'} borderColor="border">
        <Page
          fullWidth
          title={t("PROFILING")}
          secondaryActions={
            <Button
              variant="primary"
              onClick={() => {
                setRouteData({
                  data: { type: "create" },
                  to: "edit-profile",
                  from: "profile",
                });
                navigate("create");
              }}
            >
              {t("CREATE_NEW_PROFILE")}
            </Button>
          }
          primaryAction={<Activator />}
        />
      </Box>
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <BlockStack gap="600">
              {bannerState && (
                <Banner
                  tone="info"
                  onDismiss={() => {
                    setBannerState(false);
                  }}
                >
                  <Trans
                    i18nKey="PROFILING_SUBTITLE"
                    values={{ link: t("PROFILING_SUBTITLE_LINK") }}
                    components={[
                      <Link
                        key={"PROFILING_SUBTITLE_LINK"}
                        target="_blank"
                        url="https://support.cedcommerce.com/portal/en/kb/search/create-a-profile"
                      />,
                    ]}
                  />
                </Banner>
              )}
              <ProfilingTable />
            </BlockStack>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default DI(Profiling);

import { Bleed, BlockStack, Card, Divider, InlineStack, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from '@shopify/polaris'
import React from 'react'

const GeneralSettingSkelton = () => {
    return (
        <BlockStack gap={"300"}>
            <Card>
                <BlockStack gap="500">
                    <BlockStack gap="400">
                        <div style={{ "width": "150px" }} className='skeleton-height-11'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <SkeletonBodyText lines={1} />
                    </BlockStack>
                    <Bleed marginInlineStart="400" marginInlineEnd="400">
                        <Divider />
                    </Bleed>
                    <BlockStack gap="400">
                        <div style={{ "width": "100px" }} className='skeleton-height-11'>
                            <SkeletonBodyText lines={1} />
                        </div>

                        <BlockStack gap={'400'}>
                            <SkeletonBodyText lines={2} />
                            <BlockStack gap={"200"}>
                                <div className='skeleton-height-32px'>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "80%" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </BlockStack>
                        </BlockStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap="500">
                        <InlineStack align="space-between" blockAlign='center'>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <div style={{ "width": "100px" }}>
                                <SkeletonDisplayText size='small' />
                            </div>

                        </InlineStack>
                        <SkeletonBodyText lines={2} />
                        <BlockStack gap={"300"}>
                            {Array.from({ length: 4 }, (index: any) =>
                                <InlineStack blockAlign='center' gap={"300"}>
                                    <div className='custom-height_skel' style={{ "width": "16px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ "width": "150px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineStack>
                            )}
                        </BlockStack>
                    </BlockStack>
                </BlockStack>
            </Card>
            <div className='skeleton_page_header_spacing'>
                <InlineStack align='end'>
                    <div style={{ "width": "80px" }}>
                        <SkeletonDisplayText size='small' />
                    </div>
                </InlineStack>
            </div>
        </BlockStack>
    )
}

export default GeneralSettingSkelton

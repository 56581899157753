import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import FullPageLoader from "Src/Component/FullPageLoader/FullPageLoader";
import EtsyRedirect from "Src/Component/Message/EtsyRedirect";
import PrivatePanel from "Src/Component/PrivatePanel";

const ApprovePayment = lazy(
  () => import("Src/Component/Pages/Pricing/ApprovePayment/ApprovePayment")
);
const Onboarding = lazy(() => import("../Component/Onboarding"));

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="onboarding"
        element={
          <Suspense fallback={<FullPageLoader/>}>
            <Onboarding />
          </Suspense>
        }
      />
      <Route
        path="etsy-redirect"
        element={
          <Suspense fallback={<FullPageLoader/>}>
            <EtsyRedirect />
          </Suspense>
        }
      />
      <Route
        path="payment/confirmpayment"
        element={
          <Suspense fallback={<FullPageLoader/>}>
            <ApprovePayment />
          </Suspense>
        }
      />
      <Route
        path="payment/confirm-order-addon-payment"
        element={
          <Suspense fallback={<FullPageLoader/>}>
            <ApprovePayment />
          </Suspense>
        }
      />
      <Route
        path="panel/*"
        element={
          <Suspense fallback={<FullPageLoader/>}>
            <PrivatePanel />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRouter;

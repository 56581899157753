import { environment } from "Src/environment/environment";
import { GlobalState } from "./_Services";
import { DEFAULT_LANGUAGE } from "Src/Constants";

const md5 = require("md5");

/**
 * Generates an MD5 hash for a given key with a predefined suffix.
 *
 * @param {string} key - The key to hash.
 * @returns {string} The hashed key.
 */
export const GetHashed = (key: string): string => {
  if (!environment.prod) {
    return key;
  }
  const encrypt = key + "_Need_To_Encrypt";
  return md5(encrypt);
};

export const prepareHeaders = () => {
  const Bearer = GlobalState.get()("auth_token");
  const language = localStorage.getItem("language") ?? DEFAULT_LANGUAGE;
  return {
    Authorization: `Bearer ${Bearer ?? ""}`,
    "Accept-Language": language,
  };
};

import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  Divider,
  IndexTable,
  InlineGrid,
  InlineStack,
  Layout,
  Tag,
  Text,
  Thumbnail,
  useBreakpoints,
} from "@shopify/polaris";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { DI } from "Src/core";
import React from "react";
import EtsyViewSkeleton from "./Skeleton/EtsyViewSkeleton";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import {
  getFormattedDateTime,
  getValueOptionMap,
  isValidValue,
} from "Src/function";
import { dimensionUnitOptions } from "Src/Constant/SelectOptions";
export function ConvertTimestamp(timestamp: any) {
  const newTimestamp = timestamp * 1000;
  const date = new Date(newTimestamp);
  const options: any = {
    weekday: "long", // Tuesday
    year: "numeric", // 2024
    month: "long", // August
    day: "numeric", // 27
    hour: "numeric", // 6
    minute: "numeric", // 04
    hour12: true, // PM
  };
  const formattedDate = date.toLocaleString(options);
  return date;
}
const EtsyProductView = ({
  hasBeenLoading,
  productData,
  productUpdates,
  t
}: DIProps & {
  productData: any;
  hasBeenLoading: boolean;
  productUpdates: any;
}) => {
  const { mdDown } = useBreakpoints();
  const etsyProductData = productData?.product;
  const thumbnailImages = etsyProductData?.images;
  const tags = etsyProductData?.tags;
  const materials = etsyProductData?.materials;
  const styles = etsyProductData?.style;
  const whoMade = etsyProductData?.who_made;
  const whenMade = etsyProductData?.when_made?.replace("_", "-");
  const variantDetails = etsyProductData?.inventory?.products;
  const listingProperties = etsyProductData?.listing_properties?.results;
  const productUpdateVari = productUpdates?.variants;
  const propertyNamesHeadings: IndexTableHeading[] =
    variantDetails?.[0]?.property_values?.map((property: ObjStrI) => ({
      title: property?.property_name,
    })) ?? [];
  const rowMarkupVariantDetails = variantDetails?.map(
    ({ id, sku, offerings, property_values }: any, index: number) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="medium" as="span">
            {sku}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {offerings[0].price.amount
            ? offerings[0].price.amount / offerings[0].price.divisor
            : "N/A"}
        </IndexTable.Cell>
        <IndexTable.Cell>{offerings[0].quantity ?? "N/A"}</IndexTable.Cell>
        {property_values?.map((item: any) => {
          return (
            <IndexTable.Cell>
              {item?.values?.join(",") +
                " " +
                (item?.scale_name ? item?.scale_name : "")}
            </IndexTable.Cell>
          );
        })}
        <IndexTable.Cell>
          <div className="inte-custom__switcher">
            <Checkbox
              label
              checked={offerings[0]?.is_enabled}
              disabled={true}
            />
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const rowMarkupVariantTimeline = productUpdateVari?.map(
    (
      { id, sku, inventory_last_updated, updated_at_variant, updated_at }: any,
      index: number
    ) => {
      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="medium" as="span">
              {sku}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {getFormattedDateTime(updated_at_variant, true)}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {" "}
            {getFormattedDateTime(updated_at, true)}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {" "}
            {getFormattedDateTime(inventory_last_updated, true)}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const dimensionsMap: ObjStrI = getValueOptionMap(dimensionUnitOptions);
  const anyDimensionPresent =
    etsyProductData?.item_length ||
    etsyProductData?.item_width ||
    etsyProductData?.item_height;

  const dimensionsObj = {
    Length: etsyProductData?.item_length ?? "N/A",
    Width: etsyProductData?.item_width ?? "N/A",
    Height: etsyProductData?.item_height ?? "N/A",
    "Dimensions unit": anyDimensionPresent
      ? etsyProductData?.item_dimensions_unit
        ? dimensionsMap?.[etsyProductData?.item_dimensions_unit]
        : "N/A"
      : undefined,
    Weight: etsyProductData?.item_weight ?? "N/A",
    "Weight unit": etsyProductData?.item_weight_unit ?? "N/A",
  };

  if (isValidValue(etsyProductData?.exception?.last_response)) {
    return (
      <Banner tone="critical">
        <Text as="p">
          {t("ERROR_FROM_ETSY")} :{" "}
          {etsyProductData?.exception?.last_response ?? t("DETAILS_NOT_FOUND")}
        </Text>
      </Banner>
    );
  }

  return hasBeenLoading ? (
    <div className="product_view_page_layout">
      <Layout>
        <Layout.Section>
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  gap="100"
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Description:
                  </Text>
                  <div
                    className="view-description"
                    dangerouslySetInnerHTML={{
                      __html: etsyProductData?.description,
                    }}
                  />
                </InlineGrid>
                <InlineGrid
                  gap="100"
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Product Tags /Keywords:
                  </Text>
                  {tags?.length > 0 ? (
                    <InlineStack gap={"200"}>
                      {tags?.map((item: any) => {
                        return <Tag>{item}</Tag>;
                      })}
                    </InlineStack>
                  ) : (
                    <Text
                      as={"strong"}
                      variant="headingMd"
                      fontWeight="regular"
                    >
                      N/A
                    </Text>
                  )}
                </InlineGrid>
                <InlineGrid
                  gap="100"
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Material:
                  </Text>
                  {materials?.length > 0 ? (
                    <InlineStack gap={"200"}>
                      {materials?.map((item: any) => {
                        return <Tag>{item}</Tag>;
                      })}
                    </InlineStack>
                  ) : (
                    <Text
                      as={"strong"}
                      variant="headingMd"
                      fontWeight="regular"
                    >
                      N/A
                    </Text>
                  )}
                </InlineGrid>
                <InlineGrid
                  gap="100"
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Styles:
                  </Text>
                  {styles?.length > 0 ? (
                    <InlineStack gap={"200"}>
                      {styles?.map((item: any) => {
                        return <Tag>{item}</Tag>;
                      })}
                    </InlineStack>
                  ) : (
                    <Text
                      as={"strong"}
                      variant="headingMd"
                      fontWeight="regular"
                    >
                      N/A
                    </Text>
                  )}
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <Text as={"strong"} variant="headingMd" fontWeight="medium">
                  Images
                </Text>
                {thumbnailImages?.length > 0 ? (
                  <InlineStack gap={"200"}>
                    {thumbnailImages?.map((item: any) => {
                      return (
                        <Thumbnail
                          size={mdDown ? "medium" : "large"}
                          source={item.url_75x75}
                          alt="Black choker necklace"
                        />
                      );
                    })}
                  </InlineStack>
                ) : (
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    N/A
                  </Text>
                )}
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Who made it?:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {whoMade === "i_did"
                      ? "I did"
                      : whoMade === "collective"
                      ? "A member of my shop"
                      : "Another company or person"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                 gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    When did you make it?:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {whenMade}
                  </Text>
                </InlineGrid>
                <InlineGrid
                 gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    What is it?:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.is_supply
                      ? "A supply or tool to make things"
                      : "A finished product"}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                 gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Is Personalize?:
                  </Text>
                  <BlockStack gap={"200"}>
                    <Text
                      as={"strong"}
                      variant="headingMd"
                      fontWeight="regular"
                    >
                      {etsyProductData?.is_personalizable ? "Yes" : "No"}
                    </Text>
                    <Card roundedAbove="xs">
                      <div className="personlize-data">
                        <BlockStack gap={"300"}>
                          <Text
                            as={"strong"}
                            variant="headingMd"
                            fontWeight="regular"
                          >
                            Personalization is required :{" "}
                            {etsyProductData?.personalization_is_required
                              ? "Yes"
                              : "No"}
                          </Text>
                          <Text
                            as={"strong"}
                            variant="headingMd"
                            fontWeight="regular"
                          >
                            Personalization character limit :{" "}
                            {etsyProductData?.personalization_char_count_max ??
                              "N/A"}
                          </Text>
                          <Text
                            as={"strong"}
                            variant="headingMd"
                            fontWeight="regular"
                          >
                            Personalization instructions :
                            {etsyProductData?.personalization_instructions ??
                              "N/A"}
                          </Text>
                        </BlockStack>
                      </div>
                    </Card>
                  </BlockStack>
                </InlineGrid>
                <InlineGrid
                 gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Is Customizable?:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.is_customizable ? "Yes" : "No"}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>

            {listingProperties?.length > 0 && (
              <Card>
                <BlockStack gap={"300"}>
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Listing Properties
                  </Text>
                  {mdDown && <Divider/>}
                  {listingProperties?.map((item: any) => {
                    return (
                      <>
                        <InlineGrid
                          gap='100'
                          columns={{
                            md: ["oneThird", "twoThirds"],
                            xs: ["oneHalf", "oneHalf"],
                          }}
                        >
                          <Text
                            as={"strong"}
                            variant="headingMd"
                            fontWeight="medium"
                          >
                            {item.property_name}
                          </Text>
                          <InlineStack gap={"200"}>
                            <Text
                              as={"strong"}
                              variant="headingMd"
                              fontWeight="regular"
                            >
                              {item?.values
                                ?.map((valueItem: any) => valueItem)
                                .join(" , ")}
                            </Text>
                          </InlineStack>
                        </InlineGrid>
                      </>
                    );
                  })}
                </BlockStack>
              </Card>
            )}
            <Card>
              <BlockStack gap={"300"}>
                <Text as={"strong"} variant="headingMd" fontWeight="medium">
                  Variant details
                </Text>
                {mdDown && <Divider/>}
                <Card padding={"0"} roundedAbove="xs">
                  <IndexTable
                    selectable={false}
                    itemCount={2}
                    headings={[
                      { title: "SKU" },
                      { title: "Price (USD)" },
                      { title: "Quantity" },
                      ...propertyNamesHeadings,
                      { title: "Visible" },
                    ]}
                  >
                    {rowMarkupVariantDetails}
                  </IndexTable>
                </Card>
              </BlockStack>
            </Card>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <Text as={"strong"} variant="headingMd" fontWeight="medium">
                  Variant timeline
                </Text>
                {mdDown && <Divider/>}
                <Card padding={"0"} roundedAbove="xs">
                  <div className="variant-table_vertical_scroll">
                    <IndexTable
                      selectable={false}
                      itemCount={2}
                      headings={[
                        { title: "SKU" },
                        { title: "Updated at App" },
                        { title: "Updated at shopify" },
                        { title: "Inventory Update from app" },
                      ]}
                    >
                      {rowMarkupVariantTimeline}
                    </IndexTable>
                  </div>
                </Card>
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Listing id:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.listing_id ?? "N/A"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Created timestamp:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {getFormattedDateTime(
                      ConvertTimestamp(etsyProductData?.created_timestamp),
                      true
                    )}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Modified at Etsy:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {getFormattedDateTime(
                      ConvertTimestamp(
                        etsyProductData?.last_modified_timestamp
                      ),
                      true
                    )}
                  </Text>
                </InlineGrid>

                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Expire at:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {getFormattedDateTime(
                      ConvertTimestamp(etsyProductData?.ending_timestamp),
                      true
                    )}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    SKUs:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.skus?.map((item: any) => item).join(", ")}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Auto Renew:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.should_auto_renew
                      ? "Automatic"
                      : "Manual"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Private:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.is_private ? "Yes" : "No"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Featured Rank:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.featured_rank ?? "N/A"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Views:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.views ?? "N/A"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Language:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.language
                      ? etsyProductData?.language
                      : "N/A"}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Listing Type:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.listing_type
                      ? etsyProductData?.listing_type
                      : "N/A"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    File Data:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.file_data
                      ? etsyProductData?.file_data
                      : "N/A"}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Num Favorers:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.num_favorers ?? "N/A"}
                  </Text>
                </InlineGrid>
                <InlineGrid
                  gap='100'
                  columns={{
                    md: ["oneThird", "twoThirds"],
                    xs: ["oneHalf", "oneHalf"],
                  }}
                >
                  <Text as={"strong"} variant="headingMd" fontWeight="medium">
                    Non-Taxable:
                  </Text>
                  <Text as={"strong"} variant="headingMd" fontWeight="regular">
                    {etsyProductData?.non_taxable ? "Yes" : "No"}
                  </Text>
                </InlineGrid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                {Object.entries(dimensionsObj)
                  .filter(([keyName, val]) => val !== undefined)
                  .map(([keyName, val]) => (
                    <InlineGrid
                      gap='100'
                      columns={{
                        md: ["oneThird", "twoThirds"],
                        xs: ["oneHalf", "oneHalf"],
                      }}
                    >
                      <Text
                        as={"strong"}
                        variant="headingMd"
                        fontWeight="medium"
                      >
                        {keyName}:
                      </Text>
                      <Text
                        as={"strong"}
                        variant="headingMd"
                        fontWeight="regular"
                      >
                        {val}
                      </Text>
                    </InlineGrid>
                  ))}
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </div>
  ) : (
    <EtsyViewSkeleton />
  );
};

export default DI(EtsyProductView);

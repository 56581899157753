import React, { useEffect, useState } from "react";
import {
  BlockStack,
  Box,
  Checkbox,
  Divider,
  InlineGrid,
  InlineStack,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import { useProductEditContext } from "../ProductEditProvider";
import Attribute from "Src/Component/Profilling/CreateProfile/components/Attribute";
import { deepCloneObj } from "Src/function";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const RecommendeAttributes = ({
  redux: {
    settings: { customSettings },
  },
  t,
}: DIProps) => {
  const {
    profileMapped,
    localProduct,
    recommendedAttributes,
    updateLocalProduct,
    saveLocalProduct,
    metafields: productMetafields,
  } = useProductEditContext();
  const {mdUp,mdDown} = useBreakpoints();

  const [attributesState, setAttributesState] = useState<
    { value: string | string[]; scaleValue?: string; metaKeys?: string }[]
  >([]);

  const toggleProfileLevelData = () => {
    const tempProduct = deepCloneObj(localProduct);
    if (tempProduct?.recommendedAttributes) {
      delete tempProduct.recommendedAttributes;
      mapChanges(undefined, profileMapped.recommendedAttributes);
    } else {
      Object.assign(tempProduct, { recommendedAttributes: {} });
      mapChanges(undefined, {});
    }
    saveLocalProduct(tempProduct);
  };

  const updateAttributeValue = (
    index: number,
    key: "value" | "scaleValue" | "metaKeys",
    val: string | string[]
  ) => {
    if (localProduct?.recommendedAttributes) {
      const tempAttributeState = [...attributesState];
      if (key === "scaleValue" || key === "metaKeys") {
        tempAttributeState[index][key] = val as string;
      } else {
        tempAttributeState[index].value = val;
        tempAttributeState[index].scaleValue =
          recommendedAttributes[index]?.scales?.[0]?.scale_id?.toString() ??
          undefined;
      }
      setAttributesState(tempAttributeState);
      mapChangesToLocalProduct(tempAttributeState);
    }
  };

  const mapChanges = (
    attributesData = recommendedAttributes,
    updatedAttributes = localProduct?.recommendedAttributes
  ) => {
    if (updatedAttributes) {
      const tempAttributeState = attributesData.map((attr) => {
        const tempObj = {
          value: "",
          scaleValue: attr?.scales?.[0]?.scale_id?.toString() ?? undefined,
          metaKeys:
            updatedAttributes?.[attr.property_id]?.meta_keys ?? undefined,
        };
        const editValues = updatedAttributes?.[attr.property_id]?.values;
        if (editValues) {
          if (attr.is_multivalued === "1") {
            tempObj.value = editValues.map((values: string) => {
              const parsedValues = JSON.parse(values);
              const [id, name] = Object.entries(parsedValues)[0];
              return `{CedDQuote@#${id}CedDQuote@#:CedDQuote@#${name}CedDQuote@#}`;
            });
          } else {
            if (attr.possible_values) {
              const parsedValues = JSON.parse(editValues);
              const [id, name] = Object.entries(parsedValues)?.[0];
              tempObj.value = `{CedDQuote@#${id}CedDQuote@#:CedDQuote@#${name}CedDQuote@#}`;
            } else {
              tempObj.value = editValues;
            }
          }
          tempObj.scaleValue = updatedAttributes?.[attr.property_id]?.scale_id;
          if (customSettings?.isMetafieldEnabled) {
            tempObj.metaKeys = updatedAttributes?.[attr.property_id]?.meta_keys;
          }
        }
        return tempObj;
      });
      setAttributesState(tempAttributeState);
      localProduct?.recommendedAttributes &&
        mapChangesToLocalProduct(tempAttributeState);
    }
  };

  const mapChangesToLocalProduct = (tempAttributeState = attributesState) => {
    const attributesObj: any = {};
    recommendedAttributes.forEach((attr, index) => {
      const obj = { values: tempAttributeState?.[index]?.["value"] };

      if (attr.scales) {
        Object.assign(obj, {
          scale_id: tempAttributeState?.[index]?.["scaleValue"],
        });
      }

      if (customSettings?.isMetafieldEnabled) {
        Object.assign(obj, {
          meta_keys: tempAttributeState?.[index]?.["metaKeys"],
        });
      }
      Object.assign(attributesObj, { [attr.property_id]: { ...obj } });
    });
    updateLocalProduct({ recommendedAttributes: attributesObj });
  };

  useEffect(() => {
    if (!localProduct?.recommendedAttributes) {
      mapChanges(undefined, profileMapped.recommendedAttributes);
    } else {
      mapChanges();
    }
  }, []);

  return (
    <BlockStack gap={"400"}>
      <InlineStack align="space-between">
        <Text as={"strong"} variant="headingMd" fontWeight="medium">
          {t("RECOMMENDED_ATTRIBUTES")}
        </Text>
        <Checkbox
          label={t("USE_PROFILE_LEVEL_DATA")}
          checked={!localProduct?.recommendedAttributes}
          onChange={toggleProfileLevelData}
        />
      </InlineStack>
      <div
        className={`recommended_attribute_block ${
          !localProduct?.recommendedAttributes ? "disabled_block" : ""
        }`}
      >
        <BlockStack gap={"200"}>
        {mdUp && <InlineGrid columns={["oneHalf", "oneHalf"]}>
            <Text as="h6" variant="headingMd" fontWeight="semibold">
              {t("ATTRIBUTES")}
            </Text>
            {customSettings?.isMetafieldEnabled ? (
              <InlineGrid columns={["oneHalf", "oneHalf"]}>
                <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("VALUE")}
                </Text>

                <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("SHOPIFY")}
                </Text>
              </InlineGrid>
            ) : (
              <Text as="h6" variant="headingMd" fontWeight="semibold">
                {t("VALUE")}
              </Text>
            )}
          </InlineGrid>}
          {recommendedAttributes.map((attr, index) => {
            return (
                              <InlineGrid columns={mdUp ? 2 : 1} gap={mdDown ? "100" : "300"}>
  {mdDown &&   <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("ATTRIBUTES")}
                </Text>}
                <Text as={"strong"} variant="headingSm" fontWeight="regular">
                  {attr.display_name}
                </Text>
                {mdDown &&   <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("VALUE")}
                  </Text>}

                <Attribute
                  attrObj={attr}
                  value={attributesState?.[index]?.value}
                  onChange={(value) => {
                    updateAttributeValue(index, "value", value);
                  }}
                  scaleValue={attributesState?.[index]?.scaleValue}
                  onScaleChange={(value) => {
                    updateAttributeValue(index, "scaleValue", value);
                  }}
                  metaKeys={attributesState?.[index]?.metaKeys}
                  onMetaKeysChange={(value: string) => {
                    updateAttributeValue(index, "metaKeys", value);
                  }}
                  disabled={!localProduct?.recommendedAttributes}
                  isMetafieldEnabled={customSettings?.isMetafieldEnabled}
                  metafields={productMetafields}
                />
          {mdDown  && index !== recommendedAttributes.length - 1 && (<Box paddingBlockStart='300' paddingBlockEnd="200"><Divider/></Box>)}
              </InlineGrid>
            );
          })}
        </BlockStack>
      </div>
    </BlockStack>
  );
};

export default DI(RecommendeAttributes);

import { Card, InlineGrid, Select, TextField } from "@shopify/polaris";
import React from "react";
import { yesNoOptions } from "../../../../Constant/SelectOptions";
import { useProfileContext } from "../ProfileProvider";
import { BasicMappingKeys } from "Src/Interface/@Profile";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";

const Personalization = ({ t }: DIProps) => {
  const { basicMapping, updateBasicMapping, error, removeError, saveError } =
    useProfileContext();
  const { routeData } = useRoutesContext();
  const updatePersonalization = (key: BasicMappingKeys, value: string) => {
    let tempValue = value;
    if (key === "personalization_char_count_max") {
      if (parseInt(tempValue) < 1 || parseInt(tempValue) > 1024) {
        saveError({ [key]: t("PLEASE_CHOOSE_A_NUMBER_BETWEEN_1AND_1024") });
      } else {
        removeError(key);
      }
      tempValue = value !== "" ? parseInt(value).toString() : value;
    } else {
      removeError(key);
    }
    updateBasicMapping(key, tempValue);
  };

  return (
    <Card>
      <InlineGrid gap="200">
        <Select
          disabled={routeData.data.type === "view"}
          id="personalization_is_required"
          label={t("PERSONALIZATION_IS_REQ")}
          placeholder="Select"
          options={yesNoOptions}
          value={basicMapping.personalization_is_required}
          onChange={(val) => {
            updatePersonalization("personalization_is_required", val);
          }}
          helpText={t("WHEN_YES_THIS_LISTING_REQ_PERSONALIZATION")}
        />
        <TextField
          disabled={routeData.data.type === "view"}
          id="personalization_char_count_max"
          label={t("PERSONALIZATION_CHAR_LIMIT")}
          placeholder={t("ENTER_LIMIT")}
          value={basicMapping.personalization_char_count_max}
          onChange={(val) => {
            updatePersonalization("personalization_char_count_max", val);
          }}
          helpText={t("SET_A_CHAR_LIMIT_FOR_OPTIONS_LIKE_COLOR")}
          autoComplete="off"
          error={error?.["personalization_char_count_max"]}
          type="number"
        />

        <TextField
          disabled={routeData.data.type === "view"}
          id="personalization_instructions"
          label={t("PERSONALIZATION_INSTRUCTION")}
          placeholder={t("ENTER_INSTRUCTION")}
          value={basicMapping.personalization_instructions}
          onChange={(val) => {
            updatePersonalization("personalization_instructions", val);
          }}
          helpText={t("ENTER_INSTRUCTION_EXAMPLE")}
          autoComplete="off"
          error={error?.["personalization_instructions"]}
        />
      </InlineGrid>
    </Card>
  );
};

export default DI(Personalization);

import { IndexTable, SkeletonBodyText, SkeletonThumbnail, useIndexResourceState } from '@shopify/polaris'
import React from 'react'
// import './skeleton.css'

export default function RuleGroupResultModalSkeleton() {

    const gridSkeletonRow = {
        id: '1',
        images: <SkeletonThumbnail size='medium' />,
        Title: <div className='failed-order-skelton' style={{ 'width': '150px' }}><SkeletonBodyText lines={1} /></div>,
        ProductID: <div className='failed-order-skelton' style={{ 'width': '60px' }}><SkeletonBodyText lines={1} /></div>,
    }

    const rowMarkupData = Array.from({ length: 10 }, () => gridSkeletonRow);
    const { selectedResources, allResourcesSelected, handleSelectionChange } =
        useIndexResourceState(rowMarkupData);

    const rowMarkup = rowMarkupData.map((item, index) => (
        <IndexTable.Row
            id={item.id}
            key={index}
            position={index}
        >
            <IndexTable.Cell className="image-cell-view-products">
                {item.images}
            </IndexTable.Cell>
            <IndexTable.Cell>
                {item.Title}
            </IndexTable.Cell>
            <IndexTable.Cell>{item.ProductID}</IndexTable.Cell>
        </IndexTable.Row>
    ),
    );

    return (
        <div className='skeleton_for_checkbox'>
            <IndexTable
                selectable
                itemCount={rowMarkupData.length}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                    { title: "Images" },
                    { title: "Title" },
                    { title: "Product ID" },
                ]}
            >
                {rowMarkup}
            </IndexTable>
        </div>
    )
}

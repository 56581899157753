import { Tooltip, Text } from "@shopify/polaris";
import React from "react";

export const getTrimLength = (
  stringData: string,
  charLimit: number,
  fontWeight?: any,
  as?: any,
  variant?: any
) => {
  const STRING_DATA = stringData || "";
  if (STRING_DATA.length > charLimit) {
    const update = STRING_DATA.substring(0, charLimit) + "...";
    const updatedValue = (
      <Tooltip content={STRING_DATA}>
        <Text fontWeight={fontWeight} as={as} variant={variant}>
          {STRING_DATA.substring(0, charLimit) + "..."}
        </Text>
      </Tooltip>
    );
    return updatedValue;
  }
  return (
    <Text fontWeight={fontWeight} as={as} variant={variant}>
      {STRING_DATA}
    </Text>
  );
};

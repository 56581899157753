import {
  AutoSelection,
  BlockStack,
  Combobox,
  InlineStack,
  Listbox,
  Tag,
  Text,
} from "@shopify/polaris";
import React, { ReactNode, useMemo, useState } from "react";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { DI } from "Src/core";
import { DIProps, ObjStrI, SelectOptionI } from "Src/Interface/@core";

interface PropsI extends DIProps {
  id?: string;
  label?: string | ReactNode;
  description?: string;
  placeholder?: string;
  helpText?: string | ReactNode;
  options: SelectOptionI[];
  value: string[];
  onChange: (param: string[]) => void;
  noCustomValue?: boolean;
  validationObject?: { maxLength: number; regex?: RegExp; charLength?: number };
  hideExtraOptions?: boolean;
  error?: string;
  loading?: boolean;
  disabled?: boolean;
}

const CustomMultiSelect = ({
  id,
  label,
  description,
  placeholder,
  helpText,
  options,
  value,
  onChange,
  noCustomValue,
  validationObject,
  hideExtraOptions = false,
  error,
  loading = false,
  disabled = false,
  t,
}: PropsI) => {
  const [customTag, setCustomTag] = useState("");
  const { routeData } = useRoutesContext();
  const valueMap: ObjStrI = options?.reduce((prev, curr) => {
    return { ...prev, [curr.value]: curr.label };
  }, {});
  const tagsSelected: string[] = useMemo(
    () => (value ? value?.map((val) => valueMap?.[val] ?? val) : []),
    [value, valueMap]
  );

  // toggle selected tags
  const updateTags = (selected: string) => {
    let tempSelected = selected;

    if (validationObject?.regex)
      tempSelected = selected.replace(validationObject.regex, "");

    const nextSelectedTags = new Set([...value]);
    if (options.length > 0 && value.includes(tempSelected)) {
      nextSelectedTags.delete(tempSelected);
    } else {
      nextSelectedTags.add(tempSelected);
    }
    //restrict user from enter more than maximum tags allowed
    if (
      validationObject?.maxLength &&
      tagsSelected.length === validationObject.maxLength
    ) {
      return;
    }

    setCustomTag("");
    if (tempSelected?.trim().length > 0) {
      onChange(Array.from(nextSelectedTags));
    }
  };

  const removeTag = (tagToBeDeleted: string) => {
    let tagValue: string | undefined = tagToBeDeleted;
    if (options.length > 0) {
      tagValue =
        options.find((option) => option.label === tagToBeDeleted)?.value ??
        tagToBeDeleted;
    }
    onChange(value.filter((tag) => tag !== tagValue));
  };

  const hideOptionsIncaseOfMaxSelected =
    hideExtraOptions &&
    validationObject?.maxLength &&
    tagsSelected.length === validationObject.maxLength;

  const tagsOptionMarkup =
    options.length > 0 ? (
      <Listbox
        autoSelection={AutoSelection.None}
        onSelect={(tag) => {
          updateTags(tag);
        }}
      >
        {options
          .filter((option) =>
            customTag
              ? option.label.toLowerCase().includes(customTag.toLowerCase())
              : true
          )
          .map((option) => {
            return (
              <Listbox.Option
                key={option.value}
                value={option.value}
                selected={tagsSelected.includes(option.label)}
                accessibilityLabel={option.label}
              >
                <Listbox.TextOption
                  selected={tagsSelected.includes(option.label)}
                >
                  {option.label}
                </Listbox.TextOption>
              </Listbox.Option>
            );
          })}
      </Listbox>
    ) : null;

  return (
    <BlockStack gap={"100"}>
      {label ? (
        <Text as="h6" variant="bodyMd">
          {label}
        </Text>
      ) : null}
      {description ? <Text as="p">{description}</Text> : null}
      <div
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (noCustomValue) return;
            e.stopPropagation();
            updateTags(customTag);
          }
        }}
      >
        <Combobox
          allowMultiple
          activator={
            <Combobox.TextField
              id={id}
              disabled={disabled || routeData.data.type === "view"}
              placeholder={placeholder}
              helpText={helpText}
              autoComplete="off"
              label={t("SEARCH_TAGS")}
              labelHidden
              value={customTag}
              onChange={(tag) => {
                if (
                  validationObject?.maxLength !== tagsSelected.length &&
                  (!validationObject?.charLength ||
                    validationObject?.charLength >= tag.length)
                ) {
                  setCustomTag(tag);
                }
              }}
              onBlur={() => {
                if (noCustomValue) return;
                updateTags(customTag);
              }}
              error={error}
              suggestion={customTag}
              loading={loading}
              verticalContent={
                tagsSelected.length > 0 ? (
                  <InlineStack gap="100">
                    {tagsSelected.map((tag) => (
                      <Tag
                        key={`option-${tag}`}
                        onRemove={() => removeTag(tag)}
                        disabled={disabled || routeData.data.type === "view"}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </InlineStack>
                ) : null
              }
            />
          }
        >
          {hideOptionsIncaseOfMaxSelected ? null : tagsOptionMarkup}
        </Combobox>
      </div>
    </BlockStack>
  );
};

export default DI(CustomMultiSelect);

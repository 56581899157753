import { BlockStack, Box, Card, Divider, FormLayout, InlineStack, Layout, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail, useBreakpoints } from '@shopify/polaris'
import React from 'react'

const CreateProfileSkeleton = () => {
        const {mdUp} = useBreakpoints();
    return (

        <div className="custom_layout_width rule_preview_sticky">
            <Page fullWidth>
                <Layout>
                    <Layout.Section>
                        <BlockStack gap={"400"}>
                            <Card>
                                <BlockStack gap={"200"}>
                                    <BlockStack gap={"400"}>
                                        <div className='skeleton-height-18' style={{ "width": "150px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <Divider />
                                    </BlockStack>
                                    <div className='skeleton-height-32px' style={{ "width": "100%" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </BlockStack>
                            </Card>
                            <Card>
                                <BlockStack gap={"400"}>
                                    <BlockStack gap={"400"}>
                                        <div className='skeleton-height-11' style={{ "width": "150px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <Divider />
                                    </BlockStack>
                                    <BlockStack gap={"400"}>
                                        <div style={{ "width": "150px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={2} />
                                        <div className='skeleton-height-32px' style={{ "width": "100%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </BlockStack>
                                    <Divider />
                                    <BlockStack gap={"400"}>
                                        <div style={{ "width": "150px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />
                                        <InlineStack blockAlign='center' gap={"300"}>
                                            <div style={{ "width": "50px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <InlineStack gap={"200"} blockAlign='center'>
                                                <div style={{ "width": "11px" }} className='skeleton-height-11'>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                                <div style={{ "width": "50px" }}>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                            </InlineStack>

                                        </InlineStack>
                                        <Card>
                                            <BlockStack gap={"300"}>
                                                <InlineStack  gap="200" wrap={mdUp ? false : true} align='end'>
                                                <Box width="100%">
                                                        <FormLayout>
                                                            <FormLayout.Group condensed={mdUp ? true : false}>
                                                                <div className='skeleton-height-32px'>
                                                                    <SkeletonBodyText lines={1} />
                                                                </div>
                                                                <div className='skeleton-height-32px'>
                                                                    <SkeletonBodyText lines={1} />
                                                                </div>
                                                                <div className='skeleton-height-32px'>
                                                                    <SkeletonBodyText lines={1} />
                                                                </div>
                                                            </FormLayout.Group>
                                                        </FormLayout>
                                                    </Box>
                                                       <InlineStack align='end'>
                                                       <div style={{ "width": "100px" }}>
                                                        <SkeletonDisplayText size='small' />
                                                    </div>
                                                    </InlineStack>
                                                </InlineStack>
                                                <Divider />
                                                <InlineStack align='end' blockAlign='center'>
                                                   
                                                    <div className='skeleton-height-32px' style={{ "width": "200px" }}>
                                                    <SkeletonBodyText lines={1} />
                                                    </div>
                                                </InlineStack>
                                            </BlockStack>
                                        </Card>
                                    </BlockStack>
                                    <Divider />
                                    <BlockStack gap={"400"}>
                                        <div style={{ "width": "150px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />

                                        <Card>
                                            <BlockStack gap={"300"}>

                                                <FormLayout>
                                                <FormLayout.Group condensed={mdUp ? true : false}>
                                                <BlockStack gap={"200"}>
                                                            <div style={{ "width": "100px" }}>
                                                                <SkeletonBodyText lines={1} />
                                                            </div>
                                                            <div className='skeleton-height-32px'>
                                                                <SkeletonBodyText lines={1} />
                                                            </div>
                                                        </BlockStack>
                                                        <BlockStack gap={"200"}>
                                                            <div style={{ "width": "100px" }}>
                                                                <SkeletonBodyText lines={1} />
                                                            </div>
                                                            <div className='skeleton-height-32px'>
                                                                <SkeletonBodyText lines={1} />
                                                            </div>
                                                        </BlockStack>
                                                        <BlockStack gap={"200"}>
                                                            <div style={{ "width": "100px" }}>
                                                                <SkeletonBodyText lines={1} />
                                                            </div>
                                                            <div className='skeleton-height-32px'>
                                                                <SkeletonBodyText lines={1} />
                                                            </div>
                                                        </BlockStack>
                                                    </FormLayout.Group>
                                                </FormLayout>
                                            </BlockStack>
                                        </Card>
                                    </BlockStack>
                                    {Array.from({ length: 8 }, () =>
                                        <BlockStack gap={"400"}>
                                            <Divider />
                                            <BlockStack gap={"300"}>
                                                <div style={{ "width": "100px" }}>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                                <div className='skeleton-height-32px'>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                                <div style={{ "width": "75%" }}>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                            </BlockStack>
                                        </BlockStack>
                                    )}

                                </BlockStack>
                            </Card>
                            <Card>
                                <BlockStack gap={"400"}>
                                    <BlockStack gap={"400"}>
                                        <div className='skeleton-height-11' style={{ "width": "150px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </BlockStack>
                                    {Array.from({ length: 2 }, () =>
                                        <BlockStack gap={"400"}>
                                            <Divider />
                                            <BlockStack gap={"300"}>
                                                <div style={{ "width": "100px" }}>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                                <div className='skeleton-height-32px'>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                                <div style={{ "width": "75%" }}>
                                                    <SkeletonBodyText lines={1} />
                                                </div>
                                            </BlockStack>
                                        </BlockStack>
                                    )}
                                </BlockStack>
                            </Card>
                        </BlockStack>
                    </Layout.Section>
                    <Layout.Section variant="oneThird">

                        <div className="custom_card_profile">

                            <Card>
                                <BlockStack gap={"300"}>
                                    <div style={{ "width": "120px" }} className='skeleton-height-11'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <BlockStack gap={"300"}>

                                        {Array.from({ length: 4 }, () =>
                                            <div className="custom_accordion_Card">
                                                <Card roundedAbove="sm">
                                                    <InlineStack blockAlign='center' align='space-between'>
                                                        <div style={{ "width": "120px" }} className='skeleton-height-20'>
                                                            <SkeletonBodyText lines={1} />
                                                        </div>
                                                        <div style={{ "width": "16px" }} className='custom-height_skel'>
                                                            <SkeletonBodyText lines={1} />
                                                        </div>
                                                    </InlineStack>
                                                </Card>
                                            </div>
                                        )}

                                    </BlockStack>
                                </BlockStack>
                            </Card>

                        </div>

                    </Layout.Section>
                </Layout>
            </Page>
        </div>

    )
}

export default CreateProfileSkeleton

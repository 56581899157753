import { BlockStack, Box, Tabs } from "@shopify/polaris";
import React, { useCallback } from "react";

import Announcements from "./Announcements";
import { DI } from "Src/core";
import { RecentCompletedActivities } from "../Helper";
import GeneralNotificationSkeleton from "../Skeleton/GeneralNotificationSkeleton";
import { DIProps } from "Src/Interface/@core";
import RunningActivity from "../RunningActivity";

const NotificationsTab = (
  _props: {
    AllActivites: any;
    popover: boolean;
    AllAnnouncement: any;
    selectedTab: number;
    setSelectedTab: (e: number) => void;
  } & DIProps
) => {
  const {
    popover,
    AllActivites,
    AllAnnouncement,
    selectedTab,
    setSelectedTab,
    t,
  } = _props;

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelectedTab(selectedTabIndex),
    []
  );

  const tabs: any = [
    {
      id: "general",
      content: t("GENERAL"),
    },
    {
      id: "announcements",
      content: t("ANNOUNCEMENTS"),
    },
  ];

  return (
    <div className="activities-tabs">
      <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
        {tabs[selectedTab].title}
        {selectedTab === 0 ? (
          <Box paddingBlockStart={popover ? "400" : "500"}>
            {AllActivites ? (
              <BlockStack gap={popover ? "0" : "0"}>
                {popover && (
                  // <div className="popover_activity_item">
                    <RunningActivity
                      ongoing_activity={AllActivites?.ongoing_activity}
                    />
                  // </div>
                )}
                {RecentCompletedActivities(
                  _props,
                  AllActivites?.completed_activity,
                  undefined,
                  popover
                )}
              </BlockStack>
            ) : (
              <GeneralNotificationSkeleton />
            )}
          </Box>
        ) : (
          <Announcements popover={popover} AllAnnouncement={AllAnnouncement} />
        )}
      </Tabs>
    </div>
  );
};

export default DI(NotificationsTab);

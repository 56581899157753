import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Icon,
  IndexTable,
  InlineGrid,
  InlineStack,
  Select,
  Text,
  TextField,
  useBreakpoints,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteIcon, PlusIcon } from "@shopify/polaris-icons";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { DI } from "Src/core";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { environment } from "Src/environment/environment";
import { getQueryStr } from "Src/core/_Services";
import { etsyShipingMethodOptions } from "Src/Constant/SelectOptions";

const ShippingMethodMapping = ({ request: { GET, DELETE, POST }, t }: DIProps) => {
  const { mdUp, mdDown } = useBreakpoints();
  const [shippingMethodState, setShippingMethodState] = useState<{
    loading: boolean;
    data: ObjStrI[];
  }>({
    loading: false,
    data: [],
  });
  const [deleteMethodState, setDeletMethodState] = useState({
    loading: false,
    index: -1,
  });
  const [addModalState, setAddModalState] = useState({
    isOpen: false,
    loading: false,
    etsyMethod: "",
    shopifyMethod: "",
  });

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(shippingMethodState.data);

  const toggleAddModal = () => {
    setAddModalState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
      etsyMethod: prev.isOpen ? "" : prev.etsyMethod,
      shopifyMethod: prev.isOpen ? "" : prev.shopifyMethod,
    }));
  };

  const fetchShippingMap = () => {
    setShippingMethodState((prev) => ({ ...prev, loading: true }));
    GET(apiUrls.getMapShipping)
      .then((res) => {
        if (res?.success) {
          setShippingMethodState((prev) => ({ ...prev, data: res?.data }));
        } else {
          ToastAppBridge(res?.message ?? res?.msg, { isError: true });
        }
      })
      .finally(() => {
        setShippingMethodState((prev) => ({ ...prev, loading: true }));
      });
  };

  const addShippingMap = () => {
    setAddModalState((prev) => ({ ...prev, loading: true }));
    const payload = {
      shipping_method_as_template_name: "product_delete_carrier",
      etsy_map_method: addModalState.etsyMethod,
      shopify_map_method: addModalState.shopifyMethod,
    };
    POST(apiUrls.saveMapShipping, payload)
      .then((res) => {
        if (res?.success) {
          fetchShippingMap();
          toggleAddModal();
        } else {
          ToastAppBridge(res?.message ?? res?.msg, { isError: true });
        }
      })
      .finally(() => {
        setAddModalState((prev) => ({ ...prev, loading: false }));
      });
  };

  const deleteShippingMap = (index: number) => {
    setDeletMethodState((prev) => ({ ...prev, loading: true, index }));
    const detailsObj = shippingMethodState.data[index];
    const params = {
      id: detailsObj?.id,
      method_name: detailsObj?.etsy_shipping_method,
    };
    let paramsString = getQueryStr(apiUrls.deleteShippingMethod, params);
    let endpoint =
      environment.API_ENDPOINT + apiUrls.deleteShippingMethod + paramsString;
    const payload = {
      carrier_map: "product_delete_carrier",
      carrier_service: detailsObj?.shopify_shipping_method,
      etsy_carrier_name: detailsObj?.etsy_shipping_method,
      shipment_default_carrier: "",
    };
    DELETE(endpoint, payload, true)
      .then((res) => {
        if (res?.success) {
          fetchShippingMap();
        }
        ToastAppBridge(res?.message ?? res?.msg, { isError: !res?.success });
      })
      .finally(() => {
        setDeletMethodState((prev) => ({ ...prev, loading: true, index: -1 }));
      });
  };

  useEffect(() => {
    fetchShippingMap();
  }, []);

  const rowMarkup = shippingMethodState.data.map(
    ({ id, etsy_shipping_method, shopify_shipping_method }, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{etsy_shipping_method}</IndexTable.Cell>

        <IndexTable.Cell>{shopify_shipping_method}</IndexTable.Cell>
        <IndexTable.Cell>
          <Button
            tone="critical"
            variant="plain"
            icon={<Icon source={DeleteIcon} tone="critical" />}
            onClick={() => deleteShippingMap(index)}
            loading={
              deleteMethodState.index === index && deleteMethodState.loading
            }
          ></Button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );


  const rowMarkupMobile = shippingMethodState.data.map(
    ({ id, etsy_shipping_method, shopify_shipping_method }, index) => (
      <Box id={id}
        key={id}
      >
          <BlockStack gap='200'>
          <BlockStack gap='100'>
            <InlineGrid columns={2} gap='100'>
              <Text as="p" fontWeight="medium">Etsy Shipping:</Text>
              {etsy_shipping_method}
            </InlineGrid>
            <InlineGrid columns={2} gap='100'>
              <Text as="p" fontWeight="medium">Shopify Shipping:</Text>
              {shopify_shipping_method}
            </InlineGrid>
          </BlockStack>
          <InlineStack align="end">
            <Button
              tone="critical"
              variant="plain"
              children="Delete"
              onClick={() => deleteShippingMap(index)}
              loading={
                deleteMethodState.index === index && deleteMethodState.loading
              }
            ></Button>
          </InlineStack>
          </BlockStack>
          {mdDown && (<Box paddingBlockStart='200' paddingBlockEnd="200"><Divider /></Box>)}
      </Box>
    )
  );

  return (
    <>
      {shippingMethodState.data.length > 0 ? (
        <Card roundedAbove="xs">
          <BlockStack gap="300">
            <BlockStack gap={mdUp ? "200" : "0"}>
              {mdUp ? <Card padding="0">

                <IndexTable
                  itemCount={shippingMethodState.data.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  selectable={false}
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: t("ETSY_SHIPPING_METHOD") },
                    { title: t("SHOPIFY_SHIPPING_METHOD") },
                    { title: t("ACTION") },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

              </Card>
                : rowMarkupMobile
              }
            {mdUp &&  <Divider />}
            </BlockStack>

            <InlineStack align="end">
              <Button
                onClick={toggleAddModal}
                icon={<Icon source={PlusIcon} tone="base" />}
              >
                {t("ADD_METHOD_MAPPING")}
              </Button>
            </InlineStack>
          </BlockStack>
        </Card>
      ) : (
        <InlineStack align="end">
          <Button
            onClick={toggleAddModal}
            icon={<Icon source={PlusIcon} tone="base" />}
          >
            {t("ADD_METHOD_MAPPING")}
          </Button>
        </InlineStack>
      )}
      {addModalState.isOpen && (
        <ModalAppBridge
          title={t("METHOD_MAPPING")}
          footer={
            <>
              <button
                variant="primary"
                onClick={addShippingMap}
                loading={addModalState.loading && "true"}
                disabled={
                  addModalState.etsyMethod === "" ||
                  addModalState.shopifyMethod === ""
                }
              >
                {t("MAP_SHIPPING_METHOD")}
              </button>
              <button onClick={toggleAddModal}>{t("CANCEL")}</button>
            </>
          }
          id="add-shipping-method"
          open={addModalState.isOpen}
          onHide={toggleAddModal}
          body={
            <Box padding="400">
              <InlineGrid columns={{ sm: "2" }} gap="200">
                <Select
                  requiredIndicator
                  placeholder={t("SELECT")}
                  label={t("ETSY_SHIPPING_METHOD")}
                  options={etsyShipingMethodOptions}
                  onChange={(val) =>
                    setAddModalState((prev) => ({ ...prev, etsyMethod: val }))
                  }
                  value={addModalState.etsyMethod}
                />

                <TextField
                  autoComplete=""
                  label={t("SHOPIFY_SHIPPING_METHOD")}
                  requiredIndicator
                  placeholder={t("ENTER_SHOPIFY_SHIPPING_METHOD")}
                  value={addModalState.shopifyMethod}
                  onChange={(val) =>
                    setAddModalState((prev) => ({
                      ...prev,
                      shopifyMethod: val,
                    }))
                  }
                />
              </InlineGrid>
            </Box>
          }
        />
      )}
    </>
  );
};

export default DI(ShippingMethodMapping);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalState } from "Src/core/_Services";
import { AuthInitialStateI } from "Src/Interface/@core";

const initialState: AuthInitialStateI = {
  isLoggedIn: false,
  authToken: undefined,
  isAdmin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{ authToken: string; isAdmin: boolean }>
    ) => {
      state.isLoggedIn = true;
      state.isAdmin = action.payload.isAdmin;
      state.authToken = action.payload.authToken;
      GlobalState.set()("auth_token", action.payload.authToken);
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.isAdmin = false;
      state.authToken = undefined;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;

import React, { ReactNode, useMemo, useState } from "react";
import {
  BlockStack,
  Button,
  Card,
  Divider,
  InlineStack,
  Link,
  Select,
  Text,
} from "@shopify/polaris";
import { useProfileContext } from "../ProfileProvider";
import { DIProps, ObjStrI, SelectOptionI } from "Src/Interface/@core";
import { renewalOptions, yesNoOptions } from "Src/Constant/SelectOptions";
import CustomMultiSelect from "./CustomMultiSelect";
import { formatTemplateOptions } from "../CreateProfileHelper";
import AboutListing from "./AboutListing";
import EtsyCategoryName from "./EtsyCategoryName";
import RuleGroup from "./RuleGroup";
import VariationMapping from "./VariationMapping";
import { DI } from "Src/core";
import { BasicMappingKeys } from "Src/Interface/@Profile";
import AttributesGroup from "./AttributesGroup";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import Personalization from "./Personalization";
import CreateShopSectionModal from "Src/Component/Templates/components/CreateShopSectionModal";
import RequiredAttributes from "./RequiredAttributes";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { apiUrls } from "Src/Constant/url";
import { Trans } from "react-i18next";

interface SelectDataI {
  label: string;
  placeholder: string;
  helpText: string | ReactNode;
  options: SelectOptionI[];
  validationObject?: { maxLength: number; regex: RegExp };
}

interface BasicSelectDataI extends SelectDataI {
  isMultiSelect: boolean;
  keyName: BasicMappingKeys;
  noCustomValue?: boolean;
}

interface TemplatesSelectDataI extends SelectDataI {
  keyName: "shipping_templates" | "policy_templates";
}

type TemplateKeysI = "productionPartners" | "shopSections" | "shippingTemplates" | "policyTemplates" 

const BasicMapping = ({ t, request: { GET } }: DIProps) => {
  const {
    basicMapping,
    variationAttributes,
    updateBasicMapping,
    templates,
    profileData,
    updateProfileData,
    saveTemplates,
    error,
    removeError,
    requiredAttributes,
    simpleAttributes,
    setCreateModal,
    extraTemplateOptions,
    isDIGITAL,
  } = useProfileContext();
  const { routeData } = useRoutesContext();
  const [isShopSectionModalOpen, setIsShopSectionModalOpen] = useState(false);
  const [extraShopSections, setExtraShopSections] = useState<ObjStrI>({});
  const [fetchLoading, setFetchLoading] = useState<{
    [key in TemplateKeysI]: boolean;
  }>({
    shopSections: false,
    productionPartners: false,
    policyTemplates: false,
    shippingTemplates: false,
  });
  const shippingTemplateOptions: SelectOptionI[] = useMemo(
    () => [
      ...formatTemplateOptions(profileData.profile_template?.[3] ?? []),
      ...extraTemplateOptions.shipping,
    ],
    [profileData.profile_template?.[3], extraTemplateOptions.shipping]
  );

  const policyTemplateOptions: SelectOptionI[] = useMemo(
    () => [
      ...formatTemplateOptions(profileData.profile_template?.[5] ?? []),
      ...extraTemplateOptions.policy,
    ],
    [profileData.profile_template?.[5], extraTemplateOptions.policy]
  );

  const productionPartnersOptions: SelectOptionI[] = useMemo(
    () => formatTemplateOptions(profileData.profile_template?.[4] ?? []),
    [profileData.profile_template?.[4]]
  );

  const shopSectionsOptions: SelectOptionI[] = useMemo(
    () =>
      formatTemplateOptions({
        ...profileData.profile_template?.[6],
        ...extraShopSections,
      }),
    [profileData.profile_template?.[6], extraShopSections]
  );

  // helper array of object for rendering select components
  const renderBasicSelectData: BasicSelectDataI[] = [
    {
      label: t("LINK_PHOTOS_TO_VARIATIONS"),
      helpText: t("LINK_PHOTOS_TO_VARIATIONS_HELPTXT"),
      options: variationAttributes.map((attr) => ({
        label: attr.display_name,
        value: attr.property_id.toString(),
      })),
      keyName: "image_linking_option",
      placeholder: t("SELECT"),
      isMultiSelect: false,
    },
    {
      label: t("PRODUCTION_PARTNER"),
      placeholder: t("SELECT"),
      helpText: routeData?.data?.type !== "view" && (
        <BlockStack gap="100" as="span">
          <InlineStack blockAlign="center" gap={"100"} as="span">
            <Text as="span" variant="bodyMd">
              {t("TO_FETCH")}{" "}
              <Text as="span" fontWeight="medium">
                {t("PRODUCTION_PARTNER")}
              </Text>
              ,
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                fetchTemplatesFromEtsy("productionPartners");
              }}
              loading={fetchLoading.productionPartners}
            >
              {t("CLICK_HERE")}
            </Button>
          </InlineStack>
          <InlineStack blockAlign="center" gap={"100"} as="span">
            <Text as="span" variant="bodyMd">
            <Trans
                i18nKey="TO_CREATE_NEW_PRODUCTION_PARTNER"
                components={[
                  <Text as="span" fontWeight="medium" key="production">
                    {t("PRODUCTION_PARTNER")}
                  </Text>,
                ]}
              />
            </Text>
            <Link
              url="https://www.etsy.com/your/shops/me/production-partners"
              target="_blank"
            >
              {t("CLICK_HERE")}
            </Link>
          </InlineStack>
        </BlockStack>
      ),
      options: productionPartnersOptions,
      keyName: "production_partner_ids",
      isMultiSelect: true,
      noCustomValue: true,
    },
    {
      label: t("RENEWAL_OPTIONS"),
      placeholder: t("SELECT"),
      helpText: routeData?.data?.type !== "view" && (
        <InlineStack blockAlign="center" gap={"100"}>
          <Text as="p" variant="bodyMd">
            {t("ETSY_CHARGES")},{" "}
          </Text>
          <Link
            url="https://help.etsy.com/hc/en-gb/articles/360000344908-Fees-and-Listing-Multiple-Quantities?segment=selling"
            target="_blank"
          >
            {t("ETSY_CHARGES_TEXT")}
          </Link>
        </InlineStack>
      ),
      options: renewalOptions,
      keyName: "should_auto_renew",
      isMultiSelect: false,
    },
    {
      label: t("SELECT_SHOP_SECTION"),
      placeholder: t("SELECT"),
      helpText: routeData?.data?.type !== "view" && (
        <BlockStack gap="100" as="span">
          <InlineStack blockAlign="center" gap={"100"}>
            <Text as="p" variant="bodyMd">
              {t("TO_FETCH")}{" "}
              <Text as="span" fontWeight="medium">
                {t("SHOP_SECTION")}
              </Text>
              ,
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                fetchTemplatesFromEtsy("shopSections");
              }}
              loading={fetchLoading.shopSections}
            >
              {t("CLICK_HERE")}
            </Button>
          </InlineStack>
          <InlineStack blockAlign="center" gap={"100"}>
            <Text as="p" variant="bodyMd">
              <Trans
                i18nKey="TO_CREATE_SHOP_SECTION"
                components={[
                  <Text as="span" fontWeight="medium" key="shop">
                    {t("SHOP_SECTION")}
                  </Text>,
                ]}
              />
            </Text>
            <Button
              variant="plain"
              external
              onClick={() => {
                setIsShopSectionModalOpen(true);
              }}
            >
              {t("CREATE_NEW_ONE")}
            </Button>
          </InlineStack>
        </BlockStack>
      ),
      isMultiSelect: false,
      keyName: "shopSectionId",
      options: shopSectionsOptions,
    },
    {
      label: t("MATERIALS"),
      placeholder: t("ENTER_MATERIAL_TYPE"),
      helpText: t("MATERIALS_HELPTXT"),
      isMultiSelect: true,
      keyName: "materials",
      options: [],
      validationObject: { maxLength: 13, regex: /[^a-zA-Z0-9 ]/g },
    },
    {
      label: t("STYLES"),
      placeholder: t("ENTER_STYLE_PLACEHOLDER"),
      helpText: t("STYLES_HELPTXT"),
      isMultiSelect: true,
      keyName: "styles",
      options: [],
      validationObject: {
        maxLength: 2,
        regex: /[^\p{L}\p{Nd}\p{Zs}]/u,
      },
    },
    {
      label: t("IS_PERSONALIZE"),
      placeholder: t("SELECT"),
      helpText: t("IS_PERSONALIZE_HELPTXT"),
      isMultiSelect: false,
      keyName: "is_personalizable",
      options: yesNoOptions,
    },
  ];

  const renderTemplateSelectData: TemplatesSelectDataI[] = [
    {
      label: t("SHIPPING_TEMPLATE"),
      placeholder: t("SELECT"),
      helpText: routeData?.data?.type !== "view" && (
        <BlockStack gap="100" as="span">
          <InlineStack blockAlign="center" gap={"100"} as="span">
            <Text as="span" variant="bodyMd">
              {t("TO_FETCH")}{" "}
              <Text as="span" fontWeight="medium">
                {t("SHIPPING_TEMPLATE")}
              </Text>
              ,
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                fetchTemplatesFromEtsy("shippingTemplates");
              }}
              loading={fetchLoading.shippingTemplates}
            >
              {t("CLICK_HERE")}
            </Button>
          </InlineStack>
          <InlineStack as="span" blockAlign="center" gap={"100"}>
            <Text as="span" variant="bodyMd">
              {t("CHOOSE_EXISTING_TEMPLATE_OR", {
                templateName: t("SHIPPING_TEMPLATE").toLowerCase(),
              })}{" "}
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                setCreateModal({
                  isOpen: true,
                  type: "shipping",
                });
              }}
            >
              {t("CREATE_NEW_ONE")}
            </Button>
          </InlineStack>
        </BlockStack>
      ),

      options: shippingTemplateOptions,
      keyName: "shipping_templates",
    },
    {
      label: t("POLICY_TEMPLATE"),
      placeholder: t("SELECT"),
      helpText: routeData?.data?.type !== "view" && (
        <BlockStack gap="100" as="span">
          <InlineStack blockAlign="center" gap={"100"} as="span">
            <Text as="span" variant="bodyMd">
              {t("TO_FETCH")}{" "}
              <Text as="span" fontWeight="medium">
                {t("POLICY_TEMPLATE")}
              </Text>
              ,
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                fetchTemplatesFromEtsy("policyTemplates");
              }}
              loading={fetchLoading.policyTemplates}
            >
              {t("CLICK_HERE")}
            </Button>
          </InlineStack>
          <InlineStack blockAlign="center" as="span" gap={"100"}>
            <Text as="span" variant="bodyMd">
              {t("CHOOSE_EXISTING_TEMPLATE_OR", {
                templateName: t("POLICY_TEMPLATE").toLowerCase(),
              })}{" "}
            </Text>
            <Button
              variant="plain"
              external
              onClick={() => {
                setCreateModal({
                  isOpen: true,
                  type: "policy",
                });
              }}
            >
              {t("CREATE_NEW_ONE")}
            </Button>
          </InlineStack>
        </BlockStack>
      ),

      options: policyTemplateOptions,
      keyName: "policy_templates",
    },
  ];

  const getInputComp = (obj: BasicSelectDataI) => {
    const { isMultiSelect, keyName, noCustomValue, ...selectProps } = obj;
    if (isMultiSelect) {
      return (
        <CustomMultiSelect
          {...selectProps}
          onChange={(val: string[]) => {
            removeError(keyName);
            updateBasicMapping(keyName, val);
          }}
          value={basicMapping?.[keyName] ?? []}
          noCustomValue={noCustomValue}
          id={keyName}
          error={error[keyName]}
        />
      );
    } else {
      return (
        <Select
          {...selectProps}
          onChange={(val) => {
            removeError(keyName);
            updateBasicMapping(keyName, val);
          }}
          disabled={routeData?.data?.type === "view"}
          value={basicMapping?.[keyName] ?? ""}
          id={keyName}
          error={error[keyName]}
        />
      );
    }
  };

  function fetchTemplatesFromEtsy(type: TemplateKeysI) {
    const endpointsObj: { [key in TemplateKeysI]: string } = {
      policyTemplates: apiUrls.fetchReturnPolicies,
      productionPartners: apiUrls.fetchProductionPartners,
      shippingTemplates: apiUrls.fetchShippingTemplates,
      shopSections: apiUrls.fetchShopSections,
    };
    setFetchLoading((prev) => ({ ...prev, [type]: true }));
    GET(endpointsObj[type])
      .then(async (res) => {
        if (res?.success) {
          await GET(apiUrls.addNewProfile).then((res) => {
            if (res.success && Object.keys(res?.data ?? {}).length > 0) {
              const keysObj: { [key in TemplateKeysI]: string } = {
                policyTemplates: "5",
                shippingTemplates: "3",
                productionPartners: "4",
                shopSections: "6",
              };
              const profileTemplates = JSON.parse(
                JSON.stringify(profileData?.profile_template ?? {})
              );
              profileTemplates[keysObj[type]] =
                res?.data?.profile_template[keysObj[type]];
              updateProfileData({ profile_template: profileTemplates });
            }
          });
          let msgId =
            type === "productionPartners"
              ? "PRODUCTION_PARTNERS_FETCHED_SUCCESSFULLY"
              : "SHOP_SECTIONS_FETCHED_SUCCESSFULLY";
          ToastAppBridge(t(msgId));
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setFetchLoading((prev) => ({ ...prev, [type]: false }));
      });
  }
  return (
    <Card>
      <BlockStack gap={"300"}>
        <BlockStack gap={"200"}>
          <Text as="h6" variant="headingMd" fontWeight="semibold">
            {t("BASIC_MAPPING")}
          </Text>
          <Divider />
        </BlockStack>
        <EtsyCategoryName />
        {!routeData.data.profileAtUpload &&
          ![0, "0"].includes(profileData?.rule_visible) && <Divider />}
        {!routeData.data.profileAtUpload &&
          ![0, "0"].includes(profileData?.rule_visible) && <RuleGroup />}
        <Divider />
        {basicMapping?.categoryId && (
          <>
            {requiredAttributes.length > 0 && (
              <>
                <RequiredAttributes
                  title={t("REQUIRED_ATTRIBUTES")}
                  description={t("REQUIRED_ATTRIBUTES_DESC")}
                  attributes={requiredAttributes}
                  updateKey="requiredAttributes"
                  isRequired
                  errorsObj={error}
                  removeError={removeError}
                />

                <Divider />
              </>
            )}
            <VariationMapping />

            <Divider />
          </>
        )}
        {basicMapping?.categoryId && (
          <>
            {getInputComp(renderBasicSelectData[0])}
            <Divider />
          </>
        )}
        {basicMapping?.categoryId && (
          <>
            <AttributesGroup
              title={t("RECOMMENDED_ATTRIBUTES")}
              description={t("RECOMMENDED_ATTRIBUTES_DESC")}
              attributes={simpleAttributes}
              updateKey="recommendedAttributes"
              errorsObj={error}
              removeError={removeError}
            />
            <Divider />
          </>
        )}
        {/* <Divider /> */}
        <AboutListing />
        <Divider />
        {renderBasicSelectData.slice(1).map((selectData, index) => (
          <React.Fragment key={selectData.keyName}>
            {getInputComp(selectData)}
            {selectData.keyName === "is_personalizable" &&
            basicMapping.is_personalizable === "1" ? (
              <Personalization />
            ) : null}

            {!(isDIGITAL && renderBasicSelectData.length - 2 === index) && (
              <Divider />
            )}
          </React.Fragment>
        ))}

        {isShopSectionModalOpen && (
          <CreateShopSectionModal
            isOpen={isShopSectionModalOpen}
            toggleShopSectionModal={() =>
              setIsShopSectionModalOpen((prev) => !prev)
            }
            onCreation={(obj) => {
              if (obj) {
                updateBasicMapping("shopSectionId", Object.keys(obj)?.[0]);
                setExtraShopSections((prev) => ({ ...prev, ...obj }));
              }
            }}
          />
        )}

        {!isDIGITAL &&
          renderTemplateSelectData.map(
            ({ helpText, keyName, label, options, placeholder }) => (
              <Select
                error={error[keyName]}
                key={keyName}
                disabled={routeData?.data?.type === "view"}
                requiredIndicator
                label={label}
                placeholder={placeholder}
                helpText={helpText}
                options={options}
                onChange={(val) => {
                  removeError(keyName);
                  saveTemplates(keyName, val);
                }}
                value={templates[keyName]}
                id={keyName}
              />
            )
          )}
      </BlockStack>
    </Card>
  );
};

export default DI(BasicMapping);

import { Spinner } from '@shopify/polaris'
import React, { useEffect } from 'react'

const FullPageLoader = (props: any) => {
  useEffect(() => {
    // Add overflow hidden when component mounts
    document.body.style.overflow = 'hidden';

    // Cleanup: Remove overflow hidden when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, []); // Empty dependency array ensures this runs only on mount/unmount

  return (
    <div className={`fullpage-loader ${props.type === "L1" ? "opacity-loader" : ""}`}>
      <Spinner accessibilityLabel="Loading Spinner" size="large" />
    </div>
  );
}

export default FullPageLoader;

import { deepCloneObj, isConfigChecked } from "Src/function";
import { ObjStrI } from "Src/Interface/@core";

export const updateDependentConfig = (configObj: any) => {
  const tempConfig = deepCloneObj(configObj);
  if (!isConfigChecked(configObj?.sync_product_enable)) {
    tempConfig["sync-fields"] = {};
  }
  if (!isConfigChecked(configObj?.enable_product_management_with_etsy)) {
    tempConfig["etsy-sync-fields"] = {};
  }
  if (!isConfigChecked(configObj?.enable_variation_management_with_etsy)) {
    tempConfig["etsy-variation-sync-fields"] = {};
  }
  if (
    !isConfigChecked(
      configObj?.["shopify_order_name_as_etsy_receipt_id"]?.["is_enable"]
    )
  ) {
    tempConfig["shopify_order_name_as_etsy_receipt_id"] = { is_enable: "0" };
  }
  if (!isConfigChecked(configObj?.order_management)) {
    tempConfig["order_management_digital"] = "0";
  }
  if (!isConfigChecked(configObj?.order_shipment_management)) {
    tempConfig["order_shipment_without_tracking"] = "0";
    tempConfig["send_bcc"] = "0";
    tempConfig["note_to_buyer"] = "";
  }
  if (!isConfigChecked(configObj?.product_create_enable)) {
    const deleteKeys = [
      "product_create_enable",
      "product_create_type",
      "product_create_options",
      "product_options",
    ];
    deleteKeys.forEach((key) => {
      key in tempConfig && delete tempConfig[key];
    });
  }

  return tempConfig;
};

export const getSavePayload = (localConfig: any, selectedTab: number) => {
  const formIdMap: { [key: number]: string } = {
    0: "tab_etsy_setting",
    1: "tab_prod_setting",
    2: "tab_order_setting",
  };
  const { split_applied_on, ...tempConfig } = localConfig;
  const payload = {
    form_id: formIdMap?.[selectedTab],
    ...updateDependentConfig(tempConfig),
    vat_setting: {
      ...localConfig.vat_setting,
      split_applied_on,
    },
  };

  return payload;
};

export const getSettingsErrors = (configObj: any, errors: ObjStrI) => {
  const errorObj: any = { ...errors };
  if (
    isConfigChecked(configObj?.product_create_enable) &&
    configObj.product_create_type === "custom"
  ) {
    if (!configObj?.product_create_options) {
      Object.assign(errorObj, { product_create_options: true });
    }
    if (
      !(
        Array.isArray(configObj?.product_options) &&
        configObj?.product_options?.length > 0
      )
    ) {
      Object.assign(errorObj, { product_options: true });
    }
  }
  return errorObj;
};

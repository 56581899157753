import React, { createContext, ReactNode, useContext } from "react";
import createApp, { ClientApplication, AppConfigV2 } from "@shopify/app-bridge";
import { GlobalState } from "./core/_Services";

interface ShopifyContextValue {
  app: ClientApplication | null;
}

const ShopifyContext = createContext<ShopifyContextValue>({ app: null });

interface ShopifyProviderProps {
  children: ReactNode;
  apiKey: string;
}

const ShopifyProvider: React.FC<ShopifyProviderProps> = ({
  children,
  apiKey,
}) => {
  const embed = GlobalState.get()("isEmbed");
  const query = new URLSearchParams(window.location.search);

  if (query.get("data[bearer_token]") === null && embed === "false") {
    return children;
  }
  const host = GlobalState.get()("host");
  const config: AppConfigV2 = {
    apiKey,
    host,
    forceRedirect: true,
  };

  const app = createApp(config);

  return (
    <ShopifyContext.Provider value={{ app }}>
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopify = () => useContext(ShopifyContext);

export default ShopifyProvider;

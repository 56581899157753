import { Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail, useBreakpoints } from '@shopify/polaris'
import React from 'react'
import ProductEditSkeleton from './ProductEditSkeleton'

const WithPageHeaderProductEditSkeleton = () => {
    const { mdDown } = useBreakpoints()
    return (
        <div>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'200'}>
                <div className="custom_layout_width">
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>
                            {mdDown ? (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <InlineStack gap={"100"}>
                                        <SkeletonThumbnail size='extraSmall' />
                                        <SkeletonThumbnail size='small' />
                                        <div style={{ "width": "50px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </InlineStack>
                                    <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                    <InlineStack gap={"200"}>
                                        <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </InlineStack>
                                </InlineStack>
                            ) : (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <InlineStack gap={"100"}>
                                        <SkeletonThumbnail size='extraSmall' />
                                        <SkeletonThumbnail size='small' />
                                        <div style={{ "width": "100px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </InlineStack>
                                    <InlineStack gap={"200"}>

                                        <div style={{ "width": "28px" }}>
                                            <SkeletonDisplayText size='small' />
                                        </div>
                                    </InlineStack>
                                </InlineStack>
                            )}

                        </Page>
                    </div>
                </div>
            </Box>
            <Box>
                <div className="custom_layout_width">
                    <Page fullWidth>
                        <ProductEditSkeleton />
                    </Page>
                </div>
            </Box>
        </div>
    )
}

export default WithPageHeaderProductEditSkeleton

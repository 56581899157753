import React, { useMemo } from "react";
import {
  BlockStack,
  FormLayout,
  Select,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import CustomMultiSelect from "Src/Component/Profilling/CreateProfile/components/CustomMultiSelect";
import { useProductEditContext } from "../ProductEditProvider";
import {
  dimensionUnitOptions,
  processingTimeOptions,
  weightUnitOptions,
} from "Src/Constant/SelectOptions";
import { regexExpressions } from "Src/Constants";
import { getOptionsFromObj } from "Src/function";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const ConditionalAttributes = ({ t }: DIProps) => {
  const {
    localProduct,
    updateLocalProduct,
    templateOptionsMap,
    errors,
    removeError,
  } = useProductEditContext();
  const {mdUp} = useBreakpoints();

  const shopSectionOptions = useMemo(() => {
    return getOptionsFromObj(templateOptionsMap.shopSections);
  }, [templateOptionsMap.shopSections]);

  const updateDimensionsProcessing = (
    key: string,
    val: string,
    parentKey: "dimensions" | "shipping_data"
  ) => {
    if (
      !key.includes("unit") &&
      !regexExpressions.NUMBER_CHECK_WITH_DOT.test(val as string) &&
      val !== ""
    ) {
      return;
    }
    removeError(key);
    updateLocalProduct({
      [parentKey]: { ...localProduct?.[parentKey], [key]: val },
    });
  };

  return (
    <BlockStack gap={"400"}>
      <Text as={"strong"} variant="headingMd" fontWeight="medium">
        {t("CONDITIONAL_ATTRIBUTES")}
      </Text>
      <BlockStack gap={"300"}>
        <Select
          label={t("SHOP_SECTION")}
          placeholder={t("SELECT")}
          options={shopSectionOptions}
          value={localProduct?.shop_section_id}
          onChange={(val) => updateLocalProduct({ shop_section_id: val })}
          helpText={t("SHOP_SECTION_HELPTEXT")}
        />
        <BlockStack gap={"100"}>
          <Text as="span" variant="bodyMd">
            {t("DIMENSIONS")}
          </Text>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField
                id="length"
                placeholder={t("LENGTH")}
                label=""
                value={localProduct?.dimensions?.length}
                onChange={(val) => {
                  updateDimensionsProcessing("length", val, "dimensions");
                }}
                autoComplete="off"
                error={errors?.length}
              />
              <TextField
                id="width"
                placeholder={t("WIDTH")}
                label=""
                value={localProduct?.dimensions?.width}
                onChange={(val) => {
                  updateDimensionsProcessing("width", val, "dimensions");
                }}
                autoComplete="off"
                error={errors?.width}
              />
              <TextField
                id="height"
                placeholder={t("HEIGHT")}
                label=""
                value={localProduct?.dimensions?.height}
                onChange={(val) => {
                  updateDimensionsProcessing("height", val, "dimensions");
                }}
                autoComplete="off"
                error={errors?.height}
              />
              <Select
                id="unit"
                options={dimensionUnitOptions}
                value={localProduct?.dimensions?.unit}
                onChange={(val) => {
                  updateDimensionsProcessing("unit", val, "dimensions");
                }}
                label={""}
                error={errors?.unit}
                placeholder={t("SELECT_UNIT")}
              />
            </FormLayout.Group>
          </FormLayout>
          <Text as="span" variant="bodyMd" tone="subdued">
            {t("DIMENSIONS_OF_LENGTH_SHOULD_ALWAYS_BE_GREATER_THAN_OTHER")}
          </Text>
        </BlockStack>
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label={t("WEIGHT")}
              value={localProduct?.dimensions?.weight}
              onChange={(val) => {
                updateDimensionsProcessing("weight", val, "dimensions");
              }}
              autoComplete="off"
              placeholder={t("ENTER_VALUE")}
            />
            <Select
              label={t("WEIGHT_UNIT")}
              placeholder={t("SELECT_UNIT")}
              options={weightUnitOptions}
              value={localProduct?.dimensions?.weight_unit}
              onChange={(val) => {
                updateDimensionsProcessing("weight_unit", val, "dimensions");
              }}
            />
          </FormLayout.Group>
        </FormLayout>
        <CustomMultiSelect
          label={t("MATERIALS")}
          placeholder={t("ENTER_MATERIALS")}
          value={
            localProduct?.materials ? localProduct?.materials?.split(",") : []
          }
          onChange={(val) => {
            updateLocalProduct({ materials: val.join(",") });
          }}
          options={[]}
          validationObject={{ maxLength: 13, regex: /[^a-zA-Z0-9 ]/g }}
        />
        <CustomMultiSelect
          label={t("STYLES")}
          placeholder={t("ENTER_STYLES")}
          value={localProduct?.styles ? localProduct?.styles?.split(",") : []}
          onChange={(val) => {
            updateLocalProduct({ styles: val.join(",") });
          }}
          options={[]}
          validationObject={{
            maxLength: 2,
            regex: /[^\p{L}\p{Nd}\p{Zs}]/u,
          }}
        />
        <FormLayout>
        <FormLayout.Group condensed={mdUp ? true : false}>
            <TextField
              id="processing_min"
              label={t("MIN_PROCESSING_TIME")}
              value={localProduct?.shipping_data?.processing_min}
              placeholder={t("ENTER_VALUE")}
              onChange={(val) => {
                removeError("processing_min");
                updateDimensionsProcessing(
                  "processing_min",
                  val,
                  "shipping_data"
                );
              }}
              autoComplete="off"
              error={errors?.processing_min}
            />
            <TextField
              id="processing_max"
              label={t("MAX_PROCESSING_TIME")}
              value={localProduct?.shipping_data?.processing_max}
              placeholder={t("ENTER_VALUE")}
              onChange={(val) => {
                removeError("processing_max");
                updateDimensionsProcessing(
                  "processing_max",
                  val,
                  "shipping_data"
                );
              }}
              autoComplete="off"
              error={errors?.processing_max}
            />
            <Select
              id="processing_unit"
              placeholder={t("SELECT_UNIT")}
              label={t("PROCESSING_TIME_UNIT")}
              options={processingTimeOptions}
              value={localProduct?.shipping_data?.processing_unit}
              onChange={(val) => {
                removeError("processing_unit");
                updateDimensionsProcessing(
                  "processing_unit",
                  val,
                  "shipping_data"
                );
              }}
              error={errors?.processing_unit}
            />
          </FormLayout.Group>
        </FormLayout>
      </BlockStack>
    </BlockStack>
  );
};

export default DI(ConditionalAttributes);

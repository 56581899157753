import { BlockStack, Box, Card, Divider, FormLayout, InlineGrid, InlineStack, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'

const EtsyAttributeSkeleton = () => {
    return (
        <BlockStack gap={"300"}>
            <Box paddingInlineEnd={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }} paddingInlineStart={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }}>
                <div style={{ "width": "150px" }} className='skeleton-height-20'>
                    <SkeletonBodyText lines={1} />
                </div>
            </Box>
            <Card>
                <BlockStack gap={"600"}>
                    <BlockStack gap={"600"}>
                        <div style={{ "width": "150px" }} className='skeleton-height-11'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <BlockStack gap={"400"}>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    {Array.from({ length: 3 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>
                                            <div style={{ "width": "50%" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                    )}
                                </FormLayout.Group>
                            </FormLayout>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    {Array.from({ length: 2 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>
                                            <div style={{ "width": "50%" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                    )}
                                </FormLayout.Group>
                            </FormLayout>
                        </BlockStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap={"600"}>
                        <div style={{ "width": "150px" }} className='skeleton-height-11'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <BlockStack gap={"400"}>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    {Array.from({ length: 1 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>
                                            <div style={{ "width": "50%" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                    )}
                                </FormLayout.Group>
                            </FormLayout>
                            <Card>
                                <BlockStack gap={"400"}>
                                    {Array.from({ length: 3 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>
                                            <div style={{ "width": "50%" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                    )}
                                </BlockStack>
                            </Card>
                            {Array.from({ length: 2 }, (index: any) =>
                                <BlockStack gap={"300"}>
                                    <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div className='skeleton-width-100'>
                                        <SkeletonDisplayText />
                                    </div>
                                    <div style={{ "width": "50%" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </BlockStack>
                            )}
                        </BlockStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap={"600"}>
                        <div style={{ "width": "150px" }} className='skeleton-height-11'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <BlockStack gap={"400"}>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    {Array.from({ length: 1 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>
                                            <div style={{ "width": "50%" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                    )}
                                </FormLayout.Group>
                            </FormLayout>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    {Array.from({ length: 4 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>
                                            <div style={{ "width": "50%" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                    )}
                                </FormLayout.Group>
                            </FormLayout>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    {Array.from({ length: 2 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>
                                            <div style={{ "width": "50%" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                    )}
                                </FormLayout.Group>
                            </FormLayout>

                            {Array.from({ length: 2 }, (index: any) =>
                                <BlockStack gap={"300"}>
                                    <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div className='skeleton-width-100 skeleton-height-62'>
                                        <SkeletonDisplayText />
                                    </div>
                                </BlockStack>
                            )}
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    {Array.from({ length: 3 }, (index: any) =>
                                        <BlockStack gap={"300"}>
                                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div className='skeleton-width-100'>
                                                <SkeletonDisplayText />
                                            </div>

                                        </BlockStack>
                                    )}
                                </FormLayout.Group>
                            </FormLayout>
                        </BlockStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap={"600"}>
                        <InlineStack align='space-between' gap={"300"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <InlineStack blockAlign='center' gap={"200"}>
                                <div className='skeleton-height-20' style={{ "width": "20px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "100px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                        </InlineStack>
                        <div className="recommended_attribute_block">
                            <BlockStack gap={"400"}>
                                <InlineGrid columns={2} gap={"300"}>
                                    <div style={{ "width": "100px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ "width": "100px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineGrid>
                                {Array.from({ length: 5 }, (index: any) =>
                                    <InlineGrid columns={2} gap={"300"}>
                                        <div style={{ "width": "100px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>

                                        <div className='skeleton-width-100'>
                                            <SkeletonDisplayText />
                                        </div>
                                    </InlineGrid>
                                )}
                            </BlockStack>
                        </div>
                    </BlockStack>
                    {/* <BlockStack gap={"300"}>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <div className='skeleton-width-100'>
                                <SkeletonDisplayText />
                            </div>
                        </BlockStack>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "50%" }}>
                                <SkeletonBodyText lines={1} />
                            </div>

                            {Array.from({ length: 5 }, (index: any) =>
                                <div style={{ "width": "40%" }}>
                                    <InlineStack wrap={false} gap={"200"}>
                                        <div style={{ "width": "8px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />
                                    </InlineStack>
                                </div>
                            )}

                        </BlockStack>
                    </BlockStack>
                    <BlockStack gap={"300"}>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <div className='skeleton-width-100'>
                                <SkeletonDisplayText />
                            </div>
                        </BlockStack>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "50%" }}>
                                <SkeletonBodyText lines={1} />
                            </div>

                            {Array.from({ length: 1 }, (index: any) =>
                                <div style={{ "width": "40%" }}>
                                    <InlineStack wrap={false} gap={"200"}>
                                        <div style={{ "width": "8px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />
                                    </InlineStack>
                                </div>
                            )}

                        </BlockStack>
                    </BlockStack>
                    <BlockStack gap={"300"}>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "100px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <div className='skeleton-width-100 skeleton-height-62'>
                                <SkeletonDisplayText />
                            </div>
                        </BlockStack>
                        <BlockStack gap={"300"}>
                            <div style={{ "width": "50%" }}>
                                <SkeletonBodyText lines={1} />
                            </div>

                            {Array.from({ length: 3 }, (index: any) =>
                                <div style={{ "width": "40%" }}>
                                    <InlineStack wrap={false} gap={"200"}>
                                        <div style={{ "width": "8px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <SkeletonBodyText lines={1} />
                                    </InlineStack>
                                </div>
                            )}

                        </BlockStack>
                    </BlockStack> */}

                </BlockStack>
            </Card>
        </BlockStack>
    )
}

export default EtsyAttributeSkeleton

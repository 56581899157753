import React, { useState } from "react";
import { Box, TextField } from "@shopify/polaris";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { DI } from "Src/core";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";

interface PropsI extends DIProps {
  isOpen:boolean
  editObj?:ObjStrI
  toggleShopSectionModal: () => void;
  fetchTemplates?: () => void;
  onCreation?:(param:ObjStrI)=>void
}

const CreateShopSectionModal = ({
  fetchTemplates,
  request: { POST, PUT },
  t,
  isOpen,
  editObj,
  toggleShopSectionModal,
  onCreation
}: PropsI) => {
  const { createShopSection } = apiUrls;
  const [createLoading, setCreateLoading] = useState(false);
  const [shopState, setShopState] = useState({
    name: editObj?.title ?? "",
    nameError: "",
  });
  // initiates call to create/edit a shop section
  const createNewShopSection = () => {
    let error = "";
    if (shopState.name.trim().length === 0) {
      error = t("SHOP_NAME_CANNOT_BE_EMPTY");
    } else if (shopState.name.trim().length > 24) {
      error = t("SHOP_NAME_IS_TOO_LONG");
    }
    if (error) {
      setShopState((prev) => ({
        ...prev,
        nameError: error,
      }));
      return;
    }
    setCreateLoading(true);
    const payload = {
      shop_section: shopState.name,
    };
    if (editObj) {
      Object.assign(payload, { shop_section_id: editObj?.shop_section_id });
    }
    const methodName = editObj ? PUT : POST;
    methodName(createShopSection, payload)
      .then((res) => {
        if (res.success) {
          fetchTemplates?.();
          res?.template_id && onCreation?.({[res?.template_id]:shopState.name})
          setShopState((prev) => ({ ...prev, name: "" }));
          toggleShopSectionModal();
          ToastAppBridge(res?.message);
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };
  return (
    <ModalAppBridge
      open={isOpen}
      onHide={toggleShopSectionModal}
      id="create-shop-section"
      variant="base"
      title={t("CREATE_A_NEW_SHOP_SECTION")}
      body={
        <Box padding="400">
          <TextField
            autoComplete=""
            label={t("SECTION_TITLE")}
            requiredIndicator
            placeholder={t("ENTER_TITLE")}
            helpText={t("SECTION_TITLE_HELPTEXT")}
            value={shopState.name}
            onChange={(val) => {
              setShopState((prev) => ({
                ...prev,
                name: val,
                nameError: "",
              }));
            }}
            error={shopState.nameError}
          />
        </Box>
      }
      footer={
        <>
          <button
            variant="primary"
            loading={createLoading && true}
            onClick={createNewShopSection}
          >
            {t("SAVE")}
          </button>
          <button onClick={() => toggleShopSectionModal()}>{t("CANCEL")}</button>
        </>
      }
    />
  );
};

export default DI(CreateShopSectionModal);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage_tags_scroll .Polaris-TextField__VerticalContent {
  display: flex;
  padding-top: 0;
  align-items: center;
  gap: 4px;
}
.custom_card_profile > .Polaris-ShadowBevel > .Polaris-Box {
  background: var(--Color-bg-bg, #f1f1f1);
}
.custom_accordion_Card > .Polaris-ShadowBevel::before {
  box-shadow: unset;
}

.rule_preview_sticky
  > .Polaris-Page
  > .Polaris-Page__Content
  .Polaris-Layout__Section.Polaris-Layout__Section--oneThird {
  position: sticky;
  top: 0;
}


.image-cell-view-products {
  width: 60px;
}

@media screen and (max-width: 767px) {
  .match_delete_btn{
    flex: 1;
    text-align: end;
  }
}

@media screen and (max-width: 550px) {
  .products-conditions >div {
    flex-direction: column;
    align-items: flex-start;
}
.products-conditions button#add_rule_condition {
  margin-left: auto;
}
}`, "",{"version":3,"sources":["webpack://./src/Component/Profilling/CreateProfile/createprofile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,uCAAuC;AACzC;AACA;EACE,iBAAiB;AACnB;;AAEA;;;;EAIE,gBAAgB;EAChB,MAAM;AACR;;;AAGA;EACE,WAAW;AACb;;AAEA;EACE;IACE,OAAO;IACP,eAAe;EACjB;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;EACE,iBAAiB;AACnB;AACA","sourcesContent":[".manage_tags_scroll .Polaris-TextField__VerticalContent {\n  display: flex;\n  padding-top: 0;\n  align-items: center;\n  gap: 4px;\n}\n.custom_card_profile > .Polaris-ShadowBevel > .Polaris-Box {\n  background: var(--Color-bg-bg, #f1f1f1);\n}\n.custom_accordion_Card > .Polaris-ShadowBevel::before {\n  box-shadow: unset;\n}\n\n.rule_preview_sticky\n  > .Polaris-Page\n  > .Polaris-Page__Content\n  .Polaris-Layout__Section.Polaris-Layout__Section--oneThird {\n  position: sticky;\n  top: 0;\n}\n\n\n.image-cell-view-products {\n  width: 60px;\n}\n\n@media screen and (max-width: 767px) {\n  .match_delete_btn{\n    flex: 1;\n    text-align: end;\n  }\n}\n\n@media screen and (max-width: 550px) {\n  .products-conditions >div {\n    flex-direction: column;\n    align-items: flex-start;\n}\n.products-conditions button#add_rule_condition {\n  margin-left: auto;\n}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { BlockStack, Divider, InlineStack, Page, SkeletonBodyText, SkeletonTabs } from '@shopify/polaris'
import React from 'react'
import ShopifyViewSkeleton from './ShopifyViewSkeleton'
import EtsyViewSkeleton from './EtsyViewSkeleton'

const ProductViewSkeleton = () => {
    return (
        <>
            <div className="custom_layout_width">
                <Page fullWidth>
                    <InlineStack gap={"200"} blockAlign='center'>
                        <div style={{ "width": "150px" }} className='skeleton-height-11'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "60px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                </Page>
            </div>
            <Divider />
            <div className="custom_layout_width">
                <Page fullWidth>
                    <SkeletonTabs />
                    <ShopifyViewSkeleton />
                </Page>
            </div>
        </ >
    )
}

export default ProductViewSkeleton

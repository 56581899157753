import React from 'react'
import FullTemplateGridSkeleton from './FullTemplateGridSkeleton'
import { Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'

const WithPageHeaderTemplateGridSkeleton = () => {
    const { mdDown } = useBreakpoints()
    return (
        <>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <Page fullWidth>

                    {mdDown ? (
                        <InlineStack align='space-between' blockAlign='center'>
                            <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                            <InlineStack gap={"200"}>
                                <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div className='skeleton-height-32px' style={{ "width": "32px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                        </InlineStack>
                    ) : (
                        <InlineStack align='space-between' blockAlign='center'>
                            <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                            <InlineStack gap={"200"}>
                                <div style={{ "width": "100px" }}>
                                    <SkeletonDisplayText size='small' />
                                </div>
                                <div style={{ "width": "100px" }}>
                                    <SkeletonDisplayText size='small' />
                                </div>
                            </InlineStack>
                        </InlineStack>
                    )}
                </Page>
            </Box>
            <Box>
                <Page fullWidth={true}>
                    <FullTemplateGridSkeleton />
                </Page>
            </Box>

        </>
    )
}

export default WithPageHeaderTemplateGridSkeleton

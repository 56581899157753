// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inte-sortable:not(.inte-sortable--dummy) {
  position: relative;
}

/* .inte-sortable__item {
  cursor: grabbing;
} */

.inte-sortable__item--dummy {
  position: fixed;
  cursor: grabbing;
}

.inte-sortable__item--dummy > * {
  box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
  border: 1px dashed var(--inte-P1000);
}

.inte-sortable--dummy {
  opacity: 0;
  /* margin-top: 20px; */
  position: fixed;
  z-index: -1;
  visibility: hidden;
}

.inte-sortable__item img {
  user-select: none;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Settings/Sortable/Sortable.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;;GAEG;;AAEH;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;EACxC,oCAAoC;AACtC;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".inte-sortable:not(.inte-sortable--dummy) {\n  position: relative;\n}\n\n/* .inte-sortable__item {\n  cursor: grabbing;\n} */\n\n.inte-sortable__item--dummy {\n  position: fixed;\n  cursor: grabbing;\n}\n\n.inte-sortable__item--dummy > * {\n  box-shadow: 0 15px 30px rgb(0 0 0 / 20%);\n  border: 1px dashed var(--inte-P1000);\n}\n\n.inte-sortable--dummy {\n  opacity: 0;\n  /* margin-top: 20px; */\n  position: fixed;\n  z-index: -1;\n  visibility: hidden;\n}\n\n.inte-sortable__item img {\n  user-select: none;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
  BlockStack,
  Box,
  Card,
  Icon,
  InlineStack,
  Link,
  List,
  Text,
  TextField,
} from "@shopify/polaris";
import { AlertCircleIcon } from "@shopify/polaris-icons";
import React, { useMemo, useRef, useState } from "react";
import CustomMultiSelect from "Src/Component/Profilling/CreateProfile/components/CustomMultiSelect";
import { useProductEditContext } from "../ProductEditProvider";
import JoditEditor from "jodit-react";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const GeneralInformation = ({ t }: DIProps) => {
  const { localProduct, updateLocalProduct, errors, removeError } =
    useProductEditContext();

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = {
    buttons:
      "bold,italic,underline,|,ul,ol,|,fontsize,brush,paragraph,link,source",
    showStatusbar: false,
    toolbarSticky: false,
    toolbarAdaptive: false,
    showXPathInStatusbar: false,
    disablePlugins: "powered-by-jodit,drag-and-drop,drag-and-drop-element",
    showCharsCounter: false,
    showWordsCounter: false,
  };

  const Editor = useMemo(
    () => (
      <BlockStack gap="100" id="description">
        <JoditEditor
          ref={editor}
          value={localProduct.description}
          config={config}
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(val) => updateLocalProduct({ description: val })}
        />

        {errors?.description ? (
          <InlineStack gap="200" align="start" blockAlign="start">
            <div>
              <Icon source={AlertCircleIcon} tone="textCritical" />
            </div>
            <Text as="p" tone="critical">
              {errors?.description}
            </Text>
          </InlineStack>
        ) : (
          <></>
        )}
      </BlockStack>
    ),
    [errors?.description]
  );

  return (
    <BlockStack gap={"200"}>
      <Box
        paddingInlineEnd={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
        paddingInlineStart={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
      >
        <Text as="h2" variant="headingLg">
          {t("GENERAL_INFORMATION")}
        </Text>
      </Box>
      <Card>
        <BlockStack gap={"400"}>
          <BlockStack gap="100">
            <TextField
              id="title"
              label={t("TITLE")}
              value={localProduct?.title}
              placeholder={t("ENTER_TITLE")}
              onChange={(val) => {
                removeError("title");
                updateLocalProduct({ title: val });
              }}
              autoComplete="off"
              error={errors?.["title"]}
            />
            <BlockStack gap={"100"}>
              <Text as="span" variant="bodyMd" tone="subdued">
                {t("VALID_TITLE_REQUIREMENTS")}
              </Text>
              <List type="bullet">
                <List.Item>
                  <Text as="span" variant="bodyMd" tone="subdued">
                    {t("TITLE_MAX_140_CHARACTERS")}
                  </Text>
                </List.Item>
                <List.Item>
                  <Text as="span" variant="bodyMd" tone="subdued">
                    {t("TITLE_AVOID_THESE_RESTRICTED_CHARACTERS")}
                  </Text>
                </List.Item>
              </List>
            </BlockStack>
          </BlockStack>
          <BlockStack gap="100">
            <Text as="strong" variant="bodyMd">
              {t("DESCRIPTION")}
            </Text>
            {Editor}
          </BlockStack>
          <BlockStack gap={"100"}>
            <CustomMultiSelect
              label={t("PRODUCT_TAGS_KEYWORDS")}
              value={localProduct?.tags ? localProduct?.tags?.split(",") : []}
              placeholder={t("ENTER_TAGS")}
              onChange={(tags) => updateLocalProduct({ tags: tags?.join(",") })}
              options={[]}
              validationObject={{
                maxLength: 13,
                regex: /[^a-zA-Z0-9 \-’™©®]/g,
                charLength: 20,
              }}
            />
            <BlockStack gap={"100"}>
              <Text as="span" variant="bodyMd" tone="subdued">
                {t("VALID_TAG_CONTAINS")}
              </Text>
              <List type="bullet">
                <List.Item>
                  <Text as="span" variant="bodyMd" tone="subdued">
                    {t("USE_ONLY_LETTERS_NUMBERS_AND_SPECIAL")}
                  </Text>
                </List.Item>
                <List.Item>
                  <Text as="span" variant="bodyMd" tone="subdued">
                    {t("UPTO_13_TAGS")}
                  </Text>
                </List.Item>
                <List.Item>
                  <Text as="span" variant="bodyMd" tone="subdued">
                    {t("LEARN_MORE_ABOUT_ETSY_TAGS")}
                    <Link
                      url="https://www.etsy.com/in-en/seller-handbook/article/keywords-101-everything-you-need-to-know/382774281517#QA"
                      target="_blank"
                    >
                      {t("CLICK_HERE")}
                    </Link>
                  </Text>
                </List.Item>
              </List>
            </BlockStack>
          </BlockStack>
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(GeneralInformation);

import React, { createContext, useState } from "react";
import { ObjStrI } from "Src/Interface/@core";

interface ShopSectionModalStateI {
  isOpen: boolean;
  details?: ObjStrI;
}

export const TabContext = createContext<{
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  templates: any[];
  filters: ObjStrI;
  loading: boolean;
  setTemplateState: React.Dispatch<
    React.SetStateAction<{
      loading: boolean;
      templates: any[];
      filters: ObjStrI;
    }>
  >;
  isDeleteModalOpen: boolean;
  deleteTemplateIndex: number;
  consolidateTemplateId?: number;
  updateFilters: (filtersObj: ObjStrI) => void;
  toggleDeleteModal: () => void;
  updateDeleteDetails: (param: deleteModalStateI) => void;
  toggleConsolidateModal: (param?: number) => void;
  shopSectionModalState:ShopSectionModalStateI,
  toggleShopSectionModal:(details?:ObjStrI)=>void
}>({
  selected: 0,
  setSelected: () => {},
  templates: [],
  filters: {},
  loading: false,
  setTemplateState: () => {},
  updateFilters: () => {},
  isDeleteModalOpen: false,
  deleteTemplateIndex: -1,
  toggleDeleteModal: () => {},
  updateDeleteDetails: () => {},
  toggleConsolidateModal: () => {},
  shopSectionModalState:{isOpen:false},
  toggleShopSectionModal:()=>{}
});

interface deleteModalStateI {
  isOpen: boolean;
  index: number;
}
const TemplateContext = (props: any) => {
  const [selected, setSelected] = useState<number>(0);
  const [templateState, setTemplateState] = useState<{
    loading: boolean;
    templates: any[];
    filters: any;
  }>({
    loading: false,
    templates: [],
    filters: {},
  });
  const [deleteModalState, setDeleteModalState] = useState<deleteModalStateI>({
    isOpen: false,
    index: -1,
  });
  const [consolidateTemplateId, setConsolidateTemplateId] = useState<number>();
  const [shopSectionModalState, setShopSectionModalState] = useState<{
    isOpen: boolean;
    details?: ObjStrI;
  }>({ isOpen: false });

  const updateFilters = (filtersObj: ObjStrI) => {
    const copyObj = { ...templateState.filters, ...filtersObj };
    Object.keys(copyObj).forEach((key) => {
      if (copyObj[key] === "") {
        delete copyObj[key];
      }
    });
    setTemplateState((prev) => ({
      ...prev,
      filters: { ...copyObj },
    }));
  };

  const toggleDeleteModal = () => {
    setDeleteModalState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const updateDeleteDetails = (details: deleteModalStateI) => {
    setDeleteModalState((prev) => ({ ...prev, ...details }));
  };

  const toggleConsolidateModal = (id?: number) => {
    setConsolidateTemplateId(id);
  };

  const toggleShopSectionModal=(details?:ObjStrI)=>{
    setShopSectionModalState((prev) => ({
      isOpen: !prev.isOpen,
      details
    }));
  }

  return (
    <TabContext.Provider
      value={{
        selected,
        setSelected,
        filters: templateState.filters,
        templates: templateState.templates,
        loading: templateState.loading,
        setTemplateState,
        updateFilters,
        isDeleteModalOpen: deleteModalState.isOpen,
        deleteTemplateIndex: deleteModalState.index,
        toggleDeleteModal,
        updateDeleteDetails,
        consolidateTemplateId,
        toggleConsolidateModal,
        shopSectionModalState,
        toggleShopSectionModal
      }}
    >
      {props.children}
    </TabContext.Provider>
  );
};

export default TemplateContext;

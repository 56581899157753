import { Card, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const ConnectedShopSkeleton = () => {
    return (
        <Card>
            <InlineStack gap={"200"} blockAlign='center'>
                <div style={{ "width": "20px" }} className='custom-height_skel'>
                    <SkeletonBodyText lines={1} />
                </div>
                <div style={{ "width": "50px" }}>
                    <SkeletonBodyText lines={1} />
                </div>
                <div style={{ "width": "80px" }}>
                    <SkeletonBodyText lines={1} />
                </div>
            </InlineStack>
        </Card>
    )
}

export default ConnectedShopSkeleton

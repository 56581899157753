import React, { useCallback, useState } from 'react'
import ProductListingGridSkeleton from './ProductListingGridSkeleton'
import { BlockStack, Box, Card, ChoiceList, Divider, IndexFilters, IndexFiltersMode, IndexFiltersProps, InlineStack, RangeSlider, SkeletonDisplayText, SkeletonTabs, TabProps, TextField, useSetIndexFiltersMode } from '@shopify/polaris'

const FullProductListingGridSkeleton = () => {
    const searchField = <InlineStack gap={"200"}>
        <div style={{ 'width': '100px' }}>
            <SkeletonDisplayText size="small" />
        </div>
       
    </InlineStack>
    return (
        <BlockStack gap={"300"}>
            <Box paddingInlineStart={{"xs":"400","lg":"0","md":"0","sm":"0"}} paddingInlineEnd={{"xs":"400","lg":"0","md":"0","sm":"0"}}>
            <InlineStack align='space-between'>
                <InlineStack gap={"200"}>
                    <div style={{ 'width': '50px' }}>
                        <SkeletonDisplayText size="small" />
                    </div>
                    <div style={{ 'width': '50px' }}>
                        <SkeletonDisplayText size="small" />
                    </div>
                    <div style={{ 'width': '50px' }}>
                        <SkeletonDisplayText size="small" />
                    </div>
                </InlineStack>
                <InlineStack gap={"200"}>
                    <div style={{ 'width': '100px' }}>
                        <SkeletonDisplayText size="small" />
                    </div>
                </InlineStack>
            </InlineStack>
            </Box>
            <Card padding={'0'}>
                <BlockStack>
                    <BlockStack>
                        <div className='index-filter_skeleton'>
                            <InlineStack align='space-between'>
                                {searchField}
                                <InlineStack gap={"200"}>
                                    <div style={{ 'width': '28px' }}>
                                        <SkeletonDisplayText size="small" />
                                    </div>
                                    <div style={{ 'width': '28px' }}>
                                        <SkeletonDisplayText size="small" />
                                    </div>
                                </InlineStack>
                            </InlineStack>
                        </div>

                    </BlockStack>
                    <Divider />
                    <ProductListingGridSkeleton heading={[]} />
                </BlockStack>
            </Card>
        </BlockStack>
    )
}

export default FullProductListingGridSkeleton

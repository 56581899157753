import React from 'react'
import {  Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'
import BulkEditGridSkeleton from './BulkEditGridSkeleton';

const WithHeaderBulkEditSkeleton = () => {
    const { mdDown } = useBreakpoints();
    return (
        <>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className='skeleton_page_header_spacing'>
                    <Page fullWidth>
                        {mdDown ? (
                            <InlineStack align='space-between' blockAlign='center' gap='050'>
                                <div className='skeleton-height-20 ' style={{ "width": "20px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div className='skeleton-height-32px' style={{ "width": "100px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ width: "100%" }}>

                                    <div style={{ "width": "150px" }} className='skeleton-height-26'><SkeletonBodyText lines={1} /></div>

                                </div>

                            </InlineStack>
                        ) : (
                            <InlineStack align='space-between' blockAlign='center'>
                                <div style={{ width: "40%" }}>
                                    <InlineStack blockAlign='start' gap='200' align='start'>
                                        <div className='skeleton-height-20 ' style={{ "width": "20px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>

                                        <div style={{ width: "150px" }} className='skeleton-height-26'><SkeletonBodyText lines={1} /></div>

                                    </InlineStack>
                                </div>

                                <div style={{ "width": "30px" }}>
                                    <SkeletonDisplayText size='small' />
                                </div>

                            </InlineStack>
                        )}
                    </Page>
                </div>
            </Box>
            <Box>
                <Page fullWidth={true}>
                    <BulkEditGridSkeleton />
                </Page>
            </Box>

        </>
    )
}

export default WithHeaderBulkEditSkeleton

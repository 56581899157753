import { BlockStack, Card, Divider, Grid, IndexTable, InlineStack, Layout, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const EtsyOrderViewSkeleton = () => {

    const returnOrders = [
        {
            id: "1020",
            reason: <BlockStack gap={"0"}>
                <SkeletonBodyText lines={2} />
            </BlockStack>,
            notefromIssuer: <div style={{ 'width': "100px" }}><SkeletonBodyText lines={1} /></div>,
            status: <div style={{ "width": "50px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>,
            amount: <div style={{ 'width': "100px" }}><SkeletonBodyText lines={1} /></div>,
        },
    ];
    const rowMarkupReturn = returnOrders.map(
        ({ id, reason, notefromIssuer, status, amount }: any, index: number) => (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>
                    {reason}
                </IndexTable.Cell>
                <IndexTable.Cell>{notefromIssuer}</IndexTable.Cell>
                <IndexTable.Cell>{status}</IndexTable.Cell>
                <IndexTable.Cell>{amount}</IndexTable.Cell>
            </IndexTable.Row>
        )
    );

    const rowMarkupReturnUpdated = Array.from({ length: 1 }, () => rowMarkupReturn);

    const returnOrdersItems = [
        {
            id: "1020",
            title: <div style={{ "width": "250px" }}>
                <BlockStack gap={"200"}>
                    <SkeletonBodyText lines={1} />
                    <div style={{ "width": "100px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <InlineStack gap={"200"}>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                </BlockStack>
            </div>,
            shippingCost: <div style={{ 'width': "100px" }}><SkeletonBodyText lines={1} /></div>,
            productCost: <div style={{ "width": "50px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>,
            totalCost: <div style={{ 'width': "100px" }}><SkeletonBodyText lines={1} /></div>,
        },
    ];
    const rowMarkupOrderItem = returnOrdersItems.map(
        ({ id, title, shippingCost, productCost, totalCost }: any, index: number) => (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>
                    {title}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {shippingCost}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {productCost}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {totalCost}
                </IndexTable.Cell>
            </IndexTable.Row>
        )
    );
    const rowMarkupOrderItemUpdated = Array.from({ length: 1 }, () => rowMarkupOrderItem);
    return (
        <Layout>
            <Layout.Section>
                <BlockStack gap={"300"}>
                    <Card>
                        <BlockStack gap={"400"}>
                            <InlineStack gap={"200"} blockAlign="center">
                                <div style={{ "width": "100px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "50px" }} className='skeleton-height-20'>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                            <Divider />
                            <BlockStack gap={"500"}>
                                <div style={{ "width": "150px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <Card padding={"0"}>
                                <div className="custom-table-skeleton">
                                    <IndexTable
                                        selectable={false}
                                        itemCount={2}
                                        headings={[
                                            { title: "" },
                                            { title: "" },
                                            { title: "" },
                                            { title: "" },
                                        ]}
                                    >
                                        {rowMarkupOrderItemUpdated}
                                    </IndexTable>
                                    </div>
                                </Card>
                            </BlockStack>
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }}>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <Card padding={'0'}>
                            <div className="custom-table-skeleton"> <IndexTable
                                    selectable={false}
                                    itemCount={2}
                                    headings={[
                                        { title: "" },
                                        { title: "" },
                                        { title: "" },
                                        { title: "" },
                                    ]}
                                >
                                    {rowMarkupReturnUpdated}
                                </IndexTable></div>
                            </Card>
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "50px" }} className='skeleton-height-11'>
                                <SkeletonBodyText lines={1} />
                            </div>
                            <BlockStack gap={"500"}>
                                {Array.from({ length: 6 }, (_,i) =>
                                    <InlineStack align="space-between" blockAlign="center" key={i}>
                                        <div style={{ "width": "50px" }} ><SkeletonBodyText lines={1} /></div>
                                        <div style={{ "width": "50px" }} ><SkeletonBodyText lines={1} /></div>
                                    </InlineStack>
                                )}

                                <Divider />
                                <InlineStack align="space-between" blockAlign="center">
                                    <div style={{ "width": "50px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                                    <div style={{ "width": "50px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                                </InlineStack>
                            </BlockStack>
                        </BlockStack>
                    </Card>
                </BlockStack>
            </Layout.Section>
            <Layout.Section variant="oneThird">
                <BlockStack gap={"300"}>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                                <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                            </Grid>
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <BlockStack gap={"500"}>
                                {Array.from({ length: 4 }, (_,i) =>
                                    <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }} key={i}>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                    </Grid>
                                )}
                            </BlockStack>

                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <BlockStack gap={"500"}>
                                {Array.from({ length: 2 }, (_,i) =>
                                    <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }} key={i}>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                        <div style={{ "width": "100px" }}><SkeletonBodyText lines={1} /></div>
                                    </Grid>
                                )}
                            </BlockStack>
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"600"}>
                            <div style={{ "width": "150px" }} className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
                            <BlockStack gap={"200"}>
                                <SkeletonBodyText lines={2} />
                            </BlockStack>
                        </BlockStack>
                    </Card>
                </BlockStack>
            </Layout.Section>
        </Layout>
    )
}

export default EtsyOrderViewSkeleton

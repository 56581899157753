import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    // Small delay to ensure the DOM is fully loaded
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100); // Adjust delay if necessary
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollToTop;

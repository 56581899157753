import React, { useCallback, useState } from 'react'
import ReviewGridSkeleton from './ReviewGridSkeleton'
import { BlockStack, Card, ChoiceList, Divider, IndexFilters, IndexFiltersMode, IndexFiltersProps, InlineStack, RangeSlider, SkeletonBodyText, SkeletonDisplayText, SkeletonTabs, TabProps, TextField, useBreakpoints, useSetIndexFiltersMode } from '@shopify/polaris'

const FullReviewGridSkeleton = () => {
    const { mdDown } = useBreakpoints()
    const searchField = <InlineStack gap={"200"}>
        {mdDown ? (
            <div style={{ 'width': '100px' }} className='skeleton-height-32px'>
                <SkeletonBodyText lines={1} />
            </div>
        ) : (
            <div style={{ 'width': '100px' }}>
                <SkeletonDisplayText size="small" />
            </div>
        )}

    </InlineStack>
    return (
        <Card padding={'0'}>
            <BlockStack>
                <BlockStack>
                    <div className='index-filter_skeleton'>
                        <InlineStack align='space-between'>
                            {searchField}
                            <InlineStack gap={"200"}>
                                {mdDown ? (
                                    <div style={{ 'width': '32px' }} className='skeleton-height-32px'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                ) : (
                                    <div style={{ 'width': '28px' }}>
                                        <SkeletonDisplayText size="small" />
                                    </div>
                                )}

                            </InlineStack>
                        </InlineStack>
                    </div>

                </BlockStack>
                <Divider />
                <ReviewGridSkeleton />
            </BlockStack>
        </Card>
    )
}

export default FullReviewGridSkeleton

import {
  BlockStack,
  IndexTable,
  InlineStack,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  useIndexResourceState,
  useBreakpoints,
} from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
// import './skeleton.css'

export default function ReviewGridSkeleton() {
  const { t } = useTranslation();

  const gridSkeletonRow = {
    id: "1",
    productReviewed: (
      <div className="failed-order-skelton" style={{ width: "220px" }}>
        <InlineStack gap={"200"} wrap={false} blockAlign="center">
          <SkeletonThumbnail size="small" />
          <SkeletonBodyText lines={2} />
          {/* <div className='failed-order-skelton' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div> */}
        </InlineStack>
      </div>
    ),
    rating: (
      <div style={{ width: "30px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    reviewer: (
      <div style={{ width: "90px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    comment: (
      <div style={{ width: "150px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    additionalImages: (
      <div style={{ width: "70px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    reviewCreationDate: (
      <div className="failed-order-skelton" style={{ width: "70px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    Action: (
      <InlineStack gap={"200"}>
        <div style={{ width: "26px" }}>
          <SkeletonDisplayText size="small" />
        </div>
      </InlineStack>
    ),
  };

  const resourceName = {
    singular: t("REVIEW"),
    plural: t("REVIEWS"),
  };

  const rowMarkupData = Array.from({ length: 18 }, () => gridSkeletonRow);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(rowMarkupData);

  const rowMarkup = rowMarkupData.map((item, index) => (
    <IndexTable.Row id={item.id} key={index} position={index}>
      <IndexTable.Cell>{item.productReviewed}</IndexTable.Cell>
      <IndexTable.Cell>{item.rating}</IndexTable.Cell>
      <IndexTable.Cell>{item.reviewer}</IndexTable.Cell>
      <IndexTable.Cell>{item.comment}</IndexTable.Cell>
      <IndexTable.Cell>{item.additionalImages}</IndexTable.Cell>
      <IndexTable.Cell>{item.reviewCreationDate}</IndexTable.Cell>

      <IndexTable.Cell>{item.Action}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  const gridSkeletonRowMobile = {
    id: "1",
    productImage: <SkeletonThumbnail size="extraSmall" />,
    productReviewed: <SkeletonBodyText lines={1} />,
    rating: (
      <div className="failed-order-skelton" style={{ width: "30px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    reviewer: (
      <div className="failed-order-skelton" style={{ width: "90px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    comment: (
      <div className="failed-order-skelton" style={{ width: "150px" }}>
        <SkeletonBodyText lines={2} />
      </div>
    ),
    additionalImages: (
      <div className="failed-order-skelton" style={{ width: "70px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    reviewCreationDate: (
      <div className="failed-order-skelton" style={{ width: "70px" }}>
        <SkeletonBodyText lines={1} />
      </div>
    ),
    Action: (
      <div style={{ width: "26px" }}>
        <SkeletonDisplayText size="small" />
      </div>
    ),
  };
  const rowMarkupDataMobile = Array.from(
    { length: 10 },
    () => gridSkeletonRowMobile
  );
  // const { selectedResources, allResourcesSelected, handleSelectionChange } =
  //     useIndexResourceState(rowMarkupData);
  const rowMarkupMobile = rowMarkupDataMobile.map(
    (
      {
        id,
        productImage,
        productReviewed,
        rating,
        reviewer,
        comment,
        additionalImages,
        reviewCreationDate,
        Action,
      },
      index
    ) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <div
          className="mobile_card_item_inner1"
          style={{
            paddingBlockStart: "12px",
            paddingBlockEnd: "12px",
            paddingInlineStart: "16px",
            paddingInlineEnd: "16px",
            position: "relative",
          }}
        >
          <InlineStack gap={"200"} wrap={false}>
            <div style={{ 'width': '20px' }} className='skeleton-height-20'>
              <SkeletonBodyText lines={1} />
            </div>
            <div style={{ flex: 1 }}>
              <InlineStack gap="200" wrap={false} blockAlign="start">
                {productImage}
                <div style={{ flex: 1 }}>
                  <BlockStack gap={"200"}>

                    <div style={{ 'width': '170px' }} className="reviewed_product_title custom-height_skel">
                      {productReviewed}
                    </div>

                    <BlockStack gap={"200"}>
                      <InlineStack gap={"300"} blockAlign="center">
                        {rating}
                        {reviewCreationDate}
                        {reviewer}
                      </InlineStack>

                      <InlineStack gap={"200"} align="space-between" wrap={false}>
                        <div className="reviewed_product_title">
                          {comment}
                        </div>
                        <div
                          onClick={(e) => e.stopPropagation()}

                        >
                          {Action}
                        </div>
                      </InlineStack>
                      {additionalImages}
                    </BlockStack>
                  </BlockStack>
                </div>
              </InlineStack>
            </div>
          </InlineStack>
        </div>
      </IndexTable.Row>
    )
  );

  const { mdDown } = useBreakpoints();
  return (
    <IndexTable
      selectable={mdDown ? false : true}
      condensed={mdDown ? true : false}
      resourceName={resourceName}
      itemCount={rowMarkupData.length}
      selectedItemsCount={
        allResourcesSelected ? "All" : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      headings={[
        { title: t("PRODUCT_REVIEWED") },
        { title: t("RATING") },
        { title: t("REVIEWER") },
        { title: t("COMMENTS") },
        { title: t("IMAGES") },
        { title: t("CREATION_DATE") },
        { title: t("ACTION") },
      ]}
    >
      {mdDown ? rowMarkupMobile : rowMarkup}
    </IndexTable>
  );
}

import { createContext, useContext, useMemo, useState } from "react";
import { ObjStrI } from "Src/Interface/@core";
export type OrderActionT =
  | "ship-order"
  | "error-modal"
  | "bulk_order_fetch"
  | "single_order_fetch"
  | "manual-order-create"
  | "email-update"
  | "create-order-on-shopify"
  | "sync-shipped-order"
  | "sync-order-status"
  | "";
interface ModalActionI {
  isOpen: boolean;
  type: OrderActionT;
  id: string;
  data?: any;
}
interface InitialI {
  filters: ObjStrI;
  orders: any;
  loading: boolean;
  totalCount: number;
  modalAction: ModalActionI;
  setModalAction: (e: ModalActionI) => void;
  setOrders: (e: any) => void;
  setLoading: (e: boolean) => void;
  setTotalCount: (e: number) => void;
  setFilters: (filtersObj: ObjStrI) => void;
}

const initialvalue: InitialI = {
  filters: { sort: "-created_at" },
  loading: false,
  orders: [],
  totalCount: 0,
  modalAction: { id: "", isOpen: false, type: "" },
  setModalAction: (e: ModalActionI) => {},
  setOrders: (e: any) => {},
  setLoading: (e: boolean) => {},
  setTotalCount: (e: number) => {},
  setFilters: (filtersObj: ObjStrI) => {},
};

const OrderContext = createContext(initialvalue);

const OrderProvider = ({ children }: any) => {
  const [orderContextVal, setOrderContextVal] =
    useState<InitialI>(initialvalue);

  const setLoading = (loading: boolean) => {
    setOrderContextVal((prevState) => ({ ...prevState, loading }));
  };

  const setOrders = (orders: any) => {
    setOrderContextVal((prevState) => ({ ...prevState, orders }));
  };

  const setTotalCount = (totalCount: number) => {
    setOrderContextVal((prevState) => ({ ...prevState, totalCount }));
  };
  const setFilters = (filters: ObjStrI) => {
    setOrderContextVal((prevState) => ({ ...prevState, filters }));
  };
  const setModalAction = (modalAction: ModalActionI) => {
    setOrderContextVal((prevState) => ({ ...prevState, modalAction }));
  };
  const value = useMemo(
    () => ({
      ...orderContextVal,
      setLoading,
      setOrders,
      setTotalCount,
      setFilters,
      setModalAction,
    }),
    [orderContextVal]
  );
  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

const useOrderContext = () => {
  return useContext(OrderContext);
};
export { OrderProvider, useOrderContext };

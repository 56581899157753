import { BlockStack, Card, Divider, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from "@shopify/polaris";
import React from "react";

const PolicyTemplateFullPageSkelton = () => {
  return <div className="custom_layout_width">
    <Page fullWidth>
      <BlockStack gap={"400"}>
        <Card>
          <InlineStack gap={'200'}>
            <SkeletonThumbnail size="small" />

            <BlockStack gap={"300"}>
              <div style={{ "width": "100px" }}>
                <SkeletonBodyText lines={1} />
              </div>
              <div style={{ "width": "200px" }}>
                <SkeletonBodyText lines={1} />
              </div>
            </BlockStack>

          </InlineStack>
        </Card>
        <Card>
          <BlockStack gap={"300"}>
            <BlockStack gap={"400"}>
              <div style={{ "width": "150px" }}>
                <SkeletonBodyText lines={1} />
              </div>
              <Divider />
            </BlockStack>
            <BlockStack gap={"600"}>
              <InlineStack gap={"200"}>
                <SkeletonThumbnail size="extraSmall" />
                <BlockStack gap={"500"}>
                  <div style={{ "width": "100px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                </BlockStack>
              </InlineStack>
              <Divider />
              <InlineStack gap={"200"}>
                <SkeletonThumbnail size="extraSmall" />
                <BlockStack gap={"500"}>
                  <div style={{ "width": "100px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                </BlockStack>
              </InlineStack>

              <Divider />
              <BlockStack gap={"200"}>
                <div style={{ "width": "100px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <div className="skeleton-width-100">
                  <SkeletonDisplayText />
                </div>
              </BlockStack>

            </BlockStack>
          </BlockStack>
        </Card>
      </BlockStack>
    </Page>
  </div>;
};

export default PolicyTemplateFullPageSkelton;

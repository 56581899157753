import { apiUrls } from "Src/Constant/url";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { useProfileContext } from "../../ProfileProvider";
import { useEffect, useState } from "react";
import {
  Box,
  Card,
  IndexTable,
  IndexTableSelectionType,
  InlineStack,
  Pagination,
  Text,
  Thumbnail,
  useIndexResourceState,
} from "@shopify/polaris";
import { replaceLastOccurrence } from "Src/Component/HelperComponents/GlobalFunctions";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { ViewProductI } from "Src/Interface/@Profile";
import { formatRuleGroup } from "../../CreateProfileHelper";
import { getTrimLength } from "Src/Component/CharacterLimitSet";
import RuleGroupResultModalSkeleton from "./RuleGroupResultModalSkeleton";
import { useRoutesContext } from "Src/Component/RoutesProvider";

const { viewProducts } = apiUrls;

const ViewProducts = DI(
  ({ open, setOpen, ..._props }: ViewProductI & DIProps) => {
    const {
      request: { POST },
      t,
    } = _props;
    const { routeData } = useRoutesContext();

    const { rule_group, updateRuleGroup, profile, totalProduct } =
      useProfileContext();
    const { condition, select_all, ruleRows, update_on_shopify } = rule_group;

    const [loading, setLoading] = useState({
      full_loading: false,
      more_loading: false,
    });

    const [products, setProducts] = useState<
      {
        title: string;
        id: string;
        image: string;
      }[]
    >([]);
 
    const [activePage, setActivePage] = useState(1);
    
    const { selectedResources, handleSelectionChange, clearSelection,  } =
    useIndexResourceState(products, { selectedResources: update_on_shopify });

    const fetchProducts = () => {
      setLoading((prev) => ({ ...prev, full_loading: true }));
      const payload = formatRuleGroup(ruleRows);

      POST(`${viewProducts}?per-page=10&page=${activePage}`, {
        triggerType: "manual",
        product_profile: {
          condition,
          ...payload,
        },
        Profile: {
          profile_code: profile.profile_code,
          profile_code_existing: profile.profile_code_existing,
          ProfileId: profile.ProfileId,
          overwrite: "",
        },
      })
        .then((res) => {
          if (res.success && res.data?.Product_to_rules?.length > 0) {
            setProducts((prev) => {
              const temp = prev;
              res.data?.Product_to_rules.forEach(
                (product: any, index: number) => {
                  if (
                    select_all === "1" &&
                    !selectedResources.includes(product?.product_id ?? "") &&
                    routeData?.data?.type !== "edit"
                  ) {
                    handleSelectionChange(
                      IndexTableSelectionType.Single,
                      true,
                      product?.product_id ?? "",
                      index
                    );
                  }
                  temp.push({
                    image: replaceLastOccurrence(
                      JSON.parse(product?.images ?? "")?.[0]?.url ?? "",
                      ".",
                      "_small."
                    ),
                    title: product?.title ?? "",
                    id: product?.product_id ?? "",
                  });
                }
              );
              return [...temp];
            });
          }
        })
        .finally(() => {
          setLoading((prev) => ({
            ...prev,
            full_loading: false,
            more_loading: false,
          }));
        });
    };

    const handleSave = () => {
      setOpen((prev) => !prev);
      updateRuleGroup((prev) => {
        prev.select_all =
          selectedResources.length === products.length ? "1" : "0";
        prev.update_on_shopify = [...new Set(selectedResources)];
        return { ...prev };
      });
    };

    useEffect(() => {
      if (open) {
        fetchProducts();
      }
      return () => {
        setProducts([]);
        setLoading({ full_loading: false, more_loading: false });
      };
    }, [open, activePage]);

    return (
      <ModalAppBridge
        open={open}
        title={t("MATCHING_PRODUCTS")}
        footer={
          <>
            <button
              variant="primary"
              onClick={() => {
                clearSelection();
                handleSave();
                setActivePage(1);
              }}
            >
              {t("SAVE")}
            </button>
            <button
              onClick={() => {
                clearSelection();
                setActivePage(1);
                setOpen((prev) => !prev);
              }}
            >
              {t("CANCEL")}
            </button>
          </>
        }
        body={
          <Box padding="400">
            <Card padding="0">
              {loading.full_loading ? (
                <RuleGroupResultModalSkeleton />
              ) : (
                <div className="view-products-grid">
                <IndexTable
                  headings={[
                    { title: t("IMAGES") },
                    { title: t("TITLE") },
                    { title: t("PRODUCT_ID") },
                  ]}
                  itemCount={products.length}
                  resourceName={{ singular: "product", plural: "products" }}
                  selectedItemsCount={selectedResources.length}
                  onSelectionChange={handleSelectionChange}
                  loading={loading.full_loading}
                  // pagination={{
                  //   hasNext:
                  //     activePage < Math.ceil((totalProduct ?? 0) / 10) &&
                  //     !loading.more_loading,
                  //   hasPrevious: activePage > 1 && !loading.more_loading,
                  //   onNext: () => {
                  //     setActivePage((prev) => prev + 1);
                  //   },
                  //   onPrevious: () => {
                  //     setActivePage((prev) => prev - 1);
                  //   },
                  // }}
                  hasMoreItems={
                    (activePage < Math.ceil((totalProduct ?? 0) / 10) ||
                      activePage > 1) &&
                    !loading.more_loading
                  }
                >
                  {products.map(({ image, id, title }, index) => {
                    return (
                      <IndexTable.Row
                        id={id}
                        position={index}
                        key={id}
                        selected={selectedResources.includes(id)}
                      >
                        <IndexTable.Cell className="image-cell-view-products"> 
                          <Thumbnail source={image} alt="" />
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          {getTrimLength(title, 25, "", "span", "")}
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as="span">{id}</Text>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    );
                  })}
                </IndexTable>

                <Box
                paddingBlock="200"
                paddingInline="300"
                borderBlockStartWidth="025"
                borderColor="border"
                >
                <InlineStack align="space-between" blockAlign="center" gap="200">
                  <Text variant="bodyMd" as="p" tone="subdued">{selectedResources.length} {t("SELECTED")}</Text>
                  <Pagination
                    hasPrevious={activePage > 1 && !loading.more_loading}
                    hasNext={
                      activePage < Math.ceil((totalProduct ?? 0) / 10) &&
                          !loading.more_loading
                    }
                    onNext={() =>  setActivePage((prev) => prev + 1)}
                    onPrevious={() => { setActivePage((prev) => prev - 1); }}
                  />
                  </InlineStack>
                  </Box>
                </div>
              )}
            </Card>
          </Box>
        }
        id="viewProductsModal"
        variant="large"
        onHide={() => {
          setActivePage(1);
          clearSelection();
          setOpen((prev) => !prev);
        }}
      />
    );
  }
);

export default ViewProducts;

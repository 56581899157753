import {
  Box,
  Card,
  DatePicker,
  Icon,
  Popover,
  TextField,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { CalendarIcon } from "@shopify/polaris-icons";
import { DI } from "Src/core";
const SingleDatepicker = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(undefined);
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const formattedValue = selectedDate
    ? `${selectedDate.getFullYear()}-${
        selectedDate.getMonth() + 1
      }-${selectedDate.getDate()}`
    : "";

  function handleOnClose({ relatedTarget }: any) {
    setVisible(false);
  }
  function handleMonthChange(month: any, year: any) {
    setDate({ month, year });
  }
  function handleDateSelection({ end: newSelectedDate }: any) {
    setSelectedDate(newSelectedDate);
    setVisible(false);
  }
  useEffect(() => {
    if (selectedDate) {
      setDate({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      });
    }
    props.getSelDate(formattedValue);
  }, [selectedDate]);
  return (
    <Box minWidth="276px" padding={{ xs: "100" }}>
      <Popover
        active={visible}
        autofocusTarget="none"
        preferredAlignment="left"
        fullWidth
        preferInputActivator={false}
        preferredPosition="below"
        preventCloseOnChildOverlayClick
        onClose={handleOnClose}
        activator={
          <TextField
            role="combobox"
            label={props.label}
            prefix={<Icon source={CalendarIcon} />}
            value={formattedValue}
            onFocus={() => setVisible(true)}
            autoComplete="off"
          />
        }
      >
        <Card>
          <DatePicker
            month={month}
            year={year}
            disableDatesAfter={
              props.label === "From"
                ? new Date(props.localFilter.created_at.to)
                : undefined
            }
            disableDatesBefore={
              props.label === "To"
                ? new Date(props.localFilter.created_at.from)
                : undefined
            }
            selected={selectedDate}
            onMonthChange={handleMonthChange}
            onChange={handleDateSelection}
          />
        </Card>
      </Popover>
    </Box>
  );
};

export default DI(SingleDatepicker);

import {
  BlockStack,
  Box,
  Button,
  ChoiceList,
  IndexFilters,
  IndexFiltersProps,
  InlineStack,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import React, { useCallback, useMemo, useState } from "react";
import SingleDatepicker from "./SingleDatepicker";
import { useProfileGridContext } from "../ProfileGridProvider";
import useDebounce from "Src/hooks/useDebounce";
import { convertToStartCase } from "Src/function";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const Filter = ({ t }: DIProps) => {
  const { filters, updateFilters } = useProfileGridContext();
  const [sortSelected, setSortSelected] = useState(["created_at desc"]);
  const [localFilter, setLocalFilter] = useState<any>({
    created_at: { from: "", to: "" },
  });

  const sortOptions: IndexFiltersProps["sortOptions"] = [
    {
      label: t("PROFILE_NAME"),
      value: "profile_code asc",
      directionLabel: "A-Z",
    },
    {
      label: t("PROFILE_NAME"),
      value: "profile_code desc",
      directionLabel: "Z-A",
    },
    { label: t("CREATED_ON"), value: "created_at asc", directionLabel: "A-Z" },
    { label: t("CREATED_ON"), value: "created_at desc", directionLabel: "Z-A" },
  ];
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {
    changeDebouncedValue("", false);
    handleFiltersQueryChange("");
    updateFilters({});
    setLocalFilter({ created_at: { from: "", to: "" } });
  };

  const handleFiltersQueryChange = useCallback(
    (value: string) =>
      updateFilters({ ...filters, profile_name: value.trim() }),
    [filters]
  );
  // using debounced search value
  const { changeDebouncedValue, debouncedValue } = useDebounce(
    handleFiltersQueryChange,
    700
  );

  const profilesTabFilter = [
    {
      key: "enable-profile",
      label: t("ENABLE_PROFILE"),
      filter: (
        <ChoiceList
          title=""
          choices={[
            { label: t("ENABLE"), value: "enable" },
            { label: t("DISABLE"), value: "disable" },
          ]}
          selected={[filters?.enable_profile as string]}
          onChange={(e) => {
            updateFilters({ ...filters, enable_profile: e[0] });
          }}
        />
      ),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
    },
    {
      key: "profile-status",
      label: t("PROFILE_STATUS"),
      filter: (
        <ChoiceList
          title=""
          choices={[
            { label: t("COMPLETE"), value: "complete" },
            { label: t("INCOMPLETE"), value: "incomplete" },
          ]}
          selected={[filters?.profile_status as string]}
          onChange={(e) => {
            updateFilters({ ...filters, profile_status: e[0] });
          }}
        />
      ),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
    },
    {
      key: "created-at",
      label: t("CREATED_AT"),
      filter: (
        <Box paddingBlockEnd="200">
          <BlockStack gap="200">
            <SingleDatepicker
              localFilter={localFilter}
              getSelDate={(e: any) => {
                setLocalFilter((prev: any) => ({
                  ...prev,
                  created_at: { ...prev.created_at, from: e },
                }));
              }}
              label={t("FROM")}
            />
            <SingleDatepicker
              localFilter={localFilter}
              getSelDate={(e: any) =>
                setLocalFilter((prev: any) => ({
                  ...prev,
                  created_at: { ...prev.created_at, to: e },
                }))
              }
              label={t("TO")}
            />
            <InlineStack>
              <Button
                disabled={
                  localFilter.created_at.to.trim() === "" &&
                  localFilter.created_at.from.trim() === ""
                }
                onClick={() => {
                  updateFilters({
                    ...filters,
                    created_at: localFilter.created_at,
                  });
                }}
              >
                {t("ADD_FILTER")}
              </Button>
            </InlineStack>
          </BlockStack>
        </Box>
      ),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
    },
  ];
  const appliedFiltersHelper: any = {
    enable_profile: { key: "enable-profile", label: "Enable profile" },
    profile_status: { key: "profile-status", label: "Profile status" },
    created_at: { key: "created-at", label: "Created On" },
  };
  const appliedFilters = useMemo(() => {
    return Object.keys(filters)
      .filter((e) => !["sort", "profile_name"].includes(e))
      .map((key) => {
        const getLabel = () => {
          const temp = filters[key];
          if (typeof temp !== "string") {
            let Label = "";
            if ((temp["from"] as string).trim() === "") {
              Label = Label + "----/--/-- to ";
            } else {
              Label = Label + temp["from"] + " to ";
            }
            if ((temp["to"] as string).trim() === "") {
              Label = Label + "----/--/--";
            } else {
              Label = Label + temp["to"];
            }
            return convertToStartCase(Label);
          }
          return convertToStartCase(temp);
        };
        const appliedKey = appliedFiltersHelper?.[key]?.key ?? "";
        const appliedLabel = `${
          appliedFiltersHelper?.[key]?.label
        } : ${getLabel()}`;
        return {
          key: appliedKey,
          label: appliedLabel,
          onRemove: () => {
            const tempFilter = { ...filters };
            delete tempFilter[key];
            if (key === "created_at") {
              setLocalFilter({ created_at: { from: "", to: "" } });
            }
            updateFilters(tempFilter);
          },
        };
      });
  }, [filters]);

  const Abc = () => {
    alert("gggg");
  };

  return (
    <IndexFilters
      filteringAccessibilityTooltip="Search and filter (F)"
      // primaryAction={{
      //   type: 'cancel',
      //   onAction: () =>,
      //   disabled: false,
      //   loading: false
      // }}
      sortOptions={sortOptions}
      sortSelected={sortSelected}
      queryValue={debouncedValue ?? ""}
      queryPlaceholder={t("SEARCH_PROFILE_NAME")}
      onQueryChange={changeDebouncedValue}
      onQueryClear={() => {
        changeDebouncedValue("", false);
        handleFiltersQueryChange("");
      }}
      onSort={(e) => {
        setSortSelected(e);
        const value = e[0].split(" ");

        updateFilters({
          ...filters,
          sort: value[1] === "asc" ? value[0] : `-${value[0]}`,
        });
      }}
      cancelAction={{
        onAction: onHandleCancel,
        disabled: false,
        loading: false,
      }}
      tabs={[]}
      selected={0}
      filters={profilesTabFilter}
      appliedFilters={appliedFilters}
      onClearAll={() => {
        setLocalFilter({ created_at: { from: "", to: "" } });
        updateFilters({});
      }}
      mode={mode}
      setMode={setMode}
    />
  );
};

export default DI(Filter);

import React, { useState } from "react";
import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Icon,
  InlineGrid,
  InlineStack,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import { DeleteIcon, PlusIcon } from "@shopify/polaris-icons";
import CustomMultiSelect from "Src/Component/Profilling/CreateProfile/components/CustomMultiSelect";
import { useSettingsContext } from "../SettingsProvider";
import { isoCodeToCountryMap } from "Src/Constant/helpData";
import { regexExpressions } from "Src/Constants";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const CountryTaxMapping = ({t}:DIProps) => {
  const {mdUp, mdDown} = useBreakpoints();
  const { localConfig, updateLocalConfig } = useSettingsContext();
  const [countriesMapping, setCountriesMapping] = useState(
    localConfig?.vat_setting?.custom_countries &&
      localConfig?.vat_setting?.custom_countries?.length > 0
      ? localConfig?.vat_setting?.custom_countries
      : [
          {
            etsy_country: [],
            tax_value: "",
          },
        ]
  );

  const selectedCountries: string[] =
    countriesMapping?.reduce((prev: string[], curr: any) => {
      return [...prev, ...curr.etsy_country];
    }, []) ?? [];

  const countryOptions = Object.values(isoCodeToCountryMap)
    .map((country) => ({
      label: country,
      value: country,
    }))
    .filter((option) => !selectedCountries.includes(option.value));

  const addRow = () => {
    const tempCountries = [...countriesMapping];
    tempCountries.push({
      etsy_country: [],
      tax_value: "",
    });
    setCountriesMapping(tempCountries);
    updateLocalConfig({...localConfig,
      vat_setting: {
        ...localConfig?.["vat_setting"],
        custom_countries: tempCountries,
      },
    });
  };

  const removeRow = (index: number) => {
    const tempCountries = [...countriesMapping];
    tempCountries.splice(index, 1);
    setCountriesMapping([...tempCountries]);
    updateLocalConfig({...localConfig,
      vat_setting: {
        ...localConfig?.["vat_setting"],
        custom_countries: tempCountries,
      },
    });
  };

  const changeHandlerMapping = (
    index: number,
    key: "etsy_country" | "tax_value",
    value: string | string[]
  ) => {
    if (
      key === "tax_value" &&
      typeof value === "string" &&
      !regexExpressions.NUMBER_CHECK_WITH_DOT.test(value)
    ) {
      return;
    }
    const tempCountries = [...countriesMapping];
    tempCountries[index][key] = value;
    setCountriesMapping(tempCountries);
    updateLocalConfig({...localConfig,
      vat_setting: {
        ...localConfig?.["vat_setting"],
        custom_countries: tempCountries,
      },
    });
  };

  return (
    <Card roundedAbove="xs">
        <BlockStack gap={mdUp ? "200" : "0"}>
        {countriesMapping?.map((row: any, index: any) => (
          <>
          <InlineStack gap="200" wrap={mdUp ? false : true}>
            <Box width="100%">
              <InlineGrid columns={mdUp ? 2 : 1} gap="200" alignItems="start">
                <div className="combobox-tags">
                  <CustomMultiSelect
                    value={row?.etsy_country ?? []}
                    onChange={(val) => {
                      changeHandlerMapping(index, "etsy_country", val);
                    }}
                    options={countryOptions}
                    label={
                      index === 0 ? (
                        <Text as="span" fontWeight="medium" variant="bodyMd">
                          {t("ETSY_COUNTRIES")}
                        </Text>
                      ) : mdDown ?  <Text as="span" fontWeight="medium" variant="bodyMd">
                      {t("ETSY_COUNTRIES")}
                    </Text> : null
                    }
                    placeholder={t("SEARCH")}
                    noCustomValue
                  />
                </div>

                <TextField
                  label={
                    <Text as="span" fontWeight="medium" variant="bodyMd">
                      {t("TAX_VALUE")}
                    </Text>
                  }
                  labelHidden={index !== 0 && mdUp}
                  placeholder={t("ENTER_TAX_VALUE")}
                  suffix="%"
                  value={row.tax_value ?? ""}
                  onChange={(val) => {
                    changeHandlerMapping(index, "tax_value", val);
                  }}
                  autoComplete="off"
                />
              </InlineGrid>
            </Box>

            <div className="delete_btn_icon" style={{ paddingBlockStart: mdUp && index === 0 ? "30px" : mdUp ? "8px" : "0"  }}>
              <Button
                icon={mdUp ? <Icon source={DeleteIcon} tone="critical" /> : ''}
                children={mdDown ? "Delete" : ""}
                variant="plain"
                tone="critical"
                onClick={() => {
                  removeRow(index);
                }}
              />
            </div>
          </InlineStack>
          {mdDown  && (<Box paddingBlockStart='050' paddingBlockEnd="200"><Divider/></Box>)}
          </>
        ))}

        <BlockStack gap="300">
         {mdUp && <Divider />}
          <InlineStack align="end">
            <Button onClick={addRow} icon={PlusIcon}>
              {t("ADD_TAX")}
            </Button>
          </InlineStack>
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default DI(CountryTaxMapping);

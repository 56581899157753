// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-mt-12 {
    margin-top: 12px;
}

.custom_spacing_fallback>.Polaris-Box {
    padding-bottom: 0;
    margin-top: 90px;
    margin-bottom: 90px;
}

.description_fallback p {
    color: #616161;
}
.dashboard-fallback .custom_spacing_fallback>.Polaris-Box {
    margin-top: 0;
    margin-bottom: 0;
}
.dashboard-fallback img {
    width: 90px;
}
.dashboard-fallback .Polaris-EmptyState__SkeletonImage {
    width: 90px;
    height: 90px;
}
.medium-height-fallback .custom_spacing_fallback>.Polaris-Box {
    margin-top: 45px;
    margin-bottom: 45px;
}

.custom_spacing_fallback.fullpage {
    height: calc(100vh - 75px);
    display: table;
    width: 100%;
}
.custom_spacing_fallback.fullpage>.Polaris-Box {
    margin: 0;
    padding: 0;
    display: table-cell;
    vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/Component/Fallbacks/emptystate.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,cAAc;IACd,WAAW;AACf;AACA;IACI,SAAS;IACT,UAAU;IACV,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":[".empty-mt-12 {\n    margin-top: 12px;\n}\n\n.custom_spacing_fallback>.Polaris-Box {\n    padding-bottom: 0;\n    margin-top: 90px;\n    margin-bottom: 90px;\n}\n\n.description_fallback p {\n    color: #616161;\n}\n.dashboard-fallback .custom_spacing_fallback>.Polaris-Box {\n    margin-top: 0;\n    margin-bottom: 0;\n}\n.dashboard-fallback img {\n    width: 90px;\n}\n.dashboard-fallback .Polaris-EmptyState__SkeletonImage {\n    width: 90px;\n    height: 90px;\n}\n.medium-height-fallback .custom_spacing_fallback>.Polaris-Box {\n    margin-top: 45px;\n    margin-bottom: 45px;\n}\n\n.custom_spacing_fallback.fullpage {\n    height: calc(100vh - 75px);\n    display: table;\n    width: 100%;\n}\n.custom_spacing_fallback.fullpage>.Polaris-Box {\n    margin: 0;\n    padding: 0;\n    display: table-cell;\n    vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Collapsible,
  Divider,
  FormLayout,
  InlineStack,
  Page,
  Select,
  Text,
  TextField,
  Tooltip,
  useBreakpoints,
} from "@shopify/polaris";
import React, { lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import {
  amountInOptions,
  customPriceTypeOptions,
  roundOffOptionsGroups,
} from "Src/Constant/SelectOptions";
import { apiUrls } from "Src/Constant/url";
import { regexExpressions } from "Src/Constants";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { ExtraModalFormTypeI } from "Src/Interface/@Profile";
const RoundOffCalculation = lazy(
  () => import("../Components/PriceHelper/RoundOffCalculation")
);

interface FormDataI {
  name: string;
  compare_at_price: string;
  enable_custom_pricing: string;
  price_customization_type: string;
  fixed_or_percentage: string;
  custom_price_value: string;
  price_applied_type: string;
}

const Price = ({
  request: { POST },
  t,
  type = "page",
  setSaveLoading,
  saveCall = false,
  setSaveCall,
  closeModal,
  setTemplateId,
  onCreation
}: ExtraModalFormTypeI & DIProps) => {
  const {mdUp} = useBreakpoints();
  const navigate = useNavigate();
  const { saveTemplateData } = apiUrls;
  const {
    routeData: { data: editData },
    setRouteData,
  } = useRoutesContext();
  const [formData, setFormData] = useState<FormDataI>({
    name: editData?.template_name ?? `price_${Date.now()}`,
    compare_at_price: editData?.compare_at_price ?? "0",
    enable_custom_pricing: editData?.custom_pricing ?? "0",
    price_customization_type:
      editData?.price_customization_type ?? "increased_price",
    fixed_or_percentage: editData?.fixed_or_percentage ?? "fixed",
    custom_price_value: editData?.custom_price_value ?? "",
    price_applied_type: editData?.round_off ?? " ",
  });
  const [errorState, setErrorState] = useState({
    name: { error: false, errorMsg: "" },
    custom_price_value: {
      error: formData.custom_price_value === "",
      errorMsg: "",
    },
  });
  const [openPriceRoundCalc, setOpenPriceRoundCalc] = useState(false);
  const [loading, setLoading] = useState(false);
  /**
   * handles changes in formdata
   * @param key name of key to be updated
   * @param value updated value
   * @param isToggle whether value is to be toggled
   */
  const changeHandler = (
    key: keyof FormDataI,
    value: string,
    isToggle: boolean = false
  ) => {
    const numericValueKeys = ["custom_price_value"];
    if (
      numericValueKeys.includes(key) &&
      !regexExpressions.NUMBER_CHECK_WITH_DOT.test(value)
    ) {
      return;
    }
    let tempValue = value;
    if (isToggle) {
      tempValue = formData[key] === "0" ? "1" : "0";
    }
    setFormData((prev) => ({ ...prev, [key]: tempValue }));
    if (key in errorState) {
      setErrorState((prev) => ({
        ...prev,
        [key]: { error: false, errorMsg: "" },
      }));
    }
  };
  /**
   * handles validations on blur events
   * @param key name of key
   */
  const blurHandler = (key: "name" | "custom_price_value") => {
    const errorObj = { ...errorState?.[key] };
    if (key === "name" && !formData.name) {
      errorObj.errorMsg = t("TEMPLATE_NAME_CANNNOT_BE_EMPTY");
    } else if (
      key === "custom_price_value" &&
      formData.custom_price_value === ""
    ) {
      errorObj.errorMsg = t("CUSTOM_PRICE_MUST_NOT_BE_EMPTY");
    }
    if (errorObj.errorMsg) {
      errorObj.error = true;
    }
    setErrorState((prev) => ({ ...prev, [key]: errorObj }));
  };
  /**
   * checks the validations of form details
   * @returns whether form has errors
   */
  const validateForm = () => {
    const { custom_price_value, ...extractErrorState } = errorState;
    if (formData["enable_custom_pricing"] === "1") {
      if (errorState.custom_price_value.error) {
        blurHandler("custom_price_value");
        return false;
      }
    }
    return Object.values(extractErrorState).every((val) => !val.error);
  };
  // initiates the api for creation of price template
  const createPricingTemplate = () => {
    if (!validateForm()) {
      return;
    }
    const price_management: { [key: string]: string } = {
      compare_at_price: formData.compare_at_price,
      "enable-custom-pricing": formData.enable_custom_pricing,
      "price-applied-type": formData.price_applied_type,
    };
    if (formData.enable_custom_pricing === "1") {
      Object.assign(price_management, {
        "price-customization-type": formData.price_customization_type,
        "fixed-or-percentage": formData.fixed_or_percentage,
        custom_price_value: formData.custom_price_value,
      });
    }
    const payload = {
      price_management,
      template_type: "price",
      if_case_of_edit: editData?.id ?? "",
      name: formData.name,
    };
    setLoading(true);
    POST(saveTemplateData, payload)
      .then((res) => {
        if (res?.success) {
          setTemplateId && setTemplateId(res?.template_id as string);
          res?.template_id && onCreation?.({[res?.template_id]: formData.name})
          if (closeModal) {
            closeModal();
          } else {
            setRouteData({
              data: { selected: 2 },
              from: "price-form",
              to: "template",
            });
            navigate("/panel/template");
          }
        }
        ToastAppBridge(res?.message, { isError: !res?.success });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (saveCall) {
      createPricingTemplate();
      setSaveCall?.(false);
    }
  }, [saveCall]);
  return (
    <div>
      <Box borderBlockEndWidth={mdUp ? "025" : "0"} borderColor="border">
        {type !== "modal" && (
          <div className="custom_layout_width">
            <Page
              backAction={{
                content: t("PRICE_TEMPLATE"),
                onAction: () => {
                  setRouteData({
                    data: { selected: 2 },
                    from: "price-form",
                    to: "template",
                  });
                  navigate(-1);
                },
              }}
              fullWidth
              title={t("PRICE_TEMPLATE")}
              primaryAction={{
                content: t("SAVE"),
                accessibilityLabel: "Save",
                onAction: createPricingTemplate,
                loading: loading,
              }}
              // secondaryActions={[
              //   { content: "Need Help ?", accessibilityLabel: "Need Help ?" },
              // ]}
            ></Page>
          </div>
        )}
      </Box>
      <div className="custom_layout_width">
        <Page fullWidth>
          <BlockStack gap={"400"}>
            {/* <Banner onDismiss={() => { }}>
              <p>
                Price template is used to set a different price for your products
                on Etsy. Create multiple templates or use one template in multiple
                profiles to change the prices before listing your product(s).
              </p>
            </Banner> */}
            <Card>
              <BlockStack gap={"200"}>
                <BlockStack gap={"200"}>
                  <Text as="h6" variant="headingMd" fontWeight="semibold">
                    {t("TEMPLATE_NAME")}
                  </Text>
                  <Divider />
                </BlockStack>
                <TextField
                  label=""
                  placeholder={t("ENTER_NAME_FOR_THE_TEMPLATE")}
                  value={formData.name}
                  onChange={(val) => changeHandler("name", val)}
                  onBlur={() => blurHandler("name")}
                  autoComplete="off"
                  error={errorState.name.error ? errorState.name.errorMsg : ""}
                />
              </BlockStack>
            </Card>

            <Card>
              <BlockStack gap={"300"}>
                <BlockStack gap={"200"}>
                  <Text as="h6" variant="headingMd" fontWeight="semibold">
                    {t("PRICING_RULES")}
                  </Text>
                  <Divider />
                </BlockStack>
                <BlockStack gap={"300"}>
                  <Checkbox
                    label={t("ENABLE_COMPARE_AT_PRICE")}
                    helpText={t("ENABLE_COMPARE_AT_PRICE_HELPTEXT")}
                    checked={formData.compare_at_price === "1"}
                    onChange={() => changeHandler("compare_at_price", "", true)}
                  />
                  <Divider />
                  <ChoiceList
                    title=""
                    allowMultiple
                    choices={[
                      {
                        label: t("ENABLE_CUSTOM_PRICING"),
                        helpText: t("ENABLE_CUSTOM_PRICING_HELPTEXT"),
                        value: "customPricing",
                        renderChildren: () =>
                          formData["enable_custom_pricing"] === "1" ? (
                            <FormLayout>
                              <FormLayout.Group condensed={mdUp ? true : false}>
                                <Select
                                  placeholder={t("SELECT")}
                                  label={t("CHANGE_TYPE")}
                                  options={customPriceTypeOptions}
                                  value={formData["price_customization_type"]}
                                  onChange={(val) =>
                                    changeHandler(
                                      "price_customization_type",
                                      val
                                    )
                                  }
                                />
                                <Select
                                  requiredIndicator
                                  placeholder={t("SELECT")}
                                  label={t("AMOUNT_IN")}
                                  options={amountInOptions}
                                  value={formData["fixed_or_percentage"]}
                                  onChange={(val) =>
                                    changeHandler("fixed_or_percentage", val)
                                  }
                                />
                                <TextField
                                  prefix={
                                    formData["fixed_or_percentage"] !==
                                    "percentage"
                                      ? "₹"
                                      : null
                                  }
                                  label={
                                    <Tooltip
                                      content={t("ENTER_NUMERICAL_VALUES_ONLY")}
                                    >
                                      <Text as="span" variant="bodyMd">
                                        {t("VALUE")}
                                      </Text>
                                    </Tooltip>
                                  }
                                  value={formData["custom_price_value"]}
                                  onChange={(val) =>
                                    changeHandler("custom_price_value", val)
                                  }
                                  onBlur={() => {
                                    blurHandler("custom_price_value");
                                  }}
                                  autoComplete="off"
                                  placeholder="0.00"
                                  error={
                                    errorState.custom_price_value.error
                                      ? errorState.custom_price_value.errorMsg
                                      : ""
                                  }
                                />
                              </FormLayout.Group>
                            </FormLayout>
                          ) : null,
                      },
                    ]}
                    onChange={() =>
                      changeHandler("enable_custom_pricing", "", true)
                    }
                    selected={
                      formData["enable_custom_pricing"] === "1"
                        ? ["customPricing"]
                        : []
                    }
                  />
                  <Divider />
                  <BlockStack gap={"100"}>
                    <Select
                      placeholder={t("SELECT")}
                      label={
                        <BlockStack gap={"100"}>
                          <Text
                            as="h6"
                            variant="headingSm"
                            fontWeight="regular"
                          >
                            {t("PRICE_ROUND_OFF")}
                          </Text>
                          <Text as="p" variant="bodyMd" tone="subdued">
                            {t(
                              "INCREASE_OR_DECREASE_PRODUCT_PRICE_TO_ROUND_OFF"
                            )}
                          </Text>
                        </BlockStack>
                      }
                      options={roundOffOptionsGroups(t)}
                      value={formData["price_applied_type"]}
                      onChange={(val) =>
                        changeHandler("price_applied_type", val)
                      }
                    />
                    <InlineStack align="start">
                      <Button
                        onClick={() => {
                          setOpenPriceRoundCalc((prev) => !prev);
                        }}
                        ariaExpanded={openPriceRoundCalc}
                        ariaControls="basic-collapsible"
                        variant="plain"
                        disclosure={openPriceRoundCalc ? "up" : "down"}
                      >
                        {t("SHOW_PRICE_ROUND_OFF_CALCULATOR")}
                      </Button>
                    </InlineStack>
                    {openPriceRoundCalc && (
                      <Collapsible
                        open={openPriceRoundCalc}
                        id="basic-collapsible"
                        transition={{
                          duration: "500ms",
                          timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                      >
                        <Card background="bg-surface-secondary">
                          <BlockStack gap="200">
                            <Text variant="bodyMd" tone="subdued" as="p">
                              {t("PRICE_ROUND_OFF_DESC")}
                            </Text>

                            <RoundOffCalculation />
                          </BlockStack>
                        </Card>
                      </Collapsible>
                    )}
                  </BlockStack>
                </BlockStack>
              </BlockStack>
            </Card>
          </BlockStack>
        </Page>
      </div>
    </div>
  );
};

export default DI(Price);

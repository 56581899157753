import { Thumbnail, ThumbnailProps } from "@shopify/polaris";
import React from "react";
import NoImage from "Src/assets/FallBackImages/NoImage.svg";
import { hasAnyValidValue } from "Src/function";

const ThumbnailWithFallback = (_props: ThumbnailProps) => {
  const { source } = _props;
  return (
    <Thumbnail
      {..._props}
      source={hasAnyValidValue([source]) ? source : NoImage}
    />
  );
};

export default ThumbnailWithFallback;

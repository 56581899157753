import { createContext, useContext, useMemo, useState } from "react";
import { AttributesObjI, ObjStrI } from "Src/Interface/@core";

interface InitialStateI {
  product: any;
  localProduct: any;
  recommendedAttributes: AttributesObjI[];
  productLoading: boolean;
  multilocationEnabled: boolean;
  variants: any[];
  localVariants: any[];
  variantAttributes: AttributesObjI[];
  shopifyAttributeNames: string[];
  orderedImages: any[];
  saveLoading: boolean;
  templateOptionsMap: {
    shipping: ObjStrI;
    policies: ObjStrI;
    shopSections: ObjStrI;
  };
  profileMapped:any,
  errors: ObjStrI;
  metafields:string[],
  mapped_attribute_options:any
}

interface ProductEditProviderI extends InitialStateI {
  saveProductData: (param: {
    product: any;
    recommendedAttributes: AttributesObjI[];
    multilocationEnabled: boolean;
    variants: any[];
    variantAttributes: AttributesObjI[];
    shopifyAttributeNames: string[];
    orderedImages: any[];
    templateOptionsMap: {
      shipping: ObjStrI;
      policies: ObjStrI;
      shopSections: ObjStrI;
    };
    profileMapped:any,
    metafields:string[],
    mapped_attribute_options:any
  }) => void;
  saveLocalProduct: (product: any) => void;
  toggleProductLoading: () => void;
  updateLocalProduct: (obj: any) => void;
  saveLocalVariants: (variants: any[]) => void;
  updateLocalVariants: (index: number, key: string, value: string) => void;
  saveOrderedImages: (orderedImages: any[]) => void;
  toggleSaveLoading: () => void;
  saveErrors: (errorObj: ObjStrI) => void;
  removeError: (key: string) => void;
}

const initialvalue: ProductEditProviderI = {
  product: {},
  localProduct: {},
  recommendedAttributes: [],
  productLoading: false,
  multilocationEnabled: false,
  variants: [],
  localVariants: [],
  variantAttributes: [],
  shopifyAttributeNames: [],
  orderedImages: [],
  saveLoading: false,
  templateOptionsMap: {
    shipping: {},
    policies: {},
    shopSections: {},
  },
  profileMapped:{},
  errors: {},
  metafields:[],
  mapped_attribute_options:{},
  saveProductData: () => {},
  saveLocalProduct: () => {},
  // saveProductCommonField: () => {},
  toggleProductLoading: () => {},
  updateLocalProduct: () => {},
  saveLocalVariants: () => {},
  updateLocalVariants: () => {},
  saveOrderedImages: () => {},
  toggleSaveLoading: () => {},
  saveErrors: () => {},
  removeError: () => {},
};

const ProductEditContext = createContext(initialvalue);

const ProductEditProvider = ({ children }: any) => {
  const [state, setState] = useState<InitialStateI>({
    product: {},
    localProduct: {},
    recommendedAttributes: [],
    productLoading: false,
    multilocationEnabled: false,
    variants: [],
    localVariants: [],
    variantAttributes: [],
    shopifyAttributeNames: [],
    orderedImages: [],
    saveLoading: false,
    templateOptionsMap: {
      shipping: {},
      policies: {},
      shopSections: {},
    },
    profileMapped:{},
    errors: {},
    metafields:[],
    mapped_attribute_options:{}
  });

  const toggleProductLoading = () => {
    setState((prev) => ({ ...prev, productLoading: !prev.productLoading }));
  };

  const saveProductData = (param: {
    product: any;
    recommendedAttributes: AttributesObjI[];
    multilocationEnabled: boolean;
    variants: any[];
    variantAttributes: AttributesObjI[];
    shopifyAttributeNames: string[];
    orderedImages: any[];
    templateOptionsMap: {
      shipping: ObjStrI;
      policies: ObjStrI;
      shopSections: ObjStrI;
    };
    profileMapped:any,
    metafields:string[]
  }) => {
    setState((prev) => ({ ...prev, ...param }));
  };

  const saveLocalProduct = (product: any) => {
    setState((prev) => ({ ...prev, localProduct: product }));
  };
  const updateLocalProduct = (obj: any) => {
    setState((prev) => ({
      ...prev,
      localProduct: { ...prev.localProduct, ...obj },
    }));
  };

  const saveLocalVariants = (localVariants: any) => {
    setState((prev) => ({ ...prev, localVariants }));
  };

  const updateLocalVariants = (index: number, key: string, value: string) => {
    const tempVariants = [...state.localVariants];
    Object.assign(tempVariants[index], { [key]: value });
    saveLocalVariants(tempVariants);
  };

  const saveOrderedImages = (orderedImages: any[]) => {
    setState((prev) => ({ ...prev, orderedImages }));
  };

  const toggleSaveLoading = () => {
    setState((prev) => ({ ...prev, saveLoading: !prev.saveLoading }));
  };

  const saveErrors = (errorsObj: ObjStrI) => {
    setState((prev) => ({ ...prev, errors: { ...errorsObj } }));
  };

  const removeError = (errorKey: string) => {
    if (state.errors?.[errorKey]) {
      const tempError = { ...state.errors };
      delete tempError[errorKey];
      setState((prev) => ({ ...prev, errors: { ...tempError } }));
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      toggleProductLoading,
      saveProductData,
      saveLocalProduct,
      updateLocalProduct,
      saveLocalVariants,
      updateLocalVariants,
      saveOrderedImages,
      toggleSaveLoading,
      saveErrors,
      removeError,
    }),
    [state]
  );

  return (
    <ProductEditContext.Provider value={value}>
      {children}
    </ProductEditContext.Provider>
  );
};

const useProductEditContext = () => useContext(ProductEditContext);
export { ProductEditProvider, useProductEditContext };

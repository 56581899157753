import { Icon } from "@shopify/polaris";
import React, { useEffect, useRef, useState } from "react";
import { ClipboardIcon, CheckIcon } from "@shopify/polaris-icons";
import { ToastAppBridge } from "./Toast";

interface PropsI {
  text: string;
  msg: string;
}

const CopyToClipboard = ({ text, msg }: PropsI) => {
  const [copied, setCopied] = useState(false);
  const timeoutId = useRef<any>(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        ToastAppBridge(msg);
        navigator.clipboard.writeText(text);
        setCopied(true);
        timeoutId.current = setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
    >
      <Icon source={copied ? CheckIcon : ClipboardIcon} tone="base" />
    </div>
  );
};

export default CopyToClipboard;

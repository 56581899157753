import { createContext, useContext, useMemo, useState } from "react";
import { ObjStrI } from "Src/Interface/@core";
interface InitialI {
  config: any;
  discardModal: { open: boolean; callbackFunc: () => void };
  isChange: boolean;
  localConfig: any;
  selectedTab: number;
  multiLocationEnabled: boolean;
  errors: ObjStrI;
  updateErrors: (obj: ObjStrI) => void;
  setDiscardModal: (obj: { open: boolean; callbackFunc: () => void }) => void;
  setIsChange: (e: boolean) => void;
  changeTab: (index: number) => void;
  updateConfig: (config: any, multiLocationEnabled: boolean) => void;
  updateLocalConfig: (config: any) => void;
  removeError:(key:string)=>void
}

const initialvalue: InitialI = {
  config: {},
  multiLocationEnabled: false,
  discardModal: { open: false, callbackFunc: () => {} },
  setDiscardModal: ({
    open = false,
    callbackFunc = () => {},
  }: {
    open: boolean;
    callbackFunc: () => void;
  }) => {},
  isChange: false,
  setIsChange: (e: boolean) => {},
  localConfig: {},
  selectedTab: 0,
  errors: {},
  updateErrors: (obj: ObjStrI) => {},
  changeTab: (index: number) => {},
  updateConfig: (config: any, multiLocationEnabled: boolean) => {},
  updateLocalConfig: (config: any) => {},
  removeError:()=>{}
};

const SettingsContext = createContext(initialvalue);

const SettingsProvider = ({ children }: any) => {
  const [settingsContextVal, setSettingsContextVal] =
    useState<InitialI>(initialvalue);

  const changeTab = (index: number) => {
    setSettingsContextVal((prev) => ({ ...prev, selectedTab: index }));
  };

  const updateConfig = (config: any, multiLocationEnabled: boolean) => {
    setSettingsContextVal((prev) => ({
      ...prev,
      config,
      multiLocationEnabled,
    }));
  };
  const setDiscardModal = (discardModal: {
    open: boolean;
    callbackFunc: () => void;
  }) => {
    setSettingsContextVal((prev) => ({ ...prev, discardModal }));
  };
  const setIsChange = (isChange: boolean) => {
    setSettingsContextVal((prev) => ({ ...prev, isChange }));
  };

  const updateLocalConfig = (localConfig: any) => {
    setSettingsContextVal((prev) => ({ ...prev, localConfig }));
  };

  const updateErrors = (obj: ObjStrI) => {
    setSettingsContextVal((prev) => ({
      ...prev,
      errors: {...obj },
    }));
  };

  const removeError = (errorKey: string) => {
    if (settingsContextVal.errors?.[errorKey]) {
      const tempError = { ...settingsContextVal.errors };
      delete tempError[errorKey];
      setSettingsContextVal((prev) => ({ ...prev, errors: { ...tempError } }));
    }
  };

  const value = useMemo(
    () => ({
      ...settingsContextVal,
      changeTab,
      updateConfig,
      setDiscardModal,
      setIsChange,
      updateLocalConfig,
      updateErrors,
      removeError
    }),
    [settingsContextVal]
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettingsContext = () => useContext(SettingsContext);
export { SettingsProvider, useSettingsContext };

import React from 'react'
import { BlockStack, Box, InlineStack, Layout, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'
import OrderAddOnPageSkeleton from './OrderAddOnPageSkeleton';

const WithHeaderOrderAddOnSkeleton = () => {
    const { mdDown } = useBreakpoints();

    return (
        <>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className='custom_layout_width'>
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>
                            {mdDown ? (
                                <InlineStack align='space-between' blockAlign='center' gap='050'>
                                    <div className='skeleton-height-20 ' style={{ "width": "20px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div className='skeleton-height-32px' style={{ "width": "100px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <BlockStack gap='200'>
                                            <div style={{ "width": "150px" }} className='skeleton-height-26'><SkeletonBodyText lines={1} /></div>
                                            <div style={{ "width": "90%" }}>  <SkeletonBodyText lines={1} /></div>
                                        </BlockStack>
                                    </div>

                                </InlineStack>
                            ) : (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <div style={{ width: "40%" }}>
                                        <InlineStack blockAlign='start' gap='200' align='start'>
                                            <div className='skeleton-height-20 ' style={{ "width": "20px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <BlockStack gap='100'>
                                                <div style={{ width: "150px" }} className='skeleton-height-26'><SkeletonBodyText lines={1} /></div>
                                                <div style={{ width: "200px" }} className='custom-height_skel'><SkeletonBodyText lines={1} /></div>
                                            </BlockStack>
                                        </InlineStack>
                                    </div>

                                    <div style={{ "width": "30px" }}>
                                        <SkeletonDisplayText size='small' />
                                    </div>

                                </InlineStack>
                            )}
                        </Page>
                    </div>
                </div>
            </Box>
            <div className='custom_layout_width'>
                <Page fullWidth>
                    <Layout>
                        <Layout.Section>
                            <BlockStack gap="600">
                                <OrderAddOnPageSkeleton />
                            </BlockStack>
                        </Layout.Section>
                    </Layout>
                </Page>
            </div>
        </>
    )
}

export default WithHeaderOrderAddOnSkeleton

import {
  Card,
  InlineStack,
  Text,
  BlockStack,
  Button,
  Checkbox,
  Icon,
  Thumbnail,
  Image,
  Box,
  Link,
} from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import GalleryUploadModal from "./GalleryUploadModal";
import { PlusIcon } from "@shopify/polaris-icons";
import { toggleArrElement } from "Src/function";
import { useProductEditContext } from "../ProductEditProvider";

import { DragHandleIcon } from "@shopify/polaris-icons";
import Sortable from "Src/Component/Settings/Sortable/Sortable";
import AltTextUpdateModal from "./AltTextUpdateModal";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const Gallery = ({t}:DIProps) => {
  const { orderedImages, saveOrderedImages, product } = useProductEditContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [altModalState, setAltModalState] = useState({
    isOpen: false,
    index: -1,
  });
  const [imagesState, setImagesState] = useState<{
    selected: imageOptionI[];
    deleteSelected: string[];
  }>({
    selected: [],
    deleteSelected: [],
  });

  const videoData = product?.shopify_videos ?? {};
  const videos = Object.keys(videoData)[0];

  const toggleGalleryModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const toggleAltTextModal = (index: number) => {
    setAltModalState((prev) => ({ isOpen: !prev.isOpen, index }));
  };

  const toggleDeleteSelected = (toBeToggled: string) => {
    const result = toggleArrElement(imagesState.deleteSelected, toBeToggled);
    setImagesState((prev) => ({ ...prev, deleteSelected: result }));
  };
  const deleteImages = () => {
    const tempOrderedImages = orderedImages.filter(
      (img) => !imagesState.deleteSelected.includes(img.url)
    );
    saveOrderedImages(tempOrderedImages);
    setImagesState((prev) => ({ ...prev, deleteSelected: [] }));
  };

  useEffect(() => {
    const tempSelected =
      orderedImages?.slice(0, 10)?.map((img: { url: string; alt: string }) => {
        return {
          label: <Thumbnail size="large" source={img?.url} alt={img?.alt} />,
          value: img?.url,
        };
      }) ?? [];
    setImagesState((prev) => ({ ...prev, selected: tempSelected }));
  }, []);

  const dragableImages = useMemo(
    () =>
      orderedImages
        .map((img, index) => ({
          id: "thumbnail_" + index,
          url: img?.url,
          alt: img?.alt,
          content: (
            <div
              className={`Thumbnail_wrapper ${
                imagesState.deleteSelected.includes(img.url)
                  ? "item-checked"
                  : ""
              }`}
            >
              <div
                className="page_product_image_thumbnail_inner"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Checkbox
                  label={""}
                  checked={imagesState.deleteSelected.includes(img.url)}
                  onChange={() => {
                    toggleDeleteSelected(img?.url);
                  }}
                />
              </div>
              <div className="dragable_alt_wrapper">
                <div className="dragable_icon">
                  <Icon source={DragHandleIcon} />
                </div>
                <div className="alt_text">
                  <span
                    onClick={() => {
                      toggleAltTextModal(index);
                    }}
                  >
                    {t("ALT")}
                  </span>
                </div>
              </div>
              <Image src={img?.url} alt={img.alt} source="" />
            </div>
          ),
        }))
        .slice(0, 10),
    [orderedImages, imagesState.deleteSelected]
  );

  const updateAltText = (altText: string) => {
    const tempOrderedImages = [...orderedImages];
    Object.assign(tempOrderedImages[altModalState.index], { alt: altText });
    saveOrderedImages([...tempOrderedImages]);
    toggleAltTextModal(-1);
  };

  return (
    <>
      <BlockStack gap={"200"}>
        <Box
          paddingInlineEnd={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
          paddingInlineStart={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
        >
          <Text as="h2" variant="headingLg">
            {t("IMAGES_N_VIDEOS")}
          </Text>
        </Box>
        <Card>
          <BlockStack gap={"300"}>
            <Text as="p" variant="bodyMd">
              {t("YOU_CAN_UPLOAD_UPTO_10_IMAGES")}
            </Text>
            {imagesState.deleteSelected.length > 0 && (
              <InlineStack align="space-between">
                <Checkbox
                  checked
                  label={`${imagesState.deleteSelected.length} ${t("SELECTED")}`}
                />

                <Button onClick={deleteImages} variant="plain" tone="critical">
                  {t("DELETE_SELECTION")}
                </Button>
              </InlineStack>
            )}

            {imagesState.selected.length > 0 ? (
              <InlineStack gap={"400"}>
                <Sortable
                  animationDuration={300}
                  customClass="product_thumbnail_sortable"
                  data={dragableImages}
                  isSortable
                  onChange={(newArr) => {
                    saveOrderedImages([
                      ...newArr.map((img: any) => ({
                        url: img?.url,
                        alt: img?.alt,
                      })),
                      ...orderedImages.slice(10),
                    ]);
                  }}
                  videoThumbnail={
                    videoData[videos]?.url && (
                      <div className="inte-sortable__item">
                        <div className={"Thumbnail_wrapper video_thumbnail"}>
                          <div className="page_product_video_thumbnail_inner">
                            <Link
                              onClick={() =>
                                window.open(videoData[videos]?.url, "_blank")
                              }
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8V20C28 24.4183 24.4183 28 20 28H8C3.58172 28 0 24.4183 0 20V8Z"
                                  fill="black"
                                  fill-opacity="0.71"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M19.375 12.4852C20.5417 13.1587 20.5417 14.8427 19.375 15.5163L11.875 19.8464C10.7083 20.52 9.25 19.678 9.25 18.3308L9.25 9.67058C9.25 8.32343 10.7083 7.48146 11.875 8.15504L19.375 12.4852ZM18.625 14.2172C18.7917 14.121 18.7917 13.8804 18.625 13.7842L11.125 9.45407C10.9583 9.35785 10.75 9.47813 10.75 9.67058L10.75 18.3308C10.75 18.5233 10.9583 18.6436 11.125 18.5473L18.625 14.2172Z"
                                  fill="white"
                                />
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  nonSortableItem={
                    <div className="inte-sortable__item">
                      <div
                        className="add_more_gallery"
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      >
                        <Icon source={PlusIcon} tone="base" />
                      </div>
                    </div>
                  }
                />
              </InlineStack>
            ) : (
              <div
                className="custom_drop_zone"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <BlockStack gap={"200"}>
                  <InlineStack gap={"1000"} blockAlign="center" align="center">
                    <Button>
                      {t("UPLOAD_NEW")}
                      </Button>
                    <Text as={"span"} variant="bodySm">
                      {t("SELECT_EXISTING")}
                    </Text>
                  </InlineStack>
                  <Text as="span" alignment="center" tone="subdued">
                    {t("ACCEPTS_IMG_FORMAT")}
                  </Text>
                </BlockStack>
              </div>
            )}
          </BlockStack>
        </Card>
      </BlockStack>
      {isModalOpen && (
        <GalleryUploadModal
          isOpen={isModalOpen}
          toggleGalleryModal={toggleGalleryModal}
          selected={imagesState.selected}
          setSelected={(selected: imageOptionI[]) =>
            setImagesState((prev) => ({ ...prev, selected }))
          }
        />
      )}
      {altModalState.isOpen && (
        <AltTextUpdateModal
          isOpen={altModalState.isOpen}
          toggleAltTextModal={toggleAltTextModal}
          currAltText={orderedImages[altModalState.index]?.alt}
          updateAltText={updateAltText}
        />
      )}
    </>
  );
};

export default DI(Gallery);

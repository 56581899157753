import { SelectOption } from "@shopify/polaris";
import { Tone } from "@shopify/polaris/build/ts/src/components/Badge";
import { ObjI, ObjStrI } from "Src/Interface/@core";
import { ArrowUpIcon, ArrowDownIcon } from "@shopify/polaris-icons";

export const shopifyTagsMap: { [key: string]: string } = {
  etsy_site: "etsy.com",
  app_name: "Cedcommerce Etsy integration",
  receipt_id: "Etsy order id",
  expected_ship_date: "Expected Ship Date",
};

export const dashboardChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const badgesToneMap: { [key: string]: Tone } = {
  active: "success",
  "not published": "info",
  "not profiled": "warning",
  paid: "warning",
  failed: "critical",
  completed: "success",
};

export const chartBgColorMap: { [key: string]: string } = {
  active: "#b4fed2",
  "not published": "#d5ebff",
  "not profiled": "#ffd6a4",
  others: "#0000000f",
  paid: "#ffd6a4",
  failed: "#FEDAD9",
  completed: "#b4fed2",
};

export const isoCodeToCountryMap: ObjStrI = {
  AQ: "Antarctica",
  AF: "Afghanistan",
  AO: "Angola",
  AL: "Albania",
  AE: "United Arab Emirates",
  AR: "Argentina",
  AM: "Armenia",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BI: "Burundi",
  BE: "Belgium",
  BJ: "Benin",
  BF: "Burkina Faso",
  BD: "Bangladesh",
  BG: "Bulgaria",
  BA: "Bosnia and Herzegovina",
  BY: "Belarus",
  BZ: "Belize",
  BO: "Bolivia",
  BR: "Brazil",
  BN: "Brunei",
  BT: "Bhutan",
  BW: "Botswana",
  CF: "Central African Republic",
  CA: "Canada",
  CH: "Switzerland",
  CL: "Chile",
  CN: "China",
  IC: "Ivory Coast",
  CM: "Cameroon",
  CG: "Congo, Republic of",
  CO: "Colombia",
  CR: "Costa Rica",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DE: "Germany",
  DJ: "Djibouti",
  DK: "Denmark",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EG: "Egypt",
  ER: "Eritrea",
  ES: "Spain",
  EE: "Estonia",
  ET: "Ethiopia",
  FI: "Finland",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom",
  GE: "Georgia",
  GH: "Ghana",
  GN: "Guinea",
  GM: "Gambia",
  GW: "Guinea-Bissau",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GL: "Greenland",
  GT: "Guatemala",
  GF: "French Guiana",
  GY: "Guyana",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IN: "India",
  IE: "Ireland",
  IQ: "Iraq",
  IS: "Iceland",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KR: "South Korea",
  KW: "Kuwait",
  LA: "Laos",
  LB: "Lebanon",
  LR: "Liberia",
  LY: "Libya",
  LK: "Sri Lanka",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  MA: "Morocco",
  MD: "Moldova",
  MG: "Madagascar",
  MX: "Mexico",
  MK: "Macedonia",
  ML: "Mali",
  MM: "Myanmar (Burma)",
  MN: "Mongolia",
  ME: "Montenegro",
  MZ: "Mozambique",
  MR: "Mauritania",
  MW: "Malawi",
  MY: "Malaysia",
  NA: "Namibia",
  NE: "Niger",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "The Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NZ: "New Zealand",
  OM: "Oman",
  PK: "Pakistan",
  PA: "Panama",
  PE: "Peru",
  PH: "Philippines",
  PG: "Papua New Guinea",
  PL: "Poland",
  PR: "Puerto Rico",
  PT: "Portugal",
  PY: "Paraguay",
  QA: "Qatar",
  RO: "Romania",
  RU: "Russia",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SD: "Sudan",
  SN: "Senegal",
  SL: "Sierra Leone",
  SV: "El Salvador",
  SO: "Somalia",
  RS: "Serbia",
  SR: "Suriname",
  SK: "Slovakia",
  SI: "Slovenia",
  SE: "Sweden",
  SZ: "Swaziland",
  TD: "Chad",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TM: "Turkmenistan",
  TT: "Trinidad",
  TN: "Tunisia",
  TR: "Turkey",
  TW: "Taiwan",
  TZ: "Tanzania",
  UG: "Uganda",
  UA: "Ukraine",
  UY: "Uruguay",
  US: "United States",
  UZ: "Uzbekistan",
  VE: "Venezuela",
  VN: "Vietnam",
  EH: "Western Sahara",
  YE: "Yemen",
  ZA: "South Africa",
  CD: "Zaire (Democratic Republic of Congo)",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  HK: "Hong Kong",
  SG: "Singapore",
  VU: "Vanuatu",
  CV: "Cape Verde",
  WF: "Wallis and Futuna",
  BM: "Bermuda",
  GI: "Gibraltar",
  MT: "Malta",
  AD: "Andorra",
  BS: "Bahamas",
  VG: "British Virgin Islands",
  BH: "Bahrain",
  NC: "New Caledonia",
  FJ: "Fiji",
  BB: "Barbados",
  MV: "Maldives",
  MU: "Mauritius",
  FO: "Faroe Islands",
  SB: "Solomon Islands",
  AN: "Netherlands Antilles",
  LC: "Saint Lucia",
  GD: "Grenada",
  KY: "Cayman Islands",
  VI: "U.S. Virgin Islands",
  VC: "Saint Vincent and the Grenadines",
  AS: "American Samoa",
  AI: "Anguilla",
  AG: "Antigua and Barbuda",
  AW: "Aruba",
  BV: "Bouvet Island",
  IO: "British Indian Ocean Territory",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  KM: "Comoros",
  CK: "Cook Islands",
  DM: "Dominica",
  FK: "Falkland Islands (Malvinas)",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GP: "Guadeloupe",
  GU: "Guam",
  HM: "Heard Island and McDonald Islands",
  VA: "Holy See (Vatican City State)",
  IM: "Isle of Man",
  KI: "Kiribati",
  KV: "Kosovo",
  LI: "Liechtenstein",
  MO: "Macao",
  MH: "Marshall Islands",
  MQ: "Martinique",
  YT: "Mayotte",
  FM: "Micronesia, Federated States of",
  MC: "Monaco",
  MS: "Montserrat",
  NR: "Nauru",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  PW: "Palau",
  PS: "Palestine, State of",
  SH: "Saint Helena",
  KN: "Saint Kitts and Nevis",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SC: "Seychelles",
  GS: "South Georgia and the South Sandwich Islands",
  SJ: "Svalbard and Jan Mayen",
  TL: "Timor-Leste",
  TK: "Tokelau",
  TO: "Tonga",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UM: "United States Minor Outlying Islands",
  RE: "Reunion",
  GG: "Guernsey",
  AX: "Åland Islands",
  JE: "Jersey",
  BL: "Saint Barthélemy",
  SX: "Sint Maarten (Dutch part)",
  CW: "Curaçao",
  SS: "South Sudan",
  BQ: "Bonaire, Sint Eustatius and Saba",
  CS: "Serbia and Montenegro",
};

export const profileTypeMap: ObjI = {
  "0": "Manual",
  "1": "Calculated",
};

export const perPageOptions: SelectOption[] = [
  { label: "10", value: "10" },
  { label: "20", value: "20" },
  { label: "25", value: "25" },
  { label: "50", value: "50" },
  { label: "100", value: "100" },
];
export const postalCode: { [key: string]: [string, RegExp] } = {
  AX: ["#####", /^\d{5}$/], // Åland Islands
  AL: ["#####", /^\d{5}$/], // Albania
  DZ: ["#####", /^\d{5}$/], // Algeria
  AS: ["#####", /^\d{5}$/], // American Samoa
  AD: ["AD###", /^AD\d{3}$/], // Andorra
  AR: ["####", /^\d{4}$/], // Argentina
  AM: ["######", /^\d{6}$/], // Armenia
  AU: ["####", /^\d{4}$/], // Australia
  AT: ["####", /^\d{4}$/], // Austria
  AZ: ["AZ ####", /^AZ \d{4}$/], // Azerbaijan
  BH: ["#####", /^\d{5}$/], // Bahrain
  BD: ["####", /^\d{4}$/], // Bangladesh
  BB: ["BB#####", /^BB\d{5}$/], // Barbados
  BY: ["######", /^\d{6}$/], // Belarus
  BE: ["####", /^\d{4}$/], // Belgium
  BM: ["BB#####", /^BB\d{5}$/], // Bermuda
  BT: ["###", /^\d{3}$/], // Bhutan
  BA: ["#####", /^\d{5}$/], // Bosnia and Herzegovina
  BR: ["#####-###", /^\d{5}-\d{3}$/], // Brazil
  IO: ["BBND 1ZZ", /^BBND 1ZZ$/], // British Indian Ocean Territory
  VG: ["VG#####", /^VG\d{5}$/], // British Virgin Islands
  BN: ["####", /^\d{4}$/], // Brunei
  BG: ["####", /^\d{4}$/], // Bulgaria
  KH: ["#####", /^\d{5}$/], // Cambodia
  CA: ["ANA NAN", /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d$/], // Canada
  CV: ["####", /^\d{4}$/], // Cape Verde
  KY: ["KY#-####", /^KY\d{1}-\d{4}$/], // Cayman Islands
  CL: ["######", /^\d{6}$/], // Chile
  CN: ["######", /^\d{6}$/], // China
  CX: ["####", /^\d{4}$/], // Christmas Island
  CC: ["####", /^\d{4}$/], // Cocos (Keeling) Islands
  CO: ["#####", /^\d{5}$/], // Colombia
  CR: ["#####", /^\d{5}$/], // Costa Rica
  HR: ["HR-#####", /^HR-\d{5}$/], // Croatia
  CY: ["####", /^\d{4}$/], // Cyprus
  CZ: ["### ##", /^\d{3} \d{2}$/], // Czech Republic
  DK: ["####", /^\d{4}$/], // Denmark
  DO: ["#####", /^\d{5}$/], // Dominican Republic
  EC: ["#####", /^\d{5}$/], // Ecuador
  EG: ["#####", /^\d{5}$/], // Egypt
  SV: ["CP ####", /^CP \d{4}$/], // El Salvador
  EE: ["#####", /^\d{5}$/], // Estonia
  ET: ["####", /^\d{4}$/], // Ethiopia
  FK: ["FIQQ 1ZZ", /^FIQQ 1ZZ$/], // Falkland Islands (Malvinas)
  FO: ["FO-###", /^FO-\d{3}$/], // Faroe Islands
  FI: ["#####", /^\d{5}$/], // Finland
  FR: ["#####", /^\d{5}$/], // France
  GF: ["#####", /^\d{5}$/], // French Guiana
  PF: ["#####", /^\d{5}$/], // French Polynesia
  GE: ["####", /^\d{4}$/], // Georgia
  DE: ["#####", /^\d{5}$/], // Germany
  GI: ["GX111AA", /^GX111AA$/], // Gibraltar
  GR: ["### ##", /^\d{3} \d{2}$/], // Greece
  GL: ["####", /^\d{4}$/], // Greenland
  GP: ["#####", /^\d{5}$/], // Guadeloupe
  GU: ["#####", /^\d{5}$/], // Guam
  GT: ["#####", /^\d{5}$/], // Guatemala
  GG: ["GY# #AA", /^GY\d{1} \d{2}[A-Z]{2}$/], // Guernsey
  GN: ["###", /^\d{3}$/], // Guinea
  GW: ["####", /^\d{4}$/], // Guinea-Bissau
  HT: ["HT#####", /^HT\d{5}$/], // Haiti
  VA: ["#####", /^\d{5}$/], // Holy See (Vatican City State)
  HN: ["#####", /^\d{5}$/], // Honduras
  HU: ["####", /^\d{4}$/], // Hungary
  IS: ["###", /^\d{3}$/], // Iceland
  IN: ["######", /^\d{6}$/], // India
  ID: ["#####", /^\d{5}$/], // Indonesia
  IR: ["##########", /^\d{10}$/], // Iran
  IQ: ["#####", /^\d{5}$/], // Iraq
  IE: ["A#A ####", /^[AC-FHKNPRTV-Y]\d[AC-FHKNPRTV-Y] \d{4}$/], // Ireland
  IM: ["IM# #AA", /^IM\d{1} \d{2}[A-Z]{2}$/], // Isle of Man
  IL: ["#####", /^\d{5}$/], // Israel
  IT: ["#####", /^\d{5}$/], // Italy
  JP: ["###-####", /^\d{3}-\d{4}$/], // Japan
  JE: ["JE# #AA", /^JE\d{1} \d{2}[A-Z]{2}$/], // Jersey
  JO: ["#####", /^\d{5}$/], // Jordan
  KZ: ["######", /^\d{6}$/], // Kazakhstan
  KE: ["#####", /^\d{5}$/], // Kenya
  KW: ["#####", /^\d{5}$/], // Kuwait
  KG: ["######", /^\d{6}$/], // Kyrgyzstan
  LA: ["#####", /^\d{5}$/], // Laos
  LV: ["LV-####", /^LV-\d{4}$/], // Latvia
  LB: ["#### ####", /^\d{4} \d{4}$/], // Lebanon
  LS: ["###", /^\d{3}$/], // Lesotho
  LR: ["#####", /^\d{5}$/], // Liberia
  LI: ["####", /^\d{4}$/], // Liechtenstein
  LT: ["LT-#####", /^LT-\d{5}$/], // Lithuania
  LU: ["####", /^\d{4}$/], // Luxembourg
  MK: ["####", /^\d{4}$/], // Macedonia
  MG: ["###", /^\d{3}$/], // Madagascar
  MY: ["#####", /^\d{5}$/], // Malaysia
  MV: ["#####", /^\d{5}$/], // Maldives
  MT: ["AAA ####", /^[A-Z]{3} \d{4}$/], // Malta
  MH: ["#####", /^\d{5}$/], // Marshall Islands
  MQ: ["#####", /^\d{5}$/], // Martinique
  MU: ["#####", /^\d{5}$/], // Mauritius
  YT: ["#####", /^\d{5}$/], // Mayotte
  MX: ["#####", /^\d{5}$/], // Mexico
  FM: ["#####", /^\d{5}$/], // Micronesia, Federated States of
  MD: ["MD-####", /^MD-\d{4}$/], // Moldova
  MC: ["980##", /^980\d{2}$/], // Monaco
  MN: ["###-###", /^\d{3}-\d{3}$/], // Mongolia
  ME: ["#####", /^\d{5}$/], // Montenegro
  MA: ["#####", /^\d{5}$/], // Morocco
  MM: ["#####", /^\d{5}$/], // Myanmar (Burma)
  NP: ["#####", /^\d{5}$/], // Nepal
  NC: ["988##", /^988\d{2}$/], // New Caledonia
  NZ: ["####", /^\d{4}$/], // New Zealand
  NI: ["###-###-#", /^\d{3}-\d{3}-\d{1}$/], // Nicaragua
  NE: ["#####", /^\d{5}$/], // Niger
  NG: ["#####", /^\d{5}$/], // Nigeria
  NF: ["####", /^\d{4}$/], // Norfolk Island
  MP: ["#####", /^\d{5}$/], // Northern Mariana Islands
  NO: ["####", /^\d{4}$/], // Norway
  OM: ["###", /^\d{3}$/], // Oman
  PK: ["#####", /^\d{5}$/], // Pakistan
  PW: ["#####", /^\d{5}$/], // Palau
  PG: ["###", /^\d{3}$/], // Papua New Guinea
  PY: ["#####", /^\d{5}$/], // Paraguay
  PE: ["#####", /^\d{5}$/], // Peru
  PH: ["####", /^\d{4}$/], // Philippines
  PL: ["##-###", /^\d{2}-\d{3}$/], // Poland
  PT: ["####-###", /^\d{4}-\d{3}$/], // Portugal
  PR: ["#####-####", /^\d{5}-\d{4}$/], // Puerto Rico
  RE: ["#####", /^\d{5}$/], // Reunion
  RO: ["######", /^\d{6}$/], // Romania
  RU: ["######", /^\d{6}$/], // Russia
  BL: ["#####", /^\d{5}$/], // Saint Barthélemy
  SH: ["STHL 1ZZ", /^STHL 1ZZ$/], // Saint Helena
  MF: ["### ###", /^\d{3} \d{3}$/], // Saint Martin
  PM: ["#####", /^\d{5}$/], // Saint Pierre and Miquelon
  VC: ["VC#####", /^VC\d{5}$/], // Saint Vincent and the Grenadines
  SM: ["4789#", /^4789\d$/], // San Marino
  SA: ["#####", /^\d{5}$/], // Saudi Arabia
  SN: ["#####", /^\d{5}$/], // Senegal
  RS: ["#####", /^\d{5}$/], // Serbia
  SG: ["######", /^\d{6}$/], // Singapore
  SK: ["### ##", /^\d{3} \d{2}$/], // Slovakia
  SI: ["####", /^\d{4}$/], // Slovenia
  SO: ["#####", /^\d{5}$/], // Somalia
  ZA: ["####", /^\d{4}$/], // South Africa
  GS: ["SIQQ 1ZZ", /^SIQQ 1ZZ$/], // South Georgia and the South Sandwich Islands
  KR: ["###-###", /^\d{3}-\d{3}$/], // South Korea
  ES: ["#####", /^\d{5}$/], // Spain
  LK: ["#####", /^\d{5}$/], // Sri Lanka
  SJ: ["####", /^\d{4}$/], // Svalbard and Jan Mayen
  SZ: ["####", /^\d{4}$/], // Swaziland
  SE: ["### ##", /^\d{3} \d{2}$/], // Sweden
  CH: ["####", /^\d{4}$/], // Switzerland
  TW: ["####", /^\d{4}$/], // Taiwan
  TJ: ["######", /^\d{6}$/], // Tajikistan
  TZ: ["#####", /^\d{5}$/], // Tanzania
  TH: ["#####", /^\d{5}$/], // Thailand
  NL: ["#### AA", /^\d{4} [A-Z]{2}$/], // Netherlands
  TN: ["####", /^\d{4}$/], // Tunisia
  TR: ["#####", /^\d{5}$/], // Turkey
  TM: ["#####", /^\d{5}$/], // Turkmenistan
  TC: ["TKCA 1ZZ", /^TKCA 1ZZ$/], // Turks and Caicos Islands
  UA: ["#####", /^\d{5}$/], // Ukraine
  GB: ["A# #AA", /^[A-Z]{1}\d{1} [A-Z]{2}$/], // United Kingdom
  US: ["#####-####", /^\d{5}-\d{4}$/], // United States
  UM: ["96898-0000", /^96898-0000$/], // United States Minor Outlying Islands
  UY: ["#####", /^\d{5}$/], // Uruguay
  VI: ["#####-####", /^\d{5}-\d{4}$/], // US Virgin Islands
  UZ: ["######", /^\d{6}$/], // Uzbekistan
  VE: ["####", /^\d{4}$/], // Venezuela
  VN: ["######", /^\d{6}$/], // Vietnam
  WF: ["#####", /^\d{5}$/], // Wallis and Futuna
  EH: ["#####", /^\d{5}$/], // Western Sahara
  ZM: ["#####", /^\d{5}$/], // Zambia
};

export const shipping_carrier_by_country: any = {
  AU: [
    {
      shipping_carrier_id: 98,
      name: "Australia Post",
      domestic_classes: [
        {
          mail_class_key: "courier_post",
          name: "Australia Post Courier Post",
        },
        {
          mail_class_key: "express_post",
          name: "Australia Post Express Post",
        },
        {
          mail_class_key: "parcel_post",
          name: "Australia Post Parcel Post",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_economy",
          name: "Australia Post International Post Economy Air",
        },
        {
          mail_class_key: "international_standard",
          name: "Australia Post International Post Standard",
        },
        {
          mail_class_key: "international_express",
          name: "Australia Post International Post Express",
        },
      ],
    },
    {
      shipping_carrier_id: 335,
      name: "Sendle",
      domestic_classes: [
        {
          mail_class_key: "sendle_standard",
          name: "Sendle Standard",
        },
        {
          mail_class_key: "sendle_swift",
          name: "Sendle Swift",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "express_economy_select",
          name: "DHL Express Economy Select",
        },
        {
          mail_class_key: "express_worldwide_australia",
          name: "DHL Express Worldwide",
        },
        {
          mail_class_key: "express_globalmail",
          name: "DHL Express Globalmail",
        },
      ],
    },
    {
      shipping_carrier_id: 118,
      name: "DHL Global Mail",
      domestic_classes: [
        {
          mail_class_key: "dhl_ecommerce_smartmail",
          name: "DHL eCommerce Smartmail",
        },
        {
          mail_class_key: "dhl_ecommerce_packet_standard",
          name: "DHL eCommerce Packet Standard",
        },
        {
          mail_class_key: "dhl_ecommerce_packet_plus",
          name: "DHL eCommerce Packet Plus",
        },
      ],
      international_classes: [
        {
          mail_class_key: "dhl_ecommerce_packet_international",
          name: "DHL eCommerce Packet  International",
        },
        {
          mail_class_key: "dhl_ecommerce_parcel_international_direct",
          name: "DHL eCommerce Parcel International Direct",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "international_priority_express_australia",
          name: "International Priority Express",
        },
        {
          mail_class_key: "international_priority_australia",
          name: "International Priority",
        },
        {
          mail_class_key: "international_economy_australia",
          name: "International Economy",
        },
        {
          mail_class_key: "international_connect_plus",
          name: "International Connect Plus",
        },
        {
          mail_class_key: "international_priority_freight",
          name: "International Priority Freight",
        },
        {
          mail_class_key: "international_economy_freight",
          name: "International Economy Freight",
        },
        {
          mail_class_key: "international_first_australia",
          name: "International First",
        },
      ],
    },
    {
      shipping_carrier_id: 380,
      name: "AramexAustralia",
      domestic_classes: [
        {
          mail_class_key: "standard_economy",
          name: "Standard Economy",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 381,
      name: "CJPacket",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "cjdropshipping",
          name: "CJ Dropshipping",
        },
      ],
    },
    {
      shipping_carrier_id: 87,
      name: "TNT Australia",
      domestic_classes: [
        {
          mail_class_key: "road_express",
          name: "Road Express",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 382,
      name: "Direct Freight Express",
      domestic_classes: [
        {
          mail_class_key: "express",
          name: "Express",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 324,
      name: "Couriers Please",
      domestic_classes: [
        {
          mail_class_key: "couriers_please_domestic",
          name: "Couriers Please Domestic",
        },
      ],
      international_classes: [],
    },
  ],
  CA: [
    {
      shipping_carrier_id: 6,
      name: "Canada Post",
      domestic_classes: [
        {
          mail_class_key: "priority",
          name: "Priority\u2122",
        },
        {
          mail_class_key: "xpresspost",
          name: "Xpresspost\u2122",
        },
        {
          mail_class_key: "expedited_parcel",
          name: "Expedited Parcel\u2122",
        },
        {
          mail_class_key: "regular_parcel",
          name: "Regular Parcel\u2122",
        },
        {
          mail_class_key: "regular_mail",
          name: "Regular Mail",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_parcel_air",
          name: "International Parcel Air",
        },
        {
          mail_class_key: "international_parcel_surface",
          name: "International Parcel Surface",
        },
        {
          mail_class_key: "light_packet_international",
          name: "Light Packet\u2122 - International",
        },
        {
          mail_class_key: "small_packet_international_air",
          name: "Small Packet\u2122 - International Air",
        },
        {
          mail_class_key: "small_packet_international_surface",
          name: "Small Packet\u2122 - International Surface",
        },
        {
          mail_class_key: "tracked_packet_international",
          name: "Tracked Packet\u2122 - International",
        },
        {
          mail_class_key: "xpresspost_international",
          name: "Xpresspost\u2122 - International",
        },
        {
          mail_class_key: "xpresspost_usa",
          name: "Xpresspost\u2122 - USA",
        },
        {
          mail_class_key: "expedited_parcel_usa",
          name: "Expedited Parcel\u2122 - USA",
        },
        {
          mail_class_key: "small_packet_usa",
          name: "Small Packet\u2122 - USA Air",
        },
        {
          mail_class_key: "tracked_packet_usa",
          name: "Tracked Packet\u2122 - USA",
        },
        {
          mail_class_key: "light_packet_usa",
          name: "Light Packet\u2122 - USA",
        },
      ],
    },
    {
      shipping_carrier_id: 334,
      name: "Chit Chats",
      domestic_classes: [
        {
          mail_class_key: "select",
          name: "Select",
        },
        {
          mail_class_key: "slim",
          name: "Slim",
        },
        {
          mail_class_key: "collect",
          name: "Collect",
        },
        {
          mail_class_key: "canada_tracked",
          name: "Canada Tracked",
        },
      ],
      international_classes: [
        {
          mail_class_key: "us_select",
          name: "US Select",
        },
        {
          mail_class_key: "us_edge",
          name: "US Edge",
        },
        {
          mail_class_key: "international_tracked",
          name: "International Tracked",
        },
      ],
    },
    {
      shipping_carrier_id: 357,
      name: "Stallion Express",
      domestic_classes: [
        {
          mail_class_key: "domestic_express",
          name: "Domestic Express",
        },
        {
          mail_class_key: "domestic_economy",
          name: "Domestic Economy",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_express",
          name: "International Express",
        },
        {
          mail_class_key: "international_economy",
          name: "International Economy",
        },
      ],
    },
    {
      shipping_carrier_id: 337,
      name: "Canpar Courier",
      domestic_classes: [
        {
          mail_class_key: "ground_domestic",
          name: "Canpar Ground Domestic",
        },
        {
          mail_class_key: "express_domestic",
          name: "Canpar Express Domestic",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international",
          name: "Canpar International",
        },
      ],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "express_economy_select",
          name: "DHL Express Economy Select",
        },
        {
          mail_class_key: "express_worldwide_canada",
          name: "DHL Express Worldwide",
        },
        {
          mail_class_key: "express_globalmail",
          name: "DHL Express Globalmail",
        },
      ],
    },
    {
      shipping_carrier_id: 118,
      name: "DHL Global Mail",
      domestic_classes: [
        {
          mail_class_key: "dhl_ecommerce_smartmail",
          name: "DHL eCommerce Smartmail",
        },
        {
          mail_class_key: "dhl_ecommerce_packet_standard",
          name: "DHL eCommerce Packet Standard",
        },
        {
          mail_class_key: "dhl_ecommerce_packet_plus",
          name: "DHL eCommerce Packet Plus",
        },
      ],
      international_classes: [
        {
          mail_class_key: "dhl_ecommerce_packet_international",
          name: "DHL eCommerce Packet  International",
        },
        {
          mail_class_key: "dhl_ecommerce_parcel_international_direct",
          name: "DHL eCommerce Parcel International Direct",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [
        {
          mail_class_key: "ground_canada",
          name: "FedEx Ground\u00ae",
        },
        {
          mail_class_key: "two_day_canada",
          name: "FedEx 2Day\u00ae",
        },
      ],
      international_classes: [
        {
          mail_class_key: "priority_overnight_canada",
          name: "FedEx Priority Overnight\u00ae",
        },
        {
          mail_class_key: "freight_economy_canada",
          name: "FedEx Freight\u00ae Economy",
        },
        {
          mail_class_key: "international_priority_canada",
          name: "FedEx International Priority\u00ae",
        },
        {
          mail_class_key: "international_economy_canada",
          name: "FedEx International Economy\u00ae",
        },
        {
          mail_class_key: "international_ground_canada",
          name: "FedEx International Ground\u00ae",
        },
      ],
    },
    {
      shipping_carrier_id: 378,
      name: "ICSCourier",
      domestic_classes: [
        {
          mail_class_key: "ics_courier_next_day",
          name: "ICS Courier Next Day\u2122",
        },
        {
          mail_class_key: "ics_courier_ground",
          name: "ICS Courier Ground\u2122",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 336,
      name: "Landmark Global",
      domestic_classes: [
        {
          mail_class_key: "standard_domestic_canada",
          name: "Landmark Global Standard Domestic",
        },
      ],
      international_classes: [
        {
          mail_class_key: "standard_international_canada",
          name: "Landmark Global Standard International",
        },
      ],
    },
    {
      shipping_carrier_id: 119,
      name: "Purolator",
      domestic_classes: [
        {
          mail_class_key: "purolator_express",
          name: "Purolator Express\u00ae",
        },
        {
          mail_class_key: "purolator_ground",
          name: "Purolator Ground\u00ae",
        },
      ],
      international_classes: [
        {
          mail_class_key: "purolator_express_us",
          name: "Purolator Express\u00ae US",
        },
        {
          mail_class_key: "purolator_ground_us",
          name: "Purolator Ground\u00ae US",
        },
        {
          mail_class_key: "purolator_express_international",
          name: "Purolator Express\u00ae International",
        },
      ],
    },
    {
      shipping_carrier_id: 335,
      name: "Sendle",
      domestic_classes: [
        {
          mail_class_key: "sendle_standard_canada",
          name: "Sendle Standard",
        },
      ],
      international_classes: [
        {
          mail_class_key: "sendle_saver",
          name: "Sendle Saver",
        },
      ],
    },
    {
      shipping_carrier_id: 379,
      name: "UniUni",
      domestic_classes: [
        {
          mail_class_key: "domestic",
          name: "UniUni",
        },
      ],
      international_classes: [
        {
          mail_class_key: "cross_border",
          name: "UniUni Cross Border",
        },
      ],
    },
    {
      shipping_carrier_id: 2,
      name: "UPS",
      domestic_classes: [
        {
          mail_class_key: "ground_canada",
          name: "UPS Ground\u00ae",
        },
        {
          mail_class_key: "standard_canada",
          name: "UPS Standard\u00ae",
        },
        {
          mail_class_key: "domestic_freight_canada",
          name: "UPS Domestic Freight\u00ae",
        },
      ],
      international_classes: [
        {
          mail_class_key: "worldwide_expedited_canada",
          name: "UPS Worldwide Expedited\u00ae",
        },
        {
          mail_class_key: "mail_innovations",
          name: "UPS Mail Innovations\u00ae",
        },
        {
          mail_class_key: "international_freight",
          name: "UPS International Freight\u00ae",
        },
      ],
    },
  ],
  CN: [
    {
      shipping_carrier_id: 74,
      name: "China Post",
      domestic_classes: [
        {
          mail_class_key: "domestic",
          name: "China Post - Domestic",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_air",
          name: "China Post - International Air",
        },
        {
          mail_class_key: "international_sal",
          name: "China Post - International SAL",
        },
        {
          mail_class_key: "international_surface",
          name: "China Post - International Surface",
        },
        {
          mail_class_key: "international_eub",
          name: "China Post - International EUB",
        },
        {
          mail_class_key: "international_ems",
          name: "China Post - International EMS",
        },
      ],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [
        {
          mail_class_key: "domestic_express_china",
          name: "DHL Domestic Express",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_packet_priority_china",
          name: "International Packet Priority",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [
        {
          mail_class_key: "smartpost_china",
          name: "Smartpost",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_economy_china",
          name: "International Economy",
        },
      ],
    },
    {
      shipping_carrier_id: 62,
      name: "SF Express",
      domestic_classes: [
        {
          mail_class_key: "domestic_regular_express",
          name: "Domestic Regular Express",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_regular_express",
          name: "International Regular Express",
        },
      ],
    },
    {
      shipping_carrier_id: 330,
      name: "Yanwen",
      domestic_classes: [
        {
          mail_class_key: "standard_parcel",
          name: "Standard Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_standard",
          name: "International Standard",
        },
      ],
    },
    {
      shipping_carrier_id: 75,
      name: "4PX Worldwide Express",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "intl_post_link",
          name: "4PX Post Link",
        },
      ],
    },
    {
      shipping_carrier_id: 61,
      name: "China EMS",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "express_mail",
          name: "Express Mail",
        },
      ],
    },
  ],
  DE: [
    {
      shipping_carrier_id: 12,
      name: "Deutsche Post",
      domestic_classes: [
        {
          mail_class_key: "standard_letter",
          name: "Standard Letter",
        },
        {
          mail_class_key: "priority_letter",
          name: "Priority Letter",
        },
        {
          mail_class_key: "registered_letter",
          name: "Registered Letter",
        },
        {
          mail_class_key: "standard_parcel",
          name: "Standard Parcel",
        },
        {
          mail_class_key: "express_parcel",
          name: "Express Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_standard_letter",
          name: "International Standard Letter",
        },
        {
          mail_class_key: "intl_priority_letter",
          name: "International Priority Letter",
        },
        {
          mail_class_key: "intl_registered_letter",
          name: "International Registered Letter",
        },
        {
          mail_class_key: "intl_standard_parcel",
          name: "International Standard Parcel",
        },
        {
          mail_class_key: "intl_express_parcel",
          name: "International Express Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 13,
      name: "Hermes Germany",
      domestic_classes: [
        {
          mail_class_key: "hermes",
          name: "Domestic",
        },
        {
          mail_class_key: "standard_parcel",
          name: "Standard Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_hermes",
          name: "International",
        },
        {
          mail_class_key: "intl_outside_europe_parcel",
          name: "Outside of Europe Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [
        {
          mail_class_key: "priority",
          name: "Priority",
        },
        {
          mail_class_key: "express_envelope_germany",
          name: "Express Envelope",
        },
        {
          mail_class_key: "express_standard_parcel_germany",
          name: "Standard Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "express_worldwide",
          name: "DHL Express Worldwide",
        },
        {
          mail_class_key: "intl_packet_plus_germany",
          name: "Packet Plus",
        },
        {
          mail_class_key: "intl_packet_priority_germany",
          name: "International Packet Priority",
        },
        {
          mail_class_key: "intl_packet_standard",
          name: "Packet Standard",
        },
        {
          mail_class_key: "intl_packet_direct",
          name: "Packet Direct",
        },
      ],
    },
    {
      shipping_carrier_id: 41,
      name: "DPD Germany",
      domestic_classes: [
        {
          mail_class_key: "express_parcel",
          name: "Express Parcel",
        },
        {
          mail_class_key: "standard_parcel",
          name: "Standard Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_express_parcel",
          name: "International Express Parcel",
        },
        {
          mail_class_key: "international_standard_parcel",
          name: "International Standard Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 49,
      name: "GLS",
      domestic_classes: [
        {
          mail_class_key: "express_parcel",
          name: "Express Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_express_parcel",
          name: "International Express Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 2,
      name: "UPS",
      domestic_classes: [
        {
          mail_class_key: "ups_ground",
          name: "UPS Ground",
        },
        {
          mail_class_key: "ups_express",
          name: "UPS Express",
        },
        {
          mail_class_key: "ups_express_saver",
          name: "UPS Express Saver",
        },
        {
          mail_class_key: "ups_express_plus",
          name: "UPS Express Plus",
        },
        {
          mail_class_key: "ups_standard",
          name: "UPS Standard",
        },
      ],
      international_classes: [
        {
          mail_class_key: "ups_worldwide_saver",
          name: "UPS Worldwide Saver",
        },
        {
          mail_class_key: "ups_international_express",
          name: "UPS International Express",
        },
        {
          mail_class_key: "ups_express_freight",
          name: "UPS Express Freight",
        },
        {
          mail_class_key: "ups_international_express_saver",
          name: "UPS International Express Saver",
        },
        {
          mail_class_key: "ups_expedited",
          name: "UPS Expedited",
        },
        {
          mail_class_key: "ups_worldwide_economy",
          name: "UPS Worldwide Economy",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [
        {
          mail_class_key: "fedex_first",
          name: "FedEx First",
        },
        {
          mail_class_key: "fedex_priority_express",
          name: "FedEx Priority Express",
        },
        {
          mail_class_key: "fedex_priority",
          name: "FedEx Priority",
        },
        {
          mail_class_key: "fedex_priority_express_freight",
          name: "FedEx Priority Express Freight",
        },
        {
          mail_class_key: "fedex_priority_freight",
          name: "FedEx Priority Freight",
        },
      ],
      international_classes: [
        {
          mail_class_key: "fedex_international_first",
          name: "FedEx International First",
        },
        {
          mail_class_key: "fedex_international_priority_express",
          name: "FedEx International Priority Express",
        },
        {
          mail_class_key: "fedex_international_priority",
          name: "FedEx International Priority",
        },
        {
          mail_class_key: "fedex_international_connect_plus",
          name: "FedEx International Connect Plus",
        },
        {
          mail_class_key: "fedex_international_economy",
          name: "FedEx International Economy",
        },
        {
          mail_class_key: "fedex_international_connect",
          name: "FedEx International Connect",
        },
        {
          mail_class_key: "fedex_regional_economy",
          name: "FedEx Regional Economy (Europe)",
        },
      ],
    },
  ],
  ES: [
    {
      shipping_carrier_id: 15,
      name: "Correos - Espana",
      domestic_classes: [
        {
          mail_class_key: "domestic_paq_standard",
          name: "Correos \u2013 Domestic Paq Standard",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_paq_light_eu",
          name: "Correos \u2013 International Paq Light EU",
        },
        {
          mail_class_key: "international_paq_light_row",
          name: "Correos \u2013 International Paq Light ROW",
        },
      ],
    },
  ],
  FR: [
    {
      shipping_carrier_id: 19,
      name: "La Poste",
      domestic_classes: [
        {
          mail_class_key: "registered_letter",
          name: "La Poste \u2013 Registered Letter",
        },
        {
          mail_class_key: "green_letter",
          name: "La Poste \u2013 Green Letter",
        },
        {
          mail_class_key: "turquoise_services_plus_letter",
          name: "La Poste \u2013 Turquoise Services Plus Letter",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_eu",
          name: "La Poste \u2013 International EU",
        },
        {
          mail_class_key: "international_us",
          name: "La Poste \u2013 International US",
        },
        {
          mail_class_key: "international_row",
          name: "La Poste \u2013 International ROW",
        },
        {
          mail_class_key: "international_priority_letter",
          name: "La Poste \u2013 International Priority Letter",
        },
        {
          mail_class_key: "international_registered_letter",
          name: "La Poste \u2013 International Registered Letter",
        },
      ],
    },
    {
      shipping_carrier_id: 329,
      name: "Colissimo",
      domestic_classes: [
        {
          mail_class_key: "domestic_two_day",
          name: "Domestic Two Day",
        },
      ],
      international_classes: [
        {
          mail_class_key: "int_colissimo_europe",
          name: "International Colissimo Europe",
        },
        {
          mail_class_key: "int_colissimo_row",
          name: "International Colissimo Rest of the World",
        },
      ],
    },
    {
      shipping_carrier_id: 351,
      name: "Mondial Relay",
      domestic_classes: [
        {
          mail_class_key: "parcel_shipment",
          name: "Parcel Shipment",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_parcel",
          name: "International Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 47,
      name: "Chronopost",
      domestic_classes: [
        {
          mail_class_key: "next_day",
          name: "Next Day",
        },
      ],
      international_classes: [
        {
          mail_class_key: "chrono_express",
          name: "Chrono Express",
        },
        {
          mail_class_key: "chrono_classic",
          name: "Chrono Classic",
        },
      ],
    },
    {
      shipping_carrier_id: 49,
      name: "GLS",
      domestic_classes: [
        {
          mail_class_key: "express_parcel",
          name: "Express Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_express_parcel",
          name: "International Express Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 383,
      name: "UPS",
      domestic_classes: [
        {
          mail_class_key: "ups_ground",
          name: "UPS Ground",
        },
        {
          mail_class_key: "ups_express_saver",
          name: "UPS Express Saver",
        },
        {
          mail_class_key: "ups_express",
          name: "UPS Express",
        },
        {
          mail_class_key: "ups_express_plus",
          name: "UPS Express Plus",
        },
        {
          mail_class_key: "ups_standard",
          name: "UPS Standard",
        },
      ],
      international_classes: [
        {
          mail_class_key: "ups_worldwide_saver",
          name: "UPS Worldwide Saver",
        },
        {
          mail_class_key: "ups_international_express",
          name: "UPS International Express",
        },
        {
          mail_class_key: "ups_express_freight",
          name: "UPS Express Freight",
        },
        {
          mail_class_key: "ups_international_express_saver",
          name: "UPS International Express Saver",
        },
        {
          mail_class_key: "ups_expedited",
          name: "UPS Expedited",
        },
        {
          mail_class_key: "ups_worldwide_economy",
          name: "UPS Worldwide Economy",
        },
      ],
    },
    {
      shipping_carrier_id: 384,
      name: "DPD France",
      domestic_classes: [
        {
          mail_class_key: "express_parcel",
          name: "Express Parcel",
        },
        {
          mail_class_key: "standard_parcel",
          name: "Standard Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_express_parcel",
          name: "International Express Parcel",
        },
        {
          mail_class_key: "international_standard_parcel",
          name: "International Standard Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [
        {
          mail_class_key: "fedex_first",
          name: "FedEx First",
        },
        {
          mail_class_key: "fedex_priority_express",
          name: "FedEx Priority Express",
        },
        {
          mail_class_key: "fedex_priority",
          name: "FedEx Priority",
        },
        {
          mail_class_key: "fedex_priority_express_freight",
          name: "FedEx Priority Express Freight",
        },
        {
          mail_class_key: "fedex_priority_freight",
          name: "FedEx Priority Freight",
        },
      ],
      international_classes: [
        {
          mail_class_key: "fedex_international_first",
          name: "FedEx International First",
        },
        {
          mail_class_key: "fedex_international_priority_express",
          name: "FedEx International Priority Express",
        },
        {
          mail_class_key: "fedex_international_priority",
          name: "FedEx International Priority",
        },
        {
          mail_class_key: "fedex_international_connect_plus",
          name: "FedEx International Connect Plus",
        },
        {
          mail_class_key: "fedex_international_economy",
          name: "FedEx International Economy",
        },
        {
          mail_class_key: "fedex_international_connect",
          name: "FedEx International Connect",
        },
        {
          mail_class_key: "fedex_regional_economy",
          name: "FedEx Regional Economy (Europe)",
        },
      ],
    },
    {
      shipping_carrier_id: 385,
      name: "Relais Colis",
      domestic_classes: [
        {
          mail_class_key: "standard",
          name: "Standard",
        },
        {
          mail_class_key: "express",
          name: "Express",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [
        {
          mail_class_key: "priority_france",
          name: "Priority",
        },
        {
          mail_class_key: "express_envelope_france",
          name: "Express Envelope",
        },
        {
          mail_class_key: "standard_parcel_france",
          name: "Standard Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "dhl_express_worldwide_france",
          name: "DHL Express Worldwide",
        },
        {
          mail_class_key: "packet_plus_france",
          name: "Packet Plus",
        },
        {
          mail_class_key: "international_packet_priority_france",
          name: "International Packet Priority",
        },
        {
          mail_class_key: "packet_standard_france",
          name: "Packet Standard",
        },
        {
          mail_class_key: "packet_direct_france",
          name: "Packet Direct",
        },
      ],
    },
  ],
  GB: [
    {
      shipping_carrier_id: 34,
      name: "Royal Mail",
      domestic_classes: [
        {
          mail_class_key: "first_class",
          name: "Royal Mail 1st Class",
        },
        {
          mail_class_key: "first_class_signed_for",
          name: "Royal Mail Signed For 1st Class",
        },
        {
          mail_class_key: "second_class",
          name: "Royal Mail 2nd Class",
        },
        {
          mail_class_key: "second_class_signed_for",
          name: "Royal Mail Signed For 2nd Class",
        },
        {
          mail_class_key: "special_delivery_guaranteed",
          name: "Royal Mail Special Delivery Guaranteed by 1pm",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_standard",
          name: "Royal Mail International Standard",
        },
        {
          mail_class_key: "international_tracked",
          name: "Royal Mail International Tracked",
        },
        {
          mail_class_key: "international_tracked_and_signed",
          name: "Royal Mail International Tracked & Signed",
        },
        {
          mail_class_key: "international_signed",
          name: "Royal Mail International Signed",
        },
      ],
    },
    {
      shipping_carrier_id: 343,
      name: "Collect+",
      domestic_classes: [
        {
          mail_class_key: "collect_plus",
          name: "Collect+",
        },
        {
          mail_class_key: "collect_plus_two_day",
          name: "Two Day",
        },
        {
          mail_class_key: "collect_plus_economy",
          name: "Collect+ Economy",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [
        {
          mail_class_key: "next_day",
          name: "Next Day",
        },
      ],
      international_classes: [
        {
          mail_class_key: "express_worldwide",
          name: "DHL Express Worldwide",
        },
        {
          mail_class_key: "intl_packet_direct",
          name: "Packet Direct",
        },
      ],
    },
    {
      shipping_carrier_id: 344,
      name: "Evri",
      domestic_classes: [
        {
          mail_class_key: "courier_service",
          name: "Evri Courier to Home",
        },
        {
          mail_class_key: "parcel_shop",
          name: "Evri Shop to Home Standard",
        },
        {
          mail_class_key: "parcel_shop_nextday",
          name: "Evri Shop to Home Next Day",
        },
        {
          mail_class_key: "postable",
          name: "Evri Postal",
        },
        {
          mail_class_key: "postable_next_day",
          name: "Evri Postal Next Day",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_parcel_shop",
          name: "Evri Parcelshop Standard",
        },
        {
          mail_class_key: "intl_courier_service",
          name: "Evri Courier to Home",
        },
        {
          mail_class_key: "intl_parcel",
          name: "Evri International Parcel",
        },
      ],
    },
    {
      shipping_carrier_id: 2,
      name: "UPS",
      domestic_classes: [
        {
          mail_class_key: "express_saver",
          name: "UPS Express Saver\u00ae",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 8,
      name: "DPD",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "dpd_classic",
          name: "DPD Classic",
        },
        {
          mail_class_key: "dpd_direct",
          name: "DPD Direct",
        },
        {
          mail_class_key: "dpd_air",
          name: "DPD Air",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "international_uk",
          name: "FedEx International",
        },
      ],
    },
    {
      shipping_carrier_id: 353,
      name: "uShip",
      domestic_classes: [
        {
          mail_class_key: "uship",
          name: "uShip",
        },
      ],
      international_classes: [
        {
          mail_class_key: "uship_international",
          name: "uShip",
        },
      ],
    },
  ],
  IN: [
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "express_worldwide_india",
          name: "DHL Express Worldwide",
        },
      ],
    },
    {
      shipping_carrier_id: 78,
      name: "India Post International",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "intl_tracked_packet",
          name: "International Tracked Packet",
        },
      ],
    },
    {
      shipping_carrier_id: 2,
      name: "UPS",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "worldwide_expedited_india",
          name: "UPS Worldwide Expedited\u00ae",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "international_economy_india",
          name: "International Economy",
        },
      ],
    },
    {
      shipping_carrier_id: 317,
      name: "DTDC Asendia",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "dtdc_e_paq_plus",
          name: "DTDC Asendia e-PAQ Plus",
        },
      ],
    },
    {
      shipping_carrier_id: 66,
      name: "India Post",
      domestic_classes: [
        {
          mail_class_key: "first_class",
          name: "First Class",
        },
        {
          mail_class_key: "second_class",
          name: "Second Class",
        },
        {
          mail_class_key: "speed_post",
          name: "Speed Post",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 359,
      name: "Delhivery",
      domestic_classes: [
        {
          mail_class_key: "air",
          name: "Delhivery Air",
        },
        {
          mail_class_key: "surface",
          name: "Delhivery Surface",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 67,
      name: "Blue Dart",
      domestic_classes: [
        {
          mail_class_key: "air",
          name: "Blue Dart Air",
        },
        {
          mail_class_key: "surface",
          name: "Blue Dart Surface",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 360,
      name: "Ecom Express",
      domestic_classes: [
        {
          mail_class_key: "air",
          name: "Ecom Express Air",
        },
        {
          mail_class_key: "surface",
          name: "Ecom Express Surface",
        },
      ],
      international_classes: [],
    },
    {
      shipping_carrier_id: 361,
      name: "Xpressbees",
      domestic_classes: [
        {
          mail_class_key: "air",
          name: "Xpressbees Air",
        },
        {
          mail_class_key: "surface",
          name: "Xpressbees Surface",
        },
      ],
      international_classes: [],
    },
  ],
  PL: [
    {
      shipping_carrier_id: 29,
      name: "Poczta Polska",
      domestic_classes: [
        {
          mail_class_key: "domestic_pocztex_express_24",
          name: "Domestic - Pocztex Express 24",
        },
        {
          mail_class_key: "domestic_pocztex_courier_48",
          name: "Domestic - Pocztex Courier 48",
        },
        {
          mail_class_key: "domestic_city_courier",
          name: "Domestic - City Courier",
        },
        {
          mail_class_key: "domestic_courier",
          name: "Domestic - Domestic Courier",
        },
        {
          mail_class_key: "domestic_direct_courier",
          name: "Domestic - Direct Courier",
        },
        {
          mail_class_key: "domestic_parcel24",
          name: "Domestic - Parcel24",
        },
        {
          mail_class_key: "domestic_parcel48",
          name: "Domestic - Parcel48",
        },
        {
          mail_class_key: "domestic_palett_shipment",
          name: "Domestic - Palett Shipment",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_global_expres_EU",
          name: "International - Global Expres EU",
        },
        {
          mail_class_key: "international_global_expres_ROW",
          name: "International - Global Expres ROW",
        },
      ],
    },
  ],
  TR: [
    {
      shipping_carrier_id: 60,
      name: "PTT Posta",
      domestic_classes: [
        {
          mail_class_key: "domestic",
          name: "PTT Turkish Post - Domestic",
        },
        {
          mail_class_key: "express",
          name: "PTT Turkish Post - Express",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international",
          name: "PTT Turkish Post - International",
        },
        {
          mail_class_key: "international_express",
          name: "PTT Turkish Post - International Express",
        },
      ],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [
        {
          mail_class_key: "smart_mail_turkey",
          name: "DHL Smartmail Parcel",
        },
      ],
      international_classes: [
        {
          mail_class_key: "intl_packet_direct_turkey",
          name: "International Packet Direct",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [
        {
          mail_class_key: "smartpost_turkey",
          name: "Smartpost",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_economy_turkey",
          name: "International Economy",
        },
      ],
    },
    {
      shipping_carrier_id: 110,
      name: "TNT",
      domestic_classes: [
        {
          mail_class_key: "express_parcel",
          name: "TNT Domestic",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_express_parcel",
          name: "International TNT",
        },
      ],
    },
    {
      shipping_carrier_id: 2,
      name: "UPS",
      domestic_classes: [
        {
          mail_class_key: "ground_turkey",
          name: "UPS Ground\u00ae",
        },
      ],
      international_classes: [
        {
          mail_class_key: "worldwide_expedited_TURKEY",
          name: "UPS Worldwide Expedited\u00ae",
        },
      ],
    },
    {
      shipping_carrier_id: 362,
      name: "ShipEntegra",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "intl_standard",
          name: "ShipEntegra International Standard",
        },
        {
          mail_class_key: "intl_express",
          name: "ShipEntegra International Express",
        },
      ],
    },
  ],
  UA: [
    {
      shipping_carrier_id: 31,
      name: "UkrPoshta",
      domestic_classes: [
        {
          mail_class_key: "domestic",
          name: "Ukrposhta - Domestic",
        },
        {
          mail_class_key: "express",
          name: "Ukrposhta - Express",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international",
          name: "Ukrposhta - International",
        },
        {
          mail_class_key: "international_express",
          name: "Ukrposhta - International Express",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [
        {
          mail_class_key: "smartpost_ukraine",
          name: "Smartpost",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_economy_ukraine",
          name: "International Economy",
        },
      ],
    },
    {
      shipping_carrier_id: 2,
      name: "UPS",
      domestic_classes: [
        {
          mail_class_key: "ground_ukraine",
          name: "UPS Ground\u00ae",
        },
      ],
      international_classes: [
        {
          mail_class_key: "worldwide_expedited_ukraine",
          name: "UPS Worldwide Expedited\u00ae",
        },
      ],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [],
      international_classes: [
        {
          mail_class_key: "intl_packet_direct_ukraine",
          name: "International Packet Direct",
        },
      ],
    },
  ],
  US: [
    {
      shipping_carrier_id: 1,
      name: "USPS",
      domestic_classes: [
        {
          mail_class_key: "priority",
          name: "USPS Priority Mail",
        },
        {
          mail_class_key: "ground_advantage",
          name: "USPS Ground Advantage",
        },
        {
          mail_class_key: "first_class",
          name: "USPS First-Class Mail",
        },
        {
          mail_class_key: "media_mail",
          name: "USPS Media Mail",
        },
        {
          mail_class_key: "priority_express",
          name: "USPS Priority Mail Express",
        },
      ],
      international_classes: [
        {
          mail_class_key: "priority_international",
          name: "USPS Priority Mail International",
        },
        {
          mail_class_key: "priority_express_international",
          name: "USPS Priority Mail Express International",
        },
        {
          mail_class_key: "first_class_international",
          name: "USPS First Class Mail International",
        },
        {
          mail_class_key: "express_international",
          name: "USPS Express Mail International",
        },
      ],
    },
    {
      shipping_carrier_id: 3,
      name: "FedEx",
      domestic_classes: [
        {
          mail_class_key: "home_delivery",
          name: "FedEx Home Delivery\u00ae",
        },
        {
          mail_class_key: "express_saver",
          name: "FedEx Express Saver\u00ae",
        },
        {
          mail_class_key: "first_overnight",
          name: "FedEx First Overnight\u00ae",
        },
        {
          mail_class_key: "ground",
          name: "FedEx Ground\u00ae",
        },
        {
          mail_class_key: "priority_overnight",
          name: "FedEx Priority Overnight\u00ae",
        },
        {
          mail_class_key: "smart_post",
          name: "FedEx SmartPost\u00ae",
        },
        {
          mail_class_key: "standard_overnight",
          name: "FedEx Standard Overnight\u00ae",
        },
        {
          mail_class_key: "two_day",
          name: "FedEx 2Day\u00ae",
        },
        {
          mail_class_key: "two_day_am",
          name: "FedEx 2Day\u00ae AM",
        },
        {
          mail_class_key: "freight_priority",
          name: "FedEx Freight\u00ae Priority",
        },
        {
          mail_class_key: "freight_economy",
          name: "FedEx Freight\u00ae Economy",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_economy",
          name: "FedEx International Economy\u00ae",
        },
        {
          mail_class_key: "international_priority",
          name: "FedEx International Priority\u00ae",
        },
        {
          mail_class_key: "international_ground",
          name: "FedEx International Ground\u00ae",
        },
        {
          mail_class_key: "international_first",
          name: "FedEx International First\u00ae",
        },
      ],
    },
    {
      shipping_carrier_id: 2,
      name: "UPS",
      domestic_classes: [
        {
          mail_class_key: "expedited",
          name: "UPS Expedited\u00ae",
        },
        {
          mail_class_key: "express",
          name: "UPS Express\u00ae",
        },
        {
          mail_class_key: "ground",
          name: "UPS\u00ae Ground",
        },
        {
          mail_class_key: "ground_saver",
          name: "UPS\u00ae Ground Saver",
        },
        {
          mail_class_key: "next_day_air",
          name: "UPS Next Day Air\u00ae",
        },
        {
          mail_class_key: "next_day_air_saver",
          name: "UPS Next Day Air Saver\u00ae",
        },
        {
          mail_class_key: "second_day_air_am",
          name: "UPS 2nd Day Air A.M.\u00ae",
        },
        {
          mail_class_key: "three_day_select",
          name: "UPS 3 Day Select\u00ae",
        },
        {
          mail_class_key: "two_day",
          name: "UPS 2nd Day Air\u00ae",
        },
        {
          mail_class_key: "express_early",
          name: "UPS\u00ae Express Early",
        },
        {
          mail_class_key: "next_day_air_early",
          name: "UPS Next Day Air\u00ae Early",
        },
        {
          mail_class_key: "freight",
          name: "UPS\u00ae Freight",
        },
        {
          mail_class_key: "surepost",
          name: "UPS SurePost\u00ae",
        },
      ],
      international_classes: [
        {
          mail_class_key: "international_standard",
          name: "UPS\u00ae Standard",
        },
        {
          mail_class_key: "worldwide_expedited",
          name: "UPS Worldwide Expedited\u00ae",
        },
        {
          mail_class_key: "worldwide_saver",
          name: "UPS Worldwide Saver\u00ae",
        },
        {
          mail_class_key: "worldwide_express",
          name: "UPS Worldwide Express\u00ae",
        },
        {
          mail_class_key: "worldwide_express_na1",
          name: "UPS Worldwide Express\u00ae(NA1)",
        },
      ],
    },
    {
      shipping_carrier_id: 4,
      name: "DHL",
      domestic_classes: [
        {
          mail_class_key: "dhl_expedited",
          name: "DHL Expedited",
        },
        {
          mail_class_key: "dhl_expedited_max",
          name: "DHL Expedited Max",
        },
        {
          mail_class_key: "dhl_ground",
          name: "DHL Ground",
        },
      ],
      international_classes: [
        {
          mail_class_key: "express_9_00",
          name: "DHL EXPRESS 9:00",
        },
        {
          mail_class_key: "express_10_30",
          name: "DHL EXPRESS 10:00",
        },
        {
          mail_class_key: "express_12_00",
          name: "DHL EXPRESS 12:00",
        },
        {
          mail_class_key: "express_worldwide",
          name: "DHL Express Worldwide",
        },
        {
          mail_class_key: "express_envelope",
          name: "DHL EXPRESS ENVELOPE",
        },
      ],
    },
    {
      shipping_carrier_id: 353,
      name: "uShip",
      domestic_classes: [
        {
          mail_class_key: "uship",
          name: "uShip",
        },
      ],
      international_classes: [
        {
          mail_class_key: "uship_international",
          name: "uShip",
        },
      ],
    },
  ],
};

export const disabledRuleGroupOperators: {
  [key: string]: { disabled: string[]; field?: ObjStrI };
} = {
  title: { disabled: ["IN({operator})"] },
  vendor: {
    disabled: ["!="],
    field: {
      "IN({operator})": "multi-select",
      "=": "select",
    },
  },
  product_type: {
    disabled: ["!="],
    field: {
      "IN({operator})": "multi-select",
      "=": "select",
    },
  },
  tags: {
    disabled: ["=", "!=", "LIKE {operator}%", "LIKE %{operator}"],
    field: {
      "IN({operator})": "multi-select",
      "LIKE %{operator}%": "select",
      "NOT LIKE %{operator}%": "select",
    },
  },
};

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ship_carrier: {
  [name: string]: {
    carrier_name: string;
    trackable_on_etsy: 1 | 0;
  };
} = {
  "4px": {
    carrier_name: "4PX Worldwide Express",
    trackable_on_etsy: 1,
  },
  a1post: {
    carrier_name: "A1Post",
    trackable_on_etsy: 1,
  },
  abf: {
    carrier_name: "ABF Freight",
    trackable_on_etsy: 1,
  },
  acscourier: {
    carrier_name: "ACS Courier",
    trackable_on_etsy: 1,
  },
  aeroflash: {
    carrier_name: "AeroFlash",
    trackable_on_etsy: 1,
  },
  "afghan-post": {
    carrier_name: "Afghan Post",
    trackable_on_etsy: 0,
  },
  "amazon-uk-api": {
    carrier_name: "Amazon Logistics UK",
    trackable_on_etsy: 1,
  },
  amazon: {
    carrier_name: "Amazon Logistics US",
    trackable_on_etsy: 1,
  },
  "an-post": {
    carrier_name: "An Post",
    trackable_on_etsy: 1,
  },
  "anguilla-post": {
    carrier_name: "Anguilla Postal Service",
    trackable_on_etsy: 0,
  },
  apc: {
    carrier_name: "APC Postal Logistics",
    trackable_on_etsy: 1,
  },
  aramex: {
    carrier_name: "Aramex",
    trackable_on_etsy: 1,
  },
  "asendia-uk": {
    carrier_name: "Asendia UK",
    trackable_on_etsy: 1,
  },
  "asendia-usa": {
    carrier_name: "Asendia USA",
    trackable_on_etsy: 1,
  },
  "australia-post": {
    carrier_name: "Australia Post",
    trackable_on_etsy: 1,
  },
  "austrian-post": {
    carrier_name: "Austrian Post",
    trackable_on_etsy: 1,
  },
  "austrian-post-registered": {
    carrier_name: "Austrian Post Registered",
    trackable_on_etsy: 1,
  },
  "bahrain-post": {
    carrier_name: "Bahrain Post",
    trackable_on_etsy: 0,
  },
  "bangladesh-post": {
    carrier_name: "Bangladesh Post Office",
    trackable_on_etsy: 0,
  },
  bpost: {
    carrier_name: "Belgium Post Domestic",
    trackable_on_etsy: 1,
  },
  "bpost-international": {
    carrier_name: "Belgium Post International",
    trackable_on_etsy: 1,
  },
  belpost: {
    carrier_name: "Belposhta",
    trackable_on_etsy: 1,
  },
  "bh-posta": {
    carrier_name: "BH Posta",
    trackable_on_etsy: 0,
  },
  bluedart: {
    carrier_name: "Blue Dart",
    trackable_on_etsy: 1,
  },
  botswanapost: {
    carrier_name: "BotswanaPost",
    trackable_on_etsy: 0,
  },
  "brunei-post": {
    carrier_name: "Brunei Postal Services",
    trackable_on_etsy: 0,
  },
  bgpost: {
    carrier_name: "Bulgarian Posts",
    trackable_on_etsy: 1,
  },
  "cambodia-post": {
    carrier_name: "Cambodia Post",
    trackable_on_etsy: 1,
  },
  "canada-post": {
    carrier_name: "Canada Post",
    trackable_on_etsy: 1,
  },
  canpar: {
    carrier_name: "Canpar Courier",
    trackable_on_etsy: 1,
  },
  "ceska-posta": {
    carrier_name: "Ceska Posta",
    trackable_on_etsy: 1,
  },
  "china-ems": {
    carrier_name: "China EMS",
    trackable_on_etsy: 1,
  },
  "china-post": {
    carrier_name: "China Post",
    trackable_on_etsy: 1,
  },
  chitchats: {
    carrier_name: "Chit Chats",
    trackable_on_etsy: 1,
  },
  "chronopost-france": {
    carrier_name: "Chronopost France",
    trackable_on_etsy: 1,
  },
  "chronopost-portugal": {
    carrier_name: "Chronopost Portugal",
    trackable_on_etsy: 1,
  },
  "taiwan-post": {
    carrier_name: "Chunghwa Post",
    trackable_on_etsy: 1,
  },
  "city-link": {
    carrier_name: "City Link",
    trackable_on_etsy: 1,
  },
  colissimo: {
    carrier_name: "Colissimo",
    trackable_on_etsy: 1,
  },
  collectplus: {
    carrier_name: "Collect+",
    trackable_on_etsy: 1,
  },
  "brazil-correios": {
    carrier_name: "Correios de Brasil",
    trackable_on_etsy: 1,
  },
  "correios-macau": {
    carrier_name: "Correios de Macau",
    trackable_on_etsy: 0,
  },
  "portugal-ctt": {
    carrier_name: "Correios de Portugal (CTT)",
    trackable_on_etsy: 1,
  },
  "correo-argentino": {
    carrier_name: "Correo Argentino Domestic",
    trackable_on_etsy: 1,
  },
  "correo-argentino-intl": {
    carrier_name: "Correo Argentino International",
    trackable_on_etsy: 1,
  },
  "correo-uruguayo": {
    carrier_name: "Correo Uruguayo",
    trackable_on_etsy: 0,
  },
  "spain-correos-es": {
    carrier_name: "Correos - Espana",
    trackable_on_etsy: 1,
  },
  "correos-chile": {
    carrier_name: "Correos Chile",
    trackable_on_etsy: 1,
  },
  "correos-de-mexico": {
    carrier_name: "Correos De Mexico",
    trackable_on_etsy: 1,
  },
  "correos-de-costa-rica": {
    carrier_name: "Correos de Costa Rica",
    trackable_on_etsy: 1,
  },
  "correos-ecuador": {
    carrier_name: "Correos del Ecuador",
    trackable_on_etsy: 0,
  },
  courierpost: {
    carrier_name: "Courier Post",
    trackable_on_etsy: 1,
  },
  "couriers-please": {
    carrier_name: "Couriers Please",
    trackable_on_etsy: 1,
  },
  "cyprus-post": {
    carrier_name: "Cyprus Post",
    trackable_on_etsy: 1,
  },
  "deltec-courier": {
    carrier_name: "Deltec Courier",
    trackable_on_etsy: 1,
  },
  "deutsch-post": {
    carrier_name: "Deutsche Post",
    trackable_on_etsy: 1,
  },
  "dhl-benelux": {
    carrier_name: "DHL Benelux",
    trackable_on_etsy: 1,
  },
  dhl: {
    carrier_name: "DHL Express",
    trackable_on_etsy: 1,
  },
  "dhl-germany": {
    carrier_name: "DHL Germany",
    trackable_on_etsy: 1,
  },
  "dhl-global-mail": {
    carrier_name: "DHL Global Mail",
    trackable_on_etsy: 1,
  },
  "dhl-nl": {
    carrier_name: "DHL Netherlands",
    trackable_on_etsy: 1,
  },
  "dhlparcel-nl": {
    carrier_name: "DHL Parcel NL",
    trackable_on_etsy: 1,
  },
  "dhl-poland": {
    carrier_name: "DHL Polska",
    trackable_on_etsy: 1,
  },
  "dhl-es": {
    carrier_name: "DHL Spain Domestic",
    trackable_on_etsy: 1,
  },
  "dhl-global-mail-asia": {
    carrier_name: "DHL eCommerce",
    trackable_on_etsy: 1,
  },
  directlink: {
    carrier_name: "Direct Link",
    trackable_on_etsy: 1,
  },
  dpd: {
    carrier_name: "DPD",
    trackable_on_etsy: 1,
  },
  "dpd-de": {
    carrier_name: "DPD Germany",
    trackable_on_etsy: 1,
  },
  "dpd-poland": {
    carrier_name: "DPD Polska",
    trackable_on_etsy: 1,
  },
  "dpd-uk": {
    carrier_name: "DPD UK",
    trackable_on_etsy: 1,
  },
  dtdc: {
    carrier_name: "DTDC India",
    trackable_on_etsy: 1,
  },
  "ec-firstclass": {
    carrier_name: "EC-Firstclass",
    trackable_on_etsy: 1,
  },
  "egypt-post": {
    carrier_name: "Egypt Post",
    trackable_on_etsy: 0,
  },
  "el-correo": {
    carrier_name: "El Correo",
    trackable_on_etsy: 0,
  },
  "elta-courier": {
    carrier_name: "Elta Courier",
    trackable_on_etsy: 1,
  },
  "emirates-post": {
    carrier_name: "Empost",
    trackable_on_etsy: 1,
  },
  "correos-bolivia": {
    carrier_name: "Empresa de Correos de Bolivia",
    trackable_on_etsy: 0,
  },
  estafeta: {
    carrier_name: "Estafeta",
    trackable_on_etsy: 1,
  },
  estes: {
    carrier_name: "Estes",
    trackable_on_etsy: 1,
  },
  "estonian-post": {
    carrier_name: "Estonian Post",
    trackable_on_etsy: 0,
  },
  "ethiopian-post": {
    carrier_name: "Ethiopian Postal Service",
    trackable_on_etsy: 0,
  },
  evergreen: {
    carrier_name: "Evergreen",
    trackable_on_etsy: 0,
  },
  "fastway-au": {
    carrier_name: "Fastway Australia",
    trackable_on_etsy: 1,
  },
  "fastway-ireland": {
    carrier_name: "Fastway Couriers",
    trackable_on_etsy: 1,
  },
  "fastway-nz": {
    carrier_name: "Fastway New Zealand",
    trackable_on_etsy: 1,
  },
  "fastway-za": {
    carrier_name: "Fastways Couriers South Africa",
    trackable_on_etsy: 1,
  },
  fedex: {
    carrier_name: "FedEx",
    trackable_on_etsy: 1,
  },
  "fedex-uk": {
    carrier_name: "Fedex UK (Domestic)",
    trackable_on_etsy: 1,
  },
  "first-flight": {
    carrier_name: "First Flight Couriers",
    trackable_on_etsy: 1,
  },
  "flash-courier": {
    carrier_name: "Flash Courier",
    trackable_on_etsy: 1,
  },
  freightquote: {
    carrier_name: "Freightquote by C. H. Robinson",
    trackable_on_etsy: 1,
  },
  "gati-kwe": {
    carrier_name: "GATI-KWE",
    trackable_on_etsy: 1,
  },
  "ghana-post": {
    carrier_name: "Ghana Post",
    trackable_on_etsy: 0,
  },
  globegistics: {
    carrier_name: "Globegistics",
    trackable_on_etsy: 1,
  },
  gls: {
    carrier_name: "GLS",
    trackable_on_etsy: 1,
  },
  greyhound: {
    carrier_name: "Greyhound",
    trackable_on_etsy: 1,
  },
  "guernsey-post": {
    carrier_name: "Guernsey Post",
    trackable_on_etsy: 0,
  },
  "hay-post": {
    carrier_name: "Hay Post",
    trackable_on_etsy: 0,
  },
  "hellenic-post": {
    carrier_name: "Hellenic Post",
    trackable_on_etsy: 0,
  },
  "hermes-de": {
    carrier_name: "Hermes",
    trackable_on_etsy: 1,
  },
  "hermes-it": {
    carrier_name: "Hermes Italy",
    trackable_on_etsy: 1,
  },
  hermes: {
    carrier_name: "Hermes UK",
    trackable_on_etsy: 1,
  },
  "hong-kong-post": {
    carrier_name: "Hong Kong Post",
    trackable_on_etsy: 1,
  },
  "hrvatska-posta": {
    carrier_name: "Hrvatska Posta",
    trackable_on_etsy: 1,
  },
  "i-parcel": {
    carrier_name: "i-parcel",
    trackable_on_etsy: 1,
  },
  "india-post": {
    carrier_name: "India Post",
    trackable_on_etsy: 1,
  },
  "india-post-int": {
    carrier_name: "India Post International",
    trackable_on_etsy: 1,
  },
  "interlink-express": {
    carrier_name: "Interlink Express",
    trackable_on_etsy: 1,
  },
  "international-seur": {
    carrier_name: "International Seur",
    trackable_on_etsy: 1,
  },
  ipostel: {
    carrier_name: "Ipostel",
    trackable_on_etsy: 0,
  },
  "iran-post": {
    carrier_name: "Iran Post",
    trackable_on_etsy: 0,
  },
  islandspostur: {
    carrier_name: "Islandspostur",
    trackable_on_etsy: 0,
  },
  "isle-of-man-post": {
    carrier_name: "Isle of Man Post Office",
    trackable_on_etsy: 0,
  },
  "israel-post": {
    carrier_name: "Israel Post",
    trackable_on_etsy: 1,
  },
  "israel-post-domestic": {
    carrier_name: "Israel Post Domestic",
    trackable_on_etsy: 1,
  },
  "jamaica-post": {
    carrier_name: "Jamaica Post",
    trackable_on_etsy: 0,
  },
  "japan-post": {
    carrier_name: "Japan Post",
    trackable_on_etsy: 1,
  },
  "jersey-post": {
    carrier_name: "Jersey Post",
    trackable_on_etsy: 0,
  },
  "jordan-post": {
    carrier_name: "Jordan Post",
    trackable_on_etsy: 0,
  },
  kazpost: {
    carrier_name: "Kazpost",
    trackable_on_etsy: 0,
  },
  kpost: {
    carrier_name: "Korea Post",
    trackable_on_etsy: 1,
  },
  "korea-post": {
    carrier_name: "Korea Post EMS",
    trackable_on_etsy: 1,
  },
  kn: {
    carrier_name: "Kuehne + Nagel",
    trackable_on_etsy: 1,
  },
  "la-poste-colissimo": {
    carrier_name: "La Poste",
    trackable_on_etsy: 1,
  },
  "poste-monaco": {
    carrier_name: "La Poste Monaco",
    trackable_on_etsy: 0,
  },
  "poste-senegal": {
    carrier_name: "La Poste du Senegal",
    trackable_on_etsy: 0,
  },
  "poste-tunisienne": {
    carrier_name: "La Poste Tunisienne",
    trackable_on_etsy: 0,
  },
  "landmark-global": {
    carrier_name: "Landmark Global",
    trackable_on_etsy: 1,
  },
  lasership: {
    carrier_name: "LaserShip",
    trackable_on_etsy: 1,
  },
  "latvijas-pasts": {
    carrier_name: "Latvijas Pasts",
    trackable_on_etsy: 0,
  },
  libanpost: {
    carrier_name: "LibanPost",
    trackable_on_etsy: 0,
  },
  "lietuvos-pastas": {
    carrier_name: "Lietuvos Pastas",
    trackable_on_etsy: 1,
  },
  "magyar-posta": {
    carrier_name: "Magyar Posta",
    trackable_on_etsy: 1,
  },
  "makedonska-posta": {
    carrier_name: "Makedonska Posta",
    trackable_on_etsy: 0,
  },
  "malaysia-post-posdaftar": {
    carrier_name: "Malaysia Pos Daftar",
    trackable_on_etsy: 1,
  },
  "maldives-post": {
    carrier_name: "Maldives Post",
    trackable_on_etsy: 0,
  },
  maltapost: {
    carrier_name: "MaltaPost",
    trackable_on_etsy: 0,
  },
  "mauritius-post": {
    carrier_name: "Mauritius Post",
    trackable_on_etsy: 0,
  },
  mondialrelay: {
    carrier_name: "Mondial Relay",
    trackable_on_etsy: 1,
  },
  "mrw-spain": {
    carrier_name: "MRW",
    trackable_on_etsy: 1,
  },
  "mexico-multipack": {
    carrier_name: "Multipack",
    trackable_on_etsy: 1,
  },
  "myhermes-uk": {
    carrier_name: "myHermes UK",
    trackable_on_etsy: 1,
  },
  "nacex-spain": {
    carrier_name: "Nacex",
    trackable_on_etsy: 1,
  },
  "new-zealand-post": {
    carrier_name: "New Zealand Post",
    trackable_on_etsy: 1,
  },
  "tntpost-it": {
    carrier_name: "Nexive",
    trackable_on_etsy: 1,
  },
  "nieuwe-post-nederlandse-antillen-pna": {
    carrier_name: "Nieuwe Post Nederlandse Antillen (PNA)",
    trackable_on_etsy: 0,
  },
  nipost: {
    carrier_name: "Nigerian Postal Service",
    trackable_on_etsy: 1,
  },
  "nova-poshta": {
    carrier_name: "Nova Poshta",
    trackable_on_etsy: 1,
  },
  "oca-ar": {
    carrier_name: "OCA",
    trackable_on_etsy: 1,
  },
  opek: {
    carrier_name: "OPEK",
    trackable_on_etsy: 1,
  },
  "oman-post": {
    carrier_name: "Oman Post",
    trackable_on_etsy: 0,
  },
  ontrac: {
    carrier_name: "OnTrac",
    trackable_on_etsy: 1,
  },
  opt: {
    carrier_name: "OPT",
    trackable_on_etsy: 0,
  },
  "opt-nouvelle-caledonie": {
    carrier_name: "OPT de Nouvelle-Caledonie",
    trackable_on_etsy: 0,
  },
  "pakistan-post": {
    carrier_name: "Pakistan Post",
    trackable_on_etsy: 0,
  },
  "parcel-force": {
    carrier_name: "Parcelforce Worldwide",
    trackable_on_etsy: 1,
  },
  "poczta-polska": {
    carrier_name: "Poczta Polska",
    trackable_on_etsy: 1,
  },
  "pos-indonesia": {
    carrier_name: "Pos Indonesia",
    trackable_on_etsy: 1,
  },
  "pos-indonesia-int": {
    carrier_name: "Pos Indonesia International",
    trackable_on_etsy: 1,
  },
  "malaysia-post": {
    carrier_name: "Pos Malaysia",
    trackable_on_etsy: 1,
  },
  "post-aruba": {
    carrier_name: "Post Aruba",
    trackable_on_etsy: 0,
  },
  "post-fiji": {
    carrier_name: "Post Fiji",
    trackable_on_etsy: 0,
  },
  "post-luxembourg": {
    carrier_name: "Post Luxembourg",
    trackable_on_etsy: 0,
  },
  postnl: {
    carrier_name: "PostNL Domestic",
    trackable_on_etsy: 1,
  },
  "postnl-international": {
    carrier_name: "PostNL International",
    trackable_on_etsy: 1,
  },
  "postnl-3s": {
    carrier_name: "PostNL International 3S",
    trackable_on_etsy: 1,
  },
  "danmark-post": {
    carrier_name: "PostNord",
    trackable_on_etsy: 1,
  },
  postnord: {
    carrier_name: "PostNord Logistics",
    trackable_on_etsy: 1,
  },
  posta: {
    carrier_name: "Posta",
    trackable_on_etsy: 0,
  },
  "posta-kenya": {
    carrier_name: "Posta Kenya",
    trackable_on_etsy: 0,
  },
  "posta-moldovei": {
    carrier_name: "Posta Moldovei",
    trackable_on_etsy: 0,
  },
  "posta-romana": {
    carrier_name: "Posta Romana",
    trackable_on_etsy: 1,
  },
  "posta-shqiptare": {
    carrier_name: "Posta Shqiptare",
    trackable_on_etsy: 0,
  },
  "posta-slovenije": {
    carrier_name: "Posta Slovenije",
    trackable_on_etsy: 0,
  },
  "posta-srbije": {
    carrier_name: "Posta Srbije",
    trackable_on_etsy: 0,
  },
  "posta-uganda": {
    carrier_name: "Posta Uganda",
    trackable_on_etsy: 0,
  },
  "poste-italiane": {
    carrier_name: "Poste Italiane",
    trackable_on_etsy: 1,
  },
  "poste-italiane-paccocelere": {
    carrier_name: "Poste Italiane Paccocelere",
    trackable_on_etsy: 1,
  },
  "poste-maroc": {
    carrier_name: "Poste Maroc",
    trackable_on_etsy: 0,
  },
  "sweden-posten": {
    carrier_name: "Posten AB",
    trackable_on_etsy: 1,
  },
  "posten-norge": {
    carrier_name: "Posten Norge",
    trackable_on_etsy: 1,
  },
  posti: {
    carrier_name: "Posti",
    trackable_on_etsy: 1,
  },
  postmates: {
    carrier_name: "Postmates",
    trackable_on_etsy: 1,
  },
  "ptt-posta": {
    carrier_name: "PTT Posta",
    trackable_on_etsy: 1,
  },
  purolator: {
    carrier_name: "Purolator",
    trackable_on_etsy: 1,
  },
  "qatar-post": {
    carrier_name: "Qatar Post",
    trackable_on_etsy: 0,
  },
  "red-express": {
    carrier_name: "Red Express",
    trackable_on_etsy: 1,
  },
  "mexico-redpack": {
    carrier_name: "Redpack",
    trackable_on_etsy: 1,
  },
  "royal-mail": {
    carrier_name: "Royal Mail",
    trackable_on_etsy: 0,
  },
  "rl-carriers": {
    carrier_name: "RL Carriers",
    trackable_on_etsy: 1,
  },
  rpx: {
    carrier_name: "RPX Indonesia",
    trackable_on_etsy: 1,
  },
  "russian-post": {
    carrier_name: "Russian Post",
    trackable_on_etsy: 1,
  },
  sfb2c: {
    carrier_name: "S.F International",
    trackable_on_etsy: 1,
  },
  safexpress: {
    carrier_name: "Safexpress",
    trackable_on_etsy: 1,
  },
  sagawa: {
    carrier_name: "Sagawa",
    trackable_on_etsy: 1,
  },
  "saudi-post": {
    carrier_name: "Saudi Post",
    trackable_on_etsy: 1,
  },
  "italy-sda": {
    carrier_name: "SDA Express Courier",
    trackable_on_etsy: 1,
  },
  selektvracht: {
    carrier_name: "Selektvracht",
    trackable_on_etsy: 1,
  },
  "mexico-senda-express": {
    carrier_name: "Senda Express",
    trackable_on_etsy: 1,
  },
  sendle: {
    carrier_name: "Sendle",
    trackable_on_etsy: 1,
  },
  serpost: {
    carrier_name: "Serpost",
    trackable_on_etsy: 0,
  },
  "spanish-seur": {
    carrier_name: "SEUR Espana (Domestico)",
    trackable_on_etsy: 1,
  },
  "portugal-seur": {
    carrier_name: "SEUR Portugal (Domestico)",
    trackable_on_etsy: 1,
  },
  "sf-express": {
    carrier_name: "SF Express",
    trackable_on_etsy: 1,
  },
  "singapore-post": {
    carrier_name: "Singapore Post",
    trackable_on_etsy: 1,
  },
  "singapore-speedpost": {
    carrier_name: "Singapore SpeedPost",
    trackable_on_etsy: 1,
  },
  siodemka: {
    carrier_name: "Siodemka",
    trackable_on_etsy: 1,
  },
  "skynet-malaysia": {
    carrier_name: "Skynet Malaysia",
    trackable_on_etsy: 1,
  },
  skynetworldwide: {
    carrier_name: "Skynet Worldwide Express",
    trackable_on_etsy: 1,
  },
  "slovenska-posta": {
    carrier_name: "Slovenska posta",
    trackable_on_etsy: 0,
  },
  sapo: {
    carrier_name: "South Africa Post Office",
    trackable_on_etsy: 1,
  },
  stallionexpress: {
    carrier_name: "Stallion Express",
    trackable_on_etsy: 1,
  },
  "star-track": {
    carrier_name: "StarTrack",
    trackable_on_etsy: 1,
  },
  "swiss-post": {
    carrier_name: "Swiss Post",
    trackable_on_etsy: 1,
  },
  "taqbin-hk": {
    carrier_name: "TA-Q-BIN Hong Kong",
    trackable_on_etsy: 1,
  },
  "taqbin-jp": {
    carrier_name: "TA-Q-BIN Japan",
    trackable_on_etsy: 1,
  },
  "taqbin-my": {
    carrier_name: "TA-Q-BIN Malaysia",
    trackable_on_etsy: 1,
  },
  "taqbin-sg": {
    carrier_name: "TA-Q-BIN Singapore",
    trackable_on_etsy: 1,
  },
  tgx: {
    carrier_name: "TGX",
    trackable_on_etsy: 1,
  },
  "thailand-post": {
    carrier_name: "Thailand Post",
    trackable_on_etsy: 1,
  },
  tnt: {
    carrier_name: "TNT",
    trackable_on_etsy: 1,
  },
  "tnt-au": {
    carrier_name: "TNT Australia",
    trackable_on_etsy: 1,
  },
  "tnt-fr": {
    carrier_name: "TNT France",
    trackable_on_etsy: 1,
  },
  "tnt-it": {
    carrier_name: "TNT Italia",
    trackable_on_etsy: 1,
  },
  "tnt-uk": {
    carrier_name: "TNT UK",
    trackable_on_etsy: 1,
  },
  "toll-global-express": {
    carrier_name: "Toll Global Express",
    trackable_on_etsy: 0,
  },
  "toll-priority": {
    carrier_name: "Toll Priority",
    trackable_on_etsy: 1,
  },
  ttpost: {
    carrier_name: "TTPost",
    trackable_on_etsy: 0,
  },
  "uk-mail": {
    carrier_name: "UK Mail",
    trackable_on_etsy: 1,
  },
  ukrposhta: {
    carrier_name: "UkrPoshta",
    trackable_on_etsy: 1,
  },
  ups: {
    carrier_name: "UPS",
    trackable_on_etsy: 1,
  },
  "ups-freight": {
    carrier_name: "UPS Freight",
    trackable_on_etsy: 1,
  },
  uship: {
    carrier_name: "uShip",
    trackable_on_etsy: 1,
  },
  usps: {
    carrier_name: "USPS",
    trackable_on_etsy: 1,
  },
  "vanuatu-post": {
    carrier_name: "Vanuatu Post",
    trackable_on_etsy: 0,
  },
  vnpost: {
    carrier_name: "Vietnam Post",
    trackable_on_etsy: 1,
  },
  "vnpost-ems": {
    carrier_name: "Vietnam Post EMS",
    trackable_on_etsy: 1,
  },
  whistl: {
    carrier_name: "Whistl",
    trackable_on_etsy: 1,
  },
  xend: {
    carrier_name: "Xend",
    trackable_on_etsy: 1,
  },
  yakit: {
    carrier_name: "Yakit",
    trackable_on_etsy: 1,
  },
  yanwen: {
    carrier_name: "Yanwen",
    trackable_on_etsy: 1,
  },
  "yemen-post": {
    carrier_name: "Yemen Post",
    trackable_on_etsy: 0,
  },
  yodel: {
    carrier_name: "Yodel",
    trackable_on_etsy: 1,
  },
  "yodel-international": {
    carrier_name: "Yodel International",
    trackable_on_etsy: 1,
  },
  yrc: {
    carrier_name: "YRC Freight",
    trackable_on_etsy: 1,
  },
  zampost: {
    carrier_name: "Zampost",
    trackable_on_etsy: 0,
  },
  zimpost: {
    carrier_name: "Zimpost",
    trackable_on_etsy: 0,
  },
};

export const sortData = [
  { label: "Ascending", value: "", icon: ArrowUpIcon },
  { label: "Descending", value: "-", icon: ArrowDownIcon },
];

export const defaultShippingCarrierMap = {
  Australia: {
    "Australia Post": "Australia Post",
    Sendle: "Sendle",
    "Aramex Australia": "Aramex Australia",
    "TNT Australia": "TNT Australia",
    "Hunter Express": "Hunter Express",
    "Couriers Please": "Couriers Please",
    Bonds: "Bonds",
    "Allied Express": "Allied Express",
    "Direct Couriers": "Direct Couriers",
    Northline: "Northline",
    "GO Logistics": "GO Logistics",
  },
  Austria: { "\u00d6sterreichische Post": "\u00d6sterreichische Post" },
  Bulgaria: { Speedy: "Speedy" },
  Canada: {
    Intelcom: "Intelcom",
    BoxKnight: "BoxKnight",
    Loomis: "Loomis",
    GLS: "GLS",
  },
  China: {
    "China Post": "China Post",
    "DHL eCommerce Asia": "DHL eCommerce Asia",
    WanbExpress: "WanbExpress",
    YunExpress: "YunExpress",
    "Anjun Logistics": "Anjun Logistics",
    "SFC Fulfillment": "SFC Fulfillment",
    FSC: "FSC",
  },
  Czechia: { "Z\u00e1silkovna": "Z\u00e1silkovna" },
  Germany: {
    "Deutsche Post (DE)": "Deutsche Post (DE)",
    "Deutsche Post (EN)": "Deutsche Post (EN)",
    DHL: "DHL",
    "DHL Express": "DHL Express",
    Swiship: "Swiship",
    Hermes: "Hermes",
    GLS: "GLS",
  },
  Spain: { SEUR: "SEUR" },
  France: {
    Colissimo: "Colissimo",
    "Mondial Relay": "Mondial Relay",
    "Colis Priv\u00e9": "Colis Priv\u00e9",
    GLS: "GLS",
  },
  "United Kingdom": {
    Evri: "Evri",
    "DPD UK": "DPD UK",
    Parcelforce: "Parcelforce",
    Yodel: "Yodel",
    "DHL Parcel": "DHL Parcel",
    Tuffnells: "Tuffnells",
  },
  Greece: { "ACS Courier": "ACS Courier" },
  "Hong Kong SAR": { "SF Express": "SF Express" },
  Ireland: { Fastway: "Fastway", "DPD Ireland": "DPD Ireland" },
  India: {
    DTDC: "DTDC",
    "India Post": "India Post",
    Delhivery: "Delhivery",
    "Gati KWE": "Gati KWE",
    "Professional Couriers": "Professional Couriers",
    XpressBees: "XpressBees",
    "Ecom Express": "Ecom Express",
    Ekart: "Ekart",
    Shadowfax: "Shadowfax",
    Bluedart: "Bluedart",
  },
  Italy: { BRT: "BRT", "GLS Italy": "GLS Italy" },
  Japan: {
    "\u30a8\u30b3\u914d, \u897f\u6fc3\u904b\u8f38":
      "\u30a8\u30b3\u914d, \u897f\u6fc3\u904b\u8f38",
    "\u897f\u6fc3\u30b9\u30fc\u30d1\u30fc\u30a8\u30ad\u30b9\u30d7\u30ec\u30b9":
      "\u897f\u6fc3\u30b9\u30fc\u30d1\u30fc\u30a8\u30ad\u30b9\u30d7\u30ec\u30b9",
    "\u798f\u5c71\u901a\u904b": "\u798f\u5c71\u901a\u904b",
    "\u65e5\u672c\u901a\u904b": "\u65e5\u672c\u901a\u904b",
    "\u540d\u9244\u904b\u8f38": "\u540d\u9244\u904b\u8f38",
    "\u7b2c\u4e00\u8ca8\u7269": "\u7b2c\u4e00\u8ca8\u7269",
  },
  Netherlands: { "DHL Parcel": "DHL Parcel", DPD: "DPD" },
  Norway: { Bring: "Bring" },
  Poland: { Inpost: "Inpost" },
  Turkey: {
    PTT: "PTT",
    "Yurti\u00e7i Kargo": "Yurti\u00e7i Kargo",
    "Aras Kargo": "Aras Kargo",
    "S\u00fcrat Kargo": "S\u00fcrat Kargo",
  },
  "United States": {
    GLS: "GLS",
    "Alliance Air Freight": "Alliance Air Freight",
    "Pilot Freight": "Pilot Freight",
    LSO: "LSO",
    "Old Dominion": "Old Dominion",
    "R+L Carriers": "R+L Carriers",
    "Southwest Air Cargo": "Southwest Air Cargo",
  },
  "South Africa": { Fastway: "Fastway", Skynet: "Skynet" },
  Others: {
    "4PX": "4PX",
    AGS: "AGS",
    "Amazon Logistics UK": "Amazon Logistics UK",
    "Amazon Logistics US": "Amazon Logistics US",
    "An Post": "An Post",
    "Anjun Logistics": "Anjun Logistics",
    APC: "APC",
    "Asendia USA": "Asendia USA",
    "Australia Post": "Australia Post",
    Bonshaw: "Bonshaw",
    BPost: "BPost",
    "BPost International": "BPost International",
    "Canada Post": "Canada Post",
    Canpar: "Canpar",
    "CDL Last Mile": "CDL Last Mile",
    "China Post": "China Post",
    Chronopost: "Chronopost",
    Chukou1: "Chukou1",
    Colissimo: "Colissimo",
    Comingle: "Comingle",
    Coordinadora: "Coordinadora",
    Correios: "Correios",
    Correos: "Correos",
    CTT: "CTT",
    "CTT Express": "CTT Express",
    "Cyprus Post": "Cyprus Post",
    Delnext: "Delnext",
    "Deutsche Post": "Deutsche Post",
    "DHL eCommerce": "DHL eCommerce",
    "DHL eCommerce Asia": "DHL eCommerce Asia",
    "DHL Express": "DHL Express",
    DoorDash: "DoorDash",
    DPD: "DPD",
    "DPD Local": "DPD Local",
    "DPD UK": "DPD UK",
    "DTD Express": "DTD Express",
    DX: "DX",
    Eagle: "Eagle",
    Estes: "Estes",
    Evri: "Evri",
    FedEx: "FedEx",
    "First Global Logistics": "First Global Logistics",
    "First Line": "First Line",
    FSC: "FSC",
    Fulfilla: "Fulfilla",
    GLS: "GLS",
    "Guangdong Weisuyi Information Technology (WSE)":
      "Guangdong Weisuyi Information Technology (WSE)",
    "Heppner Internationale Spedition GmbH & Co.":
      "Heppner Internationale Spedition GmbH & Co.",
    "Iceland Post": "Iceland Post",
    IDEX: "IDEX",
    "Israel Post": "Israel Post",
    "Japan Post (EN)": "Japan Post (EN)",
    "Japan Post (JA)": "Japan Post (JA)",
    "La Poste": "La Poste",
    Lasership: "Lasership",
    "Latvia Post": "Latvia Post",
    "Lietuvos Pa\u0161tas": "Lietuvos Pa\u0161tas",
    Logisters: "Logisters",
    "Lone Star Overnight": "Lone Star Overnight",
    "M3 Logistics": "M3 Logistics",
    "Meteor Space": "Meteor Space",
    "Mondial Relay": "Mondial Relay",
    "New Zealand Post": "New Zealand Post",
    NinjaVan: "NinjaVan",
    "North Russia Supply Chain (Shenzhen) Co.":
      "North Russia Supply Chain (Shenzhen) Co.",
    OnTrac: "OnTrac",
    Packeta: "Packeta",
    "Pago Logistics": "Pago Logistics",
    "Ping An Da Tengfei Express": "Ping An Da Tengfei Express",
    "Pitney Bowes": "Pitney Bowes",
    "Portal PostNord": "Portal PostNord",
    "Poste Italiane": "Poste Italiane",
    PostNL: "PostNL",
    "PostNord DK": "PostNord DK",
    "PostNord NO": "PostNord NO",
    "PostNord SE": "PostNord SE",
    Purolator: "Purolator",
    "Qyun Express": "Qyun Express",
    "Royal Mail": "Royal Mail",
    "Royal Shipments": "Royal Shipments",
    "Sagawa (EN)": "Sagawa (EN)",
    "Sagawa (JA)": "Sagawa (JA)",
    Sendle: "Sendle",
    "SF Express": "SF Express",
    "SFC Fulfillment": "SFC Fulfillment",
    "SHREE NANDAN COURIER": "SHREE NANDAN COURIER",
    "Singapore Post": "Singapore Post",
    "Southwest Air Cargo": "Southwest Air Cargo",
    StarTrack: "StarTrack",
    "Step Forward Freight": "Step Forward Freight",
    "Swiss Post": "Swiss Post",
    "TForce Final Mile": "TForce Final Mile",
    Tinghao: "Tinghao",
    TNT: "TNT",
    "Toll IPEC": "Toll IPEC",
    "United Delivery Service": "United Delivery Service",
    UPS: "UPS",
    USPS: "USPS",
    Venipak: "Venipak",
    "We Post": "We Post",
    Whistl: "Whistl",
    Wizmo: "Wizmo",
    WMYC: "WMYC",
    Xpedigo: "Xpedigo",
    "XPO Logistics": "XPO Logistics",
    "Yamato (EN)": "Yamato (EN)",
    "Yamato (JA)": "Yamato (JA)",
    "YiFan Express": "YiFan Express",
    YunExpress: "YunExpress",
  },
};

export const defaultBadgeStatuses = ["4", "6", "12", "13", "archived"];

export const subscription_level: { [num: number]: string } = {
  1: "Trial",
  3: "Trial Expired",
  2: "Purchased",
  5: "Free",
  4: "License Expired",
};

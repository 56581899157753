import { Storage } from "../../Interface/@core";
import { GetHashed } from "../Helper";

/**
 * A utility object for managing global state using web storage.
 */
export const GlobalState = {
  /**
   * Sets a key-value pair in the specified storage.
   *
   * @returns {Function} A function that sets a key-value pair in the specified storage.
   * @param {string} key - The key to set.
   * @param {string} value - The value to set.
   * @param {Storage} [storage_type=sessionStorage] - The storage type to use. Defaults to sessionStorage.
   */
  set:
    (): Function =>
    (
      key: string,
      value: string,
      storage_type: Storage = sessionStorage
    ): void =>
      storage_type.setItem(GetHashed(key), value),

  /**
   * Retrieves the value associated with the given key from the specified storage.
   *
   * @returns {Function} A function that retrieves the value associated with the given key from the specified storage.
   * @param {string} key - The key to retrieve.
   * @param {Storage} [storage_type=sessionStorage] - The storage type to use. Defaults to sessionStorage.
   * @returns {string} The retrieved value.
   */
  get:
    (): Function =>
    (key: string, storage_type: Storage = sessionStorage): string =>
      storage_type.getItem(GetHashed(key)) as string,

  /**
   * Clears all key-value pairs from the specified storage.
   *
   * @returns {Function} A function that clears all key-value pairs from the specified storage.
   * @param {Storage} [storage_type=sessionStorage] - The storage type to use. Defaults to sessionStorage.
   */
  reset:
    (): Function =>
    (storage_type: Storage = sessionStorage): void =>
      storage_type.clear(),

  /**
   * Removes the key-value pair associated with the given key from the specified storage.
   *
   * @returns {Function} A function that removes the key-value pair associated with the given key from the specified storage.
   * @param {string} key - The key to remove.
   * @param {Storage} [storage_type=sessionStorage] - The storage type to use. Defaults to sessionStorage.
   */
  remove:
    (): Function =>
    (key: string, storage_type: Storage = sessionStorage): void =>
      storage_type.removeItem(GetHashed(key)),
};

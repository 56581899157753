import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Icon,
  IndexTable,
  InlineGrid,
  InlineStack,
  Select,
  Text,
  useBreakpoints,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { DeleteIcon, PlusIcon } from "@shopify/polaris-icons";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { DI } from "Src/core";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { environment } from "Src/environment/environment";
import { getQueryStr } from "Src/core/_Services";
import {
  etsyCarrierOptions,
  shopifyCarrierOptions,
} from "Src/Constant/SelectOptions";
import { t } from "i18next";

const ShippingCarrierMapping = ({
  request: { GET, DELETE, POST },
}: DIProps) => {
  const { mdUp, mdDown } = useBreakpoints();

  const [shippingCarrierState, setShippingCarrierState] = useState<{
    loading: boolean;
    data: ObjStrI[];
  }>({
    loading: false,
    data: [],
  });
  const [deleteCarrierState, setDeleteCarrierState] = useState({
    loading: false,
    index: -1,
  });
  const [addModalState, setAddModalState] = useState({
    isOpen: false,
    loading: false,
    etsyCarrier: "",
    shopifyCarrier: "",
    allowDifferentCarrierMapping: false,
  });

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(shippingCarrierState.data);

  const toggleAddModal = () => {
    setAddModalState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
      etsyCarrier: prev.isOpen ? "" : prev.etsyCarrier,
      allowDifferentCarrierMapping: prev.isOpen
        ? false
        : prev.allowDifferentCarrierMapping,
      shopifyCarrier: prev.isOpen ? "" : prev.shopifyCarrier,
    }));
  };

  const fetchShippingCarriers = () => {
    setShippingCarrierState((prev) => ({ ...prev, loading: true }));
    GET(apiUrls.getCarrierMapping)
      .then((res) => {
        if (res?.success) {
          setShippingCarrierState((prev) => ({ ...prev, data: res?.data }));
        } else {
          ToastAppBridge(res?.message ?? res?.msg, { isError: true });
        }
      })
      .finally(() => {
        setShippingCarrierState((prev) => ({ ...prev, loading: true }));
      });
  };

  const addShippingCarrier = () => {
    setAddModalState((prev) => ({ ...prev, loading: true }));
    const payload = {
      carrier_map: "product_delete_carrier",
      carrier_service: addModalState.shopifyCarrier,
      etsy_carrier_name: addModalState.etsyCarrier,
      shipment_default_carrier: "",
    };
    POST(apiUrls.saveCarrierMapping, payload)
      .then((res) => {
        if (res?.success) {
          fetchShippingCarriers();
          toggleAddModal();
        } else {
          ToastAppBridge(res?.message ?? res?.msg, { isError: true });
        }
      })
      .finally(() => {
        setAddModalState((prev) => ({ ...prev, loading: false }));
      });
  };

  const deleteShippingCarrier = (index: number) => {
    setDeleteCarrierState((prev) => ({ ...prev, loading: true, index }));
    const detailsObj = shippingCarrierState.data[index];
    const params = {
      id: detailsObj?.id,
      carrier_name: detailsObj?.etsy_carrier_name,
    };
    let paramsString = getQueryStr(apiUrls.deleteCarrierMapping, params);
    let endpoint =
      environment.API_ENDPOINT + apiUrls.deleteCarrierMapping + paramsString;
    const payload = {
      carrier_map: "product_delete_carrier",
      carrier_service: detailsObj?.shopify_carrier_name,
      etsy_carrier_name: detailsObj?.etsy_carrier_name,
      shipment_default_carrier: "",
    };
    DELETE(endpoint, payload, true)
      .then((res) => {
        if (res?.success) {
          fetchShippingCarriers();
        }
        ToastAppBridge(res?.message ?? res?.msg, { isError: !res?.success });
      })
      .finally(() => {
        setDeleteCarrierState((prev) => ({
          ...prev,
          loading: true,
          index: -1,
        }));
      });
  };

  useEffect(() => {
    fetchShippingCarriers();
  }, []);

  const rowMarkup = shippingCarrierState.data.map(
    ({ id, etsy_carrier_name, shopify_carrier_name }, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{shopify_carrier_name}</IndexTable.Cell>
        <IndexTable.Cell>{etsy_carrier_name}</IndexTable.Cell>
        <IndexTable.Cell>
          <Button
            tone="critical"
            variant="plain"
            icon={<Icon source={DeleteIcon} tone="critical" />}
            onClick={() => deleteShippingCarrier(index)}
            loading={
              deleteCarrierState.index === index && deleteCarrierState.loading
            }
          ></Button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );


  const rowMarkupMobile = shippingCarrierState.data.map(
    ({ id, etsy_carrier_name, shopify_carrier_name }, index) => (
      <Box id={id}
      key={id}>
         <BlockStack gap='200'>
         <BlockStack gap='100'>
         <InlineGrid columns={2} gap='100'>
         <Text as="p" fontWeight="medium">Shopify Shipping:</Text>

       {shopify_carrier_name}
       </InlineGrid>

       <InlineGrid columns={2} gap='100'>
       <Text as="p" fontWeight="medium">Etsy Shipping:</Text>

    {etsy_carrier_name}

    </InlineGrid>
    </BlockStack>
    <InlineStack align="end">
          <Button
            tone="critical"
            variant="plain"
             children="Delete"
            onClick={() => deleteShippingCarrier(index)}
            loading={
              deleteCarrierState.index === index && deleteCarrierState.loading
            }
          ></Button>
       </InlineStack>
       </BlockStack>
       {mdDown && (<Box paddingBlockStart='200' paddingBlockEnd="200"><Divider /></Box>)}

      </Box>
    )
  );

  const toggleDifferentCarrierMapping = () => {
    setAddModalState((prev) => ({
      ...prev,
      allowDifferentCarrierMapping: !prev.allowDifferentCarrierMapping,
    }));
  };

  const areSameCarriers =
    addModalState.etsyCarrier === addModalState.shopifyCarrier;

  const isDisabled = useMemo(() => {
    if (!areSameCarriers) {
      return !addModalState.allowDifferentCarrierMapping;
    }
    return false;
  }, [addModalState.allowDifferentCarrierMapping, areSameCarriers]);

  return (
    <>
      {shippingCarrierState.data.length > 0 ? (
       <Card roundedAbove="xs">
          <BlockStack gap="300">
          <BlockStack gap={mdUp ? "200" : "0"}>
            {mdUp ?  <Card padding="0">

                <IndexTable
                  itemCount={shippingCarrierState.data.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  selectable={false}
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: t("SHOPIFY_CARRIER") },
                    { title: t("ETSY_CARRIER") },
                    { title: t("ACTION") },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

              </Card> : rowMarkupMobile}
             {mdUp && <Divider />}
            </BlockStack>

            <InlineStack align="end">
              <Button
                onClick={toggleAddModal}
                icon={<Icon source={PlusIcon} tone="base" />}
              >
                {t("ADD_CARRIER_MAPPING")}
              </Button>
            </InlineStack>
          </BlockStack>

        </Card>
      ) : (
        <InlineStack align="end">
          <Button
            onClick={toggleAddModal}
            icon={<Icon source={PlusIcon} tone="base" />}
          >
            {t("ADD_CARRIER_MAPPING")}
          </Button>
        </InlineStack>
      )}

      {addModalState.isOpen && (
        <ModalAppBridge
          title={t("CARRIER_MAPPING")}
          footer={
            <>
              <button
                variant="primary"
                onClick={addShippingCarrier}
                loading={addModalState.loading && "true"}
                disabled={
                  isDisabled ||
                  addModalState.shopifyCarrier === "" ||
                  addModalState.etsyCarrier === ""
                }
              >
                {t("MAP_CARRIER_SERVICES")}
              </button>
              <button onClick={toggleAddModal}>{t("CANCEL")}</button>
            </>
          }
          id="add-shipping-carrier"
          open={addModalState.isOpen}
          onHide={toggleAddModal}
          body={
            <Box padding="400">
              <BlockStack gap="300">
                <InlineGrid columns={{ sm: "2" }} gap="200">
                  <Select
                    requiredIndicator
                    label={t("SHOPIFY_CARRIER_SERVICE")}
                    placeholder={t("SELECT")}
                    options={shopifyCarrierOptions}
                    onChange={(val) =>
                      setAddModalState((prev) => ({
                        ...prev,
                        shopifyCarrier: val,
                      }))
                    }
                    value={addModalState.shopifyCarrier}
                  />

                  <Select
                    label={t("ETSY_CARRIER_SERVICE")}
                    requiredIndicator
                    placeholder={t("SELECT")}
                    options={etsyCarrierOptions}
                    value={addModalState.etsyCarrier}
                    onChange={(val) =>
                      setAddModalState((prev) => ({
                        ...prev,
                        etsyCarrier: val,
                      }))
                    }
                  />
                </InlineGrid>
                {!areSameCarriers &&
                  addModalState.etsyCarrier !== "" &&
                  addModalState.shopifyCarrier !== "" && (
                    <Checkbox
                      label={t("MERGE_TWO_COUNTRIES_CONFIRMATION")}
                      checked={addModalState.allowDifferentCarrierMapping}
                      onChange={toggleDifferentCarrierMapping}
                    />
                  )}
              </BlockStack>
            </Box>
          }
        />
      )}
    </>
  );
};

export default DI(ShippingCarrierMapping);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app_notification_action span.Polaris-Text--root {
    background: #E51C00;
    position: absolute;
    height: 17px;
    min-width: 17px;
    border-radius: 100%;
    color: #fff;
    right: -5px;
    top: -5px;
    font-size: 10px;
    padding: 1px;
}

.app_notification_action button.Polaris-Button {
    padding-right: 6px;
}
.notification-btn {
    position: relative;
}
.notification-count {
    position: absolute;
    background-color: #E51C00;
    display: inline-flex;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: 550;
    align-items: center;
    justify-content: center;
}
.with-count{
    top: -8px;
    right: -8px;
    width: 18px;
    height: 18px;
}
.without-count {
    top: -4px;
    right: -4px;
    width: 12px;
    height: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Notification/notification.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,SAAS;IACT,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,SAAS;IACT,WAAW;IACX,WAAW;IACX,YAAY;AAChB;AACA;IACI,SAAS;IACT,WAAW;IACX,WAAW;IACX,YAAY;AAChB","sourcesContent":[".app_notification_action span.Polaris-Text--root {\n    background: #E51C00;\n    position: absolute;\n    height: 17px;\n    min-width: 17px;\n    border-radius: 100%;\n    color: #fff;\n    right: -5px;\n    top: -5px;\n    font-size: 10px;\n    padding: 1px;\n}\n\n.app_notification_action button.Polaris-Button {\n    padding-right: 6px;\n}\n.notification-btn {\n    position: relative;\n}\n.notification-count {\n    position: absolute;\n    background-color: #E51C00;\n    display: inline-flex;\n    border-radius: 50%;\n    color: #fff;\n    font-size: 10px;\n    font-weight: 550;\n    align-items: center;\n    justify-content: center;\n}\n.with-count{\n    top: -8px;\n    right: -8px;\n    width: 18px;\n    height: 18px;\n}\n.without-count {\n    top: -4px;\n    right: -4px;\n    width: 12px;\n    height: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

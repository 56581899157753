import {
  Bleed,
  BlockStack,
  Card,
  Divider,
  FormLayout,
  InlineStack,
  Select,
  Text,
  TextField,
  useBreakpoints,
  Thumbnail,
  Link,
  Avatar,
  Button,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import {
  CurrencyIcon,
  LocationIcon,
  PlanIcon,
} from "../../../assets/icons/Icons";
import "../settings.css";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { avatarInitials, emailValidation } from "Src/Component/HelperComponents/GlobalFunctions";
import { time_zones, times_preference } from "Src/Constant/SelectOptions";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { saveShopifyDetails } from "Src/Redux/Slices/settingsSlice";
import { useSettingsContext } from "../SettingsProvider";

const { saveShop } = apiUrls;

const ShopifyDetails = (_props: DIProps) => {
  const { request: { POST }, redux: { settings: { shopifyDetails } }, dispatch, t } = _props;
  const { isChange, setIsChange } = useSettingsContext()
  const { smDown } = useBreakpoints();
  const [shopifyData, setShopifyData] = useState({
    email: "",
    time_zone: "",
    preferred_time: "",
    email_error: ""
  });
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSave = () => {
    const { msg, success } = emailValidation(shopifyData.email)
    if (!success) {
      setShopifyData((prev) => ({ ...prev, email_error: msg }));
      return;
    }
    setSaveLoading(true);
    POST(saveShop, {
      shopify: {
        email: shopifyData.email,
        Timezonestatus: shopifyData.time_zone,
        Timezonevalue: shopifyData.preferred_time
      }
    }).then((res) => {
      if (res.success) {
        setIsChange(false);
        dispatch(saveShopifyDetails({
          ...shopifyDetails,
          email: shopifyData.email,
          time_zone: shopifyData.time_zone,
          preferred_time: shopifyData.preferred_time
        }))
        ToastAppBridge(res?.message ?? "Settings updated successfully!");
      } else {
        ToastAppBridge(res?.message ?? "Something went wrong!", { isError: true });
      }
    }).finally(() => {
      setSaveLoading(false);
    })
  }

  useEffect(() => {
    setShopifyData({
      email: shopifyDetails.email,
      preferred_time: shopifyDetails.preferred_time,
      time_zone: shopifyDetails.time_zone,
      email_error: ""
    })
  }, [])

  return (
    <BlockStack gap="300">
      <Card>
        <BlockStack gap="400">
          <BlockStack gap="200">
            <Text as="h2" variant="headingSm">
              {t("SHOPIFY_DETAILS")}
            </Text>
            <Text as="p" variant="bodyMd">
            {t("SHARE_SHOPIFY_DETAILS")}
            </Text>
          </BlockStack>
          <Bleed marginInlineStart="400" marginInlineEnd="400">
            <Divider />
          </Bleed>

          <InlineStack gap={{ xs: "400", sm: "800" }}>
            <InlineStack
              gap="300"
              blockAlign={smDown ? "start" : "center"}
              wrap={false}
            >
              <Avatar size="xl" name={shopifyDetails.owner_name} initials={avatarInitials(shopifyDetails.owner_name)} />
              <InlineStack gap={{ xs: "300", sm: "600" }}>
                <BlockStack>
                  <Text variant="bodySm" tone="subdued" as="p">
                    {t("NAME")}
                  </Text>
                  <Text variant="bodySm" as="p" fontWeight="medium">
                    {shopifyDetails.owner_name}
                  </Text>
                </BlockStack>
                <BlockStack>
                  <Text variant="bodySm" tone="subdued" as="p">
                    {t("SHOP_URL")}
                  </Text>
                  <Link target="_blank" url={`https://${shopifyDetails.shopurl}`}>{shopifyDetails.shopurl}</Link>
                </BlockStack>
              </InlineStack>
            </InlineStack>

            <div className="vl"></div>

            <InlineStack gap={{ xs: "400", sm: "800" }} wrap={false}>
              <InlineStack gap={{ xs: "200", sm: "300" }} blockAlign="center">
                {LocationIcon}
                <BlockStack>
                  <Text variant="bodySm" tone="subdued" as="p">
                    {t("COUNTRY")}
                  </Text>
                  <Text variant="bodySm" as="p" fontWeight="medium">
                    {shopifyDetails.shopify_country}
                  </Text>
                </BlockStack>
              </InlineStack>

              <InlineStack gap={{ xs: "200", sm: "300" }} blockAlign="center">
                {PlanIcon}
                <BlockStack>
                  <Text variant="bodySm" tone="subdued" as="p">
                    {t("SHOPIFY_PLAN")}
                  </Text>
                  <Text variant="bodySm" as="p" fontWeight="medium">
                    {shopifyDetails.shopify_plan}
                  </Text>
                </BlockStack>
              </InlineStack>
            </InlineStack>
          </InlineStack>

          <Bleed marginInlineStart="400" marginInlineEnd="400">
            <Divider />
          </Bleed>

          <FormLayout>
            <FormLayout.Group>
              <TextField
                type="email"
                label={t("EMAIL")}
                value={shopifyData.email}
                onChange={(val) => {
                  !isChange && setIsChange(true);
                  setShopifyData((prev) => ({ ...prev, email: val, email_error: "" }));
                }}
                onBlur={() => { setShopifyData((prev) => ({ ...prev, email_error: emailValidation(prev.email).msg })); }}
                autoComplete="off"
                helpText={t("REGISTERED_EMAIL_HELPTEXT")}
                error={!!shopifyData.email_error && shopifyData.email_error}
              />

              <Select
                label={t("TIME_ZONE")}
                options={[...time_zones]}
                onChange={(val) => {
                  !isChange && setIsChange(true);
                  setShopifyData((prev) => ({ ...prev, time_zone: val }))
                }}
                value={shopifyData.time_zone}
                helpText={t("TIME_ZONE_HELPTEXT")}
              />

              <Select
                label={t("CALL_WINDOW")}
                options={[...times_preference]}
                placeholder={t("SELECT_PREFERRED_TIME")}
                onChange={(val) => {
                  !isChange && setIsChange(true);
                  setShopifyData((prev) => ({ ...prev, preferred_time: val }))
                }}
                value={shopifyData.preferred_time}
                helpText={t("CALL_WINDOW_HELPTEXT")}
              />
            </FormLayout.Group>
          </FormLayout>
        </BlockStack>
      </Card>
      <InlineStack align="end">
        <Button variant="primary" loading={saveLoading} onClick={() => { handleSave() }}>{t("SAVE")}</Button>
      </InlineStack>
    </BlockStack>
  );
};

export default DI(ShopifyDetails);

import { BlockStack, Box, Card, InlineStack, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'

const ProductSyncingSkeleton = () => {
    return (
        <BlockStack gap={"300"}>
            <Box paddingInlineEnd={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }} paddingInlineStart={{ "xs": "400", "xl": "0", "md": "0", "lg": "0", "sm": "0" }}>
                <div style={{ "width": "150px" }} className='skeleton-height-20'>
                    <SkeletonBodyText lines={1} />
                </div>
            </Box>
            <Card>

                <BlockStack gap={"500"}>
                    <div style={{ "width": "70%" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <BlockStack gap={"300"}>
                        {Array.from({ length: 2 }, (index: any) =>
                            <div style={{ "width": "40%" }}>
                                <InlineStack wrap={false} gap={"200"} blockAlign='center'>
                                    <div style={{ "width": "20px" }} className='skeleton-height-20'>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ "width": "120px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                </InlineStack>
                            </div>
                        )}

                    </BlockStack>
                </BlockStack>

            </Card>
        </BlockStack>
    )
}

export default ProductSyncingSkeleton

import {
  Banner,
  BlockStack,
  Box,
  Card,
  Checkbox,
  Divider,
  List,
  Page,
  Select,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { apiUrls } from "Src/Constant/url";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { ExtraModalFormTypeI } from "Src/Interface/@Profile";
import PolicyTemplateFullPageSkelton from "../Skeleton/PolicyTemplateFullPageSkelton";
import { t } from "i18next";
import { returnDeadlineOptions } from "Src/Constant/SelectOptions";
import { isConfigChecked } from "Src/function";

export interface StateI {
  accepts_exchanges: string;
  accepts_returns: string;
  return_deadline: string;
}

const { getReturnPolicyById, savePolicyTemplate } = apiUrls;

const PolicyForm = (props: ExtraModalFormTypeI & DIProps) => {
  const {
    request: { GET, POST },
    t,
    navigate,
    type = "page",
    setSaveLoading,
    saveCall = false,
    setSaveCall,
    closeModal,
    setTemplateId,
    onCreation
  } = props;
  const {mdUp} = useBreakpoints();
  const { routeData, setRouteData } = useRoutesContext();
  const [state, setState] = useState<StateI>({
    accepts_exchanges: "1",
    accepts_returns: "1",
    return_deadline: "7",
  });
  const [loading, setLoading] = useState({
    getLoading: false,
    saveLoading: false,
  });

  const getReturnPolicy = () => {
    setLoading((prev) => ({ ...prev, getLoading: true }));
    GET(getReturnPolicyById, {
      "EtsyShippingTemplateSearch[return_policy_id]": routeData?.data?.id,
      "EtsyShippingTemplateSearch[status]": "policy",
    })
      .then((res) => {
        if (res.success && res?.data?.template_data?.length > 0) {
          setState({
            accepts_exchanges: `${res?.data?.template_data?.[0]?.accepts_exchanges ?? "1"
              }`,
            accepts_returns: `${res?.data?.template_data?.[0]?.accepts_returns ?? "1"
              }`,
            return_deadline: `${res?.data?.template_data?.[0]?.return_deadline ?? "7"
              }`,
          });
        }
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, getLoading: false }));
      });
  };

  const onSave = () => {
    setLoading((prev) => ({ ...prev, saveLoading: true }));
    const payload = {
      accepts_returns: state.accepts_returns,
      accepts_exchanges: state.accepts_exchanges,
      policy_submitted: "1",
      ...(routeData?.data?.id
        ? { if_case_of_edit: `${routeData?.data?.id ?? ""}` }
        : {}),
    };
    if(isConfigChecked(state.accepts_returns) || isConfigChecked(state.accepts_exchanges)) {
      Object.assign(payload, {
        return_deadline: state.return_deadline,
      });
    }
    POST(savePolicyTemplate, payload)
      .then((res) => {
        if (res.success) {
          setTemplateId && setTemplateId(res?.template_id as string);
          res?.template_id && onCreation?.({[res?.template_id]: res?.template_name})
          ToastAppBridge(res?.message);
          if (closeModal) {
            closeModal();
          } else {
            setRouteData({
              data: { selected: 3 },
              from: "policy-form",
              to: "template",
            });
            navigate("../template");
          }
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, saveLoading: false }));
      });
  };

  useEffect(() => {
    if (routeData?.data?.id) {
      getReturnPolicy();
    }
  }, []);

  useEffect(() => {
    if (saveCall) {
      onSave();
      setSaveCall?.(false);
    }
  }, [saveCall]);
  return (
    <div>
      <Box borderBlockEndWidth={mdUp ? '025' : '0'} borderColor="border">
        {type !== "modal" && (
          <div className="custom_layout_width">
            <Page
              backAction={{
                content: t("POLICY_TEMPLATE"),
                onAction: () => {
                  setRouteData({
                    data: { selected: 3 },
                    from: "policy-form",
                    to: "template",
                  });
                  navigate(-1);
                },
              }}
              fullWidth
              title={t("POLICY_TEMPLATE")}
              primaryAction={{
                content: t("SAVE"),
                accessibilityLabel: "Save",
                loading: loading.saveLoading,
                onAction() {
                  onSave();
                },
              }}
            // secondaryActions={[
            //   { content: "Need Help ?", accessibilityLabel: "Need Help ?" },
            // ]}
            ></Page>
          </div>
        )}
      </Box>
      {loading.getLoading ? (
        <PolicyTemplateFullPageSkelton />
      ) : (

        <div className="custom_layout_width">
          <Page fullWidth>
            <BlockStack gap={"400"}>
              <ConditionsOfReturn />
              <Card>
                <BlockStack gap={"300"}>
                  <BlockStack gap={"200"}>
                    <Text as="h6" variant="headingMd" fontWeight="semibold">
                      {t("SHOP_RETURN_POLICY")}
                    </Text>
                    <Divider />
                  </BlockStack>
                  <BlockStack gap={"300"}>
                    <Checkbox
                      label={t("RETURNS")}
                      helpText={t("I_ACCEPT_RETURNS")}
                      checked={state.accepts_returns === "1"}
                      disabled={loading.saveLoading}
                      onChange={(newChecked) => {
                        setState((prev) => ({
                          ...prev,
                          accepts_returns: newChecked ? "1" : "0",
                        }));
                      }}
                    />
                    <Divider />
                    <Checkbox
                      label={t("EXCHANGES")}
                      helpText={t("I_ACCEPT_EXCHANGES")}
                      checked={state.accepts_exchanges === "1"}
                      disabled={loading.saveLoading}
                      onChange={(newChecked) => {
                        setState((prev) => ({
                          ...prev,
                          accepts_exchanges: newChecked ? "1" : "0",
                        }));
                      }}
                    />
                    {(state.accepts_exchanges === "1" ||
                      state.accepts_returns === "1") && (
                        <React.Fragment>
                          <Divider />
                          <Select
                            label={t("THE_BUYER_MUST_CONTACT_ME")}
                            options={returnDeadlineOptions(t)}
                            value={state.return_deadline}
                            disabled={loading.saveLoading}
                            onChange={(selected) => {
                              setState((prev) => ({
                                ...prev,
                                return_deadline: selected,
                              }));
                            }}
                          />
                        </React.Fragment>
                      )}
                  </BlockStack>
                </BlockStack>
              </Card>
            </BlockStack>
          </Page>
        </div>

      )}
    </div>
  );
};
export default DI(PolicyForm);

const ConditionsOfReturn = () => {
  const [showBanner, setShowBanner] = useState(true);
  if (showBanner)
    return (
      <Banner
        onDismiss={() => {
          setShowBanner(false);
        }}
      >
        <Text as="h6" variant="headingSm">
          {t("RETURN_CONDITION")}
        </Text>
        <List>
          <List.Item>
            <Text as="p" variant="bodyMd">
              {t("RETURN_CONDITION_DESC")}
            </Text>
          </List.Item>
        </List>
      </Banner>
    );
  return null;
};

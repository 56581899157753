import React, { useCallback, useState } from 'react'
import ProfileGridSkeleton from './ProfileGridSkeleton'
import { BlockStack, Card, ChoiceList, Divider, IndexFilters, IndexFiltersMode, IndexFiltersProps, InlineStack, RangeSlider, SkeletonDisplayText, SkeletonTabs, TabProps, TextField, useSetIndexFiltersMode } from '@shopify/polaris'

const FullProfileGridSkeleton = () => {
    const searchField = <InlineStack gap={"200"}>
        <div style={{ 'width': '120px' }}>
            <SkeletonDisplayText size="small" />
        </div>
    </InlineStack>
    return (
        <Card padding={'0'}>
            <BlockStack>
                <BlockStack>
                    <div className='index-filter_skeleton'>
                        <InlineStack align='space-between'>
                            {searchField}
                            <InlineStack gap={"200"}>
                                <div style={{ 'width': '110px' }}>
                                    <SkeletonDisplayText size="small" />
                                </div>
                                <div style={{ 'width': '28px' }}>
                                    <SkeletonDisplayText size="small" />
                                </div>
                                <div style={{ 'width': '28px' }}>
                                    <SkeletonDisplayText size="small" />
                                </div>
                            </InlineStack>
                        </InlineStack>
                    </div>
                    <Divider />
                    <div className='filter_skeleton'>
                        <InlineStack>
                            <SkeletonTabs />
                        </InlineStack>
                    </div>
                </BlockStack>
                <Divider />
                <ProfileGridSkeleton />
            </BlockStack>
        </Card>
    )
}

export default FullProfileGridSkeleton

export const orderStatus: { [name: number]: string } = {
  3: "UNPAID",
  10: "PAID",
  1: "OPEN",
  2: "UNSHIPPED",
  5: "PROCESSING",
  7: "REFUNDED",
  4: "COMPLETED", //"ORDER_CLOSED", //Completed
  8: "MANUAL",
  9: "CANCELLED",
  12: "PARTIAL_REFUNDED",
  13: "FULLY_REFUNDED",
  14: "ARCHIVED",
  6: "FAILED",
  11: "OTHER_STATUS",
};

export const FormatOrderData = (data: any[]) => {
  return data.map((e) => ({ ...e, id: e.receipt_id }));
};

import { Box, IndexTable, InlineStack, SkeletonBodyText } from '@shopify/polaris';
import React from 'react'
import { gridSkeletonRow } from './BulkRowSkeletonData';

const BulkSkeletonRowMarkup = () => {


  const rowMarkupData = Array.from({ length: 3 }, () => gridSkeletonRow);

  return rowMarkupData.map((item, index: any) => {
    return <React.Fragment key={index}>
      <IndexTable.Row id={index} position={index}>
        <IndexTable.Cell flush className="min-w-320 title-cell bg-white">
          {item.title}
        </IndexTable.Cell>
        <IndexTable.Cell flush className="min-w-260  bg-white ">
          {item.tags}
        </IndexTable.Cell>
        <IndexTable.Cell flush className="min-w-320 bg-white ">
          {item.dimensions}
        </IndexTable.Cell>
        <IndexTable.Cell flush className="min-w-180 bg-white ">
          {item.vendor}
        </IndexTable.Cell>
        <IndexTable.Cell flush className="min-w-180 bg-white ">
          {item.shop_section}
        </IndexTable.Cell>
        <IndexTable.Cell flush className="min-w-120 bg-white ">
          {item.inventory}
        </IndexTable.Cell>
        <IndexTable.Cell flush className="min-w-120 bg-white ">
          {item.price}
        </IndexTable.Cell>
        <IndexTable.Cell flush className="min-w-160 bg-white ">
          {item.weight}
        </IndexTable.Cell>
      </IndexTable.Row>

      {/* for variants */}

      {[1, 2, 3].map((item) => (
        <IndexTable.Row id={item.toString()} key={item} position={item}>
          <IndexTable.Cell
            flush
            className="bg-color-subdued  variant-cell"
          >
            <Box paddingInline='300'>
              <InlineStack gap='200' blockAlign="center" wrap={false}>
                <div style={{ width: "90px" }} ><SkeletonBodyText lines={1} /></div>
                <div style={{ width: "60%" }} ><SkeletonBodyText lines={1} /></div>
              </InlineStack>
            </Box>
          </IndexTable.Cell>
          <IndexTable.Cell flush className="bg-color-subdued">
            <Box paddingInline='300'>
              <div style={{ width: "90%" }} ><SkeletonBodyText lines={1} /></div>
            </Box>
          </IndexTable.Cell>
          <IndexTable.Cell flush className="bg-color-subdued">
            <Box paddingInline='300'>
              <div style={{ width: "90%" }} ><SkeletonBodyText lines={1} /></div>
            </Box>
          </IndexTable.Cell>
          <IndexTable.Cell flush className=" bg-color-subdued">
            <Box paddingInline='300'>
              <div style={{ width: "90%" }} ><SkeletonBodyText lines={1} /></div>
            </Box>
          </IndexTable.Cell>
          <IndexTable.Cell flush className=" bg-color-subdued">
            <Box paddingInline='300'>
              <div style={{ width: "90%" }} ><SkeletonBodyText lines={1} /></div>
            </Box>
          </IndexTable.Cell>
          <IndexTable.Cell flush className=" bg-color-subdued">
            <Box paddingInline='300'>
              <div style={{ width: "90%" }} ><SkeletonBodyText lines={1} /></div>
            </Box>
          </IndexTable.Cell>
          <IndexTable.Cell flush className="bg-color-subdued">
            <Box paddingInline='300'>
              <div style={{ width: "90%" }} ><SkeletonBodyText lines={1} /></div>
            </Box>
          </IndexTable.Cell>
          <IndexTable.Cell flush className=" bg-color-subdued">
            <Box paddingInline='300'>
              <div style={{ width: "90%" }} ><SkeletonBodyText lines={1} /></div>
            </Box>
          </IndexTable.Cell>
        </IndexTable.Row>
      ))}


    </React.Fragment>
  }
  );
}

export default BulkSkeletonRowMarkup

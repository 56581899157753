import { BlockStack, Card, Divider, FormLayout, InlineStack, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from '@shopify/polaris'
import React from 'react'

const CreateTemplateSkeleton = () => {
    return (
        <Layout>
            <Layout.Section>
                <BlockStack gap={"300"}>
                    <Card>
                        <BlockStack gap={"200"}>
                            <BlockStack gap={"400"}>
                                <div className='skeleton-height-11' style={{ "width": "150px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <Divider />
                            </BlockStack>
                            <div className='skeleton-height-32px' style={{ "width": "100%" }}>
                                <SkeletonBodyText lines={1} />
                            </div>
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap={"200"}>
                            <BlockStack gap={"400"}>
                                <div className='skeleton-height-11' style={{ "width": "150px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <Divider />
                            </BlockStack>
                            <InlineStack blockAlign='center' gap={"300"}>
                                <div style={{ "width": "16px" }} className='custom-height_skel'>
                                    <SkeletonBodyText lines={1} />
                                </div>
                                <div style={{ "width": "150px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </InlineStack>
                        </BlockStack>
                    </Card>
                   
                    <Card>
                        <BlockStack gap={"400"}>
                            <BlockStack gap={"400"}>
                                <div className='skeleton-height-11' style={{ "width": "150px" }}>
                                    <SkeletonBodyText lines={1} />
                                </div>
                            </BlockStack>
                            {Array.from({ length: 3 }, () =>
                                <BlockStack gap={"400"}>
                                    <Divider />
                                    <BlockStack gap={"300"}>
                                        <div style={{ "width": "100px" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className='skeleton-height-32px'>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div style={{ "width": "75%" }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </BlockStack>
                                </BlockStack>
                            )}
                        </BlockStack>
                    </Card>
                </BlockStack>
            </Layout.Section>
        </Layout>
    )
}

export default CreateTemplateSkeleton

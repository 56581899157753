import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import sessionStorage from "redux-persist/lib/storage/session";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import encryptTransform from "./encryptReduxFiles";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  transforms: [encryptTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk],
  middleware: (
    getDefaultMiddleware: (arg0: {
      serializableCheck: {
        // Ignore specific actions
        ignoredActions: string[];
        ignoredPaths: string[];
      };
    }) => any
  ) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore specific actions
        ignoredActions: ["persist/PERSIST"],
        ignoredPaths: ["register"],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

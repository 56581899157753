import { environment } from "Src/environment/environment";
import { prepareHeaders } from "../Helper";
import { ObjStrI } from "Src/Interface/@core";

const message = `Sorry, the request was unsuccessful. Please try again later.`;

/**
 * returns url with query params appended
 * @param endpoint url
 * @param paramObj query params object
 * @returns url string with query params
 */
export const getQueryStr = (endpoint: string, paramObj: ObjStrI) => {
  let paramsString = "";
  let params = { ...paramObj };

  if (endpoint.search("\\?") >= 0) paramsString += "&";
  else paramsString += "?";

  for (let i = 0; i < Object.keys(params).length; i++) {
    const end = i < Object.keys(params).length - 1 ? "&" : "";
    paramsString +=
      Object.keys(params)[i] +
      "=" +
      encodeURIComponent(params[Object.keys(params)[i]]) +
      end;
  }
  return paramsString;
};
export const validateRes = (e: { status: any }) => {
  if (e?.status === 401) {
    if (window.name === "app-iframe") {
      sessionStorage.clear();
      window.location.reload();
    }
  }
  return e;
};

/**
 * Makes a GET request to the specified endpoint with the provided parameters.
 *
 * @returns {Function} A function that makes a GET request.
 * @param {string} endpoint - The endpoint to make the GET request to.
 * @param {Object} [params={}] - The parameters to include in the request.
 * @param {boolean} [fullUrl=false] - Whether the endpoint is a full URL. Defaults to false.
 * @returns {Promise<Object>} The response from the GET request.
 */
const GET =
  (): Function =>
  async (
    endpoint: string,
    params: { [name: string]: string } = {},
    fullUrl: boolean = false
  ): Promise<Object> => {
    let url = environment.API_ENDPOINT + endpoint;
    if (fullUrl) {
      url = endpoint;
    }
    let paramsString = getQueryStr(endpoint, params);
    try {
      const res = await fetch(url + paramsString, {
        method: "GET",
        headers: prepareHeaders(),
      });
      const e = await res.json();
      return validateRes(e);
    } catch (e_1) {
      return {
        success: false,
        message: message,
        code: e_1,
      };
    }
  };

/**
 * Makes a POST request to the specified endpoint with the provided body.
 *
 * @returns {Function} A function that makes a POST request.
 * @param {string} endpoint - The endpoint to make the POST request to.
 * @param {Object} body - The body of the POST request.
 * @param {boolean} [fullUrl=false] - Whether the endpoint is a full URL. Defaults to false.
 * @returns {Promise<Object>} The response from the POST request.
 */
const POST =
  (): Function =>
  async (
    endpoint: string,
    body: Object,
    fullUrl: boolean = false,
    isFormData: boolean = false
  ): Promise<Object> => {
    const url = fullUrl ? endpoint : environment.API_ENDPOINT + endpoint;
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: prepareHeaders(),
        body: !isFormData ? JSON.stringify(body) : (body as FormData),
      });
      const e = await res.json();
      return validateRes(e);
    } catch (e_1) {
      return {
        success: false,
        message: message,
        code: e_1,
      };
    }
  };

/**
 * Makes a PUT request to the specified endpoint with the provided body.
 *
 * @returns {Function} A function that makes a PUT request.
 * @param {string} endpoint - The endpoint to make the PUT request to.
 * @param {Object} body - The body of the PUT request.
 * @param {boolean} [fullUrl=false] - Whether the endpoint is a full URL. Defaults to false.
 * @returns {Promise<Object>} The response from the PUT request.
 */
const PUT =
  (): Function =>
  async (
    endpoint: string,
    body: Object,
    fullUrl: boolean = false
  ): Promise<Object> => {
    const url = fullUrl ? endpoint : environment.API_ENDPOINT + endpoint;
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: prepareHeaders(),
        body: JSON.stringify(body),
      });
      const e = await res.json();
      return validateRes(e);
    } catch (e_1) {
      return {
        success: false,
        message: message,
        code: e_1,
      };
    }
  };

/**
 * Makes a PATCH request to the specified endpoint with the provided body.
 *
 * @returns {Function} A function that makes a PATCH request.
 * @param {string} endpoint - The endpoint to make the PATCH request to.
 * @param {Object} body - The body of the PATCH request.
 * @param {boolean} [fullUrl=false] - Whether the endpoint is a full URL. Defaults to false.
 * @returns {Promise<Object>} The response from the PATCH request.
 */
const PATCH =
  (): Function =>
  async (
    endpoint: string,
    body: Object,
    fullUrl: boolean = false
  ): Promise<Object> => {
    const url = fullUrl ? endpoint : environment.API_ENDPOINT + endpoint;
    try {
      const res = await fetch(url, {
        method: "PATCH",
        headers: prepareHeaders(),
        body: JSON.stringify(body),
      });
      const e = await res.json();
      return validateRes(e);
    } catch (e_1) {
      return {
        success: false,
        message: message,
        code: e_1,
      };
    }
  };

/**
 * Makes a DELETE request to the specified endpoint.
 *
 * @returns {Function} A function that makes a DELETE request.
 * @param {string} endpoint - The endpoint to make the DELETE request to.
 * @param {boolean} [fullUrl=false] - Whether the endpoint is a full URL. Defaults to false.
 * @returns {Promise<Object>} The response from the DELETE request.
 */
const DELETE =
  (): Function =>
  async (
    endpoint: string,
    body: Object,
    fullUrl: boolean = false
  ): Promise<Object> => {
    const url = fullUrl ? endpoint : environment.API_ENDPOINT + endpoint;
    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: prepareHeaders(),
        body: JSON.stringify(body),
      });
      const e = await res.json();
      return validateRes(e);
    } catch (e_1) {
      return {
        success: false,
        message: message,
        code: e_1,
      };
    }
  };

export const request = {
  GET: GET,
  POST: POST,
  PUT: PUT,
  PATCH: PATCH,
  DELETE: DELETE,
};

import { createContext, useContext, useMemo, useState } from "react";
interface selectOptionI {
  label: string;
  value: string;
}
interface editModalI {
  isOpen: boolean;
  type: "price" | "inventory" | undefined;
}

interface InitialI {
  loading: boolean;
  setLoading: (e: boolean) => void;
  bulkproduct: any;
  setbulkProduct: (bulkData: any) => void;
  weight_unit: selectOptionI[];
  setWeight_unit: (e: selectOptionI[]) => void;
  updatedData: { [name: string]: any };
  setUpdatedData: (e: { [name: string]: any }) => void;
  error: { [name: string]: any };
  setError: (e: { [name: string]: any }) => void;
  editModal: editModalI;
  setEditModal: (e: editModalI) => void;
  shop_sections: any;
  setShop_section: (e: any) => void;
}

const initialvalue: InitialI = {
  loading: false,
  setLoading: (loading: boolean) => {},
  bulkproduct: {},
  setbulkProduct: (bulkData: any) => {},
  weight_unit: [],
  setWeight_unit: (e: selectOptionI[]) => {},
  updatedData: {},
  setUpdatedData: (e: { [name: string]: any }) => {},
  error: {},
  setError: (e: { [name: string]: any }) => {},
  editModal: { isOpen: false, type: undefined },
  setEditModal: (e: editModalI) => {},
  shop_sections: {},
  setShop_section: (e: any) => {},
};

const BulkEditContext = createContext(initialvalue);

const BulkEditProvider = ({ children }: any) => {
  const [bulkEditContextVal, setBulkEditContextVal] =
    useState<InitialI>(initialvalue);

  const setLoading = (loading: boolean) => {
    setBulkEditContextVal((prevState) => ({ ...prevState, loading }));
  };
  const setbulkProduct = (bulkproduct: any) => {
    setBulkEditContextVal((prevState) => ({ ...prevState, bulkproduct }));
  };
  const setWeight_unit = (weight_unit: any) => {
    setBulkEditContextVal((prevState) => ({ ...prevState, weight_unit }));
  };
  const setUpdatedData = (updatedData: any) => {
    setBulkEditContextVal((prevState) => ({ ...prevState, updatedData }));
  };
  const setError = (error: { [name: string]: any }) => {
    setBulkEditContextVal((prevState) => ({ ...prevState, error }));
  };
  const setEditModal = (editModal: editModalI) => {
    setBulkEditContextVal((prevState) => ({ ...prevState, editModal }));
  };
  const setShop_section = (shop_sections: any) => {
    setBulkEditContextVal((prevState) => ({ ...prevState, shop_sections }));
  };
  const value = useMemo(
    () => ({
      ...bulkEditContextVal,
      setLoading,
      setbulkProduct,
      setWeight_unit,
      setUpdatedData,
      setError,
      setEditModal,
      setShop_section,
    }),
    [bulkEditContextVal]
  );
  return (
    <BulkEditContext.Provider value={value}>
      {children}
    </BulkEditContext.Provider>
  );
};

const useBulkEditContext = () => {
  return useContext(BulkEditContext);
};
export { BulkEditProvider, useBulkEditContext };
